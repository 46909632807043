import Button from '@mui/material/Button';
import React from 'react';
import { hasPermissions } from './AppUtils';
import { Box } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';
import { useContext } from 'react';
import { ThemeContext } from '../framework/theme/themeContext';

const ButtonWithPermission = (props) => {
    const { bypassUser, resource, permissions, action, endIcon } = props
    const [hasPermission, setHasPermission] = React.useState(false);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const {themeObject, setTheme} = useContext(ThemeContext)
    React.useEffect(async () => {
        setHasPermission(permissions ? await hasPermissions(permissions.permissions, { resource, action }) : null)
    }, [permissions])
    console.log(bypassUser, permissions, hasPermission);
    return (
        <Button
            variant="contained"
            disabled={bypassUser ? false : !hasPermission}
            sx={{
                width: isSmall ? "0px" : "210px",
                backgroundColor: themeObject.palette.primary.main,
                boxShadow: "0",
                height: '41px',
                padding: '0',
                "&:hover": {
                    backgroundColor: themeObject.palette.primary.buttonContrast,
                    color: "white",
                    cursor: 'pointer',
                    boxShadow: "0"
                },
                '& .MuiButton-endIcon': {
                    position: 'absolute',
                    right: 0,
                    display: 'none',
                    alignItems: 'center', 
                    justifyContent: 'center',
                    padding: "0",
                    borderBottomRightRadius: "5px",
                    borderTopRightRadius: "5px",
                    backgroundColor: 'rgba(255,255,255,0.2)',
                    fontSize: "16px",
                    width: "41px",
                    height: '41px',
                },
                '& .MuiButton-endIcon svg': {
                    // Adjust SVG size if needed
                    // width: '32px',
                    // height: '41px',
                }
            }}
            {...props}
        >
            {
                isSmall ? <Box sx={{
                    display: 'flex',
                    alignItems: 'center', 
                    justifyContent: 'center',
                    padding: "0",
                    paddingLeft: "2px",
                    borderBottomRightRadius: "5px",
                    borderTopRightRadius: "5px",
                    backgroundColor: 'rgba(255,255,255,0.2)',
                    fontSize: "16px",
                    width: "100%",
                    height: '41px',
                }}>
                {endIcon}  </Box> : <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // border: "1px solid red",
                    width: "100%"
                }}>
                    <Box sx={{
                        textAlign: "center",
                        // border: "1px solid green",
                        width: '100%'
                    }}>
                    {props.children} 
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        padding: "0",
                        paddingLeft: "2px",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        fontSize: "16px",
                        width: "41px",
                        height: '41px',
                    }}>
                    {endIcon}
                    </Box>
                </Box>
            }
            
        </Button>
    )
}
export default ButtonWithPermission
