export const ASSESSMENT_VALUE_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: AssessmentValueFilter!
  ) {
    allAssessmentValues(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        impactNature
        description
        significance
        rating
        assessmentSubTypes {
          id
          name
        }
      }
      totalCount
    }
  }
  `

export const ASSESSMENT_VALUE_RETRIEVE_QUERY = `query($id: Int!) {
    assessmentValue(id: $id) {
        id
        impactNature
        significance
        rating
    }
  }`

export const ASSESSMENT_VALUE_UPDATE_MUTATION = `mutation(
  $id: Int!
  $name: String
  $geography: String
  $fte: Int
  $description: String
  $status: Boolean
  $parentAssessmentValueId: Int
) {
  updateAssessmentValue(
    id: $id
    name: $name
    geography: $geography
    fte: $fte
    description: $description
    status: $status
    parentAssessmentValueId: $parentAssessmentValueId
  ) {
    id
    name
    geography
    fte
    description
    status
    parentAssessmentValueId
  }
}`

export const ASSESSMENT_VALUE_CREATE_MUTATION = `mutation(
    $projectId: Int!
    $assessmentValueId: Int!
    $changeAssessmentValues: [AssessmentValueValueInput!]
  ) {
    createAssessmentValue(
      projectId: $projectId
      assessmentValueId: $assessmentValueId
      changeAssessmentValues: $changeAssessmentValues
    ) {
      id
    }
  }`

export const ASSESSMENT_VALUE_DELETE_MUTATION = `mutation($id: Int!) {
    deleteAssessmentValue(id: $id)
  }`