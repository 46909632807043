import axios from 'axios';
import jwt from 'jsonwebtoken';
import { TokenStorage } from '../utils/TokenStorage';

const powerBiApiBaseUrl = 'https://api.powerbi.com/v1.0/myorg';

const powerBiApi = async (method, endpoint, headers = {}, params = {}) => {
    const accessToken = await checkAccessTokenExpiration();

    try {
        const response = await axios({
            method,
            url: `${powerBiApiBaseUrl}/${endpoint}`,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                ...headers,
            },
            params,
        });
        return { ...response.data, accessToken };
    } catch (error) {
        console.error('Power BI API Error:', error.response?.data || error.message);
        throw error;
    }
};

export default powerBiApi;



export const checkAccessTokenExpiration = async () => {

    const accessToken = TokenStorage.getPbiAccessToken();
    // if (accessToken === 'undefined' || refreshToken === 'undefined') {
    //     TokenStorage.clear();
    //     return;
    // }
    const checkExpiration = () => {
        const decodedToken = jwt.decode(accessToken, { complete: true });

        if (!decodedToken || !decodedToken.payload || !decodedToken.payload.exp) {
            // Token is not valid or does not contain expiration information
            return true;
        }

        // Compare the expiration time with the current time (in seconds)
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const expirationTimestamp = decodedToken.payload.exp;

        return currentTimestamp >= expirationTimestamp;
    };

    const isExpired = checkExpiration();

    if (isExpired) {
        // console.log('scope ....')
        const token = await TokenStorage.getNewPbiToken();
        const { pbi_access_token } = token.data.pbiToken
        return pbi_access_token
    } else {
        return accessToken;
    }

};