export const THEME_CUSTOMIZATION_CREATE_MUTATION = `mutation(
    $url: String
    $theme: String
    $clientId: Int!
  ) {
    createThemeCustomization(
        url: $url
        theme: $theme
        clientId: $clientId
    ) {
        id
        url
        theme
        clientId
    }
  }`
  export const THEME_CUSTOMIZATION_UPDATE_MUTATION = `mutation(
    $id: ID!
    $url: String
    $theme: String
    $clientId: Int!
  ) {
    updateThemeCustomization(
        id: $id
        url: $url
        theme: $theme
        clientId: $clientId
    ) {
        id
        url
        theme
        clientId
    }
  }`


  export const THEME_CUSTOMIZATION_DELETE_MUTATION = `mutation(
    $id: ID!
  ) {
    delteThemeCustomization(
        id: $id
    ) {
        id
        url
        theme
        clientId
    }
  }`

  export const THEME_CUSTOMIZATION_VALUE_RETRIEVE_QUERY = `query($clientId: Int!) {
    getTheme(clientId: $clientId) {
        id
        url
        theme
        clientId
    }
  }`