import PBIPortfolioEmbed from "./pbiPortfolioEmbed";

export const ChangeEnvironmentPortfolioReport = (props) => {
    return (
        <PBIPortfolioEmbed
            title="Change Environment"
            reportId='421050e3-5165-4937-8598-a9e41a955361'
            pageName='ReportSection399d8bf171d16f3acf33'
            resource='Report'
            url='change_environment_portfolio'
        />
    )
}

export default ChangeEnvironmentPortfolioReport;