import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const content_padding = 16;

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40vw',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
        },
        padding: 2 * content_padding
    },
    formContent: {
        marginRight: 16,
        marginLeft: theme.spacing(5),
        [theme.breakpoints.up('md')]: {
            marginRight: 16,
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px',
            marginRight: 0
        },
    },
    fullList: {
        width: 'auto',
    },
    drawerTitleBar: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 16,
        paddingBottom: theme.spacing(5),
        marginLeft: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            margin: '0'
        },

    },
    drawerTitle: {
        flexGrow: 1,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 24,
        color: theme.palette.primary.darker,

    },

}));

export const CustomDrawer = ({ children, title, drawerAnchor, drawerOpen, onClose, }) => {
    const classes = useStyles();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'click') {
            const confirmMessage = window.confirm('The change will be lost. Are you sure you want to close it?');
            if (confirmMessage) {
                console.log("closing the drawer");
                onClose(open)
            }
        }
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        // onClose(open);
    };
    return (
        <Drawer anchor={drawerAnchor} open={drawerOpen} onClose={toggleDrawer(false)}>

            <div className={classes.content}>
                <Box className={classes.drawerTitleBar}>
                    <Typography variant="h6" className={classes.drawerTitle} >{title}</Typography>
                    <IconButton aria-label="close" size="medium" component="span" onClick={() => onClose(false)}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
                <div className={classes.formContent}>{children}</div>
            </div>
        </Drawer>
    );
}

export default CustomDrawer;
