import { Box, Typography, useMediaQuery } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Button from "@mui/material/Button";
import * as React from "react";
import { cloneElement } from "react";
import {
  ChipField,
  Datagrid,
  Filter,
  List,
  SearchInput,
  SelectField,
  SimpleList,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  useDataProvider,
  useListContext,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { YES_NO_CHOICES } from "../../framework/constants";
import { onFailureMessage } from "../../utils/AppUtils";
import PostPagination from "../../utils/PostPagination";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import MoreActions from "../customComponents/moreActions";
import PermissionCreateDrawer from "./permissionCreateDrawer";
import PermissionEditDrawer from "./permissionEditDrawer";
import { ThemeContext } from "../../framework/theme/themeContext";
import { Alert, Snackbar } from "@mui/material";

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    isCustomerAdmin,
    isSuperAdmin,
    maxResults,
    handleCreateDrawerOpen,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreatePermissionBtn {...props} />
    </TopToolbar>
  );
};

const CreatePermissionBtn = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { themeObject } = React.useContext(ThemeContext)
  const { isCustomerAdmin, permissions, isSuperAdmin, handleCreateDrawerOpen } =
    props;
  return (
    <Button
      variant="contained"
      onClick={() => handleCreateDrawerOpen(true)}
      sx={{
        width: isSmall ? "0px" : "210px",
        marginRight: "8px",
        padding: '0px',
        boxShadow: "0",
        backgroundColor: themeObject.palette.primary.main,
        "&:hover": {
          backgroundColor: themeObject.palette.primary.buttonContrast,
          color: "white",
          cursor: "pointer",
          boxShadow: "0",
        },
      }}
    >
      {isSmall ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0",
            paddingLeft: "2px",
            borderBottomRightRadius: "5px",
            borderTopRightRadius: "5px",
            backgroundColor: "rgba(255,255,255,0.2)",
            fontSize: "16px",
            width: "100%",
            height: "41px",
          }}
        >
          <AddIcon style={{ marginLeft: "-4px" }} />
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
            }}
          >
            {"Create Permission"}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0",
              paddingLeft: "2px",
              borderBottomRightRadius: "5px",
              borderTopRightRadius: "5px",
              backgroundColor: "rgba(255,255,255,0.2)",
              fontSize: "16px",
              width: "41px",
              height: "41px",
            }}
          >
            <AddIcon style={{ marginLeft: "-4px" }} />
          </Box>
        </Box>
      )}
    </Button>
  );

  // <Button label="" variant="contained" endIcon={<AddIcon />} onClick={() => handleCreateDrawerOpen(true)} />
};

const Empty = (props) => {
  const { basePath, resource } = useListContext();
  return (
    <Box textAlign="center" m={1} p={1}>
      <Typography variant="h4" paragraph>
        No Projects yet.
      </Typography>
      <Typography variant="body1">Do you want to add one?</Typography>
      <Box mt={1}>
        <CreatePermissionBtn {...props} />
      </Box>
    </Box>
  );
};

const PermissionFilter = (props) => (
  <Filter {...props}>
    <SearchInput variant="outlined" source="q" alwaysOn />
  </Filter>
);

export const PermissionList = (props) => {
  const [createDrawerOpen, setCreateDrawerOpen] = React.useState();
  const [editDrawerOpen, setEditDrawerOpen] = React.useState();
  const [record, setRecord] = React.useState();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const user = TokenStorage.getUserDetails();
  const { isSuperAdmin, isCustomerAdmin } = user ? user : {};
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({ message: name, type: type })
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  const [anchorOrigin, setAnchorOrigin] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = React.useState({
    message: "",
    type: ""
  });

  const { vertical, horizontal } = anchorOrigin;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleCreateDrawerOpen = (open) => {
    setCreateDrawerOpen(open);
  };

  const handleCreateDrawerClose = () => {
    setCreateDrawerOpen(false);
  };

  const handleEditDrawerOpen = (open) => {
    setEditDrawerOpen(open);
  };

  const handleEditDrawerClose = (open) => {
    setRecord(null);
    setEditDrawerOpen(open);
  };

  const rowActionsChoices = [
    { id: "edit", name: "Edit", resource: "Permission", action: ["edit"] },
    {
      id: "delete",
      name: "Delete",
      resource: "Permission",
      action: ["delete"],
    },
  ];

  const handleMoreActionsClick = ({ action, record }) => {
    switch (action) {
      case "edit":
        setRecord(record);
        handleEditDrawerOpen(true);
        break;
      case "delete":
        const proceed = window.confirm(
          "You are deleting the entry. Are you sure?"
        );
        if (proceed) {
          dataProvider
            .delete("Permission", { id: record.id })
            .then((response) => {
              handleSnackbarOpen("Permission deleted successfully", "success");
              refresh();
            })
            .catch((error) => {
              const error_messages = onFailureMessage(error);
              handleSnackbarOpen(error_messages, "error");
            });
        }
        break;
    }
  };

  const ActionField = ({ record }) => {
    if (record && record.action) {
      return record.action.map((item, index) => (
        <ChipField
          record={record.action}
          size="small"
          source={index}
          style={{
            backgroundColor: "#0ab39c",
            color: "#fff",
            fontWeight: "500",
          }}
        />
      ));
    }

    return null;
  };
  const ActionFieldSmallSc = () => {
    const records = useRecordContext();
    if (records && records.action) {
      return records.action.map((item, index) => (
        <ChipField
          record={records.action}
          size="small"
          source={index}
          style={{
            backgroundColor: "#0ab39c",
            color: "#fff",
            fontWeight: "500",
          }}
        />
      ));
    }

    return null;
  };
  return (
    <WithPermission
      resource="Permission"
      bypassUser={isSuperAdmin}
      action={["view"]}
      {...props}
    >
      <List
        title="Permission"
        actions={
          <ListActions
            isCustomerAdmin={isCustomerAdmin}
            isSuperAdmin={isSuperAdmin}
            permissions={props.permissions}
            handleCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
        empty={
          <Empty
            isCustomerAdmin={isCustomerAdmin}
            isSuperAdmin={isSuperAdmin}
            permissions={props.permissions}
            handleCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
        filters={<PermissionFilter />}
        bulkActionButtons={false}
        {...props}
        pagination={<PostPagination />}
      >
        {isSmall ? (
          // <SimpleList
          //     primaryText={record => record.name}
          //     // secondaryText={record => `${record.views} views`}
          //     tertiaryText={record => record.resource}
          //     // tertiaryText={record => new Date(record.published_at).toLocaleDateString('en-GB')}
          //     linkType={record => record.canEdit ? "edit" : "show"}
          // />
          <Datagrid>
            <Box
              display="flex"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <TextField label="Name" source="name" sortable={true} />
              <Box>
                <ActionFieldSmallSc
                  label="Action"
                  source="action"
                  sortable={true}
                />
              </Box>
            </Box>
          </Datagrid>
        ) : (
          <Datagrid>
            {/*<TextField source="id" sortable={false}/>*/}
            <TextField label="Name" source="name" sortable={true} />
            <TextField label="Resource" source="resource" sortable={true} />
            <ActionField label="Action" source="action" sortable={true} />
            <SelectField
              label="Allow"
              source="allow"
              optionText={
                <ChipField
                  source="name"
                  style={{ backgroundColor: "#299CDB", color: "#fff" }}
                  size="small"
                />
              }
              choices={YES_NO_CHOICES}
              sortable={true}
            />
            <TextField
              label="Description"
              source="description"
              sortable={true}
            />
            <MoreActions
              choices={rowActionsChoices}
              bypassUser={isSuperAdmin}
              permissions={props.permissions}
              handleClose={() => console.log("closing")}
              handleClick={handleMoreActionsClick}
            />
          </Datagrid>
        )}
      </List>
      <PermissionCreateDrawer
        record={record}
        drawerOpen={createDrawerOpen}
        onClose={handleCreateDrawerClose}
        handleSnackbarOpen={handleSnackbarOpen}
      />
      <PermissionEditDrawer
        record={record}
        drawerOpen={editDrawerOpen}
        onClose={handleEditDrawerClose}
        handleSnackbarOpen={handleSnackbarOpen}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
        <Alert

          onClose={hanndleSnackbar}
          severity={popupMessage.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {popupMessage.message}
        </Alert>
      </Snackbar>
    </WithPermission>
  );
};

export default PermissionList;
