import {
    Box, Button, Divider,
    Grid,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    Tooltip,
    Typography,
    useMediaQuery,
    withStyles
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from 'react';
import {
    AutocompleteArrayInput, AutocompleteInput, DateInput,
    FormDataConsumer,
    Loading,
    RadioButtonGroupInput,
    ReferenceArrayInput, ReferenceInput,
    TextInput,
    Title,
    maxLength,
    required,
    useDataProvider,
    usePermissions,
    useRefresh
} from "react-admin";
import { Form, useForm } from "react-final-form";
import { PROJECT_DRIVERS_MASTER_DATA_ID, PROJECT_PHASE_MASTER_DATA_ID, STATUS_INACTIVE_ACTIVE_CHOICES } from "../../framework/constants";
import palette from "../../framework/theme/palette";
import { handleProjectInfo, impersonatedURL, onFailureMessage } from "../../utils/AppUtils";
import { TokenStorage } from '../../utils/TokenStorage';
import WithPermission from '../../utils/WithPermission';
import { EnvironmentAssessment } from "../impactAssesment/environmentAssessment";
import { ImpactAssesment } from "../impactAssesment/impactAssesment";
import { ProjectFormButton } from "./ProjectFormButton";
import CreateMilestone from "./createMilestone";
import ForecastToolbar from "./projectForecasts";
import { Alert, Snackbar } from "@mui/material";


const useStyles = makeStyles((theme) => ({
    root: {
        "&.MuiTableCell-root": {
            padding: 50,
        }
    },
    title: {
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    },
    labelDisbale: {
        display: 'none'
    },
    subTitle: {
        margin: '16px',
    },
    inputWidthMargin: {
        marginRight: 30,
    },
    referenceInputWidth: {
        width: 350,
    },
    stepperSection: {
        backgroundColor: palette.customsColor.creamium,
        padding: "14px 16px",
        borderRadius: "5px"
        // boxShadow: "0 0 10px rgba(0,0,0,0.1)"
    },
    referenceInputWidthOvr: {
        width: '100%',
        // paddingRight: '15px'
    },
    phaseDateWidth: {
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    },
    phaseDateInput: {
        marginLeft: "15px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0px"
        }
    },
    bottomCardTitle: {
        backgroundColor: "#E0E0E0",
    },
    inputWidthChange: {
        width: '33vw',
        [theme.breakpoints.down("xs")]: {
            width: '83vw',
        }
    },
    textAreaInput: {
        minHeight: '80px',
        lineHeight: 1.5
    },
    rowHeading: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 16,
        color: "#4F4F4F",
    },
    forecastRiskBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        width: 60,
        height: 30,
        opacity: 0.3,
        borderRadius: 4,
        "&.active": {
            opacity: 1,
        },
        "&.risk-high": {
            backgroundColor: "#EB5757",
        },
        "&.risk-medium": {
            backgroundColor: "#F2994A",
        },
        "&.risk-low": {
            backgroundColor: "#27AE60",
        },
    },
    forecastRiskBoxCSR: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        width: "100%",
        height: 20,
        opacity: 0.3,
        // borderRadius: 4,
        "&.active": {
            opacity: 1,
        },
        "&.risk-high": {
            backgroundColor: "#EB5757",
        },
        "&.risk-medium": {
            backgroundColor: "#F2994A",
        },
        "&.risk-low": {
            backgroundColor: "#27AE60",
        },
    },
    changeImpactBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        width: 200,
        height: 30,
        opacity: 0.3,
        borderRadius: 4,
        "&.active": {
            opacity: 1,
        },
        "&.risk-high": {
            backgroundColor: "#EB5757",
        },
        "&.risk-medium": {
            backgroundColor: "#F2994A",
        },
        "&.risk-low": {
            backgroundColor: "#27AE60",
        },
    },
    borderRight: {
        borderRight: "2px solid #C4C4C4",
    },
    borderBottom: {
        borderBottom: "2px solid #C4C4C4",
    },
    borderRightCSR: {
        borderRight: "2px solid white",
        zIndex: -1
    },
    borderBottomCSR: {
        borderBottom: "2px solid white",
    },
    tableHeading1: {
        backgroundColor: "#4473c4",
    },
    tableHeading2: {
        backgroundColor: "#F2F2F2",
    },
    responsiveBox: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            marginBottom: '5px',
            marginLeft: '0px',
        },
    },
    responsiveTable: {
        [theme.breakpoints.down('sm')]: {
            width: `calc(100vw - 63px)`,
        },
    },
    columnFreezed: {
        position: 'sticky',
        left: 0,
        // zIndex: 999,
        borderRight: '2px solid #C4C4C4',
        backgroundColor: '#F2F2F2'
    },
    columnFreezed1: {
        position: 'sticky',
        left: 0,
        // zIndex: 2000,
        borderRight: '2px solid #C4C4C4',
        borderBottom: '2px solid #C4C4C4',
        backgroundColor: '#fff'
    },
    columnLowPriority: {
        borderRight: "2px solid #C4C4C4",
        zIndex: -1,
    },
    gridContainer: {
        padding: '0 10px',
        margin: '0 10px',
        display: "grid",
        gridColumnGap: 100,
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
        },
        [theme.breakpoints.between("sm", "md")]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
        },
    },
    actionToolbar: {
        display: 'flex',
        marginTop: 40,
        justifyContent: 'flex-end'
    },
    stepperHeader: {
        background: "none",
        padding: '10px 0',
        marginBottom: 60,
        [theme.breakpoints.down("sm")]: {
            overflowX: "auto",
            height: "100%"
        },
        borderBottom: '3px solid #dadde9',
        '& .MuiStepper-horizontal': {
            overflowX: 'auto'
        },
    },

    activeStep: {
        color: '#f0f',
    },
    selectedAssessment: {
        margin: '0 5px',
        padding: "3px 3px 3px 3px",

        textAlign: 'center',
        "&.activeRed": {
            border: '2px solid #EB5757',
            boprderRadius: '10px',
        },
        "&.activeGreen": {
            border: '2px solid #27AE60',
            boprderRadius: '10px',
        },
        "&.activeAmber": {
            border: '2px solid #F2994A',
            boprderRadius: '10px',
        },
    },
}));

function getSteps() {
    return [
        "Project Details",
        "Milestone",
        "Change Impact",
        "Change Environment",
        "Heatmap"
    ];
}
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
function getStepContent(step, handleBack, handleNext, steps, isUpdateProjectDetails, setIsUpdateProjectDetails, setActiveStep) {
    switch (step) {
        case 0:
            return <ProjectDetails
                activeStep={step}
                handleBack={handleBack}
                handleNext={handleNext}
                steps={steps}
                setIsUpdateProjectDetails={setIsUpdateProjectDetails}
                setActiveStep={setActiveStep}
            />;
        case 1:
            return <CreateMilestone
                record={isUpdateProjectDetails}
                activeStep={step}
                handleBack={handleBack}
                handleNext={handleNext}
                steps={steps}
                setIsUpdateProjectDetails={setIsUpdateProjectDetails}
            //                     projectPhases={projectPhases}
            //                 // assessmentTypes={sectionData.assessmentTypes}
            />
        case 2:
            return <ImpactAssesment record={isUpdateProjectDetails}
                activeStep={step}
                handleBack={handleBack}
                handleNext={handleNext}
                steps={steps}
                setIsUpdateProjectDetails={setIsUpdateProjectDetails} />
        case 3:
            return <EnvironmentAssessment record={isUpdateProjectDetails}
                activeStep={step}
                handleBack={handleBack}
                handleNext={handleNext}
                steps={steps}
                setIsUpdateProjectDetails={setIsUpdateProjectDetails} />

        case 4:
            return <ForecastToolbar
                activeStep={step}
                handleBack={handleBack}
                handleNext={handleNext}
                steps={steps} />
        default:
            return "unknown step";
    }
}
export const ProjectCreate = (props) => {
    // console.log(props)
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState({})
    const user = TokenStorage.getUserDetails();
    const getPorject = TokenStorage.getProjectDetails();
    const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};
    const [usesss, setNewUser] = useState(['sdklfjasld'])
    const { loading: loadingPermissions, permissions } = usePermissions();
    const [activeStep, setActiveStep] = useState(0);
    const [skippedSteps, setSkippedSteps] = useState([]);
    const steps = getSteps();
    const [completed, setCompleted] = React.useState({});
    const [isUpdateProjectDetails, setIsUpdateProjectDetails] = React.useState({});
    const [businessUnitId, setBusinessUnitId] = React.useState();
    const localProjectDetails = TokenStorage.getProjectDetails()
    const [showProjectInfo, setShowProjectInfo] = useState("");
    const [proojectExists, setProjectExits] = useState({})
    const dataProvider = useDataProvider();
    const handleNext = (data) => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    useEffect(() => {
        if (getPorject?.id) {
            handleProjectInfo(getPorject, activeStep, setShowProjectInfo)
        }
    }, [activeStep])

    React.useEffect(() => {
        if (localProjectDetails !== undefined && localProjectDetails !== null) {
            dataProvider
                .getOne("Project", { id: localProjectDetails?.id })
                .then((response) => {
                    console.log(response.data)
                    setActiveStep(response.data.stepCount)
                    setProjectExits(response.data)

                })
                .catch((error) => {
                    console.log(error)
                });
        }

    }, [])
    return (
        <WithPermission resource="Project" permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <Title className={classes.title} title={`${getPorject ? "Edit Project" : "Create Project"}`} />
            <Typography style={{ padding: '0 0 10px 0', margin: "0 0 10px 0" }}>{showProjectInfo}</Typography>
            <div className={classes.stepperSection}>


                <Stepper alternativeLabel nonLinear activeStep={activeStep} className={classes.stepperHeader}>
                    {steps.map((step, index) => {
                        return (
                            <Step key={index}>
                                {
                                    (!proojectExists?.isDraft) && (proojectExists?.status) ? <StepButton onClick={handleStep(index)} completed={completed[index]}>
                                        {step}
                                    </StepButton> : <StepLabel>{step}</StepLabel>
                                }

                                {/* <StepButton color="#f0f" onClick={handleStep(index)} className={activeStep === index ? classes.activeStep : ''}>
                                    {step}
                                </StepButton> */}
                                {/* <StepLabel>{step}</StepLabel> */}
                                {/* <StepLabel {...labelProps}>{step}</StepLabel> */}
                            </Step>
                        );
                    })}
                </Stepper>

                {activeStep === steps.length ? (
                    <Typography variant="h3" align="center">
                        Thank You
                    </Typography>
                ) : (
                    <div>
                        {getStepContent(activeStep, handleBack, handleNext, steps, isUpdateProjectDetails, setIsUpdateProjectDetails, setActiveStep)}
                    </div>

                )}
            </div>
        </WithPermission>
    )
}

const ProjectDetails = ({ activeStep, handleBack, handleNext, steps, setIsUpdateProjectDetails, setActiveStep }) => {
    const classes = useStyles();
    const user = TokenStorage.getUserDetails();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [projectDriverChoices, setProjectDriverChoices] = React.useState();
    const [projectDriversLoading, setProjectDriversLoading] = React.useState();
    const [driverName, setDriverName] = React.useState();
    const dataProvider = useDataProvider();

    const [singleProject, setSingleProject] = React.useState({});
    const [showImpBus, setShowImpBus] = React.useState(false);
    const localProjectDetails = TokenStorage.getProjectDetails()
    const [projectId, setProjectId] = React.useState();
    const [projectPhases, setProjectPhases] = React.useState();
    const [projectPhasesLoading, setProjectPhasesLoading] = React.useState(false);
    const [projectPhasesError, setProjectPhasesError] = React.useState(false);
    const [projectInfo, setProjectInfo] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [pstatus, setPstatus] = React.useState("");
    const [cstatus, setCtatus] = React.useState("");
    const [changeStatusError, setChangeStatusError] = useState("");
    const [projectStatusError, setProjectStatusError] = useState("");
    const refresh = useRefresh();
    const resource = "Project";
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setSnackbarOpen(true);
    };

    const hanndleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = React.useState({
        message: "",
        type: ""
    });

    const { vertical, horizontal } = anchorOrigin;
    const { isImpersonating } = user ? user : {};

    React.useEffect(() => {
        setProjectPhasesLoading(true);
        dataProvider
            .getOne("MasterData", { id: PROJECT_PHASE_MASTER_DATA_ID })
            .then((response) => {
                setProjectPhases(response.data.masterDataValues);
                // setNewProjectPhases(response.data.masterDataValues);
                setProjectPhasesLoading(false);
            })
            .catch((error) => {
                console.log(error)
                handleSnackbarOpen("Project phases are unavailable", "warning");
                setProjectPhasesError(error.message);
                setProjectPhasesLoading(false);
            });
    }, []);

    React.useEffect(() => {
        if (localProjectDetails !== undefined && localProjectDetails !== null) {
            dataProvider
                .getOne("Project", { id: localProjectDetails?.id })
                .then((response) => {
                    console.log(response.data)
                    const impactedBusinessUnits = _.map(
                        response.data.impactedBusinessUnits,
                        "id"
                    );
                    setSingleProject({
                        ...response.data,
                        impactedBusinessUnits,
                    });
                    console.log(impactedBusinessUnits)
                    setCtatus(response.data.changeStatus)
                    setPstatus(response.data.projectStatus)
                    // setActiveStep(response.data.stepCount)
                    response.data.businessUnitId ? setShowImpBus(true) : setShowImpBus(false)

                })
                .catch((error) => {
                    console.log(error)
                });
        }

    }, [])
    const handleSteper = async (params, isNext) => {
        const stepers = {
            id: params.id,
            isDraft: true,
            stepCount: 1
        }
        dataProvider
            .postRequest(resource, {
                queryType: "updateStep",
                data: stepers,
            })
            .then((response) => {
                // form.restart();
                console.log(response)
                // onClose(false, true);
                if (!isNext) {
                    TokenStorage.clearProjectData()
                    impersonatedURL(isImpersonating, '#/project?impersonated')
                }
                handleNext(response.data)
                // refresh();
                // createMilestoneView()
            })
            .catch((error) => {
                console.log(error)
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error")
            });
    }
    const validate = (values) => {

    }
    const onSubmit = (values, form) => {
        const phases =
            values.projectPhases && values.projectPhases.length > 0
                ? values.projectPhases
                    .filter((x) => x && x.endDate != null && x.endDate != "")
                    .map((projectPhase, index) => ({
                        name: projectPhases[index].name,
                        startDate: projectPhase.startDate,
                        endDate: projectPhase.endDate,
                    }))
                : null;
        values.projectStatus = pstatus;
        values.changeStatus = cstatus;
        // values.isDraft = true;

        if (pstatus === '') {
            setProjectStatusError("required")

        }
        if (cstatus === "") {
            setChangeStatusError("required")

        }
        if (values?.id !== undefined && (pstatus !== '' && cstatus !== '')) {
            const { isNext, ...projectValues } = values;
            dataProvider
                .update(resource, {
                    data: {
                        ...projectValues,
                        projectPhases: phases,
                    },
                }).then(async (response) => {
                    // console.log(response)

                    setIsUpdateProjectDetails(response.data)
                    if (!isNext) {
                        await handleSteper(response.data, values)
                    }
                    else {
                        handleNext(response.data)
                    }

                }).catch((error) => {
                    console.log(error)
                    const error_messages = onFailureMessage(error);
                    handleSnackbarOpen(error_messages, "error")
                });
        }
        else if (values?.id == undefined && pstatus !== '' && cstatus !== '') {
            values.isDraft = true;
            const { isNext, ...projectValues } = values;
            dataProvider
                .create(resource, {
                    data: {
                        ...projectValues,
                        projectPhases: phases,
                    },
                })
                .then(async (response) => {
                    // form.restart();
                    // onClose(false);
                    console.log(response)
                    // refresh();
                    TokenStorage.storeProjectDetails(JSON.stringify(response.data))
                    values.id = response.data.id
                    setIsUpdateProjectDetails(response.data)
                    // handleNext(values)
                    await handleSteper(response.data, isNext)

                })
                .catch((error) => {
                    console.log(error)
                    const error_messages = onFailureMessage(error);
                    handleSnackbarOpen(error_messages, "error")
                });
        }


    };
    const handleChange = (event) => {
        // setValue(event.target.value);
        setPstatus(event.target.value)
    };
    const validateStartDate = (startDate, values) => {
        const endDate = values.endDate;
        if (startDate && endDate && startDate >= endDate) {
            return "start date can not be greater than end date";
        }
    };

    const validateEndDate = (endDate, values) => {
        const startDate = values.startDate;
        if (endDate && startDate && endDate <= startDate) {
            return "end date can not be lesser than start date";
        }
    };

    const validateGoLiveDate = (goLiveDate, values) => {
        const startDate = values.startDate;
        const endDate = values.endDate;
        if (goLiveDate < startDate || goLiveDate > endDate) {
            return "Go live Date should be in between start date and end date. ";
        }
    };

    const handleBuChange = () => {
        console.log(showImpBus);
        setShowImpBus(true);
        console.log(showImpBus);
    }

    React.useEffect(() => {
        dataProvider
            .getOne("ProjecDriverMasterData", { id: PROJECT_DRIVERS_MASTER_DATA_ID })
            .then(({ data }) => {
                setProjectDriverChoices(data.masterDataValues)
                setProjectDriversLoading(false)
            })
            .catch(error => {
                console.log(error);
                setProjectDriversLoading(false)
                setProjectDriverChoices([])
            })
    }, [])


    const validatePhaseStartDate = (value, values, index) => {
        try {
            const startDate = value ? new Date(value) : null;
            const projectStartDate = values.startDate
                ? new Date(values.startDate)
                : null;
            const endDate = values.projectPhases[index].endDate
                ? new Date(values.projectPhases[index].endDate)
                : null;
            const prePhaseIndex = Math.max(index - 1, 0);
            const prePhaseEndDate = values.projectPhases[prePhaseIndex].endDate
                ? new Date(values.projectPhases[prePhaseIndex].endDate)
                : null;
            if (startDate && endDate && startDate >= endDate) {
                return "should be lesser than end date";
            }
            if (startDate && startDate < projectStartDate) {
                return `should be greater than "${projectStartDate.toLocaleDateString('en-GB')}"`;
            }
            if (
                index > 0 &&
                startDate &&
                prePhaseEndDate &&
                startDate <= prePhaseEndDate
            ) {
                return `should be greater than "${prePhaseEndDate.toLocaleDateString('en-GB')}"`;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const validatePhaseEndDate = (value, values, index) => {
        try {
            const endDate = value ? new Date(value) : null;
            const projectEndDate = values.endDate ? new Date(values.endDate) : null;
            const startDate = values.projectPhases[index].startDate
                ? new Date(values.projectPhases[index].startDate)
                : null;
            if (endDate && startDate && endDate <= startDate) {
                return "should be greater than start date";
            }
            if (endDate && endDate > projectEndDate) {
                return `should be lesser than "${projectEndDate.toLocaleDateString('en-GB')}"`;
            }
            if (startDate && !endDate) {
                return "end date is required";
            }
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <>
            {
                projectPhases ?
                    <Form onSubmit={onSubmit}
                        validate={validate}
                        initialValues={singleProject}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>

                                <Grid container spacing={2}>
                                    {
                                        (singleProject.isDraft !== undefined) ? <>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <span>Project Name</span>
                                                <TextInput
                                                    variant="outlined"
                                                    // label="Project Name"
                                                    source="name"
                                                    // id="filled-full-width"
                                                    reference=""
                                                    label={false}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    placeholder="Enter project name"
                                                    validate={[required(), maxLength(255)]}
                                                    // className={classes.inputWidthMargin}
                                                    className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.inputWidthChange,
                                                            notchedOutline: classes.inputBorder,
                                                        },
                                                    }}
                                                // onChange={(e) => { console.log(e.target.value) }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6} md={6}>
                                                <RadioButtonGroupInput
                                                    label="Status"
                                                    source="status"
                                                    row={true}
                                                    choices={STATUS_INACTIVE_ACTIVE_CHOICES}
                                                    validate={[required()]}
                                                />
                                            </Grid>
                                        </> : <Grid item xs={12} md={12} lg={12}>
                                            <span>Project Name</span>
                                            <TextInput
                                                variant="outlined"
                                                // label="Project Name"
                                                source="name"
                                                // id="filled-full-width"
                                                reference=""
                                                label={false}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder="Enter project name"
                                                validate={[required(), maxLength(255)]}
                                                // className={classes.inputWidthMargin}
                                                className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.inputWidthChange,
                                                        notchedOutline: classes.inputBorder,
                                                    },
                                                }}
                                            // onChange={(e) => { console.log(e.target.value) }}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={12} md={6} lg={6}>
                                        <span>Project Sponsor</span>
                                        <ReferenceInput
                                            variant="outlined"
                                            label={false}
                                            source="projectSponsorId"
                                            reference="User"
                                            // validate={[required()]}
                                            className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                        >
                                            <AutocompleteInput optionText="fullName" />
                                        </ReferenceInput>
                                    </Grid>
                                    {/*  <Grid className={classes.gridBoxMargin}> */}
                                    <Grid item xs={12} md={6} lg={6}>
                                        <span>Business Owner</span>
                                        <ReferenceInput
                                            variant="outlined"
                                            label={false}
                                            source="businessOwnerId"
                                            reference="User"
                                            // validate={[required()]}
                                            className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                        >
                                            <AutocompleteInput optionText="fullName" />
                                        </ReferenceInput>
                                    </Grid>


                                    <Grid item xs={12} md={6} lg={6}>
                                        {/* <div onClick={console.log("hello bussss")}> */}
                                        <span>Strategic Driver</span>
                                        <AutocompleteInput
                                            variant="outlined"
                                            label={false}
                                            source="projectDriver"
                                            optionValue="name"
                                            optionText="name"
                                            className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                            choices={projectDriverChoices}
                                            validate={[required()]}
                                            onChange={(project) => setDriverName(project)}
                                        />
                                        {/* </div> */}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <span>Strategic Driver (Display Name)</span>
                                        <TextInput
                                            variant="outlined"
                                            label={false}
                                            source="driverDisplayName"
                                            id="filled-full-width"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder="Enter project name"
                                            initialValue={driverName}
                                            validate={[required(), maxLength(255)]}
                                            // className={classes.inputWidthMargin}
                                            className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputWidthChange,
                                                    notchedOutline: classes.inputBorder,
                                                },
                                            }}
                                        // onChange={(e) => { console.log(e.target.value) }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <span>Start Date</span>
                                        <DateInput
                                            variant="outlined"
                                            label={false}
                                            source="startDate"
                                            validate={[required(), validateStartDate]}
                                            className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputWidthChange,
                                                    notchedOutline: classes.inputBorder,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    {/*  <Grid className={classes.gridBoxMargin}> */}
                                    <Grid item xs={12} md={6} lg={4}>
                                        <span>End Date</span>
                                        <DateInput
                                            variant="outlined"
                                            label={false}
                                            source="endDate"
                                            className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                            validate={[required(), validateEndDate]}
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputWidthChange,
                                                    notchedOutline: classes.inputBorder,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <span>Go Live Date</span>
                                        <DateInput
                                            variant="outlined"
                                            label={false}
                                            source="goLiveDate"
                                            className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                            validate={[required(), validateGoLiveDate]}
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputWidthChange,
                                                    notchedOutline: classes.inputBorder,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Box style={{ width: '100%', backgroundColor: '#c4cdd5' }}>

                                        <Divider variant="fullWidth" />
                                    </Box>
                                    <Typography className={classes.subTitle}>Project Phases</Typography>
                                    <Grid container className={classes.gridContainer} spacing={2}>
                                        {
                                            projectPhases?.map((projectPhase, index) => (
                                                <div key={projectPhase.id}>
                                                    {/*  <Grid className={classes.gridBoxMargin}> */}
                                                    <Grid>
                                                        <TextInput
                                                            variant="outlined"
                                                            label={`Phase ${index + 1}`}
                                                            source={`projectPhases[${index}].name`}
                                                            initialValue={projectPhase.name}
                                                            value={projectPhase.name}
                                                            disabled={true}
                                                            style={{ width: isSmall && "100%" }}
                                                            // validate={[required(), maxLength(255)]}
                                                            validate={[maxLength(255)]}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.phaseDateWidth,
                                                                    notchedOutline: classes.inputBorder,
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    {/*  <Grid className={classes.gridBoxMargin}> */}
                                                    <Grid >
                                                        <Box style={{ display: "flex", flexDirection: isSmall ? "column" : "row" }}>
                                                            <DateInput
                                                                variant="outlined"
                                                                label="Start Date"
                                                                source={`projectPhases[${index}].startDate`}
                                                                className={classes.phaseDateInput}
                                                                validate={[
                                                                    // required(),
                                                                    (value, values) => validatePhaseStartDate(value, values, index),
                                                                ]}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.phaseDateWidth,
                                                                        notchedOutline: classes.inputBorder,
                                                                    },
                                                                }}
                                                            />
                                                            <DateInput
                                                                variant="outlined"
                                                                label="End Date"
                                                                source={`projectPhases[${index}].endDate`}
                                                                className={classes.phaseDateInput}
                                                                validate={[
                                                                    // required(),
                                                                    (value, values) => validatePhaseEndDate(value, values, index),
                                                                ]}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.phaseDateWidth,
                                                                        notchedOutline: classes.inputBorder,
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {/* <h1>{projectPhase.name}</h1> */}
                                                </div>
                                            ))}
                                    </Grid>


                                    <Box style={{ width: '100%', backgroundColor: '#c4cdd5' }}>

                                        <Divider variant="fullWidth" />
                                    </Box>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <span>Change Lead</span>
                                        <ReferenceInput
                                            variant="outlined"
                                            label={false}
                                            source="changeLeaderId"
                                            reference="User"
                                            validate={[required()]}
                                            fullWidth
                                        >
                                            <AutocompleteInput optionText="fullName" />
                                        </ReferenceInput>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Typography variant="body1" style={{ display: 'block', textAlign: 'center' }}>Project Status</Typography>
                                        <Box style={{ display: 'flex', justifyContent: 'center' }}>

                                            <Box className={clsx(classes.selectedAssessment, {
                                                'activeGreen': pstatus === "green"
                                            })}>
                                                <HtmlTooltip
                                                    title={
                                                        <Box>
                                                            <Typography color="inherit">All project milestones are on track</Typography>
                                                        </Box>
                                                    }
                                                >
                                                    <Button style={{ backgroundColor: '#27AE60', width: '80px', height: '41px', opacity: pstatus === 'green' ? 1 : '0.7', }}
                                                        onClick={() => setPstatus("green")}
                                                    >
                                                        GREEN
                                                    </Button>
                                                </HtmlTooltip>

                                            </Box>


                                            <Box className={clsx(classes.selectedAssessment, {
                                                'activeAmber': pstatus === "amber"
                                            })}>
                                                <HtmlTooltip
                                                    title={
                                                        <Box>
                                                            <Typography color="inherit">One or several major milestones are delayed but delivery end date is not impacted</Typography>
                                                        </Box>
                                                    }
                                                >
                                                    <Button style={{ backgroundColor: '#F2994A', width: '80px', height: '41px', opacity: pstatus === 'amber' ? '1' : '0.7', }}
                                                        onClick={() => setPstatus("amber")}
                                                    >
                                                        AMBER
                                                    </Button>
                                                </HtmlTooltip>

                                            </Box>


                                            <Box className={clsx(classes.selectedAssessment, {
                                                'activeRed': pstatus === "red"
                                            })}>
                                                <HtmlTooltip
                                                    title={
                                                        <Box>
                                                            <Typography color="inherit">One or several major milestones are delayed and delivery end date is impacted</Typography>
                                                        </Box>
                                                    }
                                                >
                                                    <Button style={{ backgroundColor: '#EB5757', width: '80px', height: '41px', opacity: pstatus === 'red' ? '1' : '0.7', }}
                                                        onClick={() => setPstatus("red")}
                                                    >
                                                        RED
                                                    </Button>
                                                </HtmlTooltip>

                                            </Box>

                                        </Box>
                                        <p style={{ color: "#EB5757", textAlign: 'center', margin: '0 5px', padding: '0 15px' }}>{projectStatusError}</p>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Typography variant="body1" style={{ display: 'block', textAlign: 'center' }}>Change Status</Typography>
                                        <Box style={{ display: 'flex', justifyContent: 'center' }}>

                                            <Box className={clsx(classes.selectedAssessment, {
                                                'activeGreen': cstatus === "green"
                                            })}>
                                                <HtmlTooltip
                                                    title={
                                                        <Box>
                                                            <Typography color="inherit">All project milestones are on track</Typography>
                                                        </Box>
                                                    }
                                                >
                                                    <Button style={{ backgroundColor: '#27AE60', width: '80px', height: '41px', opacity: cstatus === 'green' ? 1 : '0.7', }}
                                                        onClick={() => setCtatus("green")}
                                                    >
                                                        GREEN
                                                    </Button>
                                                </HtmlTooltip>

                                            </Box>

                                            <Box className={clsx(classes.selectedAssessment, {
                                                'activeAmber': cstatus === "amber"
                                            })}>
                                                <HtmlTooltip
                                                    title={
                                                        <Box>
                                                            <Typography color="inherit">One or several major milestones are delayed but delivery end date is not impacted</Typography>
                                                        </Box>
                                                    }
                                                >
                                                    <Button style={{ backgroundColor: '#F2994A', width: '80px', height: '41px', opacity: cstatus === 'amber' ? '1' : '0.7', }}
                                                        onClick={() => setCtatus("amber")}
                                                    >
                                                        AMBER
                                                    </Button>
                                                </HtmlTooltip>

                                            </Box>

                                            <Box className={clsx(classes.selectedAssessment, {
                                                'activeRed': cstatus === "red"
                                            })}>
                                                <HtmlTooltip
                                                    title={
                                                        <Box>
                                                            <Typography color="inherit">One or several major milestones are delayed and delivery end date is impacted</Typography>
                                                        </Box>
                                                    }
                                                >
                                                    <Button style={{ backgroundColor: '#EB5757', width: '80px', height: '41px', opacity: cstatus === 'red' ? '1' : '0.7', }}
                                                        onClick={() => setCtatus("red")}
                                                    >
                                                        RED
                                                    </Button>
                                                </HtmlTooltip>

                                            </Box>
                                        </Box>
                                        <p style={{ color: "#EB5757", textAlign: 'center', margin: '0 5px', padding: '0 15px' }}>{changeStatusError}</p>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <span>Project Summary</span>
                                        <TextInput
                                            variant="outlined"
                                            label={false}
                                            source="summary"
                                            id="filled-full-width"
                                            multiline
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder="Enter project summary (max 255 characters)"
                                            validate={[maxLength(255)]}
                                            // className={classes.inputWidthMargin}
                                            className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                            InputProps={{
                                                classes: {
                                                    input: classes.textAreaInput,
                                                    notchedOutline: classes.inputBorder,
                                                },
                                            }}
                                        // onChange={(e) => { console.log(e.target.value) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <span>Project Commentary</span>
                                        <TextInput
                                            variant="outlined"
                                            label={false}
                                            source="commentary"
                                            id="filled-full-width"
                                            multiline
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder="Enter project commentary (max 255 characters)"
                                            validate={[maxLength(255)]}
                                            // className={classes.inputWidthMargin}
                                            className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                            InputProps={{
                                                classes: {
                                                    input: classes.textAreaInput,
                                                    notchedOutline: classes.inputBorder,
                                                },
                                            }}
                                        // onChange={(e) => { console.log(e.target.value) }}
                                        />
                                    </Grid>
                                    {/*  <Grid className={classes.gridBoxMargin}> */}
                                    <Grid item xs={12} md={6} lg={6}>
                                        {/* <div onClick={console.log("hello bussss")}> */}
                                        <span>Driver Business Unit</span>
                                        <BusinessUnitCP setShowImpBus={setShowImpBus} handleSnackbarOpen={handleSnackbarOpen} />
                                        {/* <ReferenceInput
                                            onChange={() => handleBuChange()}
                                            variant="outlined"
                                            label={false}
                                            source="businessUnitId"
                                            reference="BusinessUnit"
                                            validate={[required()]}
                                            className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                        >
                                            <AutocompleteInput optionText="name" />
                                        </ReferenceInput> */}
                                        {/* </div> */}
                                    </Grid>
                                    {/*  <Grid className={classes.gridBoxMargin}> */}
                                    <Grid item xs={12} md={6} lg={6}>
                                        <span>Impacted Business Unit</span>
                                        {
                                            showImpBus ? <FormDataConsumer>
                                                {({ formData, ...rest }) => (
                                                    <ReferenceArrayInput
                                                        fullWidth
                                                        variant="outlined"
                                                        label=""
                                                        source="impactedBusinessUnits"
                                                        reference="BusinessUnit"
                                                        className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                                        filter={{
                                                            // excludeIds:
                                                            //     formData && formData.businessUnitId
                                                            //         ? [formData.businessUnitId]
                                                            //         : null,

                                                        }}
                                                    >
                                                        <AutocompleteArrayInput optionText="name" />
                                                    </ReferenceArrayInput>
                                                )}
                                            </FormDataConsumer> : <TextInput
                                                disabled
                                                source="body"
                                                variant="outlined"
                                                label="Impacted Business Unit"
                                                className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <ProjectFormButton activeStep={activeStep} handleSubmit={handleSubmit} handleBack={handleBack} steps={steps} isDraft={singleProject?.isDraft === undefined ? true : singleProject.isDraft} />
                                <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                                    <Alert

                                        onClose={hanndleSnackbar}
                                        severity={popupMessage.type}
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        {popupMessage.message}
                                    </Alert>
                                </Snackbar>
                            </form>

                        )}
                    />
                    :
                    <Loading />
            }
        </>

    );
};


function BusinessUnitCP({ setShowImpBus, handleSnackbarOpen }) {
    const form = useForm();
    const classes = useStyles();
    const { values } = form.getState();
    return (
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                const handleBuiChange = (event, newValue) => {
                    // Add the newly selected business unit to the impactedBusinessUnits array
                    // console.log(formData)
                    // formData['impactedBusinessUnits'] = [97]
                    // console.log(newValue, formData)
                    // console.log(form)
                    // console.log(form)
                    // console.log(values)
                    if (values && values.impactedBusinessUnits) {
                        const impactedBUs = [...values.impactedBusinessUnits];
                        if (impactedBUs.includes(event)) {

                            form.change('impactedBusinessUnits', impactedBUs)
                        } else {
                            form.change('impactedBusinessUnits', [...impactedBUs, event])
                            handleSnackbarOpen("Impacted business unit updated.", "success")
                        }
                    }
                    else {

                        form.change('impactedBusinessUnits', [event])
                    }

                    // const updatedImpactedBusinessUnits = [...formData.impactedBusinessUnits, newValue.id];
                    // Update the form data with the updated impactedBusinessUnits array
                    setShowImpBus(true);
                    // rest.input.onChange(updatedImpactedBusinessUnits);
                };
                return (
                    <ReferenceInput
                        onChange={handleBuiChange}
                        variant="outlined"
                        label={false}
                        source="businessUnitId"
                        reference="BusinessUnit"
                        validate={[required()]}
                        className={`${classes.referenceInputWidthOvr} ${classes.inputWidthMargin}`}
                    >
                        <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
    )
}