
export const CHANGE_ASSESSMENT_MASTERS_BY_TYPE_LIST_QUERY = `query($type: [String!]) {
    getAllChangeAssessmentMastersByType (type: $type) {
      id
      name
      type
      description
      dimensionWeight
      impactLevels {
        id
        name
        description
        dimensionScore
        }
    }
  }`

export const CHANGE_ASSESSMENT_MASTER_UPDATE_MUTATION = `mutation(
  $id: ID!
  $description: String!
  $impactLevels: [ImpactLevelInput!]!
){
  updateChangesAssessmentMaster(
  id: $id
  description: $description
  impactLevels: $impactLevels
  ){
    id
    name
    type
    description
    impactLevels {
      id
      name
      description
    }
  }
}`