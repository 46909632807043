import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
import moment from "moment";
import React from "react";

class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    componentDidUpdate() {
        this.myChart.data.labels = this.props.chartData.labels;
        this.myChart.data.datasets = [...this.props.chartData.datasets.milestonesData, ...this.props.chartData.datasets.phasesData];
        this.myChart.update();
    }

    componentDidMount() {
        this.myChart = new Chart(this.canvasRef.current, {
            type: 'bar',
            data: {
                labels: this.props.chartData.labels,
                datasets: [...this.props.chartData.datasets.milestonesData, ...this.props.chartData.datasets.phasesData],
            },
            options: {
                indexAxis: "y",
                aspectRatio: Math.abs(moment(this.props.chartData.startDate).diff(moment(this.props.chartData.endDate), 'months')) >= 2 ? 1.4 : 1.8,
                // height: 200,
                legend: {
                    display: true,
                },
                interaction: {
                    mode: 'dataset'
                },
                layout: {
                    padding: {
                        left: 10, // Add padding if needed
                        right: 10,
                        top: 10,
                        bottom: 10
                    }
                },
                plugins: {
                    title: {
                        display: true,
                        text: 'Milestone'
                    },
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                if (Array.isArray(context.raw)) {
                                    console.log(context)
                                    const startDate = moment(context.raw[0]).format("DD/MM/YYYY");
                                    const endDate = moment(context.raw[1]).format("DD/MM/YYYY");
                                    return `${startDate} - ${endDate}`;

                                } else {
                                    const date = moment(context.raw).format("DD/MM/YYYY");
                                    const milestonePointHover = context.dataset.name + ", " + date;
                                    return milestonePointHover;
                                }
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,

                // barPercentage: 0.8, // Adjust the bar width as needed (0.8 means 80% of the available space)
                // categoryPercentage: 0.8,
                scales: {
                    x: {
                        borderWidth: 10,
                        stacked: false,
                        min: moment(this.props.chartData.startDate).startOf('month').add(-1, "day"),
                        max: moment(this.props.chartData.endDate).endOf('month').add(1, "day"),
                        type: "time",
                        time: {
                            unit: "month",
                        },
                        // ticks: {
                        //     major: {
                        //         enabled: true
                        //     },
                        //     maxRotation: 0,
                        //     minRotation: 0,
                        //     callback: (context, index) => {
                        //         // Customize the tick label
                        //         // console.log(this.props.chartData.datasets.phasesData)
                        //         // const startDate = moment(this.props.chartData.startDate);
                        //         // const endDate = moment(this.props.chartData.endDate)
                        //         // return `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`;

                        //         // const dateRanges = [
                        //         //     { start: '2023-01-01', end: '2023-01-07' },
                        //         //     { start: '2023-01-08', end: '2023-01-14' },
                        //         //     { start: '2023-01-15', end: '2023-01-21' },
                        //         //     { start: '2023-01-22', end: '2023-01-24' },
                        //         //     { start: '2023-01-25', end: '2023-01-29' },
                        //         //     // Add more date ranges as needed
                        //         // ];
                        //         // console.log(this.props.chartData.datasets)
                        //         // const dateRangess = this.props.chartData.datasets.phasesData.map(phase => {
                        //         //     return { start: phase.data[0][0], end: phase.data[0][1] };
                        //         // });
                        //         // // console.log(dateRangess)
                        //         // const rangeIndex = Math.floor(index / 2); // Each date range occupies two ticks
                        //         // const startDate = moment(dateRangess[rangeIndex].start);
                        //         // const endDate = moment(dateRangess[rangeIndex].end);

                        //         // return `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`;
                        //         // let displayedDates = [];

                        //         // const dateRanges = this.props.chartData.datasets.phasesData.map(phase => {
                        //         //     return { start: phase.data[0][0], end: phase.data[0][1] };
                        //         // });
                        //         // const dateIndex = Math.floor(index / 2); // Each date range occupies two ticks

                        //         // if (dateIndex < dateRanges.length) {
                        //         //     const startDate = moment(dateRanges[dateIndex].start);
                        //         //     const endDate = moment(dateRanges[dateIndex].end);
                        //         //     const formattedDate = `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`;

                        //         //     // Check if the date is already displayed
                        //         //     if (!displayedDates.includes(formattedDate)) {
                        //         //         displayedDates.push(formattedDate);
                        //         //         return formattedDate;
                        //         //     }
                        //         // }

                        //         // return '';
                        //         const dateRanges = this.props.chartData.datasets.phasesData.map(phase => {
                        //             return { start: phase.data[0][0], end: phase.data[0][1] };
                        //         });
                        //         const dateIndex = Math.floor(index / 2); // Each date range occupies two ticks

                        //         if (dateIndex < dateRanges.length) {
                        //             const startDate = moment(dateRanges[dateIndex].start);
                        //             const endDate = moment(dateRanges[dateIndex].end);
                        //             const formattedDate = `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`;

                        //             // if (Array.isArray(context.raw)) {
                        //             // This is a tooltip, return the formatted date range
                        //             // return formattedDate;
                        //             return (index % 2 === 0) ? formattedDate : '';
                        //             // } else {
                        //             // This is an X-axis tick, return the same information
                        //             // const milestonePointHover =  formattedDate;
                        //             // return milestonePointHover;
                        //             // }
                        //         }

                        //         return '';
                        //     }
                        // }
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        showBorder: true,
                        suggestedMin: 0, // set your desired minimum value
                        suggestedMax: 50, //,
                        ticks: {
                            beginAtZero: true,
                            stepSize: 10000,
                            min: 0,
                            max: 50
                        }
                        // // display: false,
                        // grid: {
                        //     display: false,
                        //     drawBorder: false,
                        // }
                    }
                }
            },
            plugins: {
                // afterDraw: (chart) => {
                //     const ctx = chart.ctx;
                //     const xAxis = chart.scales.x;

                //     // Draw the start and end dates
                //     const startDateLabel = moment(this.props.chartData.startDate).format('DD/MM/YYYY');
                //     const endDateLabel = moment(this.props.chartData.endDate).format('DD/MM/YYYY');

                //     const startX = xAxis.getPixelForValue(moment(this.props.chartData.startDate).valueOf());
                //     const endX = xAxis.getPixelForValue(moment(this.props.chartData.endDate).valueOf());
                //     const labelY = chart.height - 10;

                //     // Draw the start and end date labels
                //     ctx.textAlign = 'center';
                //     ctx.fillStyle = 'black';
                //     ctx.fillText(startDateLabel, startX, labelY);
                //     ctx.fillText(endDateLabel, endX, labelY);

                //     return ctx
                // }
                legend: {
                    display: false
                }
            }
        });
    }

    render() {
        return <canvas ref={this.canvasRef} />;
    }
}

export default LineChart;
