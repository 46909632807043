import {
    Box, Button, Card, createStyles, Divider, Grid, LinearProgress, Paper, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography, useMediaQuery
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DownloadIcon from '@mui/icons-material/Download';
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import {
    AutocompleteInput,
    Loading,
    ReferenceInput, Title, useDataProvider,
    useNotify, usePermissions
} from "react-admin";
import { Form } from "react-final-form";
import { CHANGE_COMPONENTS_ID } from "../../framework/constants";
import dataProvider from "../../framework/dataProvider";
import { impersonatedURL, saveChangeReport, saveFileFromBinary } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import ImpactChart from "../customComponents/impactChart";


const useStyles = makeStyles((theme) => ({
    topCard: {
        padding: theme.spacing(2),
        paddingLeft: "20px",
        borderRadius: 12,
        width: "100%",
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
    },
    bottomCard: {
        borderRadius: 12,
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
        marginTop: 60,
        marginBottom: '10em'
    },
    bottomCardTitle: {
        backgroundColor: "#E0E0E0",
    },
    legends: {
        width: 24,
        height: 12,
        borderRadius: 6,
        "&.high": {
            backgroundColor: "#EB5757",
        },
        "&.medium": {
            backgroundColor: "#F2994A",
        },
        "&.low": {
            backgroundColor: "#27AE60",
        },
    },
    responsivelegends: {
        [theme.breakpoints.down('sm')]: {
            width: 14,
            height: 82,
            borderRadius: 4,
            "&.high": {
                backgroundColor: "#EB5757",
            },
            "&.medium": {
                backgroundColor: "#F2994A",
            },
            "&.low": {
                backgroundColor: "#27AE60",
            },
        },
    },
    legendsMargin: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '5px',
        },
    },
    impactChartStyle: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            boxSizing: 'border-box',
            height: '120px',
            width: '120px',
        },
    },
    resourcingBox: {
        display: "flex",
        flexDirection: "row",
        padding: 24,
        alignItems: "center",
        justifyContent: "space-between",
        color: "#FFFFFF",
        height: 64,
        borderRadius: 6,
        fontWeight: 'normal'
    },
    resourcingBox2: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#333333",
        border: "1px solid #828282",
        height: 64,
        borderRadius: 12,
        backgroundColor: "#F2F2F2",
    },
    impactOverallBox: {
        display: "flex",
        flexDirection: "row",
        padding: 24,
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF",
        height: 64,
        width: 250,
        borderRadius: 6,
        fontWeight: 'normal',
        [theme.breakpoints.down("md")]: {
            width: "8.75rem",
            height: "3rem",
            fontSize: ".7rem"
        }
    },
    submitButton: {
        backgroundColor: "#ACACAC",
        minWidth: 171,
        minHeight: 48,
        borderRadius: 8,
        color: "#FFF",
        textTransform: "capitalize",
        marginRight: 18,
    },
    drawerTitle: {
        fontWeight: 500,
        fontSize: 16,
        fontStyle: "normal",
    },
    hrBar: {
        height: 4,
        backgroundColor: "#333333",
        position: "absolute",
        width: "99%",
        top: 176,
        marginLeft: 1,
        border: "none",
    },
    hrBarResponsive: {
        height: 4,
        backgroundColor: "#333333",
        position: "absolute",
        width: "99%",
        top: '85px',
        marginLeft: 1,
        border: "none",
    },
    tableContainer: {
        boxShadow: "none",
        border: "1px solid #C4C4C4",
    },
    overallChangeText: {
        color: "#4F4F4F",
        fontSize: 24,
        fontWeight: 500,
    },
    overallResponsiveChangeText: {
        [theme.breakpoints.down('xs')]: {
            color: "#4F4F4F",
            fontSize: '14px',
            fontWeight: 500,
        },
    },
    tableBox: {
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    responsiveBoxAnalysis: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            width: '100%',
            paddingLeft: '10px'
        },
    },
    paddingReponsive: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingBottom: '15px',
        },
    },
    titleFontSize: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    }

}));

const customProgressBar = (props) => {
    const progressBar = { width: props.value, backgroundColor: props.barColor, height: 10, borderRadius: 5, };
    return <div style={{ width: props.value, backgroundColor: props.barColor, height: 10, borderRadius: 5, }} />;
}
const BorderLinearProgress = (props) => {
    const ProgressBar = withStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: grey[200],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: props.barColor,
        },
    })(LinearProgress);
    return <ProgressBar {...props} />;
};

const CellWithRightBorder = withStyles((theme) =>
    createStyles({
        root: {
            borderRightWidth: 1,
            borderRightColor: "#C4C4C4",
            borderRightStyle: "solid",
        },
    })
)(TableCell);

const AnalysisOverview = (props) => {
    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [loading, setLoading] = React.useState(false);
    const [changeAssessmentData, setChangeAssessmentData] = React.useState();
    const [error, setError] = React.useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { loading: loadingPermissions, permissions } = usePermissions();
    const user = TokenStorage.getUserDetails();
    const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};
    impersonatedURL(isImpersonating, '#/analysis_overview?impersonated');

    const pdfRef = React.createRef();
    const options = {
        orientation: "portrait",
        unit: "in",
        format: [17.5, 19]
    };
    const getChangeAssessment = async (projectId, businessUnitId) => {
        return dataProvider
            .getOne("AssessmentOverview", {
                id: null,
                data: { projectId, businessUnitId },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                setError(
                    "No assessment found for the provided project and business unit"
                );
            });

    };



    const onSubmit = async (values) => {
        setLoading(true);

        const changeAssessmentDetails = await getChangeAssessment(
            values.projectId,
            values.businessUnitId
        );

        if (changeAssessmentDetails) {
            setChangeAssessmentData(changeAssessmentDetails);
            setError(false);
            setLoading(false);
            return;
        }

        // if the execution reached this line, it means that there is no change assessment details found
        setError("No assessment found for the provided project and business unit");
        setLoading(false);
    };
    console.log("change assessment details", JSON.stringify(changeAssessmentData));

    const handleExportChangeAssessmentPDF = () => {
        const changeAssesmentReportDataForPDF = JSON.stringify(changeAssessmentData);

        dataProvider
            .postRequestForPDF("ExportChangeAssessmentDataForPDF", {
                queryType: "retrieve",
                data: { changeAssesmentReportDataForPDF },
            })
            .then((response) => {
                saveChangeReport(response.data); //here you will saved the cooked pdf from the backend using response.data
            })
            .catch((error) => {
                notify("No change assessment data found to export");
            });

    }
    return (
        <WithPermission resource="ChangeAssessment" permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <Box display="flex" flexDirection="column">
                <Title className={classes.titleFontSize} title="Change Impact Assessment Report" />
                <Box>
                    <Card className={classes.topCard} >
                        <Form
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item md={5} xs={12} sm={12}>
                                            <ReferenceInput
                                                variant="outlined"
                                                label="Project"
                                                source="projectId"
                                                reference="Project"
                                                onChange={(value) => {
                                                    setChangeAssessmentData(false);
                                                    form.change("businessUnitId", null);
                                                }}
                                                fullWidth
                                            >
                                                <AutocompleteInput optionText="name" />
                                            </ReferenceInput>
                                        </Grid>

                                        <Grid item md={5} xs={12} sm={12} mt={-1}>
                                            <ReferenceInput
                                                variant="outlined"
                                                label="Business Unit"
                                                source="businessUnitId"
                                                reference="BusinessUnit"
                                                filter={{ projectId: values.projectId }}
                                                onChange={(value) => {
                                                    setChangeAssessmentData(false);
                                                    values.businessUnitId = value;
                                                    console.log(values);
                                                    onSubmit(values);
                                                }}
                                                disabled={!values.projectId}
                                                fullWidth
                                            >
                                                <AutocompleteInput optionText="name" />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item md={2}>
                                            {changeAssessmentData &&
                                                <Button
                                                    style={{ marginTop: '5px' }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleExportChangeAssessmentPDF}
                                                    disabled={isSmall}
                                                >{<DownloadIcon fontSize='small' />}Export</Button>
                                            }
                                        </Grid>

                                    </Grid>
                                </form>
                            )}
                        />

                    </Card>

                </Box>
                <div ref={pdfRef} >
                    <AnalyzedDetail
                        loading={loading}
                        error={error}
                        changeAssessmentData={changeAssessmentData}
                    />
                </div>

            </Box>
        </WithPermission>
    );
};



const AnalyzedDetail = ({ loading, error, changeAssessmentData }) => {
    const classes = useStyles();
    const ref = React.useRef()
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const notify = useNotify();



    const getBarColor = (value) => {
        let color = "";
        switch (true) {
            case value <= 30:
                color = "#27AE60";
                break;
            case value <= 70:
                color = "#F2994A";
                break;
            case value <= 100:
                color = "#EB5757";
                break;
            default:
                color = "#000000";
                break;
        }
        return color;
    };

    const getImpactBtnBgColor = (value) => {
        let color = "";
        switch (true) {
            case value <= 42:
                color = "#27AE60";
                break;
            case value <= 98:
                color = "#F2994A";
                break;
            case value > 98:
                color = "#EB5757";
                break;
            default:
                color = "#000000";
                break;
        }
        return color;
    };

    const getImpactBtTxt = (value) => {
        let color = "";
        switch (true) {
            case value <= 42:
                color = "Low";
                break;
            case value <= 98:
                color = "Medium";
                break;
            case value > 98:
                color = "High";
                break;
            default:
                color = "NA";
                break;
        }
        return color;
    };

    if (loading) return <Loading />;

    if (error)
        return (
            <Box mt={3}>
                <Card className={classes.bottomCard}>
                    <Box p={3}>
                        <p>{error}</p>
                    </Box>
                </Card>
            </Box>
        );

    if (!changeAssessmentData) return null;


    if (changeAssessmentData) {
        const changeAssessmentValues = {};
        for (const changeAssessmentValue of changeAssessmentData.changeManagementImpacts) {
            const value = changeAssessmentValue.assessmentValue;
            const subTypeName = changeAssessmentValue.assessmentSubType.name;
            const typeName = changeAssessmentValue.assessmentType.name;
            const sectionId = changeAssessmentValue.assessmentSectionId;
            _.defaultsDeep(changeAssessmentValues, {
                [sectionId]: {
                    [typeName]: { [subTypeName]: { assessmentValue: value } },
                },
            });
        }
        changeAssessmentData.changeAssessmentValues = changeAssessmentValues;


        var changeMgtRows = [];
        Object.keys(changeAssessmentData.changeAssessmentValues[CHANGE_COMPONENTS_ID]).forEach(
            (assessmentTypeName) => {
                const assessmentType =
                    changeAssessmentData.changeAssessmentValues[CHANGE_COMPONENTS_ID][assessmentTypeName];
                const scale_effort_value = assessmentType["Rating - Scale/Effort"]
                    ? (assessmentType["Rating - Scale/Effort"].assessmentValue.rating /
                        10) *
                    100
                    : null;
                const challenge_value = assessmentType["Rating - Challenge"]
                    ? (assessmentType["Rating - Challenge"].assessmentValue.rating / 10) *
                    100
                    : null;

                changeMgtRows.push(
                    <TableRow key={assessmentTypeName}>
                        <CellWithRightBorder component="th" scope="row" align="left">
                            {assessmentTypeName}
                        </CellWithRightBorder>
                        <CellWithRightBorder align="left">
                            <div style={{ width: "100%", backgroundColor: grey[200], height: 10, borderRadius: 5 }}>
                                <div style={{ width: `${scale_effort_value}%`, backgroundColor: getBarColor(scale_effort_value), height: 10, borderRadius: 5, }} />
                            </div>
                        </CellWithRightBorder>
                        <CellWithRightBorder align="left">
                            <div style={{ width: "100%", backgroundColor: grey[200], height: 10, borderRadius: 5 }}>
                                <div style={{ width: `${challenge_value}%`, backgroundColor: getBarColor(challenge_value), height: 10, borderRadius: 5, }} />
                            </div>
                        </CellWithRightBorder>
                    </TableRow>
                );
            }
        );
    }


    const handleExportChangeAssesmentReport = () => {
        const changeAssesmentReportData = JSON.stringify(changeAssessmentData);
        dataProvider
            .postRequest("ExportChangeImpactAssesmentReport", {
                queryType: "retrieve",
                data: { changeAssesmentReportData }
            }).then(response => {
                saveFileFromBinary(response.data, "Change Assessment Report")
            })
            .catch(error => {
                notify("No data found to export")
            });
    }



    return (
        <Box ref={ref}>
            <>
                <Card className={classes.bottomCard}>
                    <Box className={classes.tableBox} >
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-start"
                            className={`${classes.bottomCardTitle} ${classes.responsiveBoxAnalysis}`}
                            p={2}
                            pl={10}
                        >
                            <Box flexDirection="row">
                                <Typography className={classes.drawerTitle}>
                                    Project Sponsor -{" "}
                                    {changeAssessmentData.project.projectSponsor.fullName}
                                </Typography>
                            </Box>

                            {isSmall ? (
                                <>
                                    <Box style={{ marginTop: "10px" }} flexDirection="row" >
                                        <Typography className={classes.drawerTitle}>
                                            Change Lead -{" "}
                                            {changeAssessmentData.project.changeLeader.fullName}
                                        </Typography>
                                    </Box>

                                    <Box style={{ marginTop: "10px", marginBottom: '10px' }} >
                                        <Typography className={classes.drawerTitle}>
                                            Duration -{" "}
                                            {moment(changeAssessmentData.project.startDate).format(
                                                "DD MMM YYYY"
                                            )}{" "}
                                            to{" "}
                                            {moment(changeAssessmentData.project.endDate).format(
                                                "DD MMM YYYY"
                                            )}
                                        </Typography>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box style={{ marginLeft: "13em" }} flexDirection="row" >
                                        <Typography className={classes.drawerTitle}>
                                            Change Lead -{" "}
                                            {changeAssessmentData.project.changeLeader.fullName}
                                        </Typography>
                                    </Box>

                                    <Box style={{ marginLeft: "12em" }} >
                                        <Typography className={classes.drawerTitle}>
                                            Duration -{" "}
                                            {moment(changeAssessmentData.project.startDate).format(
                                                "DD MMM YYYY"
                                            )}{" "}
                                            to{" "}
                                            {moment(changeAssessmentData.project.endDate).format(
                                                "DD MMM YYYY"
                                            )}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                    <Divider />
                    <Box pl={10} pr={10} pb={5} className={classes.paddingReponsive}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box className={classes.overallResponsiveChangeText}>
                                {isSmall ? (

                                    <Typography variant="h6" className={classes.overallResponsiveChangeText} >Overall change impact</Typography>
                                ) : (
                                    <Typography variant="h6" className={classes.overallChangeText}>Overall change impact</Typography>
                                )
                                }
                            </Box>
                            {isSmall ? (
                                <Box style={{ marginLeft: 10 }}>
                                    <Box width={240} height={240} position="relative" className={classes.impactChartStyle}>
                                        <ImpactChart
                                            minValue={0}
                                            maxValue={460}
                                            value={changeAssessmentData.overallChangeImpactValue}
                                        />
                                        {isSmall ? (
                                            <hr className={classes.hrBarResponsive} />
                                        ) : (
                                            <hr className={classes.hrBar} />
                                        )}

                                    </Box>
                                </Box>
                            )
                                :
                                (
                                    <Box style={{ marginLeft: 92 }}>
                                        <Box width={240} height={240} position="relative" className={classes.impactChartStyle}>
                                            <ImpactChart
                                                minValue={0}
                                                maxValue={460}
                                                value={changeAssessmentData.overallChangeImpactValue}
                                            />
                                            {isSmall ? (
                                                <hr className={classes.hrBarResponsive} />
                                            ) : (
                                                <hr className={classes.hrBar} />
                                            )}

                                        </Box>
                                    </Box>
                                )}
                        </Box>
                        <Box display="flex" flexDirection="row" pb={3} className={classes.responsivelegends}>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <Box className={clsx(classes.legends, { ["low"]: true })}></Box>
                                <Box className={classes.legendsMargin} ml={2}>Low</Box>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                ml={3}
                            >
                                <Box
                                    className={clsx(classes.legends, { ["medium"]: true })}
                                ></Box>
                                <Box className={classes.legendsMargin} ml={2}>Medium</Box>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center" ml={3}>
                                <Box className={clsx(classes.legends, { ["high"]: true })}></Box>
                                <Box className={classes.legendsMargin} ml={2}>High</Box>
                            </Box>
                        </Box>
                        <Box >
                            <TableContainer
                                component={Paper}
                                className={classes.tableContainer}
                            >
                                <Table aria-label="simple table">
                                    <TableHead style={{ borderBottom: "1px solid #C4C4C4" }}>
                                        <TableRow>
                                            <CellWithRightBorder align="left">
                                                Change Management Component
                                            </CellWithRightBorder>
                                            <CellWithRightBorder align="left">
                                                Scope / Effort
                                            </CellWithRightBorder>
                                            <CellWithRightBorder align="left">
                                                Challenge
                                            </CellWithRightBorder>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {changeMgtRows}
                                        <TableRow>
                                            <CellWithRightBorder
                                                component="th"
                                                scope="row"
                                                align="left"
                                            >
                                                {isSmall ? (
                                                    <Typography variant="h6" className={classes.overallResponsiveChangeText} >Overall change impact</Typography>
                                                ) : (
                                                    <Typography variant="h6" className={classes.overallChangeText}>Overall change impact</Typography>
                                                )}
                                            </CellWithRightBorder>
                                            <CellWithRightBorder align="center">
                                                {isSmall ? (
                                                    <Typography variant="h6" className={classes.overallResponsiveChangeText} >Overall Scope/Effort</Typography>
                                                ) : (
                                                    <Typography variant="h6" className={classes.overallChangeText}>Overall Scope/Effort</Typography>
                                                )}
                                                <Box display="flex" justifyContent="center" mt={2}>
                                                    <Box className={classes.impactOverallBox} position="relative" style={{
                                                        backgroundColor: getImpactBtnBgColor(changeAssessmentData
                                                            .overallChangeMgtImpactValues[0])
                                                    }}>
                                                        <Box fontWeight="fontWeightBold">
                                                            <h2>{getImpactBtTxt(changeAssessmentData
                                                                .overallChangeMgtImpactValues[0])}</h2>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </CellWithRightBorder>
                                            <CellWithRightBorder align="center">
                                                {isSmall ? (
                                                    <Typography variant="h6" className={classes.overallResponsiveChangeText} >Overall Complexity</Typography>
                                                ) : (
                                                    <Typography variant="h6" className={classes.overallChangeText}>Overall Complexity</Typography>
                                                )}
                                                <Box display="flex" justifyContent="center" mt={2}>
                                                    <Box className={classes.impactOverallBox} position="relative" style={{
                                                        backgroundColor: getImpactBtnBgColor(changeAssessmentData
                                                            .overallChangeMgtImpactValues[1])
                                                    }}>
                                                        <Box fontWeight="fontWeightBold">
                                                            <h2>{getImpactBtTxt(changeAssessmentData
                                                                .overallChangeMgtImpactValues[1])}</h2>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </CellWithRightBorder>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Card>
            </>

        </Box>
    );
};

const Resourcing = ({ title, value, ...rest }) => {
    const classes = useStyles();

    return (
        <Box
            className={classes.resourcingBox}
            style={{ backgroundColor: value ? "#27AE60" : "#EB5757" }}
            {...rest}
        >
            <Box>
                <InfoOutlinedIcon />
            </Box>
            <Box ml={2} fontWeight="fontWeightBold">
                <h2>{title}</h2>
            </Box>
            {value ? (
                <Box ml={2}>
                    <CheckCircleIcon fontSize="large" />
                </Box>
            ) : (
                <Box ml={2}>
                    <CancelIcon fontSize="large" />
                </Box>
            )}
        </Box>
    );
};

const Estimate = ({ startIcon, title, value, valueIcon, ...rest }) => {
    const classes = useStyles();

    return (
        <Box className={classes.resourcingBox2} {...rest}>
            <Box p={3} display="flex" flexDirection="row" alignItems="center">
                <Box>{startIcon}</Box>
                <Box ml={2} fontWeight="fontWeightBold">
                    <h2>{title}</h2>
                </Box>
            </Box>
            <Box height="70%" borderRight={1} borderColor="#C4C4C4">
                <Divider orientation="vertical" style={{ backgroundColor: '#C4C4C4' }} />
            </Box>
            <Box
                p={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
                fontWeight={500}
            >
                {valueIcon}
                <h2>{value}</h2>
            </Box>
        </Box>
    );
};

export default AnalysisOverview;
