import PBIEmbed from "./pbiEmbed";

export const CRAProjectReport = (props) => {
    return (
        <PBIEmbed
            title="Change Risk Report"
            reportId='31504e2a-80ad-44b4-9142-464fa6bf1031'
            pageName="8547b9940e69110e3367"
            resource='Report'
            url='change_risk_assessment_report'
        />
    )
}

export default CRAProjectReport;