
export const RISK_MATRIX_MASTERS_BY_TYPE_LIST_QUERY = `query {
    getRiskMatrixMasters {
      id
      riskLabel
      riskDescription
      riskScore
      type
    }
  }`

  export const RISK_MATRIX_MASTER_UPDATE_MUTATION = `mutation(
    $id: ID!
    $riskDescription: String!
  ) {
    updateRiskMatrixMaster(
      id: $id
      riskDescription: $riskDescription
    ) {
      id
      riskLabel
      riskDescription
      riskScore
      type
    }
  }
  `