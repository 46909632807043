import PBIPortfolioEmbed from "./pbiPortfolioEmbed";

export const ChangeHeatmapPortfolioReport = (props) => {
    return (
        <PBIPortfolioEmbed
            title="Change Heatmap Report"
            reportId='421050e3-5165-4937-8598-a9e41a955361'
            pageName='ReportSection68f8e96159e0658664b8'
            resource='Report'
            url='change_heatmap_portfolio'
        />
    )
}

export default ChangeHeatmapPortfolioReport;