export const BUSINESS_READLINESS_MASTER_DATA_QUERY_LIST = `query($type: ReadinessGovernance!){
    getReadinessGovernanceMasters(type: $type){
      id
      name
    }
  }`
export const BUSINESS_READLINESS_UPDATE_MUTATION = `mutation (
  $id: ID!
  $name: String!
  ){
  updateReadinessGovernanceMaster(
    id: $id
    name: $name
  ){
      id
      name
    }
  }`
export const BUSINESS_READLINESS_CREATE_MUTATION = `mutation (
    $projectId: Int!
    $type: ReadinessGovernance!
    $readinessGovernanceAssessments: [ReadinessGovernanceAssessmentInput!]
  ) {
    createReadinessGovernanceAssessments(
      projectId: $projectId
      type: $type
      readinessGovernanceAssessments: $readinessGovernanceAssessments
    ) {
      id
      name
      assessment
      commentary
    }
  }`;

export const BUSINESS_READLINESS_QUERY = `query(
    $projectId: Int!, 
    $type: ReadinessGovernance!
  ) {
    getReadinessGovernanceAssessments(
      projectId: $projectId, 
      type: $type) {
      id
      name
      assessment
      commentary
      type
      createdAt
    }
  }
  `


