import { Box, Button, Grid } from "@material-ui/core";
import { MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";
import { Notification, TextInput, required, useDataProvider, useNotify } from "react-admin";
import { Form } from "react-final-form";
import { Link, useHistory } from 'react-router-dom';
import cat_logo from "../../assets/images/cat_logo.png";
import backgroundLogo from '../../assets/images/loginImage.png';
import theme from '../../framework/theme';
import { onFailureMessage } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
    formLayout: {
        display: "flex",
        justifyContent: "center",
    },
    fieldOptions: {
        width: 440,
        marginBottom: 36,
        borderColor: "#E1E1E1 !important",
        borderRadius: 8,
        "&::placeholder": {
            color: "#FFF",
        },
    },
    fieldTags: {
        color: "#B5B5B5",
        fontSize: 16,
        fontFamily: "Roboto",
        fontWeight: "normal",
        margin: 0,
        marginBottom: 5,
    },
}));

export const ForgotPassword = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const history = useHistory();

    const onSubmit = (values, form) => {
        dataProvider
            .postRequest("SetNewPassword", {
                queryType: "setNewPassword",
                data: { ...values },
            }).then(({ data }) => {
                form.restart();
                notify("Your password has been reset successfully. Please check your e-mail","success");
                history.push("/login");
            })
            .catch(error => {
                const error_messages = onFailureMessage(error);
                notify(error_messages, "error");
            });
    }

    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <MuiThemeProvider theme={theme}>
            <Box backgroundColor="#ffffff">

                <Grid container alignItems="center" justifyContent="center">
                    <Grid item md={6} >
                        <Form
                            style={{ width: matches ? "20rem" : "25rem" }}
                            onSubmit={onSubmit}
                            render={({ handleSubmit }) => {
                                return (
                                    <Box display="flex" flexDirection="column" alignItems="center" p={2} pt={10}>
                                        <Box width={250} textAlign="center">
                                            <img src={cat_logo} width="200px" alt='Change Assessment tool logo' />
                                        </Box>
                                        <Box flex={1} justifyContent="center" pt={10}>
                                            <Box>
                                                <h5 className={classes.fieldTags}>Username</h5>
                                                <TextInput
                                                    style={{ width: matches ? "20rem" : "25rem"}}
                                                    variant="outlined"
                                                    placeholder="Username"
                                                    label=""
                                                    source="email"
                                                    type="email"
                                                    validate={[required()]}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: "#E1E1E1",
                                                            fontFamily: "Roboto",
                                                            fontWeight: "normal",
                                                        },
                                                    }}
                                                    fullWidth />
                                            </Box>
                                            <Box display="flex" justifyContent="center">
                                                <Button
                                                    style={{ width: matches ? "20rem" : "25rem" }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                    fullWidth>
                                                    Reset Password
                                                </Button>
                                            </Box>
                                            <Box mt={2} textAlign="right">
                                                <Link style={{ color: '#00a0d9', textDecorationColor: '#00a0d9' }}
                                                    to={{
                                                        pathname: '/login',
                                                    }}
                                                >
                                                    <small>Login?</small>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item md={6} >
                        <Box height="100vh" display="flex" flexDirection="column" sx={{ backgroundColor: '#00a0d9', textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src={backgroundLogo} alt='Forget Password background logo' style={{ width: "100%" }} border="0" />
                        </Box>
                    </Grid>
                </Grid>
                <Notification />
            </Box>
        </MuiThemeProvider>
    )
}

export default ForgotPassword;