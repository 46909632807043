export const LOGIN_MUTATION = `mutation($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    user {
      id
      fullName
      email
      isSuperAdmin
      isCustomerAdmin
      isFirstTimeLogin
      customerAdminId
      roles {
        id
        name
      }
      permissions {
        id
        name
        resource
        action
        allow
      }
    }
    pbi_access_token
    pbi_refresh_token
  }
}`

export const USER_CREATE_MUTATION = `mutation(
    $surname: String
    $firstName: String!
    $fullName: String!
    $email: String!
    $companyName: String
    $location: String
    $industry: String
    $isManagedService: Boolean
    $customerAdminName: String
    $description: String
    $businessUnitId: Int
    $roles: [Int!]
  ) {
    createUser(
      surname: $surname
      firstName: $firstName
      fullName: $fullName
      email: $email
      companyName: $companyName
      location: $location
      industry: $industry
      isManagedService: $isManagedService
      customerAdminName: $customerAdminName
      description: $description
      businessUnitId: $businessUnitId
      roles: $roles
    ) {
      id
      surname
      firstName
      fullName
      email
      companyName
      location
      industry
      isManagedService
      customerAdminName
      description
      status
      isFirstTimeLogin
      businessUnit {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }`

export const USER_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: UserFilter!
  ) {
    allUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        surname
        firstName
        fullName
        email
        companyName
        location
        industry
        isManagedService
        customerAdminName
        description
        status
        isDisabled
        isFirstTimeLogin
        lastLoggedInAt
        businessUnit {
          id
          name
        }
        roles {
          id
          name
        }
      }
      totalCount
    }
  }`


export const USER_RETRIEVE_QUERY = `query($id: Int!) {
    user(id: $id) {
      id
      surname
      firstName
      fullName
      email
      companyName
      location
      industry
      isManagedService
      customerAdminName
      description
      status
      isCustomerAdmin
      isSuperAdmin
      isFirstTimeLogin
      isDisabled
      businessUnit {
        id
        name
      }
      roles {
        id
        name
      }
      permissions{
        id
        name
        resource
        action
        allow
    }
    }
  }`

export const USER_UPDATE_MUTATION = `mutation(
    $id: Int!
    $surname: String
    $firstName: String!
    $fullName: String
    $email: String!
    $companyName: String
    $location: String
    $industry: String
    $isManagedService: Boolean
    $customerAdminName: String
    $description: String
    $businessUnitId: Int
    $status: Boolean!
    $roles: [Int!]
    $isDisabled: Boolean
  ) {
    updateUser(
      id: $id
      surname: $surname
      firstName: $firstName
      fullName: $fullName
      email: $email
      companyName: $companyName
      location: $location
      industry: $industry
      isManagedService: $isManagedService
      customerAdminName: $customerAdminName
      description: $description
      businessUnitId: $businessUnitId
      status: $status
      roles: $roles
      isDisabled: $isDisabled
    ) {
      id
      surname
      firstName
      fullName
      email
      companyName
      location
      industry
      isManagedService
      customerAdminName
      description
      status
      isFirstTimeLogin
      
      businessUnit {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }`

export const USER_DELETE_MUTATION = `mutation($id: Int!) {
    deleteUser(id: $id)
  }`

export const CHANGE_PASSWORD_MUTATION = `mutation($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    token
    user {
      id
      fullName
      email
      isSuperAdmin
      isCustomerAdmin
      isFirstTimeLogin
    }
  }
}`

export const SET_NEW_PASSWORD_MUTATION = `mutation($email: String!) {
    setNewPassword(email: $email)
}`

export const EXPORT_USER_QUERY = `
  query{
    exportUsers
  }`

export const EXPORT_CLIENT_QUERY = `
  query{
    exportClients
  }`