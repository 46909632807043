export const ASSESSMENT_SECTION_LIST_QUERY = `query(
  $page: Int
  $perPage: Int
  $sortField: String
  $sortOrder: String
  $filter: AssessmentSectionFilter!
) {
  allAssessmentSections(
    page: $page
    perPage: $perPage
    sortField: $sortField
    sortOrder: $sortOrder
    filter: $filter
  ) {
    items {
      id
      name
      assessmentTypes {
        id
        name
        description
        assessmentSubTypes {
          id
          name
          description
          assessmentValues {
            id
            nature
            description
            rating
            signific
          }
        }
      }
    }
    totalCount
  }
}`

export const ASSESSMENT_SECTION_RETRIEVE_QUERY = `query($id: Int!) {
    assessmentValue(id: $id) {
        id
        impactNature
        significance
        rating
    }
  }`

export const ASSESSMENT_SECTION_UPDATE_MUTATION = `mutation(
  $id: Int!
  $name: String
  $geography: String
  $fte: Int
  $description: String
  $status: Boolean
  $parentAssessmentSectionId: Int
) {
  updateAssessmentSection(
    id: $id
    name: $name
    geography: $geography
    fte: $fte
    description: $description
    status: $status
    parentAssessmentSectionId: $parentAssessmentSectionId
  ) {
    id
    name
    geography
    fte
    description
    status
    parentAssessmentSectionId
  }
}`

export const ASSESSMENT_SECTION_CREATE_MUTATION = `mutation(
    $projectId: Int!
    $assessmentValueId: Int!
    $changeAssessmentSections: [AssessmentSectionValueInput!]
  ) {
    createAssessmentSection(
      projectId: $projectId
      assessmentValueId: $assessmentValueId
      changeAssessmentSections: $changeAssessmentSections
    ) {
      id
    }
  }`

export const ASSESSMENT_SECTION_DELETE_MUTATION = `mutation($id: Int!) {
    deleteAssessmentSection(id: $id)
  }`