import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Title } from "react-admin";
import { CHANGE_ASSESSMENT } from "../../framework/constants";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import ChangeAssessmentMasterDataList from "./changeAssessmentMasterData";
import FaqList from "./faq";
import ProjectMasterDataList from "./projectMasterData";
import ReadinessGovernancetMasterDataList from "./readinessGovernanceMaster";
import RiskAssessmentMasterDataList from "./riskAssessmentMasterData";
import RiskMatrixDataList from "./riskMatrixData";
import { useMediaQuery } from "@material-ui/core";
import { Alert, ButtonBase, Snackbar } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const useStyles = makeStyles((theme) => ({
    root: {
        // width: '25%',
        width: '90vw',
        // overflowX: "hidden",
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
    '& .MuiTabs-fixed': {
        overflow: 'auto'
    },
    tabPanel: {
        marginTop: 30,
        marginBottom: 50
    },
    tabItemStyle: {
        textTransform: 'capitalize'
    },
    title: {
        textTransform: "uppercase",
        // fontSize: "20px" // Adjust the font size as needed
    }
}));


const MyTabScrollButton = React.forwardRef((props, ref) => {
    const { direction, ...other } = props;

    return (
        <ButtonBase
            component="div"
            ref={ref}
            style={{
                opacity: other.disabled ? 0 : 1, "&:focus": {
                    backgroundColor: '#0b5bdb',
                },
            }}
            {...other}
        >
            {direction === "left" ? (
                <ArrowBackIosNewIcon sx={{
                    backgroundColor: '#00A0D9', textAlign: 'center', color: '#fff', marginRight: '10px', borderRadius: '50%', fontSize: '30px', padding: '5px', "&:focus": {
                        backgroundColor: '#0b5bdb',
                    },
                    "&:hover": {
                        backgroundColor: '#0486b5',
                    },
                }} />
            ) : (
                <ArrowForwardIosIcon sx={{
                    backgroundColor: '#00A0D9', textAlign: 'center', color: '#fff', marginLeft: '10px', borderRadius: '50%', fontSize: '30px', padding: '5px', "&:focus": {
                        backgroundColor: '#0b5bdb',
                    },
                    "&:hover": {
                        backgroundColor: '#0486b5',
                    },
                }} />
            )}
        </ButtonBase>
    );
});
const StyledTabs = withStyles({

    root: {
        maxWidth: "85vw",
        '& .MuiTabs-fixed': {
            overflowX: 'auto !important',

        },
        // backgroundColor: "#fff"
        // borderBottom: '2px solid #ccc', // Border for all tabs
    },
    indicator: {
        display: "none",
        // justifyContent: "center",
        // backgroundColor: "transparent",
        // height: 5,
        // "& > span": {
        //     maxWidth: 80,
        //     width: "100%",
        //     backgroundColor: "#EFF2F7"
        // },
    },
    '& .Mui-selected': {
        borderBottom: '10px solid #f0f', // Remove border for the active tab
    },
    '& .MuiTabs-fixed': {
        overflowX: 'auto !important',

    }
})((props) => <Tabs  {...props} style={{ overflow: 'auto' }} ScrollButtonComponent={MyTabScrollButton} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        color: "#000",
        fontWeight: theme.typography.fontWeightRegular,
        // fontSize: theme.typography.pxToRem(15),
        fontSize: '14px',
        padding: "0 20px",
        "&:focus": {
            opacity: 1,
        },
        minWidth: 97,
        // paddingLeft: 0,
        // paddingRight: 0,
        borderBottom: "2px solid #cdd0d1",
        '&:not($selected)': {
            // borderBottomColor: '#ccc', // Add border for unselected tabs
        },
        "&$selected": {
            // borderTop: "2px solid #0Ab39C",
            marginBottom: '2px',
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '5px',
            // paddingBottom: "-10px",
            fontWeight: '600',
            borderBottom: 'none',
            // backgroundColor: "#EFF2F7", // Change this to the color you desire
            backgroundColor: "#FFFFFF", // Change this to the color you desire
            color: "#000", // Change this to the color you desire for the text

        },
    },
    selected: {}
}))((props) => <Tab disableRipple {...props} />);

const AntTabs = withStyles({
    root: {
    },
    indicator: {
        backgroundColor: '#828282',
        height: 5,
    },
})(Tabs);


const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        marginRight: theme.spacing(4),
        color: '#B5B5B5',
        '&$selected': {
            color: '#252525',
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: 16,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function MasterDataTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const user = TokenStorage.getUserDetails();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setSnackbarOpen(true);
    };

    const hanndleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = React.useState({
        message: "",
        type: ""
    });

    const { vertical, horizontal } = anchorOrigin;
    const { isSuperAdmin, isCustomerAdmin } = user ? user : {};

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <WithPermission resource="MasterData" bypassUser={isSuperAdmin} action={["view"]} {...props} >
            <div className={classes.root} >
                <Title title="Master Data" className={classes.title} />
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"

                >
                    <StyledTab className={classes.tabItemStyle} label="Change Impact" {...a11yProps(0)} />
                    <StyledTab className={classes.tabItemStyle} label="Change Environment" {...a11yProps(1)} />
                    <StyledTab className={classes.tabItemStyle} label="Risk Assessment" {...a11yProps(2)} />
                    <StyledTab className={classes.tabItemStyle} label="Risk Matrix" {...a11yProps(3)} />
                    <StyledTab className={classes.tabItemStyle} label="Change Readiness" {...a11yProps(4)} />
                    <StyledTab className={classes.tabItemStyle} label="Change Governance" {...a11yProps(5)} />
                    <StyledTab className={classes.tabItemStyle} label="Project" {...a11yProps(6)} />
                    <StyledTab className={classes.tabItemStyle} label="FAQ" {...a11yProps(7)} />
                </StyledTabs>
                <TabPanel value={value} index={0} className={classes.tabPanel}>
                    <ChangeAssessmentMasterDataList type={CHANGE_ASSESSMENT.IMPACT} handleSnackbarOpen={handleSnackbarOpen} />
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.tabPanel}>
                    <ChangeAssessmentMasterDataList type={CHANGE_ASSESSMENT.ENVIRONMENT} handleSnackbarOpen={handleSnackbarOpen} />
                </TabPanel>
                <TabPanel value={value} index={2} className={classes.tabPanel}>
                    <RiskAssessmentMasterDataList handleSnackbarOpen={handleSnackbarOpen} />
                </TabPanel>
                <TabPanel value={value} index={3} className={classes.tabPanel}>
                    <RiskMatrixDataList handleSnackbarOpen={handleSnackbarOpen}/>
                </TabPanel>
                <TabPanel value={value} index={4} className={classes.tabPanel}>
                    <ReadinessGovernancetMasterDataList type="Readiness" handleSnackbarOpen={handleSnackbarOpen} />
                </TabPanel>
                <TabPanel value={value} index={5} className={classes.tabPanel}>
                    <ReadinessGovernancetMasterDataList type="Governance" handleSnackbarOpen={handleSnackbarOpen}/>
                </TabPanel>
                <TabPanel value={value} index={6} className={classes.tabPanel}>
                    <ProjectMasterDataList handleSnackbarOpen={handleSnackbarOpen} />
                </TabPanel>
                <TabPanel value={value} index={7} className={classes.tabPanel}>
                    <FaqList handleSnackbarOpen={handleSnackbarOpen}/>
                </TabPanel>
            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                <Alert

                    onClose={hanndleSnackbar}
                    severity={popupMessage.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {popupMessage.message}
                </Alert>
            </Snackbar>
        </WithPermission>
    );
}
