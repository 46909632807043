import teal from '@material-ui/core/colors/teal';
import { alpha } from '@material-ui/core/styles';

function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
    lighter: '#ddf6ff',
    light: '#19aadd',
    main: '#00a0d9',
    dark: '#0693c6',
    darker: '#495057',
    contrastText: '#ffffff'
};
const SECONDARY = {
    lighter: teal[400],
    light: "#6ee1d4",
    main: "#606f89",
    dark: "#007f74",
    darker: teal[800],
    contrastText: '#fff',
};
const INFO = {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#04297A',
    contrastText: '#fff'
};
const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#219653',
    darker: '#08660D',
    contrastText: GREY[800]
};
const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FF800A',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: GREY[800]
};
const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#EB5757',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#fff'
};
const CUSTOMSCOLORS = {
    main: '#0ab39c',
    secondary: "#299CDB",
    success: "#219653",
    gray: "#F3F3F9",
    creamium: "#ffffff",
    darkBlue: "#495057",
    primary: "#405189",
    darkGray: "#dadde9",
    lightGray: "#f3f6f9",
    amber: "#f06548",
    lighter: "#e1ebfd",
    // tail: "#0ab39c"
}
const blueTheme = {
    main: "#028FC2",
    secondary: "#400d73",
    pink: "#cc0ca6"
}
const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    secondary: createGradient(SECONDARY.light, SECONDARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main)
};

const palette = {
    common: { black: '#000', white: '#fff' },
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    customsColor: {...CUSTOMSCOLORS},
    grey: GREY,
    gradients: GRADIENTS,
    divider: GREY[500_24],
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: GREY[200], neutral: GREY[200] },
    action: {
        active: GREY[600],
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48
    }
};

export default palette;