import PBIPortfolioEmbed from "./pbiPortfolioEmbed";

export const RiskManagementReport = (props) => {
    return (
        <PBIPortfolioEmbed
            title="Risk Management"
            reportId='421050e3-5165-4937-8598-a9e41a955361'
            pageName='ReportSection1f83c28d1d0e52759470'
            resource='Report'
            url='risk_management_portfolio'
        />
    )
}

export default RiskManagementReport;