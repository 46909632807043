import {
    Box, Card,
    Divider, IconButton, makeStyles, Tooltip, Typography, useMediaQuery, withStyles
} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useState } from "react";
import { required, SelectInput } from "react-admin";
import { Field } from 'react-final-form';
import styled from "styled-components";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const StyledSelectInput = styled(SelectInput)`
    .MuiOutlinedInput-root {
        background-color: #ffffff;
        font-size: 14px;
        line-height: 18px;
    }
`;

const useStyles = makeStyles((theme) => ({
    categoryCardHeader: {
        textAlign: "center",
        backgroundColor: "#F2F2F2",
        color: "#333333",
        fontSize: 16,
        fontStyle: "bold",
    },
    categoryCardBody: {
        textAlign: "center",
        backgroundColor: "#F2F2F2",
    },
    cardOutStyle: {
        boxShadow: 'none',
        borderRadius: 12,
        backgroundColor: '#F2F2F2'
    },
    cardMinimalText: {
        padding: 15,
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            display: "none"
        },
        textAlign: 'left'
    },
    outlineIcon: {
        width: 16,
        height: 16,
        marginTop: 3
    }
}));

export const ReviewCard = ({
    title,
    select_choices,
    assessmentSectionId,
    assessmentTypeId,
    assessmentSubTypeId,
    description,
    onChange,
}) => {
    const classes = useStyles();
    const handleActualsChange = (assessmentValueId) => {
        if (typeof onChange === "function") {
            onChange(assessmentSectionId, assessmentTypeId, assessmentSubTypeId, assessmentValueId)
        }
    }

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [assessmentValueDescription, setAssessmentValueDescription] = useState("");

    return (
        <Card className={classes.cardOutStyle}>
            <Box
                display="flex"
                justifyContent="center"
                p={2}
                className={classes.categoryCardHeader}
            >
                <Box display="flex" alignItems="center">
                    <Typography className={classes.heading}>
                        {title}
                    </Typography>
                    <LightTooltip title={description} placement="left-start">
                        <IconButton aria-label="info">
                            <InfoOutlinedIcon className={classes.outlineIcon} />
                        </IconButton>
                    </LightTooltip>
                </Box>
            </Box>
            <Divider />
            <Box
                display="flex"
                flexDirection={isSmallScreen && "column"}
                justifyContent="space-around"
                className={classes.categoryCardBody}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    flex={1}
                    p={2}
                >
                    <Box>Rating</Box>
                    <Box>
                        <StyledSelectInput
                            variant="outlined"
                            source={`changeAssessmentValues[_${assessmentSectionId}][_${assessmentTypeId}][_${assessmentSubTypeId}].assessmentValueId`}
                            label=""
                            optionText="nature"
                            validate={[required()]}
                            onChange={(e) => handleActualsChange(e.target.value)}
                            choices={select_choices}
                            helperText={isSmallScreen && `${assessmentValueDescription}`}
                        />
                    </Box>
                </Box>
                {!isSmallScreen && <Divider orientation="vertical" flexItem />}
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    flex={1}
                >
                    <Box className={classes.cardMinimalText}>
                        <Typography>
                            <Field name={`changeAssessmentValues['_${assessmentSectionId}']['_${assessmentTypeId}']['_${assessmentSubTypeId}'].assessmentValueId`} subscription={{ value: true }}>
                                {({ input: { value } }) => {
                                    if (value) {
                                        let text = select_choices.find(x => x.id == value).description;
                                        setAssessmentValueDescription(text);
                                        return text;
                                    }
                                    return null;
                                }}
                            </Field>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};

export default ReviewCard;