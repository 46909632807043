import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, } from '@mui/material';
import { withStyles } from '@mui/styles';
import { BorderBottom, Height } from '@mui/icons-material';

const styles = {
    root: {
        overflowX: 'auto',
        padding: 0
    },
    table: {
        minWidth: 650,
    },

};

const ResponsiveTable = ({ classes, tableResponse }) => {
    return (
        <TableContainer className={classes.root} style={{ padding: 0 }}>
            <Table component={Paper} className={classes.table} aria-label="responsive table">
                <TableBody>
                    {[...Array(6)].map((_, rowIdx) => (
                        <TableRow key={rowIdx}>
                            {[...Array(6)].map((_, colIdx) => (
                                <TableCell key={colIdx} sx={getTableCellStyle(rowIdx, colIdx)}>
                                    {getTableCellName(rowIdx, colIdx, tableResponse)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={6} sx={{
                            textAlign: "center",
                            border: "1px solid black",
                            backgroundColor: "#b3b3b3",
                            borderTop: "none",
                            fontWeight: "600"
                        }}>Consequence</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default withStyles(styles)(ResponsiveTable);


const getTableCellStyle = (rowIdx, colIdx) => {
    const style = { textAlign: "center", border: "1px solid black", fontWeight: "600", px: 10 }
    const low = { ...style, backgroundColor: "#c6efce", color: "#75bf84", }
    const medium = { ...style, backgroundColor: "#ffeb9c", color: "#d1c184", }
    const high = { ...style, backgroundColor: "#ffc000", color: "#bd910d", }
    const extreme = { ...style, backgroundColor: "#ffc7ce", color: "#c78189", }

    //low style
    if (rowIdx === 0 && (colIdx === 1 || colIdx === 2 || colIdx === 3)) {
        return low
    }

    if (colIdx === 1 && (rowIdx === 1 || rowIdx === 2)) {
        return low
    }

    //medium style
    if (rowIdx === 0 && (colIdx === 4 || colIdx === 5)) {
        return medium
    }

    if (rowIdx === 1 && (colIdx === 2 || colIdx === 3)) {
        return medium
    }

    if (rowIdx === 2 && (colIdx === 2)) {
        return medium
    }

    if (colIdx === 1 && (rowIdx === 3 || rowIdx === 4)) {
        return medium
    }

    //high style
    if (rowIdx === 1 && (colIdx === 4 || colIdx === 5)) {
        return high
    }

    if (rowIdx === 2 && (colIdx === 3 || colIdx === 4)) {
        return high
    }

    if (rowIdx === 3 && (colIdx === 2 || colIdx === 3)) {
        return high
    }

    if (colIdx === 2 && (rowIdx === 4)) {
        return high
    }

    //extreme style
    if (rowIdx === 2 && (colIdx === 5)) {
        return extreme
    }

    if (rowIdx === 3 && (colIdx === 4 || colIdx === 5)) {
        return extreme
    }

    if (rowIdx === 4 && (colIdx === 3 || colIdx === 4 || colIdx === 5)) {
        return extreme
    }

    if (colIdx === 0 && rowIdx < 5) {
        return { border: "1px solid black", }
    }

    if (rowIdx === 5 && colIdx > 0) {
        return {
            border: "1px solid black",

        }
    }

    if (colIdx === 0 && rowIdx === 5) {
        return {
            backgroundColor: "#b3b3b3",
            // borderBottom: "none",
            textAlign: "center",
            borderLeft: "1px solid black",
            fontWeight: "600",
            borderBottom: "#b3b3b3",
            width: 100
        }
    }


}

const getTableCellName = (rowIdx, colIdx, data) => {
    const likelihood = data.filter((item) => item.type === "Likelihood");
    const consequence = data.filter((item) => item.type === "Consequences");

    if (rowIdx === 0 && (colIdx === 1 || colIdx === 2 || colIdx === 3)) {
        return "LOW"
    }

    if (colIdx === 1 && (rowIdx === 1 || rowIdx === 2)) {
        return "LOW"
    }

    if (rowIdx === 0 && (colIdx === 4 || colIdx === 5)) {
        return "MEDIUM"
    }

    if (rowIdx === 1 && (colIdx === 2 || colIdx === 3)) {
        return "MEDIUM"
    }

    if (rowIdx === 2 && (colIdx === 2)) {
        return "MEDIUM"
    }

    if (colIdx === 1 && (rowIdx === 3 || rowIdx === 4)) {
        return "MEDIUM"
    }

    if (rowIdx === 1 && (colIdx === 4 || colIdx === 5)) {
        return "HIGH"
    }

    if (rowIdx === 2 && (colIdx === 3 || colIdx === 4)) {
        return "HIGH"
    }

    if (rowIdx === 3 && (colIdx === 2 || colIdx === 3)) {
        return "HIGH"
    }

    if (colIdx === 2 && (rowIdx === 4)) {
        return "HIGH"
    }

    if (rowIdx === 2 && (colIdx === 5)) {
        return "EXTREME"
    }

    if (rowIdx === 3 && (colIdx === 4 || colIdx === 5)) {
        return "EXTREME"
    }

    if (rowIdx === 4 && (colIdx === 3 || colIdx === 4 || colIdx === 5)) {
        return "EXTREME"
    }

    if (colIdx === 0 && rowIdx < 5) {
        return <Typography sx={{ width: "250px" }}>
            <Typography sx={{ fontWeight: "500" }}>
                {rowIdx + 1}. {likelihood[rowIdx]?.riskLabel}
            </Typography>
            {likelihood[rowIdx]?.riskDescription}
        </Typography>
    }

    if (rowIdx === 5 && colIdx > 0) {
        return <Typography sx={{ width: "300px", }}>
            <Typography sx={{ fontWeight: "500" }}>
                {colIdx}. {consequence[colIdx - 1]?.riskLabel}
            </Typography>
            {consequence[colIdx - 1]?.riskDescription}
        </Typography>
    }

    if (colIdx === 0 && rowIdx === 5) {
        return "Likelihood"
    }
}



// const getTableCell = (rowIdx, colIdx, type) => {
//     const style = { textAlign: "center", border: "1px solid black", fontWeight: "600" }
//     if (rowIdx === 0 && (colIdx === 1 || colIdx === 2 || colIdx === 3)) {
//         return <TableCell key={colIdx} sx={{...style,backgroundColor: "#c6efce", color: "#75bf84",}}>
//             LOW
//         </TableCell>
//     }

//     if (rowIdx === 0 && (colIdx === 4 || colIdx === 5)) {
//         return <TableCell key={colIdx} sx={{...style,backgroundColor: "#c6efce", color: "#75bf84",}}>
//         LOW
//     </TableCell>
//     }
// }