import axios from "axios";
import qs from "qs";
import { formatResponse, IsJsonString, sanitizeJson } from "../utils/AppUtils";
import { TokenStorage } from "../utils/TokenStorage";
import { API_URL, API_URL_FOR_PDF } from "./constants";
import { Query } from "./graphqlQueries";

const apiUrl = API_URL;
const apiUrlForPDF = API_URL_FOR_PDF;
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => {
        // Return a successful response back to the calling service

        /**
         * since graphql sends success response for all the requests
         * modified response should be sent by checking the status code on the response body
         */
        if (
            response.data.errors &&
            response.data.errors.length > 0 &&
            response.data.errors[0].extensions
        ) {
            const responseStatusCode = response.data.errors[0].extensions.code;
            formatResponse(response, responseStatusCode);
        }

        if (response.status >= 400) {
            return new Promise((resolve, reject) => {
                reject({ response: response });
            });
        }

        return response;
    },
    (error) => {
        if (
            error.response.data.errors &&
            error.response.data.errors.length > 0 &&
            error.response.data.errors[0].extensions
        ) {
            const responseStatusCode =
                error.response.data.errors[0].extensions.code;
            formatResponse(error.response, responseStatusCode);
        }

        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);

export const httpClient = async (url, options = {}) => {
    const token = TokenStorage.getToken();
    const impersonateUser = TokenStorage.getImpersonateUserDetails();

    options.headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json, text/plain, */*",
    };

    if (impersonateUser) {
        options.headers["impersonateid"] = impersonateUser.id;
    }

    return await axiosInstance({
        ...options,
        url: url,
        data:
            options.body && IsJsonString(options.body)
                ? sanitizeJson(JSON.parse(options.body))
                : options.body,
        // timeout: REQUEST_TIMEOUT
    }).then((resp) => {
        return { headers: resp.headers, json: resp.data, status: resp.status };
    });
};

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { filter } = params;

        const query = {
            page,
            perPage,
            sortField: field,
            sortOrder: order,
            filter,
        };

        const body = {
            operationName: null,
            query: Query[resource]["list"]["query"],
            variables: query,
        };

        const url = apiUrl;

        return httpClient(url, {
            method: "POST",
            body: body,
        }).then(({ headers, json }) => {
            if ("data" in json) {
                return {
                    data: json.data[Query[resource]["list"]["query_name"]]
                        .items,
                    total: json.data[Query[resource]["list"]["query_name"]]
                        .totalCount,
                };
            } else {
                throw new Error(
                    "The total number of results is unknown. The DRF data provider " +
                    "expects responses for lists of resources to contain this " +
                    "information to build the pagination. If you're not using the " +
                    "default PageNumberPagination class, please include this " +
                    'information using the Content-Range header OR a "count" key ' +
                    "inside the response."
                );
            }
        });
    },

    getOne: (resource, params) => {
        return httpClient(apiUrl, {
            method: "POST",
            body: {
                operationName: null,
                query: Query[resource]["retrieve"]["query"],
                variables: {
                    id: params.id, ...params.data
                },
            },
        }).then(({ json }) => ({
            data: {
                ...json.data[Query[resource]["retrieve"]["query_name"]],
                id: json.data[Query[resource]["retrieve"]["query_name"]].id || params.id,
            },
        }));
    },

    getMany: (resource, params) => {
        const ids = params.ids.map((record) => {
            if (record.hasOwnProperty("id")) return record.id;
            return record;
        });

        return Promise.all(
            ids.map((id) =>
                httpClient(apiUrl, {
                    method: "POST",
                    body: {
                        operationName: null,
                        query: Query[resource]["retrieve"]["query"],
                        variables: { id: id },
                    },
                })
            )
        ).then((responses) => ({
            data: responses.map(
                (response) =>
                    response.json.data[
                    Query[resource]["retrieve"]["query_name"]
                    ]
            ),
        }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { filter, target, id } = params;

        const query = {
            limit: perPage,
            offset: (page - 1) * perPage - 1,
            ordering: `${order === "ASC" ? "" : "-"}${field}`,
            ...filter,
            [target]: id,
        };
        const url = `${apiUrl}/${resource}/?${qs.stringify(query, {
            arrayFormat: "comma",
        })}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.results,
            total: json.count,
        }));
    },

    update: (resource, params) =>
        httpClient(apiUrl, {
            method: "POST",
            body: JSON.stringify({
                operationName: null,
                query: Query[resource]["update"]["query"],
                variables: { id: params.id, ...params.data },
            }),
        }).then(({ json }) => {
            return {
                data: json.data[Query[resource]["update"]["query_name"]],
            };
        }),

    updateMany: (resource, params) => {
        return Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}/`, {
                    method: "PUT",
                    body: JSON.stringify(params.data),
                })
            )
        ).then((responses) => ({
            data: responses.map((response) => response.json),
        }));
    },

    create: (resource, params) => {
        return httpClient(apiUrl, {
            method: "POST",
            body: JSON.stringify({
                operationName: null,
                query: Query[resource]["create"]["query"],
                variables: params.data,
            }),
        }).then(({ json }) => ({
            data: json.data[Query[resource]["create"]["query_name"]],
        }));
    },

    delete: (resource, params) =>
        httpClient(apiUrl, {
            method: "POST",
            body: JSON.stringify({
                operationName: null,
                query: Query[resource]["delete"]["query"],
                variables: { id: params.id },
            }),
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        return Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}/`, {
                    method: "DELETE",
                })
            )
        ).then((responses) => ({
            data: responses.map((response) => response.json),
        }));
    },

    getExport: (resource, params) => {
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([0, 10]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${qs.stringify(query, {
            arrayFormat: "comma",
        })}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data,
            total: json.total,
        }));
    },

    getRequest: (resource, params) => {
        return httpClient(
            `${apiUrl}/${resource}/${qs.stringify(params, {
                arrayFormat: "comma",
            })}/`
        ).then(({ headers, json }) => ({
            data: json,
        }));
    },

    postRequest: (resource, params) => {
        return httpClient(apiUrl, {
            method: "POST",
            body: JSON.stringify({
                operationName: null,
                query: Query[resource][params.queryType]["query"],
                variables: params.data,
            }),
        }).then(({ json }) => ({
            data: json.data[Query[resource][params.queryType]["query_name"]],
        }));
    },

    putRequest: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/`, {
            method: "PUT",
            body: JSON.stringify(params),
        }).then(({ headers, json }) => ({
            data: json,
        }));
    },
    postRequestForPDF: (resource, params) => {
        return httpClient(apiUrlForPDF, {
            method: "POST",
            body: JSON.stringify({
                operationName: null,
                query: Query[resource][params.queryType]["query"],
                variables: params.data,
            }),
        }).then(({ json }) => ({
            data: json.data[Query[resource][params.queryType]["query_name"]],
        }));
    },
};
