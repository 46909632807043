import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import {
    AutocompleteInput,
    RadioButtonGroupInput,
    TextInput,
    maxLength,
    required,
    useDataProvider,
    useRefresh
} from "react-admin";
import { Form } from "react-final-form";
import { YES_NO_CHOICES } from "../../framework/constants";
import { onFailureMessage } from "../../utils/AppUtils";
import CustomDrawer from "../customComponents/customDrawer";

const useStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        // padding: 16
    },
    inputMargin: {
        marginTop: 5
    },
    gridItemPadding: {
        padding: '0 0 14px 8px !important'
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: '#BDBDBD'
    },
    formWidth: {
        maxWidth: 350
    }
});

const industries = [
    { id: "Agriculture, Forestry and Fishing", name: "Agriculture, Forestry and Fishing" },
    { id: "Mining", name: "Mining" },
    { id: "Manufacturing", name: "Manufacturing" },
    { id: "Electricity, Gas, Water and Waste Services", name: "Electricity, Gas, Water and Waste Services" },
    { id: "Construction", name: "Construction" },
    { id: "Wholesale Trade", name: "Wholesale Trade" },
    { id: "Retail Trade", name: "Retail Trade" },
    { id: "Accommodation and Food Services", name: "Accommodation and Food Services" },
    { id: "Transport, Postal and Warehousing", name: "Transport, Postal and Warehousing" },
    { id: "Information Media and Telecommunications", name: "Information Media and Telecommunications" },
    { id: "Financial and Insurance Services", name: "Financial and Insurance Services" },
    { id: "Rental, Hiring and Real Estate Services", name: "Rental, Hiring and Real Estate Services" },
    { id: "Professional, Scientific and Technical Services", name: "Professional, Scientific and Technical Services" },
    { id: "Administrative and Support Services", name: "Administrative and Support Services" },
    { id: "Public Administration and Safety", name: "Public Administration and Safety" },
    { id: "Education and Training", name: "Education and Training" },
    { id: "Health Care and Social Assistance ", name: "Health Care and Social Assistance " },
    { id: "Arts and Recreation Services", name: "Arts and Recreation Services" },
    { id: "Government", name: "Government" },
    { id: "Other Services", name: "Other Services" },
];

export const UserCreateDrawer = ({ initialValues, drawerOpen, onClose,handleSnackbarOpen}) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const classes = useStyles();
    const [currency, setCurrency] = React.useState();

    const resource = "User";
    const onSubmit = (values, form) => {
        console.log(values);
        dataProvider
            .create(resource, {
                data: {
                    ...values,
                    firstName: values.fullName,
                },
            })
            .then((response) => {
                form.initialize({});
                form.restart();
                onClose(false);
                handleSnackbarOpen("Customer added successfully", "success");
                refresh();
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error");
            });
    };

    const validate = () => { };

    const handleChange = (event) => {
        setCurrency(event.target.value);
    };
    return (
        <CustomDrawer
            drawerAnchor="right"
            title="Create Client"
            drawerOpen={drawerOpen}
            onClose={onClose}
        >
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                    <form className={classes.formWidth} onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Client Name"
                                    source="companyName"
                                    validate={[required(), maxLength(255)]}
                                    className={classes.inputMargin}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Location"
                                    source="location"
                                    validate={[required(), maxLength(255)]}
                                    className={classes.inputMargin}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <AutocompleteInput
                                    variant="outlined"
                                    source="industry"
                                    label="Industry"
                                    choices={industries}
                                    className={classes.inputMargin}
                                    fullWidth
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <RadioButtonGroupInput
                                    label="Managed Services"
                                    source="isManagedService"
                                    row={true}
                                    choices={YES_NO_CHOICES}
                                    validate={[required()]}
                                    style={{ display: 'flex' }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Admin Name"
                                    source="fullName"
                                    validate={[required(), maxLength(255)]}
                                    className={classes.inputMargin}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Email"
                                    source="email"
                                    type="email"
                                    validate={[required(), maxLength(255)]}
                                    className={classes.inputMargin}
                                    inputProps={{
                                        classes: {
                                            notchedOutline: classes.inputBorder
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Comments"
                                    source="description"
                                    multiline
                                    validate={[maxLength(255)]}
                                    className={classes.inputMargin}
                                    inputProps={{
                                        style: {
                                            height: 80,
                                        },
                                        classes: {
                                            notchedOutline: classes.inputBorder
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                                <div className={classes.toolbar}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        Create
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        onClick={() => onClose(false)}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </CustomDrawer>
    );
};

export default UserCreateDrawer;
