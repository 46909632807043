import {
  Box,
  Button,
  Divider,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Loading, useDataProvider, useNotify } from "react-admin";
import { impersonatedURL, onFailureMessage } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import { Alert, Snackbar } from "@mui/material";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  actionToolbar: {
    display: "flex",
    marginTop: 40,
    justifyContent: "flex-end",
  },
  forecastTitle: {
    backgroundColor: "#F2F2F2",
    display: "flex",
  },
  forecastCard: {
    marginBottom: 30,
    margin: "0 auto",
    width: "90%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  forecastCardRow: {
    display: "flex",
  },
  forecastCardWeek: {
    width: "17%",
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      flexDirection: "column",
    },
    textAlign: "center",
  },
  forecastRiskContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    placeItems: "start",
    margin: "5px 4px",
    alignItems: "flex-start",
  },
  forecastRiskBox: {
    width: "150px",
    height: 51,
    [theme.breakpoints.down("md")]: {
      minWidth: 64,
      height: 40,
    },
    opacity: 0.3,
    borderRadius: 4,
    "&.active": {
      opacity: 0.7,
    },
    "&.risk-high": {
      backgroundColor: "#EB5757",
    },
    "&.risk-medium": {
      backgroundColor: "#F2994A",
    },
    "&.risk-low": {
      backgroundColor: "#27AE60",
    },
    "&.border": {
      opacity: 1,
    },
  },
  forecastTitleText: {
    fontSize: 16,
    fontWeight: 500,
    fontStyle: "normal",
    margin: 0,
  },
  commentary: {
    width: "100%",
    height: "150px",
  },
  selectedAssessment: {
    margin: "0 10px",
    padding: "4px 4px",
    boprderRadius: "10px",
    textAlign: "center",
    "&.activeRed": {
      border: "2px solid #EB5757",
    },
    "&.activeGreen": {
      border: "2px solid #27AE60",
    },
    "&.activeAmber": {
      border: "2px solid #F2994A",
    },
  },
  overallImpact: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const EnvironmentAssessment = ({
  record,
  activeStep,
  handleBack,
  handleNext,
  steps,
  setIsUpdateProjectDetails,
}) => {
  const [assessmentMasters, setAssessmentMaster] = useState([]);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  // const form = useForm();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const localProjectDetails = TokenStorage.getProjectDetails();
  const [impactForcast, setImpactForcast] = useState([]);
  // const formValues = form.getState().values;
  const [activeIndex, setActiveIndex] = useState([]);
  const [envCalculation, setEnvCalculation] = useState([]);
  const [mtCalculation, setMtCalculation] = useState([]);
  const [stCalculation, setStCalculation] = useState([]);
  const [commentary, setCommentary] = useState([]);
  const [overallCommentary, setOverallCommentary] = useState([
    { commentary: "", type: "Environment" },
    { commentary: "", type: "Maturity" },
    { commentary: "", type: "Saturation" },
  ]);
  const [impactResponse, setImpactResponse] = useState([]);
  const [isUpdatedAssessment, setIsUpdatedAssesment] = useState([]);
  const [isImpactsLoading, setImpactsLoading] = useState(true);
  const user = TokenStorage.getUserDetails();
  const { isImpersonating } = user ? user : {};
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({ message: name, type: type })
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  const [anchorOrigin, setAnchorOrigin] = useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = useState({
    message: "",
    type: ""
  });

  const { vertical, horizontal } = anchorOrigin;

  useEffect(() => {
    dataProvider
      .postRequest("ChangeAssessmentMaster", {
        // id: null,
        queryType: "retrieve",
        data: { type: ["Maturity", "Saturation", "Environment"] },
      })
      .then((response) => {
        setAssessmentMaster(response.data);
        setImpactsLoading(false);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    dataProvider
      .postRequest("ChangeAssessmentDetails", {
        // id: null,
        queryType: "retrieve",
        data: {
          projectId: localProjectDetails.id,
          type: ["Maturity", "Saturation", "Environment"],
        },
      })
      .then((response) => {
        setActiveIndex(
          response.data.assessmentDetails.map((item) => item.impactLevel)
        );
        setCommentary(
          response.data.assessmentDetails.map((item) => item.commentary)
        );
        setEnvCalculation(
          response.data.assessmentDetails.map((item) => {
            if (item.type == "Environment") return item.score;
          })
        );
        setMtCalculation(
          response.data.assessmentDetails.map((item) => {
            if (item.type == "Maturity") return item.score;
          })
        );
        setStCalculation(
          response.data.assessmentDetails.map((item) => {
            if (item.type == "Saturation") return item.score;
          })
        );
        setIsUpdatedAssesment(response.data);
        setImpactForcast(response.data.assessmentDetails);
        setOverallCommentary(
          response.data.assessmentScores.length > 0
            ? response.data.assessmentScores
            : [
              { commentary: "", type: "Environment" },
              { commentary: "", type: "Maturity" },
              { commentary: "", type: "Saturation" },
            ]
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleRiskBtnClick = async (index, imp, activeIndx) => {
    const calc =
      Number(imp?.dimensionWeight) * Number(activeIndx?.dimensionScore);

    console.log(imp.type, calc);

    if (imp.type === "Environment") {
      const updateCalc = [...envCalculation];
      updateCalc[index] = calc;
      setEnvCalculation(updateCalc);
    } else if (imp.type === "Maturity") {
      const updateCalc = [...mtCalculation];
      updateCalc[index] = calc;
      setMtCalculation(updateCalc);
    } else if (imp.type === "Saturation") {
      const updateCalc = [...stCalculation];
      updateCalc[index] = calc;
      setStCalculation(updateCalc);
    }

    const udpateIndex = [...activeIndex];
    udpateIndex[index] = activeIndx?.name;
    setActiveIndex(udpateIndex);
    const params = {
      projectId: localProjectDetails?.id,
      changeAssessmentMasterId: Number(imp.id),
      impactLevel: activeIndx?.name,
      type: imp.type,
      score: calc,
      id: impactForcast[index]?.id || null,
    };
    const updateImpacts = [...impactForcast];
    updateImpacts[index] = params;
    setImpactForcast(updateImpacts);
  };
  const handleCommentary = (index, id, value) => {
    if (value?.length <= 255) {
      const updateCommentary = [...commentary];
      updateCommentary[index] = value;
      setCommentary(updateCommentary);
    }
  };
  const handleOverallCommentary = (index, value, type) => {
    // console.log(value)
    if (value?.length <= 255) {
      const updateCommentary = [...overallCommentary];
      updateCommentary[index] = {
        id: updateCommentary[index]?.id || null,
        commentary: value,
        type: type,
      };
      setOverallCommentary(updateCommentary);
    }
  };
  const handleSteper = async (values, status) => {
    const stepers = {
      id: localProjectDetails.id,
      isDraft: true,
      stepCount: 4,
    };
    dataProvider
      .postRequest("Project", {
        queryType: "updateStep",
        data: stepers,
      })
      .then((response) => {
        // form.restart();
        console.log(response);
        // onClose(false, true);
        if (status == "draft") {
          TokenStorage.clearProjectData();
          impersonatedURL(isImpersonating, "#/project?impersonated");
        } else {
          handleNext(response.data);
        }
        // refresh();
        // createMilestoneView()
      })
      .catch((error) => {
        console.log(error);
        const error_messages = onFailureMessage(error);
        handleSnackbarOpen(error_messages, "error")
      });
  };

  const validation = (value) => {
    // console.log(value)
    let hasImpactLevel = true
    if (value.length === 9) {
      value.map((item) => {
        if (!item) {
          handleSnackbarOpen(`Please fill the all change environment assessment`, "warning")
          hasImpactLevel = false
        }
      })
      return hasImpactLevel
    }
    else {
      handleSnackbarOpen(`Please fill the all change environment assessment`, "warning")
    }

    return false
  }
  const handleSubmit = (status) => {
    // console.log(impactForcast);
    // const uniqueArray = Array.from(new Set(impactForcast.map(item => item.changeAssessmentMasterId)))
    //     .map(changeAssessmentMasterId => impactForcast.find(item => item.changeAssessmentMasterId === changeAssessmentMasterId));

    const isValidated = validation(impactForcast)
    // if (impactForcast.length === 9) {
    if (isValidated) {


      const requestBody = impactForcast.map((item, index) => {
        const com = commentary[index];
        if (com !== undefined) {
          return {
            ...item,
            commentary: com,
          };
        } else {
          return {
            ...item,
            commentary: "",
          };
        }
      });
      const overallAssessments = [
        {
          id: overallCommentary[0]?.id || null,
          projectId: localProjectDetails.id,
          overallScore: [
            ...envCalculation,
            ...mtCalculation,
            ...stCalculation,
          ].reduce((acc, current) => acc + (current || 0), 0),
          commentary: overallCommentary[0]?.commentary,
          type: "Environment",
        },
        {
          id: overallCommentary[1]?.id || null,
          projectId: localProjectDetails.id,
          overallScore: mtCalculation.reduce(
            (acc, current) => acc + (current || 0),
            0
          ),
          commentary: overallCommentary[1]?.commentary,
          type: "Maturity",
        },
        {
          id: overallCommentary[2]?.id || null,
          projectId: localProjectDetails.id,
          overallScore: stCalculation.reduce(
            (acc, current) => acc + (current || 0),
            0
          ),
          commentary: overallCommentary[2]?.commentary,
          type: "Saturation",
        },
      ];
      const values = {
        projectId: localProjectDetails.id,
        assessmentDetails: requestBody,
        assessmentScores: overallAssessments,
        type: ["Maturity", "Saturation", "Environment"],
      };
      // if (isUpdatedAssessment.length > 0) {
      dataProvider
        .postRequest("ChangeAssessmentDetails", {
          queryType: "update",
          data: values,
        })
        .then(async (response) => {
          setImpactResponse(response.data);
          //
          if (status === "draft" || localProjectDetails?.isDraft == true) {
            await handleSteper(response.data, status);
          } else {
            handleNext(response.data);
          }
          console.log(response);
        })
        .catch((error) => {
          handleSnackbarOpen(`Environment assessment changes: ${error.message}`, "error");
          console.log(error);
        });
    }
    // } else {

    // }
  };

  const getEnvCalculation = (calculation) => {
    const totalCalculation = calculation?.reduce(
      (acc, current) => acc + (current || 0),
      0
    );

    if (totalCalculation >= 1 && totalCalculation <= 4) {
      return { color: "#27AE60", label: "Low" };
    } else if (totalCalculation > 22 && totalCalculation <= 7) {
      return { color: "#F2994A", label: "Medium" };
    } else if (totalCalculation > 9) {
      return { color: "#EB5757", label: "High" };
    }
    return { color: "", label: "" }; // Handle other cases if needed
  };
  const getMtCalculation = (calculation) => {
    const totalCalculation = calculation?.reduce(
      (acc, current) => acc + (current || 0),
      0
    );

    if (totalCalculation >= 1 && totalCalculation <= 4) {
      return { color: "#27AE60", label: "Low" };
    } else if (totalCalculation > 22 && totalCalculation <= 7) {
      return { color: "#F2994A", label: "Medium" };
    } else if (totalCalculation > 9) {
      return { color: "#EB5757", label: "High" };
    }
    return { color: "", label: "" }; // Handle other cases if needed
  };
  const getStCalculation = (calculation) => {
    const totalCalculation = calculation?.reduce(
      (acc, current) => acc + (current || 0),
      0
    );

    if (totalCalculation >= 1 && totalCalculation <= 22) {
      return { color: "#27AE60", label: "Low" };
    } else if (totalCalculation > 22 && totalCalculation <= 30) {
      return { color: "#F2994A", label: "Medium" };
    } else if (totalCalculation > 30) {
      return { color: "#EB5757", label: "High" };
    }
    return { color: "", label: "" }; // Handle other cases if needed
  };
  return (
    <div>
      {!isImpactsLoading ? (
        assessmentMasters?.map((impact, index) => (
          <div>
            {isSmall ? (
              <div>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography className={classes.heading}>
                    {index + 1}
                    {". "} {impact?.name}
                  </Typography>
                  <LightTooltip
                    title={impact?.description}
                    placement="left-start"
                  >
                    <IconButton aria-label="info">
                      <InfoOutlinedIcon className={classes.outlineIcon} />
                    </IconButton>
                  </LightTooltip>
                </Box>
                <Box
                  className={clsx(classes.selectedAssessment, {
                    activeRed:
                      impact.impactLevels[2]?.name ===
                      impactForcast[index]?.impactLevel,
                  })}
                >
                  <Button
                    className={clsx(classes.forecastRiskBox, {
                      active: impact
                        ? impact.impactLevels[2]?.name === "Emerging"
                        : false,
                      "risk-high": true,
                      border:
                        impact.impactLevels[2]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                    style={{ width: "100%" }}
                    onClick={() =>
                      handleRiskBtnClick(
                        index,
                        impact,
                        impact.impactLevels[2]
                      )
                    }
                  >
                    {impact.impactLevels[2]?.name}
                  </Button>
                </Box>
                <Box
                  className={clsx(classes.selectedAssessment, {
                    activeAmber:
                      impact.impactLevels[1]?.name ===
                      impactForcast[index]?.impactLevel,
                  })}
                >
                  <Button
                    className={clsx(classes.forecastRiskBox, {
                      active: impact
                        ? impact.impactLevels[1]?.name === "Developing"
                        : false,
                      "risk-medium": true,
                      border:
                        impact.impactLevels[1]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                    style={{ width: "100%" }}
                    onClick={() =>
                      handleRiskBtnClick(
                        index,
                        impact,
                        impact.impactLevels[1]
                      )
                    }
                  >
                    {impact.impactLevels[1]?.name}
                  </Button>
                </Box>
                <Box
                  className={clsx(classes.selectedAssessment, {
                    activeGreen:
                      impact.impactLevels[0]?.name ===
                      impactForcast[index]?.impactLevel,
                  })}
                >
                  <Button
                    className={clsx(classes.forecastRiskBox, {
                      active: impact
                        ? impact.impactLevels[0]?.name === "Mature"
                        : false,
                      "risk-low": true,
                      border:
                        impact.impactLevels[0]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                    style={{ width: "100%" }}
                    onClick={() =>
                      handleRiskBtnClick(
                        index,
                        impact,
                        impact.impactLevels[0]
                      )
                    }
                  >
                    {impact.impactLevels[0]?.name}
                  </Button>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <TextareaAutosize
                    aria-label="commentary"
                    minRows={3}
                    placeholder={`Enter ${impact.type} commentary (max 255 characters)`}
                    style={{
                      width: "90%",
                      margin: "10px 0",
                      padding: "4px 4px",
                    }}
                    onChange={(e) =>
                      handleCommentary(index, impact.id, e.target.value)
                    }
                    value={commentary[index] || ""}
                  />
                </Box>
                <Box sx={{ margin: "10px" }}>
                  <Divider />
                </Box>
              </div>
            ) : (
              <div className={classes.forecastCardRow}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    flexDirection: "column",
                    width: "20%",
                    padding: "18px",
                    borderRight: "1px solid #C4C4C4",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.heading}>
                      {index + 1}
                      {". "} {impact?.name}
                    </Typography>
                    <LightTooltip
                      title={impact?.description}
                      placement="left-start"
                    >
                      <IconButton aria-label="info">
                        <InfoOutlinedIcon className={classes.outlineIcon} />
                      </IconButton>
                    </LightTooltip>
                  </Box>
                </Box>
                <div className={classes.forecastRiskContainer}>
                  <Box
                    className={clsx(classes.selectedAssessment, {
                      activeRed:
                        impact.impactLevels[2]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                  >
                    <HtmlTooltip
                      title={
                        <Box>
                          <Typography color="inherit">
                            {" "}
                            {impact.impactLevels[2]?.description &&
                              impact.impactLevels[2]?.description
                                .split("\n")
                                .map((text, index) => (
                                  <React.Fragment key={index}>
                                    {text}
                                    <br />
                                  </React.Fragment>
                                ))}
                          </Typography>
                        </Box>
                      }
                    >
                      <Button
                        className={clsx(classes.forecastRiskBox, {
                          active: impact
                            ? impact.impactLevels[2]?.name === "Emerging"
                            : false,
                          "risk-high": true,
                          border:
                            impact.impactLevels[2]?.name ===
                            impactForcast[index]?.impactLevel,
                        })}
                        onClick={() =>
                          handleRiskBtnClick(
                            index,
                            impact,
                            impact.impactLevels[2]
                          )
                        }
                      >
                        {impact.impactLevels[2]?.name}
                      </Button>
                    </HtmlTooltip>
                  </Box>
                  <Box
                    className={clsx(classes.selectedAssessment, {
                      activeAmber:
                        impact.impactLevels[1]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                  >
                    <HtmlTooltip
                      title={
                        <Box>
                          <Typography color="inherit">
                            {" "}
                            {impact.impactLevels[1]?.description &&
                              impact.impactLevels[1]?.description
                                .split("\n")
                                .map((text, index) => (
                                  <React.Fragment key={index}>
                                    {text}
                                    <br />
                                  </React.Fragment>
                                ))}
                          </Typography>
                        </Box>
                      }
                    >
                      <Button
                        className={clsx(classes.forecastRiskBox, {
                          active: impact
                            ? impact.impactLevels[1]?.name === "Developing"
                            : false,
                          "risk-medium": true,
                          border:
                            impact.impactLevels[1]?.name ===
                            impactForcast[index]?.impactLevel,
                        })}
                        onClick={() =>
                          handleRiskBtnClick(
                            index,
                            impact,
                            impact.impactLevels[1]
                          )
                        }
                      >
                        {impact.impactLevels[1]?.name}
                      </Button>
                    </HtmlTooltip>
                  </Box>
                  <Box
                    className={clsx(classes.selectedAssessment, {
                      activeGreen:
                        impact.impactLevels[0]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                  >
                    <HtmlTooltip
                      title={
                        <Box>
                          <Typography color="inherit">
                            {" "}
                            {impact.impactLevels[0]?.description &&
                              impact.impactLevels[0]?.description
                                .split("\n")
                                .map((text, index) => (
                                  <React.Fragment key={index}>
                                    {text}
                                    <br />
                                  </React.Fragment>
                                ))}
                          </Typography>
                        </Box>
                      }
                    >
                      <Button
                        className={clsx(classes.forecastRiskBox, {
                          active: impact
                            ? impact.impactLevels[0]?.name === "Mature"
                            : false,
                          "risk-low": true,
                          border:
                            impact.impactLevels[0]?.name ===
                            impactForcast[index]?.impactLevel,
                        })}
                        onClick={() =>
                          handleRiskBtnClick(
                            index,
                            impact,
                            impact.impactLevels[0]
                          )
                        }
                      >
                        {impact.impactLevels[0]?.name}
                      </Button>
                    </HtmlTooltip>
                  </Box>
                  <Box>
                    {/* <TextField id="standard-basic" label="Commentary" InputProps={{
                                    classes: {
                                        input: classes.commentary,
                                        // notchedOutline: classes.inputBorder,
                                    },
                                }} name="comentary" variant="outlined"  /> */}

                    <TextareaAutosize
                      aria-label="commentary"
                      minRows={3}
                      placeholder={`Enter ${impact.type} commentary (max 255 characters)`}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleCommentary(index, impact.id, e.target.value)
                      }
                      value={commentary[index] || ""}
                    />
                  </Box>
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <Loading />
      )}
      <Box style={{ width: "100%", backgroundColor: "#c4cdd5" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {mtCalculation.length > 0 &&
          overallCommentary.map(
            (item, index) =>
              item.type === "Maturity" && (
                <Box style={{ width: "100%" }}>
                  <p
                    style={{ fontSize: "20px", fontWeight: "600", textAlign: 'center' }}
                  >{`Change ${item.type}`}</p>
                  <Box
                    className={classes.overallImpact}
                  >
                    {mtCalculation.reduce(
                      (acc, current) => acc + (current || 0),
                      0
                    ) > 0 &&
                      mtCalculation.reduce(
                        (acc, current) => acc + (current || 0),
                        0
                      ) < 2 && (
                        <Button
                          style={{
                            padding: "20px 16px",
                            borderRadius: "5px",
                            backgroundColor: "#27AE60",
                            width: isSmall ? "90%" : "180px",
                          }}
                        >
                          Mature
                        </Button>
                      )}
                    {mtCalculation.reduce(
                      (acc, current) => acc + (current || 0),
                      0
                    ) > 1 &&
                      mtCalculation.reduce(
                        (acc, current) => acc + (current || 0),
                        0
                      ) < 3 && (
                        <Button
                          style={{
                            padding: "20px 16px",
                            borderRadius: "5px",
                            backgroundColor: "#F2994A",
                            width: isSmall ? "90%" : "180px",
                          }}
                        >
                          Developing
                        </Button>
                      )}
                    {mtCalculation.reduce(
                      (acc, current) => acc + (current || 0),
                      0
                    ) > 2 && (
                        <Button
                          style={{
                            padding: "20px 16px",
                            borderRadius: "5px",
                            backgroundColor: "#EB5757",
                            width: isSmall ? "90%" : "180px",
                          }}
                        >
                          Emerging
                        </Button>
                      )}
                    <TextareaAutosize
                      aria-label="commentary"
                      minRows={3}
                      placeholder="Enter Overall Maturity commentary (max 255 characters)"
                      style={{
                        width: isSmall ? "90%" : "320px",
                        margin: isSmall ? "10px 0" : "0 5px",
                        padding: "6px 2px",
                      }}
                      onChange={(e) =>
                        handleOverallCommentary(1, e.target.value, "Maturity")
                      }
                      value={item?.commentary || ""}
                      helperText={`${item?.commentary?.length}/255 characters`}
                    />
                  </Box>
                </Box>
              )
          )}
        {stCalculation.length > 0 &&
          overallCommentary.map(
            (item, index) =>
              item.type === "Saturation" && (
                <Box style={{ width: "100%" }}>
                  <p
                    style={{ fontSize: "20px", fontWeight: "600", textAlign: 'center' }}
                  >{`Change ${item.type}`}</p>
                  <Box
                    className={classes.overallImpact}
                  >
                    {stCalculation.reduce(
                      (acc, current) => acc + (current || 0),
                      0
                    ) >= 1 &&
                      stCalculation.reduce(
                        (acc, current) => acc + (current || 0),
                        0
                      ) <= 4 && (
                        <Button
                          style={{
                            padding: "20px 16px",
                            borderRadius: "5px",
                            backgroundColor: "#27AE60",
                            width: isSmall ? "90%" : "180px",
                          }}
                        >
                          Mature
                        </Button>
                      )}
                    {stCalculation.reduce(
                      (acc, current) => acc + (current || 0),
                      0
                    ) >= 5 &&
                      stCalculation.reduce(
                        (acc, current) => acc + (current || 0),
                        0
                      ) <= 7 && (
                        <Button
                          style={{
                            padding: "20px 16px",
                            borderRadius: "5px",
                            backgroundColor: "#F2994A",
                            width: isSmall ? "90%" : "180px",
                          }}
                        >
                          Developing
                        </Button>
                      )}
                    {stCalculation.reduce(
                      (acc, current) => acc + (current || 0),
                      0
                    ) > 7 && (
                        <Button
                          style={{
                            padding: "20px 16px",
                            borderRadius: "5px",
                            backgroundColor: "#EB5757",
                            width: isSmall ? "90%" : "180px",
                          }}
                        >
                          Emerging
                        </Button>
                      )}
                    <TextareaAutosize
                      aria-label="commentary"
                      minRows={3}
                      placeholder="Enter Overall Saturation commentary (max 255 characters)"
                      style={{
                        width: isSmall ? "90%" : "320px",
                        margin: isSmall ? "10px 0" : "0 5px",
                        padding: "6px 2px",
                      }}
                      onChange={(e) =>
                        handleOverallCommentary(2, e.target.value, "Saturation")
                      }
                      value={item?.commentary || ""}
                    />
                  </Box>
                </Box>
              )
          )}
        {[...envCalculation, ...mtCalculation, ...stCalculation].length > 0 &&
          overallCommentary.map(
            (item, index) =>
              item.type === "Environment" && (
                <Box style={{ width: "100%" }}>
                  <p
                    style={{ fontSize: "20px", fontWeight: "600", textAlign: 'center' }}
                  >{`Change ${item.type}`}</p>
                  <Box
                    className={classes.overallImpact}
                  >
                    {[
                      ...envCalculation,
                      ...mtCalculation,
                      ...stCalculation,
                    ].reduce((acc, current) => acc + (current || 0), 0) > 0 &&
                      [
                        ...envCalculation,
                        ...mtCalculation,
                        ...stCalculation,
                      ].reduce((acc, current) => acc + (current || 0), 0) <=
                      11 && (
                        <Button
                          style={{
                            padding: "20px 16px",
                            borderRadius: "5px",
                            backgroundColor: "#27AE60",
                            width: isSmall ? "90%" : "180px",
                          }}
                        >
                          Mature
                        </Button>
                      )}
                    {[
                      ...envCalculation,
                      ...mtCalculation,
                      ...stCalculation,
                    ].reduce((acc, current) => acc + (current || 0), 0) > 11 &&
                      [
                        ...envCalculation,
                        ...mtCalculation,
                        ...stCalculation,
                      ].reduce((acc, current) => acc + (current || 0), 0) <=
                      19 && (
                        <Button
                          style={{
                            padding: "20px 16px",
                            borderRadius: "5px",
                            backgroundColor: "#F2994A",
                            width: isSmall ? "90%" : "180px",
                          }}
                        >
                          Developing
                        </Button>
                      )}
                    {[
                      ...envCalculation,
                      ...mtCalculation,
                      ...stCalculation,
                    ].reduce((acc, current) => acc + (current || 0), 0) >
                      19 && (
                        <Button
                          style={{
                            padding: "20px 16px",
                            borderRadius: "5px",
                            backgroundColor: "#EB5757",
                            width: isSmall ? "90%" : "180px",
                          }}
                        >
                          Emerging
                        </Button>
                      )}
                    <TextareaAutosize
                      aria-label="commentary"
                      minRows={3}
                      placeholder="Enter Overall Environment commentary (max 255 characters)"
                      style={{
                        width: isSmall ? "90%" : "320px",
                        margin: isSmall ? "10px 0" : "0 5px",
                        padding: "6px 2px",
                      }}
                      onChange={(e) =>
                        handleOverallCommentary(
                          0,
                          e.target.value,
                          "Environment"
                        )
                      }
                      value={item?.commentary || ""}
                    />
                  </Box>
                </Box>
              )
          )}
      </Box>

      <div className={classes.actionToolbar}>
        {activeStep > 0 && (
          <Button
            type="button"
            variant="outlined"
            color="primary"
            size="large"
            style={{ marginRight: 25 }}
            onClick={handleBack}
          >
            Previous
          </Button>
        )}
        {!(activeStep === steps.length) && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            style={{ marginRight: 25 }}
            onClick={() => handleSubmit("notDraft")}
          >
            Next
          </Button>
        )}
        {!(activeStep === steps.length) &&
          localProjectDetails?.isDraft == true && (
            <Button
              type="button"
              variant="contained"
              color="secondary"
              size="large"
              style={{ marginRight: 25 }}
              onClick={() => handleSubmit("draft")}
            // onClick={this.handleSubmit}
            >
              Save To Draft
            </Button>
          )}
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
        <Alert

          onClose={hanndleSnackbar}
          severity={popupMessage.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {popupMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
