
export const RISK_ASSESSMENT_CREATE_MUTATION = `mutation($projectId: Int!, $riskAssessments: [RiskAssessmentInput!]!,$otherRiskAssessments: [OtherRiskAssessmentMasterInput]) {
    createRiskAssessment(
      projectId: $projectId
      riskAssessments: $riskAssessments
      otherRiskAssessments: $otherRiskAssessments
    ) {
      id
      projectId
      riskAssessmentMasterId
      consequence
      likelihood
      score
      commentary
    }
  }`;

  export const RISK_ASSESSMENT_QUERY = `query($projectId: Int!) {
    getRiskAssessments(
      projectId: $projectId
    ) {
      id
      projectId
      riskAssessmentMasterId
      consequence
      likelihood
      score
      commentary
      createdAt
    }
  }
  `


  