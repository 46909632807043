import { Box, Grid, TextField, Typography } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import arrayMutators from "final-form-arrays";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import {
    AutocompleteArrayInput, AutocompleteInput, DateInput, Error, FormDataConsumer, Loading,
    RadioButtonGroupInput, ReferenceArrayInput, ReferenceInput,
    TextInput,
    maxLength,
    required,
    useDataProvider,
    useNotify
} from "react-admin";
import { Field, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import {
    INACTIVE_ACTIVE_CHOICES, PROJECT_DRIVERS_MASTER_DATA_ID, PROJECT_PHASE_MASTER_DATA_ID
} from "../../framework/constants";
import {
    onFailureMessage,
    startEndDatesOfWeeks
} from "../../utils/AppUtils";
import Wizard from "../customComponents/wizard";
import ProjectCustomDrawer from "./projectCustomDrawer";
import ProjectForecastsInputsContainer from "./projectForecastsInputsContainer";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    subTitle: {
        marginBottom: 16,
    },
    forecastTitle: {
        backgroundColor: "#F2F2F2",
        display: "flex",
    },
    forecastCard: {
        marginBottom: 30,
        margin: "0 auto",
        width: "90%",
        [theme.breakpoints.down("md")]: {
            width: "100%"
        }
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: "#BDBDBD",
    },
    inputWidthChange: {
        width: 322,
        [theme.breakpoints.down("xs")]: {
            width: '83vw',
        }
    },
    inputWidthMargin: {
        // marginRight: 100,
    },
    referenceInputWidth: {
        width: 350,
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        }
    },
    gridBoxMargin: {
        marginBottom: 12,
        width: 350,
    },
    phaseDateWidth: {
        width: 132,
        [theme.breakpoints.down("xs")]: {
            width: '35vw'
        }
    },
    gridContainer: {
        display: "grid",
        gridColumnGap: 100,
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
        },
        [theme.breakpoints.between("sm", "md")]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
        },
    },
    forecastTopDiv: {
        width: "95%",
    },
    projectNoteText: {
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 400,
        marginBottom: 30,
    },
    tabsComponentDiv: {
        marginTop: 20,
    },
    input: {
        borderColor: "#BDBDBD",
        width: '350px',
        position: 'relative',
        borderRadius: '4px'
    },
}));

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        height: 5,
        "& > span": {
            maxWidth: 80,
            width: "50%",
            backgroundColor: "#4F4F4F",
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        color: "#000",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: 60,
        "&:focus": {
            opacity: 1,
        },
        minWidth: 97,
        paddingLeft: 0,
        paddingRight: 0,
    },
}))((props) => <Tab disableRipple {...props} />);

export const ProjectEditDrawer = ({ record, drawerOpen, onClose }) => {
    const [initialValues, setInitialValues] = React.useState();
    const [projectInfo, setProjectInfo] = React.useState("");
    const [defaulDrriver, setDefaultDriver] = React.useState({ name: '' })
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const resource = "Project";
    const handleProjectDriverValues = (event, newValue) => {
        // console.log(newValue)
        setDefaultDriver(newValue)
    }
    const onSubmit = (values, form) => {
        const phases =
            values.projectPhases && values.projectPhases.length > 0
                ? values.projectPhases
                    .filter((x) => x && x.endDate != null && x.endDate != "")
                    .map((projectPhase, index) => ({
                        name: projectPhase.name,
                        startDate: projectPhase.startDate,
                        endDate: projectPhase.endDate,
                        goLiveDate: projectPhase.goLiveDate,
                    }))
                : null;
        let weeks = startEndDatesOfWeeks(
            moment(values.startDate),
            moment(values.endDate)
        );

        const impactedBuCount = values.impactedBusinessUnits
            ? values.impactedBusinessUnits.length
            : 0;
        let requiredWeeksCount = weeks.length * (impactedBuCount + 1);
        let buArrays = 0;
        for (let i = 0; i < values.projectForecasts.length; i++) {
            if (Array.isArray(values.projectForecasts[i])) {
                buArrays++;
            }
        }

        if (buArrays == 0) {
            let forecasts = [];
            for (let i = 0; i < values.projectForecasts.length; i++) {
                if (values.projectForecasts[i].week > weeks.length) {
                    continue;
                } else {
                    if (
                        values.projectForecasts[i].businessUnitId ==
                        values.businessUnitId ||
                        values.impactedBusinessUnits.includes(
                            values.projectForecasts[i].businessUnitId
                        )
                    ) {
                        forecasts[i] = values.projectForecasts[i];
                    }
                }
            }

            forecasts.forEach((forecast) => {
                delete forecast.id;
                delete forecast.businessUnit;
                delete forecast.projectId;
            });

            const newForecastsValues = forecasts.flat();
            values.projectForecasts = newForecastsValues;
        } else {
            let mainBuForecasts = [values.projectForecasts[values.businessUnitId]];
            let allForecasts = [...mainBuForecasts];
            values.impactedBusinessUnits.forEach((index) => {
                allForecasts.push(values.projectForecasts[index]);
            });
            allForecasts.forEach((forecast) => {
                forecast.forEach((el) => {
                    delete el.id;
                    delete el.businessUnit;
                    delete el.projectId;
                });
            });

            const newForecastsValues = allForecasts.flat();
            values.projectForecasts = newForecastsValues;
        }
        values.projectPhases = phases;
        values.projectDriver = defaulDrriver
        // console.log(values, form)
        dataProvider
            .update(resource, {
                data: values,
            })
            .then((response) => {
                form.restart();
                onClose(false);
                notify("Project updated successfully","success");
                window.location.reload(false);
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                notify(error_messages, "error");
            });
    };

    const WizardFormError = ({ name }) => (
        <Field
            name={name}
            subscription={{ touched: true, error: true }}
            render={({ meta: { touched, error } }) =>
                touched && error ? (
                    <span style={{ color: "#d32f2f" }}>{error}</span>
                ) : null
            }
        />
    );

    React.useEffect(() => {
        if (record && record.id)
            dataProvider.getOne(resource, { id: record.id }).then((response) => {
                response.data.projectForecasts = [];
                const impactedBusinessUnits = _.map(
                    response.data.impactedBusinessUnits,
                    "id"
                );
                setInitialValues({
                    ...response.data,
                    impactedBusinessUnits,
                });
                setDefaultDriver(response.data.projectDriver)
                // console.log("dsfasdfsadfsdfasdf",response.data )
            });
    }, [record]);

    return (
        <ProjectCustomDrawer
            drawerAnchor="right"
            title="Edit Project"
            projectInfo={projectInfo}
            drawerOpen={drawerOpen}
            onClose={onClose}
        >
            {initialValues && (
                <Wizard
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    mutators={{
                        ...arrayMutators,
                    }}
                    onClose={onClose}
                    setProjectInfo={setProjectInfo}
                >
                    <Wizard.Page>
                        <ProjectEditInputs driverDefalt={initialValues} selectedDrover={defaulDrriver} setDefaultDriver={handleProjectDriverValues} />
                    </Wizard.Page>
                    <Wizard.Page>
                        <Typography className={classes.subTitle}>Project Phases</Typography>
                        <ProjectPhasesInputs />
                    </Wizard.Page>
                    <Wizard.Page
                        validate={(values) => {
                            const errors = {};
                            let weeks = startEndDatesOfWeeks(
                                moment(values.startDate),
                                moment(values.endDate)
                            );
                            let weeksCount = weeks.length;
                            const impactedBuCount = values.impactedBusinessUnits
                                ? values.impactedBusinessUnits.length
                                : 0;
                            let forecastsValues =
                                values && values.projectForecasts
                                    ? values.projectForecasts.flat().filter(x => x.risk)
                                    : null;
                            if (forecastsValues) {
                                if (
                                    forecastsValues.length <
                                    weeksCount * (impactedBuCount + 1)
                                ) {
                                    errors.projectForecasts = "Please fill all the project forecast details";
                                }
                            } else {
                                errors.projectForecasts = "Please fill all the project forecast details";
                            }
                            return errors;
                        }}
                    >
                        <div className={classes.forecastTopDiv}>
                            <div>
                                <Typography>Change Impact Forecast</Typography>
                                <ForecastToolbar />
                            </div>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                            <WizardFormError name="projectForecasts" />
                        </div>
                    </Wizard.Page>
                </Wizard>
            )}
        </ProjectCustomDrawer>
    );
};

const ProjectEditInputs = ({ driverDefalt, setDefaultDriver, selectedDrover }) => {
    const classes = useStyles();
    const [projectDriverChoices, setProjectDriverChoices] = React.useState();
    const [projectDriversLoading, setProjectDriversLoading] = React.useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const filterSelectedOption = (options, selectedOption) => {
        return options?.filter(option => option !== selectedOption);
    };
    const validateStartDate = (startDate, values) => {
        const endDate = values.endDate;
        if (startDate && endDate && startDate >= endDate) {
            return "start date can not be greater than end date";
        }
    };

    const validateEndDate = (endDate, values) => {
        const startDate = values.startDate;
        if (endDate && startDate && endDate <= startDate) {
            return "end date can not be lesser than start date";
        }
    };
    // ----------------------------

    const validateGoLiveDate = (goLiveDate, values) => {
        const startDate = values.startDate;
        if (goLiveDate && startDate && goLiveDate <= startDate) {
            return "Go live date can be greater than start date";
        }
    };
    // ----------------------------------

    React.useEffect(() => {
        console.log(driverDefalt)
        setProjectDriversLoading(true);
        dataProvider
            .getOne("ProjecDriverMasterData", { id: PROJECT_DRIVERS_MASTER_DATA_ID })
            .then(({ data }) => {
                setProjectDriverChoices(data.masterDataValues);
                setProjectDriversLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setProjectDriversLoading(false);
                setProjectDriverChoices([]);
                notify("could not able to fetch project driver list","warning");
            });
    }, []);

    return (
        <Grid container spacing={2}>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <TextInput
                    variant="outlined"
                    label="Project Name"
                    source="name"
                    validate={[required(), maxLength(255)]}
                    className={classes.inputWidthMargin}
                    InputProps={{
                        classes: {
                            input: classes.inputWidthChange,
                            notchedOutline: classes.inputBorder,
                        },
                    }}
                />
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <ReferenceInput
                    disabled={true}
                    variant="outlined"
                    label="Business Unit"
                    source="businessUnitId"
                    reference="BusinessUnit"
                    validate={[required()]}
                    className={`${classes.referenceInputWidth} ${classes.inputWidthMargin}`}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <ReferenceArrayInput
                            fullWidth
                            variant="outlined"
                            label="Impacted Business Unit"
                            source="impactedBusinessUnits"
                            reference="BusinessUnit"
                            className={`${classes.referenceInputWidth} ${classes.inputWidthMargin}`}
                            filter={{
                                excludeIds:
                                    formData && formData.businessUnitId
                                        ? [formData.businessUnitId]
                                        : null,
                            }}
                        >
                            <AutocompleteArrayInput optionText="name" />
                        </ReferenceArrayInput>
                    )}
                </FormDataConsumer>
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <ReferenceInput
                    variant="outlined"
                    label="Project Sponsor"
                    source="projectSponsorId"
                    reference="User"
                    validate={[required()]}
                    className={`${classes.referenceInputWidth} ${classes.inputWidthMargin}`}
                >
                    <AutocompleteInput optionText="fullName" />
                </ReferenceInput>
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <ReferenceInput
                    variant="outlined"
                    label="Business Owner"
                    source="businessOwnerId"
                    reference="User"
                    validate={[required()]}
                    className={`${classes.referenceInputWidth} ${classes.inputWidthMargin}`}
                >
                    <AutocompleteInput optionText="fullName" />
                </ReferenceInput>
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>

                {/* <AutocompleteInput
                    variant="outlined"
                    label="Strategic Driver"
                    source="projectDriver"
                    optionValue="name"
                    optionText="name"
                    choices={projectDriverChoices}
                    defaultValue={'habi jabi'}
                    validate={[required()]}
                    fullWidth
                /> */}
                <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    // options={projectDriverChoices?.map((option) => option.name)}
                    options={filterSelectedOption(projectDriverChoices?.map(option => option.name), selectedDrover)}
                    defaultValue={[{ name: driverDefalt.projectDriver }]}
                    // value={driverDefalt.projectDriver}
                    value={selectedDrover}
                    className={classes.input}
                    // ListboxProps={{ style: { maxHeight: 20 } }}
                    renderInput={(params) => (
                        <TextField {...params}
                            label="Strategic Driver"
                            variant="outlined"
                            size="small"
                            required
                        />
                    )}
                    onChange={setDefaultDriver}
                />
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <DateInput
                    variant="outlined"
                    label="Start Date"
                    source="startDate"
                    validate={[required(), validateStartDate]}
                    InputProps={{
                        classes: {
                            input: classes.inputWidthChange,
                            notchedOutline: classes.inputBorder,
                        },
                    }}
                />
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <DateInput
                    variant="outlined"
                    label="End Date"
                    source="endDate"
                    style={{ marginRight: 100 }}
                    validate={[required(), validateEndDate]}
                    InputProps={{
                        classes: {
                            input: classes.inputWidthChange,
                            notchedOutline: classes.inputBorder,
                        },
                    }}
                    className={classes.inputWidthMargin}
                />
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <ReferenceInput
                    variant="outlined"
                    label="Change Lead"
                    source="changeLeaderId"
                    reference="User"
                    validate={[required()]}
                    className={`${classes.referenceInputWidth} ${classes.inputWidthMargin}`}
                >
                    <AutocompleteInput optionText="fullName" />
                </ReferenceInput>
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <DateInput
                    variant="outlined"
                    label="Go Live Date"
                    source="goLiveDate"
                    style={{ marginRight: 100 }}
                    validate={[required(), validateGoLiveDate]}
                    InputProps={{
                        classes: {
                            input: classes.inputWidthChange,
                            notchedOutline: classes.inputBorder,
                        },
                    }}
                    className={classes.inputWidthMargin}
                />
            </Grid>
            <Grid item xs={12}>
                <RadioButtonGroupInput
                    label="Status"
                    source="status"
                    row={true}
                    choices={INACTIVE_ACTIVE_CHOICES}
                    validate={[required()]}
                />
            </Grid>
        </Grid>
    );
};

const ProjectPhasesInputs = () => {
    const classes = useStyles();
    const form = useForm();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = React.useState();
    const [error, setError] = React.useState();

    const validateStartDate = (value, values, index) => {
        try {
            const startDate = value ? new Date(value) : null;
            const projectStartDate = values.startDate
                ? new Date(values.startDate)
                : null;
            const endDate = values.projectPhases[index].endDate
                ? new Date(values.projectPhases[index].endDate)
                : null;
            const prePhaseIndex = Math.max(index - 1, 0);
            const prePhaseEndDate = values.projectPhases[prePhaseIndex].endDate
                ? new Date(values.projectPhases[prePhaseIndex].endDate)
                : null;
            if (startDate && endDate && startDate >= endDate) {
                return "should be lesser than end date";
            }
            if (startDate && startDate < projectStartDate) {
                return `should be greater than "${projectStartDate.toLocaleDateString('en-GB')}"`;
            }
            if (
                index > 0 &&
                startDate &&
                prePhaseEndDate &&
                startDate <= prePhaseEndDate
            ) {
                return `should be greater than "${prePhaseEndDate.toLocaleDateString('en-GB')}"`;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const validateEndDate = (value, values, index) => {
        try {
            const endDate = value ? new Date(value) : null;
            const projectEndDate = values.endDate ? new Date(values.endDate) : null;
            const startDate = values.projectPhases[index].startDate
                ? new Date(values.projectPhases[index].startDate)
                : null;
            if (endDate && startDate && endDate <= startDate) {
                return "should be greater than start date";
            }
            if (endDate && endDate > projectEndDate) {
                return `should be lesser than "${projectEndDate.toLocaleDateString('en-GB')}"`;
            }
            if (startDate && !endDate) {
                return "end date is required";
            }
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {
        const formValues = form.getState().values;
        if (!formValues.projectPhases || (formValues.projectPhases && formValues.projectPhases.length === 0)) {
            setLoading(true);
            dataProvider
                .getOne("MasterData", { id: PROJECT_PHASE_MASTER_DATA_ID })
                .then((response) => {
                    form.change("projectPhases", response.data.masterDataValues)
                    setLoading(false);
                })
                .catch((error) => {
                    notify(`project phases are unavailable: ${error.message}`, "warning");
                    setError(error.message);
                    setLoading(false);
                });
        }
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <Error title={error} />;
    }

    return (
        <Grid container className={classes.gridContainer} spacing={2}>
            <FieldArray name="projectPhases">
                {({ fields }) =>
                    fields.map((name, index) => {
                        return (
                            <div key={name}>
                                <Grid >
                                    <TextInput
                                        variant="outlined"
                                        label={`Phase ${index + 1}`}
                                        source={`${name}.name`}
                                        disabled={true}
                                        validate={[required(), maxLength(255)]}
                                        InputProps={{
                                            classes: {
                                                input: classes.inputWidthChange,
                                                notchedOutline: classes.inputBorder,
                                            },
                                        }}
                                    />
                                </Grid>
                                {/*  <Grid className={classes.gridBoxMargin}> */}
                                <Grid >
                                    <Box style={{ display: "flex" }}>
                                        <DateInput
                                            variant="outlined"
                                            label="Start Date"
                                            source={`${name}.startDate`}
                                            style={{ marginRight: 15 }}
                                            validate={[
                                                required(),
                                                (value, values) => validateStartDate(value, values, index),
                                            ]}
                                            InputProps={{
                                                classes: {
                                                    input: classes.phaseDateWidth,
                                                    notchedOutline: classes.inputBorder,
                                                },
                                            }}
                                        />
                                        <DateInput
                                            variant="outlined"
                                            label="End Date"
                                            source={`${name}.endDate`}
                                            style={{ marginLeft: 15 }}
                                            validate={[
                                                required(),
                                                (value, values) => validateEndDate(value, values, index),
                                            ]}
                                            InputProps={{
                                                classes: {
                                                    input: classes.phaseDateWidth,
                                                    notchedOutline: classes.inputBorder,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </div>
                        )
                    })}
            </FieldArray>
        </Grid>
    );
};

const ProjectForecastsInputs = ({ businessUnitId, projectId }) => {
    const form = useForm();
    const dataProvider = useDataProvider();
    const formValues = form.getState().values;
    const availableForecasts = formValues.projectForecasts ? formValues.projectForecasts[businessUnitId] : null;
    const [projectForecasts, setProjectForecasts] = React.useState(availableForecasts);
    const [loading, setLoading] = React.useState();
    const { businessUnitId: mainBu, startDate, endDate } = formValues;

    React.useEffect(() => {
        setLoading(true);
        if (!availableForecasts || (availableForecasts && availableForecasts.length === 0)) {
            dataProvider
                .getList("ProjectForecast", {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: "endDate", order: "ASC" },
                    filter: { projectId, businessUnitId },
                })
                .then(({ data }) => {
                    let forecasts = data && data.length ? data : [];
                    let weeks = startEndDatesOfWeeks(moment(startDate), moment(endDate));
                    const forecastsCount = forecasts.length;
                    const weeksCount = weeks.length;
                    if (forecastsCount > weeksCount) {
                        forecasts.splice(weeksCount, forecastsCount);
                    } else if (forecastsCount < weeksCount) {
                        for (let i = forecastsCount; i < weeksCount; i++) {
                            forecasts[i] = {
                                ...weeks[i],
                                businessUnitId: businessUnitId,
                            }
                        }
                    }
                    setProjectForecasts(forecasts)
                    form.change(`projectForecasts[${businessUnitId}]`, forecasts);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error)
                    setProjectForecasts(null)
                    setLoading(false);
                })
        } else {
            setLoading(false);
        }
    }, [businessUnitId, projectId]);

    if (loading) return <Loading />
    if (projectForecasts) return <ProjectForecastsInputsContainer
        isCreate={false}
        projectForecasts={projectForecasts}
        setProjectForecasts={setProjectForecasts}
        businessUnitId={businessUnitId}
    />;

    return null;
};

const ForecastToolbar = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [value, setValue] = React.useState(0);
    const [valuedUnits, setvaluedUnits] = React.useState([]);
    const form = useForm();
    const formValues = form.getState().values;
    const impactedUnits = formValues.impactedBusinessUnits;
    const projectTitle = formValues.name;
    const { businessUnitId, id: projectId } = formValues;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        const variableInputs = async () => {
            const variableName = await dataProvider.getMany("BusinessUnit", {
                ids: impactedUnits,
            });
            setvaluedUnits(variableName.data);
        };
        if (impactedUnits) variableInputs();
    }, []);


    const ForecastPanel = (props) => {
        const { children, value, index } = props;
        return <Box display={value === index ? "block" : "none"}>{children}</Box>
    };

    return (
        <div className={classes.tabsComponentDiv}>
            <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
                variant="scrollable"
                scrollButtons="auto"
            >
                <StyledTab label={`${projectTitle}`} />
                {valuedUnits.length > 0
                    ? valuedUnits.map((unit) => (
                        <StyledTab key={unit.id} label={`${unit.name}`}></StyledTab>
                    ))
                    : null}
            </StyledTabs>
            <Typography className={classes.padding} />
            <ForecastPanel value={value} index={0}>
                <h6 className={classes.projectNoteText}>
                    Note : Click / tap on the color brick to select the impact. You can
                    change the severity of any week by selecting color brick of that week.
                    Please note that the followed bricks will change to same impact which
                    is selected
                </h6>
                <ProjectForecastsInputs businessUnitId={businessUnitId} projectId={projectId} />
            </ForecastPanel>
            {valuedUnits.length > 0
                ? valuedUnits.map((unit, index) => (
                    <ForecastPanel key={unit.id} value={value} index={index + 1}>
                        <h6 className={classes.projectNoteText}>
                            Note : Click / tap on the color brick to select the impact. You
                            can change the severity of any week by selecting color brick of
                            that week. Please note that the followed bricks will change to
                            same impact which is selected
                        </h6>
                        <ProjectForecastsInputs businessUnitId={unit.id} projectId={projectId} />
                    </ForecastPanel>
                ))
                : null}
        </div>
    );
};
export default ProjectEditDrawer;
