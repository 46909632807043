import React from 'react';
import {  hasPermissions } from './AppUtils';
import ErrorCard from './ErrorCard';
import { Loading } from 'react-admin';


const WithPermission = (props) => {
    const { bypassUser, permissions, resource, action, ...rest } = props;
    // console.log(bypassUser, permissions, resource, action);
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(false)
    React.useEffect(async () => {
        const hasPermission = permissions ? await hasPermissions(permissions.permissions, { resource, action }) : null
        if (!bypassUser && !hasPermission) {
            setError(true)
        } else {
            setError(false)
        }
        setLoading(false)
    }, [permissions])
    if (loading) {
        return (
            // <Loading />
            <Loading />
        )
    }
    if (error) {
        return (
            <ErrorCard
                message='Access Denied'
                text={`You do not have permission to ${resource} page.`}
                align='center'
            />
        )
    }

    return props.children;
}

export default WithPermission