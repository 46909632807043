import { Grid, Tooltip, Typography, useMediaQuery, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, CircularProgress, Divider, IconButton, InputAdornment, TextField, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Loading, Title, useDataProvider, useNotify } from "react-admin";
import { getFormatExtension, impersonatedURL, wordpressEndpoint } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DescriptionIcon from '@mui/icons-material/Description';
import axios from "axios";
import { Link } from 'react-router-dom';
import { WORDPRESS_ENDPOINT } from "../../framework/constants";
import SearchIcon from '@mui/icons-material/Search';
var FileSaver = require("file-saver");

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "uppercase",
    },
    nonAccordionCard: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    title: {
        textTransform: "uppercase",
        // fontSize: "20px" // Adjust the font size as needed
    }
}));

const LearningCentre = () => {
    const classes = useStyles();
    const user = TokenStorage.getUserDetails();
    const { isImpersonating } = user ? user : {};
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [posts, setPosts] = useState(null);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [query, setQuery] = useState('');
    impersonatedURL(isImpersonating, '#/learning_centre?impersonated');

    const handleToolsAndTemplateDownload = (file) => {
        const filename = file.title + getFormatExtension(file.format)
        setButtonLoader(file.title);
        dataProvider
            .postRequest("ExportLearningCentre", {
                queryType: "retrieve",
                data: { filename }
            }).then(response => {
                downloadFile(JSON.parse(response.data))
            })
            .catch(error => {
                notify("No data found to export", "warning")
                setButtonLoader(false);
            });
    }

    const downloadFile = ({ filename, data }) => {
        const { ContentType, Body } = data;

        let arrayBuffer;
        if (Body instanceof Uint8Array) {
            arrayBuffer = Body.buffer;
        } else {
            arrayBuffer = Buffer.from(Body).buffer;
        }
        const blob = new Blob([arrayBuffer], { type: ContentType });
        FileSaver.saveAs(blob, filename);
        setButtonLoader(false);
    };
    const handleSearch = (event) => {
        const searchQuery = event.target.value.toLowerCase();
        setQuery(searchQuery);

        const filtered = posts.filter(post =>
            post.title.rendered.toLowerCase().includes(searchQuery)
        );
        setFilteredPosts(filtered);
    };
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${WORDPRESS_ENDPOINT}wp-json/wp/v2/posts?categories=2&orderby=id&order=asc`);
                setPosts(response.data);
                setFilteredPosts(response.data)
            } catch (error) {
                console.error('Error fetching posts', error);
            }
        };

        fetchPosts();
    }, [])

    if (!posts) {
        return <Loading />
    }
    return (
        <div>
            <Title title="Learning Centre" className={classes.title} />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 5 }}>
                <Box >
                    <span style={{ fontWeight: "700" }} >Description: </span>
                    Get the help you need with our extensive collection of support guides.
                </Box>
                <Box >
                    <TextField
                        type="text"
                        placeholder="Search"
                        value={query}
                        onChange={handleSearch}
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon sx={{ color: "rgba(0,0,0,0.2)" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>


            <Grid container spacing={2}>
                {
                    !filteredPosts.length ? <Box>No reference found</Box> :
                        <TableContainer component={Paper} sx={{ width: "50%" }}>
                            <Table sx={{}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ref</TableCell>
                                        <TableCell align="center">Name</TableCell>
                                        <TableCell align="center">Media Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredPosts.map((row, i) => (
                                        <TableRow
                                            key={row.name}
                                        >
                                            <TableCell width={10} component="th" scope="row">
                                                {i + 1}
                                            </TableCell>
                                            <TableCell align="left">{row.title.rendered}</TableCell>
                                            <TableCell align="center" sx={{ borderLeft: "1px solid lightgrey" }}>
                                                <IconButton>
                                                    <Link to={`/learning_centre/${row.slug}`}><DescriptionIcon sx={{ color: "grey" }} /></Link>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                }
            </Grid>
            <Box mt={10} mb={5}>
                <Typography style={{ fontWeight: "700" }}>Legend:</Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton> <DescriptionIcon /> </IconButton>
                    <Typography >Quick Guide Reference</Typography>
                </Box>
            </Box>
        </div>
    );
};

export default LearningCentre;


// const fileDetails = [
//     {
//         "title": "QRG - 0. Setting up Users v2.0",
//         "format": "PDF",
//     },
//     {
//         "title": "QRG - 1. Create_edit_delete a project v2.0",
//         "format": "PDF",
//     },
//     {
//         "title": "QRG - 2. Complete a Change Risk Assessment v2.0",
//         "format": "PDF",
//     },
//     {
//         "title": "QRG - 3. Complete a Change Business Readiness Assessment v2.0",
//         "format": "PDF",
//     },
//     {
//         "title": "QRG - 4. Complete a Change Business Governance Assessment v2.0",
//         "format": "PDF",
//     },
//     {
//         "title": "QRG - 5. Add_edit_delete SME requirement v2.0",
//         "format": "PDF",
//     },
//     {
//         "title": "QRG - 6. Complete a GoNo-Go Criteria  v2.0",
//         "format": "PDF",
//     },
//     {
//         "title": "QRG - 7. Complete a Project CloseOut  v2.0",
//         "format": "PDF",
//     },
// ]