
import axios from 'axios';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import { Loading } from "react-admin";
import powerBiApi, { checkAccessTokenExpiration } from '../../framework/pbiDataProvider';
import { TokenStorage } from '../../utils/TokenStorage';
import { getClientId } from '../../utils/AppUtils';
function DashboardPowerBI() {
    const user = TokenStorage.getUserDetails()
    const impersonateUser = TokenStorage.getImpersonateUserDetails();
    const [pbiEmbedConfig, setPbiEmbedConfig] = useState({
        type: 'report',
        id: '',
        embedUrl: "",
        accessToken: "",
        tokenType: models.TokenType.Aad,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                }
            },
            navContentPaneEnabled: false,
            // background: models.BackgroundType.Transparent
        },
        eventHooks: {
            accessTokenProvider: checkAccessTokenExpiration
        }
    })

    useEffect(() => {
        powerBiApi("GET", "reports/421050e3-5165-4937-8598-a9e41a955361").then(res => {
            // powerBiApi("GET", "reports/3a255288-d78d-4328-b3f7-b6c19037e82d").then(res => {
            const { id, embedUrl, accessToken } = res;
            // embedUrl: `${embedUrl}&filter=Projects/Client ID eq '55'`
            setPbiEmbedConfig({ ...pbiEmbedConfig, id, embedUrl: `${embedUrl}&$filter=Projects/Client eq ${getClientId()}`, accessToken })
            // setPbiEmbedConfig({ ...pbiEmbedConfig, id, embedUrl: `${embedUrl}&$filter=Table/id eq '100'`, accessToken })
        });

    }, [])
    const handleExportReport = async () => {
        // const exportId = await handleExportId()
        const exportId = "MS9CbG9iSWRWMi0xMWUzOWU5MC03MWQyLTRkNTktOTlhYS01NzgyYjgwZDAzMDdadmRLZmlBbnlDUDFEdXB3LW43SzE5SzItOGIxSjJ6QkpBelRlTktNN1VjPS4="
        console.log(exportId)
        const accessToken = await checkAccessTokenExpiration();
        const config = {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/pdf'
            }
        }
        try {
            const responsesss = await axios.get(`https://api.powerbi.com/v1.0/myorg/reports/5bdc13e1-1891-4216-b3cb-e6ea97f9e9cc/exports/${exportId}/file`, config)
            // console.log(typeof responsesss.data)
            const linkSource = `data:application/pdf;base64,${responsesss.data}`;
            const downloadLink = document.createElement("a");
            const fileName = "dashboard.pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click()

        } catch (err) {
            console.log(err)
        }
    }
    const handleExportId = async () => {
        const accessToken = await checkAccessTokenExpiration();
        const bodyParam =
        {
            "format": "pdf",
            "powerBIReportConfiguration": {
                "settings": {
                    "locale": "en-US",
                    "timeZoneId": "Central Asia Standard Time",
                    "excludeHiddenPages": true
                }

            },
            "sectionsToExport": [
                "ReportSection3c68044bd9e19bebdef0"
            ]
        }
        const config = {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }
        try {
            const responsesss = await axios.post('https://wabi-australia-southeast-redirect.analysis.windows.net/export/reports/5bdc13e1-1891-4216-b3cb-e6ea97f9e9cc/asyncexports', bodyParam, config)
            // console.log(typeof responsesss.data)
            console.log(responsesss)
            return responsesss.data.id

        } catch (err) {
            console.log(err.message)
        }
    }
    return (
        <div>

            {/* {<Box display="flex" justifyContent="flex-end" my={2} >
                <Button variant="contained" onClick={handleExportReport} endIcon={<FileDownloadOutlinedIcon style={{ marginLeft: "-4px" }} />} sx={{
                    width: "210px",
                    marginRight: "8px",
                    backgroundColor: "#028FC2",
                    boxShadow: "0",
                    height: '41px',
                    // paddingX: '0',
                    "&:hover": {
                        backgroundColor: "#027aa6",
                        color: "white",
                        cursor: 'pointer',
                        boxShadow: "0"
                        // border: "0.125rem solid lightgrey"
                    },
                    '& .MuiButton-endIcon': {
                        position: 'absolute',
                        right: 0,
                        // marginLeft: '8px', // Adjust margin as needed
                        // paddingLeft: '8px', // Adjust padding as needed
                        display: 'inline-flex', // Ensure icon and background have proper alignment
                        alignItems: 'center', // Ensure icon and background have proper alignment
                        justifyContent: 'center',
                        padding: "0",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        backgroundColor: 'rgba(255,255,255,0.2)', // Adjust background color as needed
                        fontSize: "16px",
                        width: "41px",
                        height: '41px',
                    },
                    '& .MuiButton-endIcon svg': {
                        // Adjust SVG size if needed
                        // width: '32px',
                        // height: '41px',
                    }
                }}>{"Export"}</Button>

            </Box>
            } */}
            {
                !pbiEmbedConfig.embedUrl ? <Loading /> : <PowerBIEmbed
                    embedConfig={pbiEmbedConfig}
                    eventHandlers={
                        new Map([
                            ['loaded', async function () {
                                // await window.report.updateFilters(models.FiltersOperations.RemoveAll);
                                // const filtersArray = [
                                //     {
                                //         $schema: "http://powerbi.com/product/schema#basic",
                                //         target: {
                                //             table: "Projects",
                                //             column: "Client ID"
                                //         },
                                //         operator: "In",
                                //         values: [getClientId()]
                                //     }
                                // ];
                                // await window.report.updateFilters(
                                //     models.FiltersOperations.Add,
                                //     filtersArray
                                // );
                                // console.log('Report has loaded', window.report);
                                // navigator.clipboard.writeText(JSON.stringify(window.report))
                                // console.log('Report has loaded', window.report);
                            }],
                            // ['rendered', async function () {
                            //     const filters = await window.report.getFilters();
                            //     console.log(filters)
                            // }],
                            ['error', function (event) { console.log(event.detail); }],
                        ])
                    }

                    cssClassName={"Embed-container"}

                    getEmbeddedComponent={(embeddedReport) => {
                        console.log(embeddedReport)
                        window.report = embeddedReport;
                    }}
                />
            }

        </div>
    );
}

export default DashboardPowerBI;