import { Box, ListItem, Typography, useMediaQuery } from "@material-ui/core";
import Drawer from '@material-ui/core/Drawer';
import IconButton from "@material-ui/core/IconButton";
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from "@material-ui/icons/AccountCircle";
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import SettingsIcon from '@material-ui/icons/Settings';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import WorkIcon from '@material-ui/icons/Work';
import AdjustIcon from '@mui/icons-material/Adjust';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AttributionIcon from '@mui/icons-material/Attribution';

import BeenhereIcon from '@mui/icons-material/Beenhere';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GavelIcon from '@mui/icons-material/Gavel';
import Groups3Icon from '@mui/icons-material/Groups3';
import InterestsRoundedIcon from '@mui/icons-material/InterestsRounded';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import RecommendIcon from '@mui/icons-material/Recommend';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';
import clsx from 'clsx';
import _ from "lodash";
import * as React from "react";
import { NullableBooleanInput, useAuthProvider, useLogout, usePermissions, useRefresh } from "react-admin";
import { Link, useHistory } from 'react-router-dom';
import cat_logo_white from "../assets/images/cat_logo_white.png";
import { TokenStorage } from "../utils/TokenStorage";
import { MainMenuItem, SubMenuItem, SubSubMenuItem } from "../views/customComponents/menuItem";
import { Opacity } from "@mui/icons-material";
import PaletteIcon from '@mui/icons-material/Palette';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BarChartIcon from '@mui/icons-material/BarChart';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import { ThemeContext } from "./theme/themeContext";

const drawerWidth = 300;


// const StyledMenu = styled((props) => (
//     <Menu
//         elevation={0}
//         anchorOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//         }}
//         transformOrigin={{
//             vertical: 'top',
//             horizontal: 'left',
//         }}
//         {...props}
//     />
// ))(({ theme }) => ({
//     '& .MuiPaper-root': {
//         borderRadius: 6,
//         // marginTop: theme.spacing(5),
//         // marginLeft: theme.spacing(5),
//         padding: '4px 4px',
//         marginTop: '0',
//         minWidth: 180,
//         backgroundColor: '#00A0D9',
//         color:
//             theme.palette.mode === 'light' ? '#fff' : theme.palette.grey[300],
//         boxShadow:
//             'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
//         '& .MuiMenu-list': {
//             padding: '4px 0',
//         },
//         '& .MuiMenuItem-root': {
//             '& .MuiSvgIcon-root': {
//                 fontSize: 18,
//                 color: '#fff',
//                 marginRight: theme.spacing(1.5),
//             },
//             '&:active': {
//                 backgroundColor: alpha(
//                     theme.palette.primary.main,
//                     theme.palette.action.selectedOpacity,
//                 ),
//             },
//         },
//     },
// }));

const useSidebarStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        textTransform: 'capitalize'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: theme.palette.primary.main,
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        backgroundColor: theme.palette.primary.main,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 12px',
        paddingLeft: 0,

        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toggleToolbar: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'flex-end',
        padding: '10px 2px',
        marginTop: '16px',
        // paddingLeft: 0,
        [theme.breakpoints.down('md')]: {
            display: 'none',

        },
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toggleMenu: {
        position: 'relative',
        width: 30,
        height: 30,
        background: '#522d5b',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: -15,
            left: 0,
            height: 15,
            width: 15,
            borderBottomLeftRadius: '50%',
            boxShadow: '0 7px 0 0 #522d5b',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: -15,
            left: 0,
            height: 15,
            width: 15,
            borderTopLeftRadius: '50%',
            boxShadow: '0 -7px 0 0 #522d5b',
        }
    },
    menu: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    hamburgerMenu: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    navIcon: {
        [theme.breakpoints.up('md')]: {
            display: 'none',

        },
    },
    // navIconLg: {
    //     [theme.breakpoints.up('sm')]: {
    //         display: 'none',

    //     },
    // },
    paper: {
        // background: '#00A0D9 !important',
        background: `${theme.palette.primary.main} !important` 
    }
    ,
    iconHover: {
        backgroundColor: "transparent !important",
        padding: 0,
        marginRight: 10,
        marginBottom: 10,
        cursor: "auto",
    },
    chevDown: {
        color: "white",
        display: "flex",
        alignItems: "center",
        marginLeft: 15,
        height: "100%",
        fontSize: 30,
        cursor: "pointer",
    },
    menuTypography: {
        textDecoration: "none",
        padding: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#E3E3E3'
        }
    },
    profileName: {
        fontFamily: "Mulish",
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: 0.2,
        color: "white",
        textTransform: "capitalize",
        margin: 0,
        fontStyle: "normal",
        marginBottom: 7,

    },
    profileDesc: {
        fontFamily: "Mulish",
        fontSize: 10,
        letterSpacing: 0.2,
        color: "white",
        textTransform: "capitalize",
        fontStyle: "normal",
        margin: 0,
    },
    profileNmenu: {
        display: "flex",
        marginLeft: '25px',

        [theme.breakpoints.down('sm')]: {
            // display: 'none'
        },
    },
    hamburgerProfile: {
        width: '100%',
        color: 'white',
        textAlign: 'left !important'
    },
    paper: {
        overflowY: 'auto',
    },
    hoverContent: {
        position: 'absolute',
        // top: 0,
        // top: 0,
        left: 70,
        width: clsx(100 % - `${drawerWidth}px`),
        // height: '100vh',
        backgroundColor: theme.palette.primary.main,
        // padding: theme.spacing(2),
        display: 'none',
        zIndex: 100,
        borderRadius: '5px'

    },
    hoverContentVisible: {
        display: 'block',

    },
    navMenu: {
        width: '70vw',
        // backgroundColor: "#00A0D9",
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            display: 'none',
            opacity: 0,
            '& .MuiBackdrop-root': {
                display: 'none !important'
            }
        },
    }

}));

const formatProfileDetail = (user) => {
    const user_role = user.isSuperAdmin
        ? "Super Admin"
        : user.isCustomerAdmin
            ? "Customer Admin"
            : _.map(user.roles, "name");
    return {
        name: user.fullName,
        role: user_role,
        isImpersonating: user.isImpersonating,
    };
};

const Sidebar = ({ open, handleDrawer, handleDrawerOpen, handleDrwerClose }) => {
    const classes = useSidebarStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [state, setState] = React.useState(false);
    const [profileArrowOpen, setProfileArrowOpen] = React.useState(false);
    const [subAchorEl, setSubAnchorEl] = React.useState(null);
    const [hoverContentVisible, setHoverContentVisible] = React.useState(false);
    const {theme, setTheme} = React.useContext(ThemeContext)
    const subMenuOpen = Boolean(subAchorEl);
    const handleSidebarCollaps = () => {
        handleCloseSubMenu()
        handleDrawer()
    }

    const toggleHoverContent = (param) => {
        setHoverContentVisible(param);
    };
    const handleClick = () => {
        handleDrawerOpen();
        setProfileArrowOpen(!profileArrowOpen);
        handleCloseSubMenu()
    };

    const [canViewResource, setCanViewResource] = React.useState();
    const [isSuperAdmin, setIsSuperAdmin] = React.useState();
    const [isCustomerAdmin, setIsCustomerAdmin] = React.useState();
    const ref = React.useRef(null);

    const { loading: loadingPermissions, permissions } = usePermissions();

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            handleDrwerClose();
            handleCloseSubMenu()
            handleProfileClose();
        }
    };
    // profile bar
    const history = useHistory();
    const [block, setBlock] = React.useState(false)
    //   const theme = useTheme();
    const [profielBarOpen, setOpen] = React.useState(false);
    const [profile, setProfile] = React.useState({
        name: "",
        role: "",
        isImpersonating: false,
    });
    const [menuName, setMenuName] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);




    const handleOpenSubMenu = (event, name) => {
        setMenuName(name)
        setSubAnchorEl(event.currentTarget);
    };
    const handleCloseSubMenu = () => {
        setMenuName("")
        setSubAnchorEl(null);
    };
    const logout = useLogout();
    const authProvider = useAuthProvider();
    const refresh = useRefresh();

    const profileOpen = Boolean(anchorEl);

    const handleProfileMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfileClose = () => {
        setProfileArrowOpen(false);
        setState(false);
        setAnchorEl(null);
    };

    const handleProfileLogoutClick = () => {
        logout();
        handleProfileClose();
        setState(false);
    };

    const handleStopImpersonationClick = () => {
        // redirect to home page
        history.push("/");
        TokenStorage.clearImpersonateUserDetails();

        // refresh page to clear all the states
        window.location.hash = "#/user"
        window.location.reload();
        authProvider.getUserDetails().then(user => {
            const formattedProfile = formatProfileDetail(user)
            setProfile(formattedProfile);
            refresh(true)
        }).catch(error => {

        })
        setState(false);
    };
    const handleProfileClick = () => {
        handleProfileClose();
        history.push("/change_password");
        setState(false);
    }

    React.useEffect(() => {
        // set profile details of impersonating user
        // if there is no impersonate user,
        // set profile details of original user
        authProvider
            .getUserDetails()
            .then((user) => {
                const formattedProfile = formatProfileDetail(user);
                setProfile(formattedProfile);
            })
            .catch((error) => { });
    }, []);

    //end of profile bar
    const list = (
        <Box
            sx={{ width: "70vw", display: "flex", flexDirection: 'column', justifyContent: 'space-between', height: "100vh" }}
            role="presentation"

        >
            <List style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                <div>
                    <List>
                        {!isSuperAdmin && <MainMenuItem
                            isDrawerOpen={open}
                            handleDrawerOpen={handleDrawerOpen}
                            primaryText="Dashboard"
                            to="/"
                            exact
                            leftIcon={<DashboardIcon />}
                            bypassUser={isCustomerAdmin}
                            allowedPermissions={[
                                { resource: "Dashboard", action: ["view"] },
                            ]}
                            onMobileMenuClick={() => setState(false)}

                        >
                        </MainMenuItem>}
                        {
                            !isSuperAdmin &&

                            <MainMenuItem
                                isDrawerOpen={open}
                                handleDrawerOpen={handleDrawerOpen}
                                primaryText="Projects"
                                to="/project"
                                exact
                                leftIcon={<WorkIcon />}>
                            </MainMenuItem>
                        }
                        <MainMenuItem
                            isDrawerOpen={open}
                            handleDrawerOpen={handleDrawerOpen}
                            primaryText="Change Assessments (Input)"
                            to="/project"
                            exact
                            leftIcon={<AssignmentIcon />}
                            bypassUser={isCustomerAdmin}
                        // allowedPermissions={[
                        //     { resource: "Project", action: ["view"] },
                        //     { resource: "Milestone", action: ["view"] },
                        //     { resource: "SME Requirement", action: ["view"] },
                        // ]}
                        >
                            <SubMenuItem
                                leftIcon={<ChangeHistoryIcon sx={{ color: 'white' }} />}
                                to={`/risk_assessment`}
                                primaryText="Change Risk Assessment"
                                bypassUser={isCustomerAdmin}
                                allowedPermissions={[
                                    { resource: "Change Assessment", action: ["edit"] },
                                    { resource: "Change Assessment", action: ["view"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                            <SubMenuItem
                                leftIcon={<BusinessCenterIcon sx={{ color: 'white' }} />}
                                to={`/readiness`}
                                primaryText="Business Readiness"
                                bypassUser={isCustomerAdmin}
                                allowedPermissions={[
                                    { resource: "Change Assessment", action: ["edit"] },
                                    { resource: "Change Assessment", action: ["view"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                            <SubMenuItem
                                leftIcon={<GavelIcon sx={{ color: 'white' }} />}
                                to={`/governance`}
                                primaryText="Change Governance"
                                bypassUser={isCustomerAdmin}
                                allowedPermissions={[
                                    { resource: "Change Assessment", action: ["edit"] },
                                    { resource: "Change Assessment", action: ["view"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                            <SubMenuItem
                                leftIcon={<Groups3Icon sx={{ color: 'white' }} />}
                                to={`/sme_requirements`}
                                primaryText="SME Requirements"
                                bypassUser={isCustomerAdmin}
                                allowedPermissions={[
                                    { resource: "Change Assessment", action: ["edit"] },
                                    { resource: "Change Assessment", action: ["view"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                            <SubMenuItem
                                leftIcon={<RecommendIcon sx={{ color: 'white' }} />}
                                to={`/go_no_go_criteria`}
                                primaryText="Go/No-Go Criteria"
                                bypassUser={isCustomerAdmin}
                                allowedPermissions={[
                                    { resource: "Change Assessment", action: ["edit"] },
                                    { resource: "Change Assessment", action: ["view"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                            <SubMenuItem
                                leftIcon={<BeenhereIcon sx={{ color: 'white' }} />}
                                to={`/close_out_criteria`}
                                primaryText="Project Close-Out"
                                bypassUser={isCustomerAdmin}
                                allowedPermissions={[
                                    { resource: "Change Assessment", action: ["edit"] },
                                    { resource: "Change Assessment", action: ["view"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                        </MainMenuItem>

                        <MainMenuItem
                            isDrawerOpen={open}
                            handleDrawerOpen={handleDrawerOpen}
                            primaryText="Reports"
                            to="/project"
                            exact
                            leftIcon={<DonutLargeIcon />}>
                            <SubMenuItem
                                key="sub-menu-1"
                                to="/reports"
                                primaryText="Portfolio Reports"
                                leftIcon={<AssessmentIcon />}
                                bypassUser={isCustomerAdmin}
                                allowedPermissions={[
                                    { resource: "Report", action: ["view"] },
                                ]}
                                handleDrawerOpen={handleDrawerOpen}
                                onMobileMenuClick={() => setState(true)}
                            >
                                <SubSubMenuItem
                                    key="sub-sub-menu-1"
                                    to="/change_impact_portfolio"
                                    primaryText="Change Impact"
                                    leftIcon={<AnalyticsIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-2"
                                    to="/change_environment_portfolio"
                                    primaryText="Change Environment"
                                    leftIcon={<PlaylistAddCheckCircleIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-3"
                                    to="/risk_management_portfolio"
                                    primaryText="Risk Management"
                                    leftIcon={<ReportProblemIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-4"
                                    to="/sme_requirement_portfolio"
                                    primaryText="SME Requirement"
                                    leftIcon={<Groups3Icon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-5"
                                    to="/change_heatmap_portfolio"
                                    primaryText="Change Heatmap"
                                    leftIcon={<BubbleChartIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                            </SubMenuItem>
                            <SubMenuItem
                                key="sub-menu-2"
                                to="/project_report"
                                primaryText="Project Reports"
                                leftIcon={<AssignmentTurnedInIcon />}
                                bypassUser={isCustomerAdmin}
                                handleDrawerOpen={handleDrawerOpen}
                                allowedPermissions={[
                                    { resource: "Report", action: ["view"] },
                                ]}
                            >
                                <SubSubMenuItem
                                    key="sub-sub-menu-1"
                                    to="/change_impact_project"
                                    primaryText="Change Impact Assessment"
                                    leftIcon={<BarChartIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-2"
                                    to="/change_environment_project"
                                    primaryText="Change Environment"
                                    leftIcon={<PlaylistAddCheckCircleIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-3"
                                    to="/change_saturation_project"
                                    primaryText="Change Saturation"
                                    leftIcon={<RunningWithErrorsIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-4"
                                    to="/change_maturity_project"
                                    primaryText="Change Maturity"
                                    leftIcon={<NetworkCheckIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-5"
                                    to="/change_heatmap_project"
                                    primaryText="Change Heatmap"
                                    leftIcon={<BubbleChartIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-6"
                                    to="/change_risk_assessment_report"
                                    primaryText="Change Risk Assessment"
                                    leftIcon={<ChangeHistoryIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-7"
                                    to="/business_readiness_report"
                                    primaryText="Business Readiness"
                                    leftIcon={<BusinessCenterIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-8"
                                    to="/change_governance_report"
                                    primaryText="Change Governance"
                                    leftIcon={<GavelIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-9"
                                    to="/sme_requirement_project_report"
                                    primaryText="SME Requirements"
                                    leftIcon={<Groups3Icon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-10"
                                    to="/go_no_go_report"
                                    primaryText="Go/No-Go Criteria"
                                    leftIcon={<RecommendIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                                <SubSubMenuItem
                                    key="sub-sub-menu-11"
                                    to="/close_out_report"
                                    primaryText="Project Close-Out"
                                    leftIcon={<BeenhereIcon sx={{ color: "white" }} />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                />
                            </SubMenuItem>
                        </MainMenuItem>
                        {!isSuperAdmin && <MainMenuItem
                            isDrawerOpen={open}
                            handleDrawerOpen={handleDrawerOpen}
                            primaryText="Tools and Templates"
                            to="/cat_templates"
                            exact
                            leftIcon={<InterestsRoundedIcon fontSize="13" />}
                            bypassUser={isCustomerAdmin}
                            onMobileMenuClick={() => setState(false)}
                        >

                        </MainMenuItem>}
                        {!isSuperAdmin && <MainMenuItem
                            isDrawerOpen={open}
                            handleDrawerOpen={handleDrawerOpen}
                            primaryText="Learning Centre"
                            to="/learning_centre"
                            exact
                            leftIcon={<AutoStoriesIcon fontSize="12" />}
                            bypassUser={isCustomerAdmin}
                            onMobileMenuClick={() => setState(false)}
                        >

                        </MainMenuItem>}
                        {!isSuperAdmin && <MainMenuItem
                            isDrawerOpen={open}
                            handleDrawerOpen={handleDrawerOpen}
                            primaryText="FAQs"
                            to="/faq"
                            exact
                            leftIcon={<LiveHelpIcon />}
                            bypassUser={isCustomerAdmin}
                            onMobileMenuClick={() => setState(false)}
                        // allowedPermissions={[
                        //     { resource: "FAQs", action: ["view"] },
                        // ]}
                        >
                        </MainMenuItem>}
                        <MainMenuItem
                            isDrawerOpen={open}
                            handleDrawerOpen={handleDrawerOpen}
                            primaryText="Set Up"
                            to="/user"
                            exact
                            leftIcon={<SettingsIcon />}>
                            <SubMenuItem
                                to={`/BusinessUnit`}
                                primaryText="Organisational Chart"
                                bypassUser={isCustomerAdmin}
                                leftIcon={<BusinessIcon sx={{ color: "white" }} />}
                                allowedPermissions={[
                                    { resource: "BusinessUnit", action: ["view"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                            <SubMenuItem
                                to={`/user`}
                                primaryText={isCustomerAdmin ? "Roles and Users" : "Users"}
                                leftIcon={<PersonIcon sx={{ color: "white" }} />}
                                bypassUser={isCustomerAdmin || isSuperAdmin}
                                allowedPermissions={[
                                    { resource: "User", action: ["view"] },
                                    { resource: "Role", action: ["view"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                            <SubMenuItem
                                to={`/permission`}
                                primaryText="Permission"
                                bypassUser={isSuperAdmin}
                                leftIcon={<LockIcon sx={{ color: "white" }} />}
                                allowedPermissions={[
                                    { resource: "Permission", action: ["view"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                            <SubMenuItem
                                to={`/MasterData`}
                                primaryText="Master Data"
                                bypassUser={isSuperAdmin}
                                leftIcon={<AnalyticsIcon sx={{ color: "white" }} />}
                                allowedPermissions={[
                                    { resource: "MasterData", action: ["view", "edit", "create"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                            <SubMenuItem
                                to={`/theme_customization`}
                                primaryText="Theme Customization"
                                leftIcon={<PaletteIcon sx={{ color: "white" }} />}
                                bypassUser={isCustomerAdmin}
                                allowedPermissions={[
                                    { resource: "Theme", action: ["view"] },
                                ]}
                                onMobileMenuClick={() => setState(false)}
                            />
                        </MainMenuItem>


                    </List>
                </div>
                {/* profile bar */}
            </List>
            <div>
                <div>
                    <List >
                        <ListItemButton onClick={handleClick}>
                            <ListItemIcon style={{ paddingLeft: '8px' }}>
                                <AccountCircle style={{ color: 'white', fontSize: '24px' }} />
                            </ListItemIcon>
                            <ListItemText primary={<div  >
                                <h3 className={classes.profileName}>{profile.name}</h3>
                                <h3 className={classes.profileDesc}>{profile.role}</h3>
                            </div>} />
                            {profileArrowOpen ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                        </ListItemButton>
                        <Collapse in={profileArrowOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                                {
                                    !profile.isImpersonating &&
                                    <ListItemButton sx={{ pl: 5, color: 'white' }} onClick={handleProfileClick}>
                                        <ListItemText primary="Change Password" />
                                    </ListItemButton>
                                }
                                <ListItemButton sx={{ pl: 5, color: 'white' }}
                                    onClick={handleProfileLogoutClick}>
                                    <ListItemText primary="Logout" />
                                </ListItemButton>
                                {
                                    profile.isImpersonating &&
                                    <Tooltip

                                        placement="top"
                                    >
                                        <ListItemButton sx={{ pl: 5, color: 'white' }} onClick={handleStopImpersonationClick}>
                                            <ListItemText primary="Stop Impersonation" />
                                        </ListItemButton>
                                    </Tooltip>

                                }
                            </List>
                        </Collapse>
                        <Tooltip placement="top" title="Logout">
                            <ListItemButton sx={{ pl: 3, color: 'white' }}
                                onClick={handleProfileLogoutClick}>
                                <ListItemIcon>
                                    <LogoutIcon style={{ color: 'white' }} />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </Tooltip>
                        {
                            profile.isImpersonating &&
                            <Tooltip placement="top" title="Stop Impersonation">
                                <ListItemButton sx={{ pl: 3, color: 'white' }} onClick={handleStopImpersonationClick}>
                                    <ListItemIcon>
                                        <LockOpenIcon style={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Stop Impersonation" />
                                </ListItemButton>
                            </Tooltip>
                        }
                    </List>

                </div>
            </div>
            <Typography variant="body2" style={{ color: 'white', marginLeft: '30px', marginTop: '20px' }} display="block" gutterBottom>
                v 0.0.1
            </Typography>
        </Box>
    );

    const HoverDashboard = (
        <Box id="sub-dashboard" style={{ color:'#fff' }}>
            <List>
                <ListItem button component={Link} to="/" onClick={handleCloseSubMenu}>
                    <ListItemIcon>
                        <DashboardIcon style={{ color: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </List>
        </Box>
    )
    const hoverTools = (
        <div>
            {!open &&
                <Box style={{ color: '#fff' }}>
                    <List>
                        <ListItem button component={Link} to="/cat_templates" onClick={handleCloseSubMenu}>
                            <ListItemIcon>
                                <InterestsRoundedIcon fontSize="13" style={{ color: "#fff" }} />
                            </ListItemIcon>
                            <ListItemText primary="Tools and Templates" />
                        </ListItem>
                    </List>
                </Box>
            }
        </div>

    )

    const hoverLearningCentre = (
        <div>
            {!open &&
                <Box style={{ color: '#fff' }}>
                    <List>
                        <ListItem button component={Link} to="/learning_centre" onClick={handleCloseSubMenu}>
                            <ListItemIcon>
                                <AutoStoriesIcon fontSize="12" style={{ color: "#fff" }} />
                            </ListItemIcon>
                            <ListItemText primary="Learning Centre" />
                        </ListItem>
                    </List>
                </Box>
            }
        </div>

    )

    const hoverFAQ = (
        <div>
            {!open &&
                <Box style={{ color: '#fff' }}>
                    <List>
                        <ListItem button component={Link} to="/faq" onClick={handleCloseSubMenu}>
                            <ListItemIcon>
                                <LiveHelpIcon style={{ color: "#fff" }} />
                            </ListItemIcon>
                            <ListItemText primary="FAQs" />
                        </ListItem>
                    </List>
                </Box>
            }
        </div>

    )
    const HoverProject = (
        <div>
            {!open && <Box style={{ color: '#fff' }}>
                <List>
                    <ListItem button component={Link} to="/project" onClick={handleCloseSubMenu}>
                        <ListItemIcon>
                            <WorkIcon style={{ color: "#fff" }} />
                        </ListItemIcon>
                        <ListItemText primary="Projects" />
                        {/* {subMenuOpen ? <ExpandLess /> : <ExpandMore />} */}
                    </ListItem>
                </List>
            </Box>}
        </div>
    )
    const HoverAssessment = (
        <div>
            {
                !open &&

                <Box style={{ color: '#fff' }}>
                    <List id="sub-menu-item">
                        <ListItem button>
                            <ListItemIcon>
                                <AssignmentIcon style={{ color: "#fff" }} />
                            </ListItemIcon>
                            <ListItemText primary="Change Assessments (Input)" />
                            {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding >
                                <SubMenuItem
                                    leftIcon={<ChangeHistoryIcon sx={{ color: 'white' }} />}
                                    to={`/risk_assessment`}
                                    primaryText="Change Risk Assessment"
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Change Assessment", action: ["edit"] },
                                        { resource: "Change Assessment", action: ["view"] },
                                    ]}
                                    onMobileMenuClick={() => setState(false)}
                                />
                                <SubMenuItem
                                    leftIcon={<BusinessCenterIcon sx={{ color: 'white' }} />}
                                    to={`/readiness`}
                                    primaryText="Change Business Readiness"
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Change Assessment", action: ["edit"] },
                                        { resource: "Change Assessment", action: ["view"] },
                                    ]}
                                    onMobileMenuClick={() => setState(false)}
                                />
                                <SubMenuItem
                                    leftIcon={<GavelIcon sx={{ color: 'white' }} />}
                                    to={`/governance`}
                                    primaryText="Change Governance"
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Change Assessment", action: ["edit"] },
                                        { resource: "Change Assessment", action: ["view"] },
                                    ]}
                                    onMobileMenuClick={() => setState(false)}
                                />
                                <SubMenuItem
                                    leftIcon={<Groups3Icon sx={{ color: 'white' }} />}
                                    to={`/sme_requirements`}
                                    primaryText="SME Requirements"
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Change Assessment", action: ["edit"] },
                                        { resource: "Change Assessment", action: ["view"] },
                                    ]}
                                    onMobileMenuClick={() => setState(false)}
                                />
                                <SubMenuItem
                                    leftIcon={<RecommendIcon sx={{ color: 'white' }} />}
                                    to={`/go_no_go_criteria`}
                                    primaryText="Go/No-Go Criteria"
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Change Assessment", action: ["edit"] },
                                        { resource: "Change Assessment", action: ["view"] },
                                    ]}
                                    onMobileMenuClick={() => setState(false)}
                                />
                                <SubMenuItem
                                    leftIcon={<BeenhereIcon sx={{ color: 'white' }} />}
                                    to={`/close_out_criteria`}
                                    primaryText="Close-Out Criteria"
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Change Assessment", action: ["edit"] },
                                        { resource: "Change Assessment", action: ["view"] },
                                    ]}
                                    onMobileMenuClick={() => setState(false)}
                                />
                            </List>
                        </Collapse>
                    </List>
                </Box>
            }
        </div>
    )

    const HoverReports = (
        <div>
            {
                !open &&

                <Box style={{ color: '#fff' }}>
                    <List>
                        <ListItem button>
                            <ListItemIcon>
                                <DonutLargeIcon style={{ color: "#fff" }} />
                            </ListItemIcon>
                            <ListItemText primary="Reports" />
                            {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <SubMenuItem
                                    key="sub-menu-1"
                                    to="/reports"
                                    primaryText="Portfolio Reports"
                                    leftIcon={<AssessmentIcon />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                    handleDrawerOpen={() => { }}
                                    isSubSubMenuOpen={true}
                                    onMobileMenuClick={() => setState(true)}
                                >
                                    <SubSubMenuItem
                                        key="sub-sub-menu-1"
                                        to="/change_impact_portfolio"
                                        primaryText="Change Impact"
                                        leftIcon={<AnalyticsIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-2"
                                        to="/change_environment_portfolio"
                                        primaryText="Change Environment"
                                        leftIcon={<PlaylistAddCheckCircleIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-3"
                                        to="/risk_management_portfolio"
                                        primaryText="Risk Management"
                                        leftIcon={<ReportProblemIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-4"
                                        to="/sme_requirement_portfolio"
                                        primaryText="SME Requirement"
                                        leftIcon={<Groups3Icon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-5"
                                        to="/change_heatmap_portfolio"
                                        primaryText="Change Heatmap"
                                        leftIcon={<BubbleChartIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                </SubMenuItem>
                                <SubMenuItem
                                    key="sub-menu-2"
                                    to="/project_report"
                                    primaryText="Project Reports"
                                    leftIcon={<AssignmentTurnedInIcon />}
                                    bypassUser={isCustomerAdmin}
                                    handleDrawerOpen={() => { }}
                                    isSubSubMenuOpen={true}
                                    allowedPermissions={[
                                        { resource: "Report", action: ["view"] },
                                    ]}
                                >
                                    <SubSubMenuItem
                                        key="sub-sub-menu-1"
                                        to="/change_impact_project"
                                        primaryText="Change Impact Assessment"
                                        leftIcon={<BarChartIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-2"
                                        to="/change_environment_project"
                                        primaryText="Change Environment"
                                        leftIcon={<PlaylistAddCheckCircleIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-3"
                                        to="/change_saturation_project"
                                        primaryText="Change Saturation"
                                        leftIcon={<RunningWithErrorsIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-4"
                                        to="/change_maturity_project"
                                        primaryText="Change Maturity"
                                        leftIcon={<NetworkCheckIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-5"
                                        to="/change_heatmap_project"
                                        primaryText="Change Heatmap"
                                        leftIcon={<BubbleChartIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-6"
                                        to="/change_risk_assessment_report"
                                        primaryText="Change Risk Assessment"
                                        leftIcon={<ChangeHistoryIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-7"
                                        to="/business_readiness_report"
                                        primaryText="Business Readiness"
                                        leftIcon={<BusinessCenterIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-8"
                                        to="/change_governance_report"
                                        primaryText="Change Governance"
                                        leftIcon={<GavelIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-9"
                                        to="/sme_requirement_project_report"
                                        primaryText="SME Requirements"
                                        leftIcon={<Groups3Icon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-10"
                                        to="/go_no_go_report"
                                        primaryText="Go/No-Go Criteria"
                                        leftIcon={<RecommendIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                    <SubSubMenuItem
                                        key="sub-sub-menu-11"
                                        to="/close_out_report"
                                        primaryText="Project Close-Out"
                                        leftIcon={<BeenhereIcon sx={{ color: "white" }} />}
                                        bypassUser={isCustomerAdmin}
                                        allowedPermissions={[
                                            { resource: "Report", action: ["view"] },
                                        ]}
                                    />
                                </SubMenuItem>
                            </List>
                        </Collapse>
                    </List>
                </Box>
            }
        </div>
    )
    const HoverSetup = (
        <div>
            {
                !open &&

                <Box style={{ color: '#fff' }}>
                    <List>

                        <ListItem button>
                            <ListItemIcon>
                                <SettingsIcon style={{ color: "#fff" }} />
                            </ListItemIcon>
                            <ListItemText primary="Set Up" />
                            {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    isCustomerAdmin && <ListItem button className={classes.nested} component={Link} to="/BusinessUnit" onClick={handleCloseSubMenu}>
                                        <ListItemIcon>
                                            <BusinessIcon style={{ color: "#fff" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Organisational Chart" />
                                    </ListItem>
                                }
                                {
                                    (isCustomerAdmin || isSuperAdmin) && <ListItem button className={classes.nested} component={Link} to="/user" onClick={handleCloseSubMenu}>
                                        <ListItemIcon>
                                            <PersonIcon style={{ color: "#fff" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={isCustomerAdmin ? "Roles and Users" : "Users"} />
                                    </ListItem>
                                }
                                {
                                    isSuperAdmin && <ListItem button className={classes.nested} component={Link} to="/permission" onClick={handleCloseSubMenu}>
                                        <ListItemIcon>
                                            <LockIcon style={{ color: "#fff" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Permission" />
                                    </ListItem>
                                }
                                {
                                    isSuperAdmin && <ListItem button className={classes.nested} component={Link} to="/MasterData" onClick={handleCloseSubMenu}>
                                        <ListItemIcon>
                                            <AnalyticsIcon style={{ color: "#fff" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Master Data" />
                                    </ListItem>
                                }
                                {
                                    (isCustomerAdmin) && <ListItem button className={classes.nested} component={Link} to="/theme_customization" onClick={handleCloseSubMenu}>
                                        <ListItemIcon>
                                            <PaletteIcon style={{ color: "#fff" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Theme Customization" />
                                    </ListItem>
                                }
                            </List>
                        </Collapse>
                    </List>
                </Box>
            }
        </div>
    )
    const hoverAccounts = (
        <div>
            {!open &&
                <List style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', color: '#fff' }}>
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon style={{ paddingLeft: '8px' }}>
                            <AccountCircle style={{ color: 'white', fontSize: '24px' }} />
                        </ListItemIcon>
                        <ListItemText primary={<div  >
                            <h3 className={classes.profileName}>{profile.name}</h3>
                            <h3 className={classes.profileDesc}>{profile.role}</h3>
                        </div>} />
                        {!profile.isImpersonating && (subMenuOpen ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />)}
                    </ListItemButton>
                    <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                            {
                                !profile.isImpersonating &&
                                <ListItemButton sx={{ pl: 5, color: 'white' }} onClick={handleProfileClick}>
                                    <ListItemText primary="Change Password" />
                                </ListItemButton>
                            }
                        </List>
                    </Collapse>
                </List>
            }
        </div>
    )
    React.useEffect(() => {

        const user = TokenStorage.getUserDetails();
        if (user) {
            setIsSuperAdmin(user.isSuperAdmin)
            setIsCustomerAdmin(user.isCustomerAdmin)
        }

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };




    }, []);

    React.useEffect(() => {

        handleHoverDomMenu('main-dashboard', 'sub-dashboard', '15px')

        handleHoverDomMenu('main-project', "sub-project", '30px')

        handleHoverDomMenu('main-assessment', "sub-assessment", '45px')

        handleHoverDomMenu('main-reports', "sub-reports", '60px')

        handleHoverDomMenu('main-learning', "sub-learning", '75px');
        handleHoverDomMenu('main-tools', "sub-tools", '75px');

        handleHoverDomMenu('main-setup', "sub-setup", '90px')

        handleHoverDomMenu('main-faq', "sub-faq", '105px')

        handleHoverDomMenu('main-account', "sub-account", '105px')

    }, [menuName])
    function handleHoverDomMenu(mainId, subId, mt) {
        const menuItem = document.getElementById(`${mainId}`);
        const submenu = document.getElementById(`${subId}`);
        if (menuItem && submenu) {
            menuItem.addEventListener('mouseenter', function () {

                const rect = this.getBoundingClientRect();
                const offsetTop = rect.top + window.pageYOffset;

                const height = rect.height;
                const offsetBottom = offsetTop - height;

                submenu.style.marginTop = mainId === "main-account" ? `${offsetBottom + 30}px` : `${offsetTop}px`;
                submenu.style.display = 'block';
            });
            submenu.addEventListener('mouseenter', function () {
                submenu.style.display = 'block';
            });
            menuItem.addEventListener('mouseleave', function () {
                submenu.style.display = 'none';
            });
            submenu.addEventListener('mouseleave', function () {
                submenu.style.display = 'none';
            });
            submenu.addEventListener('click', function () {
                submenu.style.display = 'none';
            });
        }

    }
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Drawer
                    ref={ref}
                    variant="permanent"
                    className={clsx(classes.drawer, classes.menu, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx(classes.paper, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,

                        }),

                    }}
                    sx={{ overflow: 'visible !important' }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
                        <div>
                            <div style={{ margin: 8, marginTop: 12, }}>
                                <img src={theme.img} width="100%" alt="Sidebar Change Assessment Tool Logo" />
                            </div>

                            <List style={{ position: "relative", textTransform: "capitalize" }}>

                                {!isSuperAdmin && <div id="main-dashboard"
                                    onMouseEnter={(e) => handleOpenSubMenu(e, 'dashboard')}
                                > <MainMenuItem
                                    isDrawerOpen={open}
                                    handleDrawerOpen={handleDrawerOpen}
                                    onClick={handleCloseSubMenu}
                                    primaryText="Dashboard"
                                    to="/"
                                    exact
                                    leftIcon={<DashboardIcon />}
                                    bypassUser={isCustomerAdmin}
                                    allowedPermissions={[
                                        { resource: "Dashboard", action: ["view"] },
                                    ]}>
                                    </MainMenuItem>
                                </div>}
                                {!isSuperAdmin &&
                                    <div id="main-project" onMouseEnter={(e) => handleOpenSubMenu(e, 'project')}>
                                        <MainMenuItem
                                            isDrawerOpen={open}
                                            handleDrawerOpen={handleDrawerOpen}
                                            primaryText="Projects"
                                            to="/project"
                                            exact
                                            leftIcon={<WorkIcon />}>
                                        </MainMenuItem>
                                    </div>
                                }

                                <div id="main-assessment" onMouseEnter={(e) => handleOpenSubMenu(e, 'assessment')}>

                                    <MainMenuItem
                                        isDrawerOpen={open}
                                        handleDrawerOpen={handleDrawerOpen}
                                        primaryText="Change Assessment (Input)"
                                        to="/project"
                                        exact
                                        leftIcon={<AssignmentIcon />}
                                        bypassUser={isCustomerAdmin}
                                    // allowedPermissions={[
                                    //     { resource: "Project", action: ["view"] },
                                    //     { resource: "Milestone", action: ["view"] },
                                    //     { resource: "SME Requirement", action: ["view"] },
                                    // ]}
                                    >
                                        <SubMenuItem
                                            leftIcon={<ChangeHistoryIcon sx={{ color: 'white' }} />}
                                            to={`/risk_assessment`}
                                            primaryText="Change Risk Assessment"
                                            bypassUser={isCustomerAdmin}
                                            allowedPermissions={[
                                                { resource: "Change Assessment", action: ["edit"] },
                                                { resource: "Change Assessment", action: ["view"] },
                                            ]}
                                            onMobileMenuClick={() => setState(false)}
                                        />
                                        <SubMenuItem
                                            leftIcon={<BusinessCenterIcon sx={{ color: 'white' }} />}
                                            to={`/readiness`}
                                            primaryText="Business Readiness"
                                            bypassUser={isCustomerAdmin}
                                            allowedPermissions={[
                                                { resource: "Change Assessment", action: ["edit"] },
                                                { resource: "Change Assessment", action: ["view"] },
                                            ]}
                                            onMobileMenuClick={() => setState(false)}
                                        />
                                        <SubMenuItem
                                            leftIcon={<GavelIcon sx={{ color: 'white' }} />}
                                            to={`/governance`}
                                            primaryText="Change Governance"
                                            bypassUser={isCustomerAdmin}
                                            allowedPermissions={[
                                                { resource: "Change Assessment", action: ["edit"] },
                                                { resource: "Change Assessment", action: ["view"] },
                                            ]}
                                            onMobileMenuClick={() => setState(false)}
                                        />
                                        <SubMenuItem
                                            leftIcon={<Groups3Icon sx={{ color: 'white' }} />}
                                            to={`/sme_requirements`}
                                            primaryText="SME Requirements"
                                            bypassUser={isCustomerAdmin}
                                            allowedPermissions={[
                                                { resource: "Change Assessment", action: ["edit"] },
                                                { resource: "Change Assessment", action: ["view"] },
                                            ]}
                                            onMobileMenuClick={() => setState(false)}
                                        />
                                        <SubMenuItem
                                            leftIcon={<RecommendIcon sx={{ color: 'white' }} />}
                                            to={`/go_no_go_criteria`}
                                            primaryText="Go/No-Go Criteria"
                                            bypassUser={isCustomerAdmin}
                                            allowedPermissions={[
                                                { resource: "Change Assessment", action: ["edit"] },
                                                { resource: "Change Assessment", action: ["view"] },
                                            ]}
                                            onMobileMenuClick={() => setState(false)}
                                        />
                                        <SubMenuItem
                                            leftIcon={<BeenhereIcon sx={{ color: 'white' }} />}
                                            to={`/close_out_criteria`}
                                            primaryText="Project Close-Out"
                                            bypassUser={isCustomerAdmin}
                                            allowedPermissions={[
                                                { resource: "Change Assessment", action: ["edit"] },
                                                { resource: "Change Assessment", action: ["view"] },
                                            ]}
                                            onMobileMenuClick={() => setState(false)}
                                        />
                                    </MainMenuItem>

                                </div>
                                <div id="main-reports" onMouseEnter={(e) => handleOpenSubMenu(e, 'reports')}>
                                    <MainMenuItem
                                        isDrawerOpen={open}
                                        handleDrawerOpen={handleDrawerOpen}
                                        primaryText="Reports"
                                        to="/project"
                                        exact
                                        leftIcon={<DonutLargeIcon />}

                                    >
                                        <SubMenuItem
                                            key="sub-menu-1"
                                            to="/reports"
                                            primaryText="Portfolio Reports"
                                            leftIcon={<AssessmentIcon />}
                                            bypassUser={isCustomerAdmin}
                                            handleDrawerOpen={() => { }}
                                            allowedPermissions={[
                                                { resource: "Report", action: ["view"] },
                                            ]}
                                            onMobileMenuClick={() => setState(false)}
                                        >
                                            <SubSubMenuItem
                                                key="sub-sub-menu-1"
                                                to="/change_impact_portfolio"
                                                primaryText="Change Impact"
                                                leftIcon={<AnalyticsIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-2"
                                                to="/change_environment_portfolio"
                                                primaryText="Change Environment"
                                                leftIcon={<PlaylistAddCheckCircleIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-3"
                                                to="/risk_management_portfolio"
                                                primaryText="Risk Management"
                                                leftIcon={<ReportProblemIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-4"
                                                to="/sme_requirement_portfolio"
                                                primaryText="SME Requirement"
                                                leftIcon={<Groups3Icon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-5"
                                                to="/change_heatmap_portfolio"
                                                primaryText="Change Heatmap"
                                                leftIcon={<BubbleChartIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                        </SubMenuItem>
                                        <SubMenuItem
                                            key="sub-menu-2"
                                            to="/project_report"
                                            primaryText="Project Reports"
                                            leftIcon={<AssignmentTurnedInIcon />}
                                            bypassUser={isCustomerAdmin}
                                            handleDrawerOpen={() => { }}
                                            allowedPermissions={[
                                                { resource: "Report", action: ["view"] },
                                            ]}
                                        >
                                            <SubSubMenuItem
                                                key="sub-sub-menu-1"
                                                to="/change_impact_project"
                                                primaryText="Change Impact Assessment"
                                                leftIcon={<BarChartIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-2"
                                                to="/change_environment_project"
                                                primaryText="Change Environment"
                                                leftIcon={<PlaylistAddCheckCircleIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-3"
                                                to="/change_saturation_project"
                                                primaryText="Change Saturation"
                                                leftIcon={<RunningWithErrorsIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-4"
                                                to="/change_maturity_project"
                                                primaryText="Change Maturity"
                                                leftIcon={<NetworkCheckIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-5"
                                                to="/change_heatmap_project"
                                                primaryText="Change Heatmap"
                                                leftIcon={<BubbleChartIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-6"
                                                to="/change_risk_assessment_report"
                                                primaryText="Change Risk Assessment"
                                                leftIcon={<ChangeHistoryIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-7"
                                                to="/business_readiness_report"
                                                primaryText="Business Readiness"
                                                leftIcon={<BusinessCenterIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-8"
                                                to="/change_governance_report"
                                                primaryText="Change Governance"
                                                leftIcon={<GavelIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-9"
                                                to="/sme_requirement_project_report"
                                                primaryText="SME Requirements"
                                                leftIcon={<Groups3Icon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-10"
                                                to="/go_no_go_report"
                                                primaryText="Go/No-Go Criteria"
                                                leftIcon={<RecommendIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                            <SubSubMenuItem
                                                key="sub-sub-menu-11"
                                                to="/close_out_report"
                                                primaryText="Project Close-Out"
                                                leftIcon={<BeenhereIcon sx={{ color: "white" }} />}
                                                bypassUser={isCustomerAdmin}
                                                allowedPermissions={[
                                                    { resource: "Report", action: ["view"] },
                                                ]}
                                            />
                                        </SubMenuItem>
                                    </MainMenuItem>
                                </div>

                                {!isSuperAdmin && <div id="main-tools" onMouseEnter={(e) => handleOpenSubMenu(e, 'tools')}>

                                    <MainMenuItem
                                        isDrawerOpen={open}
                                        handleDrawerOpen={handleDrawerOpen}
                                        primaryText="Tools and Templates"
                                        to="/cat_templates"
                                        exact
                                        leftIcon={<InterestsRoundedIcon fontSize="13" />}
                                        bypassUser={isCustomerAdmin}
                                        onMobileMenuClick={() => setState(false)}
                                    >
                                    </MainMenuItem> </div>}
                                {!isSuperAdmin && <div id="main-learning" onMouseEnter={(e) => handleOpenSubMenu(e, 'learning')}>

                                    <MainMenuItem
                                        isDrawerOpen={open}
                                        handleDrawerOpen={handleDrawerOpen}
                                        primaryText="Learning Centre"
                                        to="/learning_centre"
                                        exact
                                        leftIcon={<AutoStoriesIcon fontSize="12" />}
                                        bypassUser={isCustomerAdmin}
                                        onMobileMenuClick={() => setState(false)}
                                    >
                                    </MainMenuItem> </div>}
                                {!isSuperAdmin && <div id="main-faq" onMouseEnter={(e) => handleOpenSubMenu(e, 'faq')} ><MainMenuItem
                                    isDrawerOpen={open}
                                    handleDrawerOpen={handleDrawerOpen}
                                    onClick={handleCloseSubMenu}
                                    primaryText="FAQs"
                                    to="/faq"
                                    exact
                                    leftIcon={<LiveHelpIcon />}
                                    bypassUser={isCustomerAdmin}
                                // allowedPermissions={[
                                //     { resource: "FAQs", action: ["view"] },
                                // ]}
                                >
                                </MainMenuItem>
                                </div>}
                                <div id="main-setup" onMouseEnter={(e) => handleOpenSubMenu(e, 'setup')}>
                                    <MainMenuItem
                                        isDrawerOpen={open}
                                        handleDrawerOpen={handleDrawerOpen}
                                        primaryText="Set Up"
                                        to="/user"
                                        exact
                                        leftIcon={<SettingsIcon />}>
                                        <SubMenuItem
                                            to={`/BusinessUnit`}
                                            primaryText="Organisational Chart"
                                            bypassUser={isCustomerAdmin}
                                            leftIcon={<BusinessIcon sx={{ color: "white" }} />}
                                            allowedPermissions={[
                                                { resource: "BusinessUnit", action: ["view"] },
                                            ]}
                                        />
                                        <SubMenuItem
                                            to={`/user`}
                                            primaryText={isCustomerAdmin ? "Roles and Users" : "Users"}
                                            leftIcon={<PersonIcon sx={{ color: "white" }} />}
                                            bypassUser={isCustomerAdmin || isSuperAdmin}
                                            allowedPermissions={[
                                                { resource: "User", action: ["view"] },
                                                { resource: "Role", action: ["view"] },
                                            ]}
                                        />
                                        <SubMenuItem
                                            to={`/permission`}
                                            primaryText="Permission"
                                            bypassUser={isSuperAdmin}
                                            leftIcon={<LockIcon sx={{ color: "white" }} />}
                                            allowedPermissions={[
                                                { resource: "Permission", action: ["view"] },
                                            ]}
                                        />
                                        <SubMenuItem
                                            to={`/MasterData`}
                                            primaryText="Master Data"
                                            bypassUser={isSuperAdmin}
                                            leftIcon={<AnalyticsIcon sx={{ color: "white" }} />}
                                            allowedPermissions={[
                                                { resource: "MasterData", action: ["view", "edit", "create"] },
                                            ]}
                                        />
                                        <SubMenuItem
                                            to={`/theme_customization`}
                                            primaryText="Theme Customization"
                                            leftIcon={<PaletteIcon sx={{ color: "white" }} />}
                                            bypassUser={isCustomerAdmin}
                                            allowedPermissions={[
                                                { resource: "Theme", action: ["view"] },
                                            ]}
                                            onMobileMenuClick={() => setState(false)}
                                        />
                                    </MainMenuItem>
                                </div>


                            </List>
                        </div>
                        <div>
                            <List style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-end' }} >

                                <div>
                                    <ListItemButton onClick={handleClick} id="main-account" onMouseEnter={(e) => handleOpenSubMenu(e, 'account')}>
                                        <ListItemIcon style={{ paddingLeft: '8px' }}>
                                            <AccountCircle style={{ color: 'white', fontSize: '24px' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={<div  >
                                            <h3 className={classes.profileName}>{profile.name}</h3>
                                            <h3 className={classes.profileDesc}>{profile.role}</h3>
                                        </div>} />
                                        {
                                            !profile.isImpersonating ? profileArrowOpen ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} /> : null
                                        }
                                        {/* {console.log(!profile.isImpersonating)} */}
                                    </ListItemButton>
                                    <Collapse in={profileArrowOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>

                                            {
                                                !profile.isImpersonating &&
                                                <ListItemButton sx={{ pl: 5, color: 'white' }} onClick={handleProfileClick}>
                                                    <ListItemText primary="Change Password" />
                                                </ListItemButton>
                                            }
                                        </List>
                                    </Collapse>
                                </div>
                                <Tooltip placement="top" title="Logout">
                                    <ListItemButton sx={{ pl: 3, color: 'white' }}
                                        onClick={handleProfileLogoutClick}>
                                        <ListItemIcon>
                                            <LogoutIcon style={{ color: 'white' }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Logout" />
                                    </ListItemButton>
                                </Tooltip>
                                {
                                    profile.isImpersonating &&
                                    <Tooltip placement="top" title="Stop Impersonation">
                                        <ListItemButton sx={{ pl: 3, color: 'white' }} onClick={handleStopImpersonationClick}>
                                            <ListItemIcon>
                                                <LockOpenIcon style={{ color: 'white' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Stop Impersonation" />
                                        </ListItemButton>
                                    </Tooltip>
                                }
                            </List>
                            {!open ? '' : <Typography variant="body2" style={{ color: 'white', marginLeft: '30px' }} display="block" gutterBottom>
                                v 0.0.1
                            </Typography>}
                        </div>

                    </div>
                </Drawer >


                <Box id="sub-dashboard" className={classes.hoverContent} anchorEl={subAchorEl}>
                    {HoverDashboard}
                </Box>
                <Box id="sub-project" className={classes.hoverContent} anchorEl={subAchorEl}>
                    {HoverProject}
                </Box>
                <Box id="sub-assessment" className={classes.hoverContent} anchorEl={subAchorEl}>
                    {HoverAssessment}
                </Box>
                <Box id="sub-reports" className={classes.hoverContent} anchorEl={subAchorEl}>
                    {HoverReports}
                </Box>
                <Box id="sub-learning" className={classes.hoverContent} anchorEl={subAchorEl}>
                    {hoverLearningCentre}
                </Box>
                <Box id="sub-tools" className={classes.hoverContent} anchorEl={subAchorEl}>
                    {hoverTools}
                </Box>
                <Box id="sub-setup" className={classes.hoverContent} anchorEl={subAchorEl}>
                    {HoverSetup}
                </Box>
                <Box id="sub-faq" className={classes.hoverContent} anchorEl={subAchorEl}>
                    {hoverFAQ}
                </Box>
                <Box id="sub-account" className={classes.hoverContent} anchorEl={subAchorEl}>
                    {hoverAccounts}
                </Box>
            </div>

            <div>
                <Box sx={{ flexGrow: 1 }}>
                    <IconButton
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        style={{ marginTop: '0px' }}
                        className={classes.navIcon}
                        onClick={() => setState(true)}
                    >
                        {/* <SignalCellularAltIcon style={{ transform: 'scaleX(-1) rotate(-90deg)', olor: '#878a99',fontSize: '30px',}} /> */}
                    </IconButton>
                </Box>
                <div>

                    <React.Fragment>
                        <Drawer
                            PaperProps={{ classes: { root: classes.navMenu } }}
                            classes={{ paper: classes.paper }}
                            open={isSmall ? open : false}
                            onClose={() => setState(false)}
                        >
                            <div style={{ margin: 8, marginTop: 12, }}>
                                <img src={theme.img} width="200px" alt="Sidebar Change Assessment Tool Logo" />
                            </div>
                            {list}
                        </Drawer>
                    </React.Fragment>

                </div>
            </div>

        </>
    );
};

export default Sidebar;