import React from "react";
import { SelectInput, RadioButtonGroupInput, required, NumberInput } from "react-admin";
import {

    Typography,
    Card,
    Divider,
    Box,
    makeStyles,
} from "@material-ui/core";
import styled from "styled-components";
import { YES_NO_CHOICES } from "../../framework/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 16,
        paddingLeft: 40,
        paddingTop: 35,
        boxShadow: 'none',
        borderRadius: 12
    },
}));

export const ChangeManagementResourcing = () => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <Box display="flex" flexDirection="column">
                <Box display="flex">
                    <Typography>Have you budgeted for change management resourcing in your Business Case?</Typography>
                </Box>
                <Box display="flex" >
                    <RadioButtonGroupInput label="" source="q1" row={true} choices={YES_NO_CHOICES} validate={[required()]} />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column">
                <Box display="flex">
                    <Typography>Did the results of the Change Assessment roughly align to the Change Management resource estimates in your Business Case?</Typography>
                </Box>
                <Box display="flex" >
                    <RadioButtonGroupInput label="" source="q2" row={true} choices={YES_NO_CHOICES} validate={[required()]} />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column">
                <Box display="flex">
                    <Typography>What is your Change Management budget for resourcing? (Express in FTE)</Typography>
                </Box>
                <Box display="flex" >
                    <NumberInput label="FTE" variant="outlined" source="q3" validate={[required()]} />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column">
                <Box display="flex">
                    <Typography>Total dollars to be allocated in Business Case for Change Management (salary and non-salary costs, including Communications)</Typography>
                </Box>
                <Box display="flex" >
                    <NumberInput label="Costs" variant="outlined" source="q4" validate={[required()]} />
                </Box>
            </Box>
        </Card>
    );
};

export default ChangeManagementResourcing;