import { Title, useDataProvider } from "react-admin";
import { TokenStorage } from "../utils/TokenStorage";
import WithPermission from "../utils/WithPermission";
// import ChangeImpact from "./dashboardComponents/changeImpact";
// import NumberOfProjects from "./dashboardComponents/numberOfProjects";
// import SmeRequired from "./dashboardComponents/smeRequired";
// import UpcomingProject from "./dashboardComponents/upcomingProject";
import { impersonatedURL } from '../utils/AppUtils';
import DashboardPowerBI from '../views/powerbiReports/Dashboard';
import { makeStyles } from "@mui/styles";
import React from "react";
import { useHistory } from 'react-router-dom';
import PBIPortfolioEmbed from "../views/portfolioReport/pbiPortfolioEmbed";
const useStyles = makeStyles({
    title: {
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    }
});


export const Dashboard = (props) => {
    const classes = useStyles();
    const user = TokenStorage.getUserDetails();
    const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};
    const dataProvider = useDataProvider();
    const history = useHistory();
    impersonatedURL(isImpersonating, '#/?impersonated')

    React.useEffect(async () => {
        if (isSuperAdmin) {
            history.push('/user')
        }
        // setLoading(true);
        await dataProvider
            .getList("Faq", {
                pagination: { page: 1, perPage: 1 },
                sort: { field: "id", order: "ASC" },
                filter: {},
            })
            .then((response) => {
                // setFaqs(response.data);
                // setLoading(false);
            })
            .catch((error) => {
                // setError(error.message);
                // setLoading(false);
            });
    }, []);


    return (
        <PBIPortfolioEmbed
            title="Portfolio Change Dashboard"
            reportId='421050e3-5165-4937-8598-a9e41a955361'
            pageName="ReportSection34b4aead0ccafc11a6f7"
            resource='Report'
            url=''
        />
    )
}

export default Dashboard;

// <WithPermission resource="Dashboard" bypassUser={isCustomerAdmin} action={["view"]} {...props} >
{/* <Title title="Portfolio Change Dashboard" className={classes.title} />
            <DashboardPowerBI /> */}
// </WithPermission>