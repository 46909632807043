import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
    AutocompleteInput,
    BooleanInput,
    CheckboxGroupInput,
    RadioButtonGroupInput,
    TextInput,
    maxLength,
    required,
    useDataProvider,
    useNotify,
    useRefresh,
} from "react-admin";
import { Form } from "react-final-form";
import {
    INACTIVE_ACTIVE_CHOICES,
    STATUS_INACTIVE_ACTIVE_CHOICES,
    YES_NO_CHOICES,
} from "../../framework/constants";
import { onFailureMessage } from "../../utils/AppUtils";
import CustomDrawer from "../customComponents/customDrawer";
import { Box, Switch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        bottom: 0,
        marginBottom: 40,
        // width: 360
        // padding: 16
    },
    inputMargin: {
        marginTop: 5
    },
    gridItemPadding: {
        padding: '0 0 14px 8px !important'
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: '#BDBDBD'
    },
    formWidth: {
        maxWidth: 360
    },
    mobileBtn: {
        [theme.breakpoints.down('xs')]: {
            width: "40vw",
        },
    }
}));

const industries = [
    { id: "Agriculture, Forestry and Fishing", name: "Agriculture, Forestry and Fishing" },
    { id: "Mining", name: "Mining" },
    { id: "Manufacturing", name: "Manufacturing" },
    { id: "Electricity, Gas, Water and Waste Services", name: "Electricity, Gas, Water and Waste Services" },
    { id: "Construction", name: "Construction" },
    { id: "Wholesale Trade", name: "Wholesale Trade" },
    { id: "Retail Trade", name: "Retail Trade" },
    { id: "Accommodation and Food Services", name: "Accommodation and Food Services" },
    { id: "Transport, Postal and Warehousing", name: "Transport, Postal and Warehousing" },
    { id: "Information Media and Telecommunications", name: "Information Media and Telecommunications" },
    { id: "Financial and Insurance Services", name: "Financial and Insurance Services" },
    { id: "Rental, Hiring and Real Estate Services", name: "Rental, Hiring and Real Estate Services" },
    { id: "Professional, Scientific and Technical Services", name: "Professional, Scientific and Technical Services" },
    { id: "Administrative and Support Services", name: "Administrative and Support Services" },
    { id: "Public Administration and Safety", name: "Public Administration and Safety" },
    { id: "Education and Training", name: "Education and Training" },
    { id: "Health Care and Social Assistance ", name: "Health Care and Social Assistance " },
    { id: "Arts and Recreation Services", name: "Arts and Recreation Services" },
    { id: "Government", name: "Government" },
    { id: "Other Services", name: "Other Services" },
];

export const UserEditDrawer = ({ record, drawerOpen, onClose, handleSnackbarOpen }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const [initialValues, setInitialValues] = React.useState();
    const [checked, setChecked] = React.useState(true);
    const classes = useStyles();

    const resource = "User";

    const onSubmit = (values, form) => {
        // console.log(values)
        const { isFirstTimeLogin, ...newValues } = values;
        dataProvider
            .update(resource, {
                id: record.id,
                data: {
                    ...newValues,
                    firstName: values.fullName,
                },
            })
            .then((response) => {
                form.initialize({});
                form.restart();
                onClose(false);
                handleSnackbarOpen("Customer updated successfully", "success")
                refresh();
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error")

            });
    };

    const validate = () => { };

    React.useEffect(() => {
        if (record && record.id)
            dataProvider.getOne(resource, { id: record.id }).then((response) => {
                setInitialValues(response.data);
                setChecked(!response.data.isDisabled);
            });
    }, [record]);

    const handleSwitchChange = (event, form) => {
        const confirmed = window.confirm(`Are you sure you want to ${event.target.checked ? 'enable' : 'disable'} this user?`);
        if (confirmed) {
            setChecked(event.target.checked);
            form.change("isDisabled", !event.target.checked)
        }
    };


    return (
        <CustomDrawer
            drawerAnchor="right"
            title="Edit Client"
            drawerOpen={drawerOpen}
            onClose={onClose}
        >
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit, form, values }) => (
                    <form className={classes.formWidth} onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Client Name"
                                    source="companyName"
                                    validate={[required(), maxLength(255)]}
                                    className={classes.inputMargin}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Location"
                                    source="location"
                                    validate={[required(), maxLength(255)]}
                                    className={classes.inputMargin}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <AutocompleteInput
                                    variant="outlined"
                                    source="industry"
                                    label="Industry"
                                    choices={industries}
                                    className={classes.inputMargin}
                                    fullWidth
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <RadioButtonGroupInput
                                    label="Managed Services"
                                    source="isManagedService"
                                    row={true}
                                    choices={YES_NO_CHOICES}
                                    validate={[required()]}
                                    style={{ display: 'flex' }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Admin Name"
                                    source="fullName"
                                    validate={[required(), maxLength(255)]}
                                    className={classes.inputMargin}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Email"
                                    source="email"
                                    type="email"
                                    validate={[required(), maxLength(255)]}
                                    className={classes.inputMargin}
                                    inputProps={{
                                        classes: {
                                            notchedOutline: classes.inputBorder
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RadioButtonGroupInput
                                    label="Status"
                                    source="status"
                                    row={true}
                                    choices={STATUS_INACTIVE_ACTIVE_CHOICES}
                                    validate={[required()]}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                {/* <CheckboxGroupInput  
                                label=""  
                                fullWidth
                                variant="outlined"   
                                optionText="name" 
                                source="isDisabled" 
                                choices={[
                                    { id: false, name: 'Disable the user' },
                                    ]} 
                                /> */}
                                {/* {console.log("isDisabled", form)}
                                {console.log("isDisabled", values)}
                                <ControlledSwitches
                                    isDisabled={values.isDisabled}
                                /> */}
                                <Box>

                                    <Switch
                                        checked={checked}
                                        onChange={(e) => handleSwitchChange(e, form)}
                                    />
                                    {values.isDisabled ? "User disabled" : "User enabled"}
                                </Box>
                                {/* <ConfirmableBooleanInput label="Disable the user" source="isDisabled" /> */}

                            </Grid>
                            <Grid item xs={12} className={classes.gridItemPadding}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Comments"
                                    source="description"
                                    multiline
                                    validate={[maxLength(255)]}
                                    className={classes.inputMargin}
                                    inputProps={{
                                        style: {
                                            height: 80,
                                        },
                                        classes: {
                                            notchedOutline: classes.inputBorder
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.toolbar}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.mobileBtn}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        onClick={() => onClose(false)}
                                        className={classes.mobileBtn}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </CustomDrawer>
    );
};

export default UserEditDrawer;
