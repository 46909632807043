import { Box, Button, Card, Checkbox, useMediaQuery } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import clsx from "clsx";
import moment from "moment";
import { Field, useForm } from "react-final-form";
import { startEndDatesOfWeeks } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
    forecastTitle: {
        backgroundColor: "#F2F2F2",
        display: "flex",
    },
    forecastCard: {
        marginBottom: 30,
        margin: "0 auto",
        overflowX: "auto",
        width: "90%",
        [theme.breakpoints.down("md")]: {
            width: "100%"
        }
    },
    forecastCardRow: {
        display: "flex",
    },
    forecastCardWeek: {
        width: "20%",
        fontSize: 14,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        [theme.breakpoints.down("md")]: {
            fontSize: 14,
            flexDirection: "column",
        },
        textAlign: "center",
    },
    forecastRiskContainer: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        placeItems: "center",
    },
    forecastRiskBox: {
        width: 100,
        height: 45,
        [theme.breakpoints.down("md")]: {
            minWidth: 64,
            height: 40,
        },
        [theme.breakpoints.down("sm")]: {
            width: 50,
            height: 40,
        },
        opacity: 0.3,
        borderRadius: 4,
        "&.active": {
            opacity: 1,
        },
        "&.risk-high": {
            backgroundColor: "#EB5757",
        },
        "&.risk-medium": {
            backgroundColor: "#F2994A",
        },
        "&.risk-low": {
            backgroundColor: "#27AE60",
        },
    },
    forecastTitleText: {
        fontSize: 16,
        fontWeight: 500,
        fontStyle: "normal",
        margin: 0,
    },
    selectedForcast: {
        // margin: '0 10px',
        // width: "30%",
        padding: "4px 4px",
        boprderRadius: '10px',
        textAlign: 'center',
        "&.activeRed": {
            border: '2px solid #EB5757'
        },
        "&.activeGreen": {
            border: '2px solid #27AE60'
        },
        "&.activeAmber": {
            border: '2px solid #F2994A'
        },
    },
}));

const ProjectForecastsInputsContainer = ({ projectForecasts, setProjectForecasts, businessUnitId, isCreate }) => {
    const form = useForm();
    const formValues = form.getState().values;
    const classes = useStyles();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const { businessUnitId: mainBu, startDate, endDate } = formValues;
    const firstImpactedBu = formValues.impactedBusinessUnits[0].id
    const handleRiskBtnClick = async (index, risk) => {
        projectForecasts[index].risk = risk;
        projectForecasts[index].week = index + 1;
        // assign same risk for all the higher weeks
        if (isCreate) {
            for (let i = index + 1; i < projectForecasts.length; i++) {
                projectForecasts[i].risk = risk;
                projectForecasts[i].week = i + 1;
            }

        }
        form.change(`projectForecasts[${businessUnitId}]`, [...projectForecasts]);
        setProjectForecasts([...projectForecasts]);
    };

    const isAllWeeksForecasted = (forecasts) => {
        let isForecasted = true;
        for (let i = 0; i < forecasts.length; i++) {
            if (forecasts[i] && !forecasts[i].risk) {
                isForecasted = false;
                break;
            }
        }

        return isForecasted;
    };

    const handleSkipForecast = (skip) => {
        if (skip) {
            const formValues = form.getState().values;
            const impactedUnits = formValues.impactedBusinessUnits;
            const weeksCount = startEndDatesOfWeeks(moment(startDate), moment(endDate)).length;
            const mainBuForecasts = formValues.projectForecasts[firstImpactedBu]; // replaced mainBu to firstImpactedBu
            form.batch(() => {
                for (const impactedUnit of impactedUnits) {
                    const forecastsLength = formValues.projectForecasts[impactedUnit.id] ? formValues.projectForecasts[impactedUnit.id].length : 0;
                    if (isCreate && !(forecastsLength < weeksCount)) {
                        continue;
                    }
                    // set this main bu forecasts to impacted bu
                    const impactedForeCasts = mainBuForecasts.map(projectForecast => (
                        { ...projectForecast, businessUnitId: impactedUnit.id }
                    ));
                    form.change(`projectForecasts[${impactedUnit.id}]`, impactedForeCasts);
                }
            });
            // set this main bu forecasts to current bu
            const forecasts = mainBuForecasts.map(projectForecast => (
                { ...projectForecast, businessUnitId: businessUnitId }
            ))
            setProjectForecasts([...forecasts])
        }
    }

    return (
        <Box>
            <Card className={classes.forecastCard} elevation={3}>
                <div className={classes.forecastTitle}>
                    <div className={classes.forecastCardWeek} style={{ borderRight: "1px solid #C4C4C4", padding: 18, marginRight: 5 }}>
                        <h6 className={classes.forecastTitleText}>Weeks</h6>
                    </div>
                    <div className={classes.forecastRiskContainer}>
                        <h6 className={classes.forecastTitleText}>HIGH</h6>
                        <h6 className={classes.forecastTitleText}>MEDIUM</h6>
                        <h6 className={classes.forecastTitleText}>LOW</h6>
                    </div>
                </div>
                {projectForecasts.map((projectForecast, index) => (
                    <div className={classes.forecastCardRow}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "20%", padding: "18px", borderRight: "1px solid #C4C4C4", marginRight: 5 }}>
                            <Box whiteSpace="nowrap">Week {index + 1}</Box>
                            <Box className={classes.forecastCardWeek} >
                                <Box whiteSpace="nowrap">
                                    {moment(projectForecast.startDate).format("DD-MMM")}
                                </Box>
                                <Box whiteSpace="nowrap">
                                    {isSmallScreen ? <MoreVertIcon color="primary" sx={{ opacity: .5 }} /> : " - "}
                                </Box>
                                <Box whiteSpace="nowrap">
                                    {moment(projectForecast.endDate).format("DD-MMM")}
                                </Box>
                            </Box>
                        </Box>
                        <div className={classes.forecastRiskContainer}>
                            <Box className={clsx(classes.selectedForcast, {
                                'activeRed': projectForecast
                                    ? projectForecast.risk === "H"
                                    : false
                            })}>
                                <Button
                                    className={clsx(classes.forecastRiskBox, {
                                        "active": projectForecast
                                            ? projectForecast.risk === "H"
                                            : false,
                                        "risk-high": true,
                                    })}
                                    onClick={() => handleRiskBtnClick(index, "H")}
                                />
                            </Box>

                            <Box className={clsx(classes.selectedForcast, {
                                'activeAmber': projectForecast
                                    ? projectForecast.risk === "M"
                                    : false
                            })}>
                                <Button
                                    className={clsx(classes.forecastRiskBox, {
                                        "active": projectForecast
                                            ? projectForecast.risk === "M"
                                            : false,
                                        "risk-medium": true,
                                    })}
                                    onClick={() => handleRiskBtnClick(index, "M")}
                                />
                            </Box>

                            <Box className={clsx(classes.selectedForcast, {
                                'activeGreen': projectForecast
                                    ? projectForecast.risk === "L"
                                    : false
                            })}>
                                <Button
                                    className={clsx(classes.forecastRiskBox, {
                                        "active": projectForecast
                                            ? projectForecast.risk === "L"
                                            : false,
                                        "risk-low": true,
                                    })}
                                    onClick={() => handleRiskBtnClick(index, "L")}
                                />
                            </Box>

                        </div>
                    </div>
                ))}
            </Card>
            <Box>
                <Field name="projectForecasts" subscription={{ value: true }}>
                    {({ input: { value } }) =>
                        value && value[firstImpactedBu] && value[firstImpactedBu].length > 0 ?
                            (
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    <FormGroup row>
                                        <FormControlLabel control={<Checkbox
                                            color="primary"
                                            source="skip_forecast"
                                            disabled={!isAllWeeksForecasted(value[firstImpactedBu])}
                                            onChange={handleSkipForecast}
                                        />} label="Copy to all impacted Business Unit's forecast" />
                                    </FormGroup>
                                </Box>
                            )
                            : null
                    }
                </Field>
            </Box>
        </Box>
    );
};

export default ProjectForecastsInputsContainer;