export const ASSESSMENT_SUB_TYPE_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: AssessmentSubTypeFilter
  ) {
    allAssessmentSubTypes(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        name
        description
        assessmentTypeId
        assessmentType {
          id
          name
          description
          assessmentSectionId
        }
        assessmentValues {
          id
          nature
          description
          signific
          rating
        }
      }
      totalCount
    }
  }`

export const ASSESSMENT_SUB_TYPE_RETRIEVE_QUERY = `query($id: Int!) {
    assessmentSubType(id: $id) {
      id
      name
      description
      assessmentTypeId
      assessmentType {
        id
        name
        description
        assessmentSectionId
      }
      assessmentValues {
        id
        nature
        description
        signific
        rating
      }
    }
  }`