import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Form, useForm } from "react-final-form";

const useStyles = makeStyles((theme) => ({
    projectCard: {
        width: "95%",
        height: "80%",
        marginTop: 30,
        marginLeft: 20,
        marginRight: 10,
        borderRadius: 12,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            height: '100%',
            margin: '0px',
            borderRadius: 0,
        },
    },
    content: {
        width: "100%",
        [theme.breakpoints.up("md")]: {},
        padding: "35px 40px 0 47px",
        [theme.breakpoints.down("xs")]: {
            padding: "15px 10px 0 10px",

            // width: "60%"
            overflowX: 'hidden'
        },
    },
    formContent: {
        marginRight: 10,
        marginLeft: 10,
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.down("xs")]: {
            // border: '1px solid red',
            // paddingRight: '30px',
            // margin: 0
        },
    },
    fullList: {
        width: "auto",
    },
    drawerTitleBar: {
        display: "flex",
        alignItems: "center",
        marginBottom: 24,
    },
    drawerTitle: {
        flexGrow: 1,
        fontFamily: "Inter",
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: 24,
        [theme.breakpoints.down("xs")]: {
            marginLeft: '3%',
        },
    },
    drawerOverflow: {
        overflowY: "hidden",
    },
    backDrop: {
        // background: "rgba(255,255,255,0.2)",
        background: "blue"
    },
}));

export const ProjectCustomDrawer = ({
    children,
    title,
    drawerAnchor,
    drawerOpen,
    onClose,
    projectInfo
}) => {
    const classes = useStyles();
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        onClose(open);
    };



    // if (projectTitleInfoString) {
    //     console.log(projectTitleInfoString);
    // } else {
    //     console.log("No valid values to display.");
    // }


    return (
        <Drawer
            classes={{ paper: classes.projectCard }}
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    // onClose(event, reason);
                    toggleDrawer(true)
                }
            }}
            anchor={drawerAnchor}
            open={drawerOpen}
        // onClose={toggleDrawer(false)}
        >
            <div className={classes.content}>
                <Box className={classes.drawerTitleBar}>
                    <Typography variant="h6" className={classes.drawerTitle}>
                        {title}
                        <span style={{ fontSize: 12, marginLeft: 10, color: 'lightslategrey' }}>{projectInfo}</span>
                    </Typography>
                    <IconButton aria-label="close" size="medium" component="span"
                        onClick={() => {
                            onClose(false);
                            window.location.reload(false);
                        }}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
                <div className={classes.formContent}>{children}</div>
            </div>
        </Drawer>
    );
};

export default ProjectCustomDrawer;

// const FormData= ()=>{

//     return <Form>

//     </Form>
// }