export const MILESTONE_LIST_QUERY = `query($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: MilestoneFilter!){
    allMilestones(page:$page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter:$filter){
      items{
        id
        name
        projectPhase
        milestoneDate
        projectId
        project {
          id
        }
      }
      totalCount
      projectPhases{
        id
        name
        startDate
        endDate        
      }
    }
  }`

export const MILESTONE_RETRIEVE_QUERY = `query($id: Int!) {
    businessUnit(id: $id) {
        id
        name
        geography
        fte
        description
        status
        parentBusinessUnitId
    }
  }`

export const MILESTONE_UPDATE_MUTATION = `mutation(
    $projectId: Int!
    $milestones: [MilestoneInput!]!
  ) {
    updateMilestones(
      projectId: $projectId
      milestones: $milestones
    ) {
      id
      name
      projectPhase
      milestoneDate
      projectId
    }
  }`

  export const MILESTONE_CREATE_MUTATION = `mutation(
    $projectId: Int!
    $milestones: [MilestoneInput!]!
  ) {
    createMilestones(
      projectId: $projectId
      milestones: $milestones
    ) {
      id
      name
      projectPhase
      milestoneDate
      projectId
    }
  }`

export const MILESTONE_DELETE_MUTATION = `mutation($id: Int!) {
    deleteMilestone(
      id: $id
    )
  }`