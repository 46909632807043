export const PROJECT_FORECAST_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ProjectForecastFilter
  ) {
    allProjectForecasts(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        week
        startDate
        endDate
        risk
        projectId
        businessUnitId
      }
      totalCount
    }  
  }`;

export const PROJECT_FORECAST_CREATE_MUTATION = `mutation(
  $projectId: Int!
  $clientId: Int!
  $projectForecasts: [ProjectForecastInput!]
) {
  createProjectForecasts(
    projectId: $projectId
    clientId: $clientId
    projectForecasts: $projectForecasts
  ) {
    id
    startDate
    endDate
    risk
    week
    businessUnit {
      name
    }
    businessUnitId
    projectId
  }
}
`;