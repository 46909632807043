import { formatResponse, onFailureMessage } from "../utils/AppUtils";
import { TokenStorage } from "../utils/TokenStorage";
import { API_URL, NETWORK_ERROR_MESSAGE } from './constants';
import { Query } from './graphqlQueries';

const apiUrl = API_URL;

export default {
    login: async({ username, password }) => {
        const resource = "login";
        const url = apiUrl;

        const request = new Request(url, {
            method: "POST",
            body: JSON.stringify({
                operationName: null,
                query: Query[resource]["login"]["query"],
                variables: {
                    email: username.trim(),
                    password: password
                }
            }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return await fetch(request)
            .then(async response => {
                if (response.status < 200 || response.status >= 300) {
                    const responseJson = await response.json()
                    const error_message = responseJson ? onFailureMessage(responseJson) : "something went wrong";
                    throw new Error(error_message)
                }
                return response.json();
            })
            .then((response) => {
                if (response.errors && response.errors.length > 0 && response.errors[0].extensions) {
                    const responseStatusCode = response.errors[0].extensions.code;
                    formatResponse(response, responseStatusCode);
                    throw new Error(onFailureMessage({
                        response: {
                            status: response.status,
                            data: response,
                        },
                    }));
                }
                const { token, user ,pbi_refresh_token,pbi_access_token} = response.data[Query[resource]["login"]["query_name"]]
                TokenStorage.storeToken(token);
                TokenStorage.pbiStoreToken(pbi_access_token,pbi_refresh_token)
                TokenStorage.storeUserDetails(JSON.stringify(user));
                // TokenStorage.storeRefreshToken(refresh);
                return user;
            }).catch(error => {
                if (error.message === "Failed to fetch")
                    throw new Error(NETWORK_ERROR_MESSAGE)
                throw new Error(error.message);
            })
    },
    logout: () => {
        TokenStorage.clear();
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            TokenStorage.clear();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return TokenStorage.getToken() ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        const userDetails = TokenStorage.getUserDetails();
        const { permissions, roles } = userDetails ? userDetails : {};
        return (permissions || roles) ? Promise.resolve({ permissions, roles }) : Promise.reject();
    },
    getUserDetails: () => {
        const user = TokenStorage.getUserDetails();
        return user ? Promise.resolve(user) : Promise.reject();
    },
};