import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Popover from '@material-ui/core/Popover';
import { makeStyles, MuiThemeProvider, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import { Box } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { Notification, useAuthProvider, useLogout, useRefresh } from "react-admin";
import { useHistory } from "react-router-dom";
import { TokenStorage } from "../utils/TokenStorage";
import Sidebar from "./sidebar";
// import theme from "./theme";
import palette from "./theme/palette";
import { ThemeContext } from "./theme/themeContext";

import dataProvider from "./dataProvider";
import { getClientId } from "../utils/AppUtils";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        backgroundColor: '#F3F3F9',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
    },
    title: {
        flexGrow: 1,
        fontWeight: 700,
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontSize: '18px !important',
        letterSpacing: 0.3,
        color: palette.primary.darker,
        [theme.breakpoints.down('sm')]: {
            // flexGrow: 0,
            // color: 'red',
            // paddingLeft: '10px',
            // textAlign: 'left !important'
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        [theme.breakpoints.down('sm')]: {
            // flexDirection: 'column-reverse',

        },

        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toggleToolbar: {
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "flex-end",
        margin: theme.spacing(3, 0),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',

        },

        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: "#F3F3F9",
        minHeight: "100vh",
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginTop: "-80px"
        },
    },
    titleBar: {
        display: "flex",
        alignItems: "center",
        marginLeft: "-15px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "20px"
        },
    },
    rightContent: {
        display: "flex",
        alignItems: "center",
    },
    profileContent: {
        display: "flex",
        flexDirection: "column",
    },
    profileName: {
        fontFamily: "Mulish",
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: 0.2,
        color: "#252733",
        textTransform: "capitalize",
        margin: 0,
        fontStyle: "normal",
        marginBottom: 7,

    },
    profileDesc: {
        fontFamily: "Mulish",
        fontSize: 10,
        letterSpacing: 0.2,
        color: "#252733",
        textTransform: "capitalize",
        fontStyle: "normal",
        margin: 0,
    },
    profileNmenu: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            display: 'none'

        },
    },
    iconHover: {
        backgroundColor: "transparent !important",
        padding: 0,
        marginRight: 10,
        marginBottom: 10,
        cursor: "auto",
    },
    chevDown: {
        color: "#A1A1A1",
        display: "flex",
        alignItems: "center",
        marginLeft: 15,
        height: "100%",
        fontSize: 30,
        cursor: "pointer",
    },
    menuTypography: {
        textDecoration: "none",
        padding: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#E3E3E3'
        }
    },


}));

const formatProfileDetail = (user) => {
    const user_role = user.isSuperAdmin
        ? "Super Admin"
        : user.isCustomerAdmin
            ? "Customer Admin"
            : _.map(user.roles, "name");
    return {
        name: user.fullName,
        role: user_role,
        isImpersonating: user.isImpersonating,
    };
};

export default function MiniDrawer(props) {
    const classes = useStyles();
    const history = useHistory();
    const [block, setBlock] = React.useState(false);
    const themes = useTheme();
    const { themeObject, setTheme, theme, contextUser } = React.useContext(ThemeContext)
    //   const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [defaultTheme, setDefaultTheme] = React.useState({});
    const [defaultLogo, setDefaultLogo] = React.useState("");

    const [profile, setProfile] = React.useState({
        name: "",
        role: "",
        isImpersonating: false,
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const logout = useLogout();
    const authProvider = useAuthProvider();
    const refresh = useRefresh();

    const profileOpen = Boolean(anchorEl);

    const handleProfileMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfileClose = () => {
        setAnchorEl(null);
    };

    const handleProfileLogoutClick = () => {
        logout();
        handleProfileClose();
    };

    React.useEffect(() => {
        const user = TokenStorage.getUserDetails();
        const impersonateUser = TokenStorage.getImpersonateUserDetails();
        const clientId = impersonateUser ? impersonateUser.id : (user && user.isCustomerAdmin) ? user.id : (user && user.customerAdminId) || null;
        const variables = {
            data: {
                clientId: clientId,
            },
        };
        dataProvider
            .getOne("ThemeCustomize", variables)
            .then((response) => {

                // setTheme({ id: response.data.theme, img: `data:image/png;base64,${response.data.url}` })
                const oldState = { ...theme };
                oldState['id'] = response.data.theme;
                if (response.data.url) {
                    oldState['img'] = `data:image/png;base64,${response.data.url}`;
                }
                // console.log(res)
                setTheme(oldState)
            })
            .catch((error) => {
                console.log(error.message)
            });
    }, [contextUser.isCustomerAdmin])
    const handleStopImpersonationClick = () => {
        // redirect to home page
        history.push("/");
        TokenStorage.clearImpersonateUserDetails();

        // refresh page to clear all the states
        window.location.hash = "#/user"
        window.location.reload();
        authProvider.getUserDetails().then(user => {
            const formattedProfile = formatProfileDetail(user)
            setProfile(formattedProfile);
            refresh(true)
        }).catch(error => {

        })
    };

    const handleSidebarOpen = () => {
        setOpen(true);
    };

    const handleSidebarClose = () => {
        setOpen(false);
    };

    const handleSidebar = () => {
        setOpen(!open);
    };

    const handleProfileClick = () => {
        handleProfileClose();
        history.push("/change_password");
    }

    // const profileBar = (
    //     <div className={classes.profileNmenu}>
    //         <IconButton
    //             aria-label="account of current user"
    //             aria-controls="menu-appbar"
    //             aria-haspopup="true"
    //             // color="primary"
    //             size="medium"
    //             disableFocusRipple
    //             disableRipple
    //             disableTouchRipple
    //             className={classes.iconHover}
    //             style={{
    //                 color: profile.isImpersonating ? 'orange' : '#00a0d9'
    //             }}
    //         >
    //             <AccountCircle fontSize="medium" />
    //         </IconButton>
    //         <Popover
    //             id="menu-appbar"
    //             open={profileOpen}
    //             anchorEl={anchorEl}
    //             onClose={handleProfileClose}
    //             anchorOrigin={{
    //                 vertical: "bottom",
    //                 horizontal: "center",
    //             }}
    //             transformOrigin={{
    //                 vertical: "top",
    //                 horizontal: "right",
    //             }}
    //         >
    //             {
    //                 !profile.isImpersonating && <Typography className={classes.menuTypography} onClick={handleProfileClick}>Change Password</Typography>
    //             }
    //             <Typography className={classes.menuTypography} onClick={handleProfileLogoutClick}>Logout</Typography>
    //             {
    //                 profile.isImpersonating && <Typography className={classes.menuTypography} onClick={handleStopImpersonationClick}>Stop Impersonation</Typography>
    //             }
    //         </Popover>

    //         <div >
    //             <h3 className={classes.profileName}>{profile.name}</h3>
    //             <h3 className={classes.profileDesc}>{profile.role}</h3>
    //         </div>
    //         <div>
    //             <KeyboardArrowDownIcon
    //                 onClick={handleProfileMenu}
    //                 className={classes.chevDown}
    //             />
    //         </div>
    //     </div>
    // );

    // React.useEffect(() => {
    //     // set profile details of impersonating user
    //     // if there is no impersonate user,
    //     // set profile details of original user
    //     authProvider
    //         .getUserDetails()
    //         .then((user) => {
    //             const formattedProfile = formatProfileDetail(user);
    //             setProfile(formattedProfile);
    //         })
    //         .catch((error) => { });
    // }, []);

    return (


        <MuiThemeProvider theme={themeObject}>

            <div className={classes.root}>

                <CssBaseline />

                <Sidebar open={open} handleDrawer={handleSidebar} handleDrawerOpen={handleSidebarOpen} handleDrwerClose={handleSidebarClose} />
                {/* <div className={classes.toggleToolbar}>
                    <IconButton onClick={handleSidebar} disableFocusRipple={true} disableRipple={true}>
                        {!open ? theme.direction === 'rtl' ? <ChevronLeftIcon style={{ color: '#00A0D9' }} /> : <SignalCellularAltIcon style={{ transform: 'scaleX(-1) rotate(-90deg)', color: '#00A0D9' }} /> : <ChevronLeftIcon style={{ color: '#00A0D9' }} />}
                    </IconButton>
                </div> */}
                <main className={classes.content}>
                    <Box className={classes.titleBar}>
                        <Box className={classes.toggleToolbar}
                            onClick={() => setOpen(!open)} >
                            <IconButton
                                disableFocusRipple={true}
                                disableRipple={true}
                            >
                                {!open ? themeObject.direction === 'rtl' ? <ChevronLeftIcon style={{ color: '#878a99' }} /> : <SignalCellularAltIcon
                                    style={{
                                        transform: 'scaleX(-1) rotate(-90deg)',
                                        color: '#878a99',
                                        fontSize: '30px',
                                        "&:hover": {
                                            borderRadius: 4,
                                        },
                                    }} /> : <ChevronLeftIcon style={{ color: '#878a99', fontSize: "30px" }} />}
                            </IconButton>
                        </Box>
                        <div
                            className={classes.toolbar}
                            style={{ marginTop: "-10px" }}
                        >
                            <Typography
                                variant="h6"
                                id="react-admin-title"
                                className={classes.title}
                                noWrap
                            ></Typography>
                            {/* {profileBar} */}
                        </div>
                    </Box>
                    {props.children}
                </main>
                <Notification anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
            </div>
        </MuiThemeProvider>
    );
}
