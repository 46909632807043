import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";


const ErrorCard = ({ message, align, text, errorType }) => {
    let history = useHistory();

    const useStyles = makeStyles((theme) => ({
        errorCard: {
            padding: 32,
            textAlign: align ? align : 'left',
            color: theme.palette.error.main
        },
    }));

    const classes = useStyles();

    return <Card className={classes.errorCard} alignCenter={true}>
        <Typography>{message}</Typography>
        <Typography>{text}</Typography>
        <br />
        <Button
            variant="contained"
            color='primary'
            startIcon={<ArrowBackIcon />}
            onClick={() => history.goBack(-1)}
        >Go Back</Button>
    </Card>
}

export default ErrorCard;