export const KPI_CRITERIA_LIST_QUERY = `query(
    $projectId: Int!, 
    $type: KpiType!
  ) {
    getKpiCriterias(
      projectId: $projectId, 
      type: $type) {
      projectId
      kpi
      assessment
      commentary
      type
      createdAt
    }
  }`

export const KPI_CRITERIA_CREATE_MUTATION = ` mutation (
    $projectId: Int!
    $type: KpiType!
    $kpiCriterias: [KpiCriteriasInput!]
  ) {
    createKpiCriterias(
      projectId: $projectId
      type: $type
      kpiCriterias: $kpiCriterias
    ) {
      id
      kpi
      assessment
      commentary
    }
  }`
