export const PROJECT_CREATE_MUTATION = `mutation(
    $name: String!
    $projectSponsorId: Int
    $businessOwnerId: Int
    $projectDriver: String
    $startDate: Date
    $endDate: Date
    $businessUnitId: Int
    $impactedBusinessUnits: [Int]
    $changeLeaderId: Int
    $projectPhases: [ProjectPhaseInput]
    $goLiveDate: Date
    $driverDisplayName: String
    $projectStatus: String
    $changeStatus: String
    $summary: String
    $commentary: String
    $isDraft: Boolean!
  
  ) {
    createProject(
      name: $name
      projectSponsorId: $projectSponsorId
      businessOwnerId: $businessOwnerId
      projectDriver: $projectDriver
      startDate: $startDate
      endDate: $endDate
      businessUnitId: $businessUnitId
      impactedBusinessUnits: $impactedBusinessUnits
      changeLeaderId: $changeLeaderId
      projectPhases: $projectPhases
      goLiveDate: $goLiveDate
	    driverDisplayName: $driverDisplayName
	    projectStatus: $projectStatus
	    changeStatus: $changeStatus
	    summary: $summary
	    commentary: $commentary
	    isDraft: $isDraft
    ) {
      id
      name
      projectSponsorId
      businessOwnerId
      projectDriver
      startDate
      endDate
      businessUnitId
      businessUnit {
          id
          name
      }
      impactedBusinessUnits {
        id
        name
      }
      changeLeaderId
      changeLeader {
          id
          fullName
      }
      projectPhases {
        id
        name
        startDate
        endDate
      }
      status
      goLiveDate
      isDraft
      stepCount
    }
  }`

export const PROJECT_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ProjectFilter!
  ) {
    allProjects(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        stepCount
        isDraft
        id
        name
        projectSponsorId
        projectSponsor {
            id
            isCustomerAdmin
            fullName
        }
        businessOwnerId
        projectDriver
        startDate
        endDate
        goLiveDate
        changeLeaderId
        businessUnitId
        changeLeader {
            id
            fullName
        }
        businessOwner {
          id
          isCustomerAdmin
          fullName
        }
        status
        impactedBusinessUnits {
          id
          name
      }
      impactedBusinessUnitIds
      }
      totalCount
      
    }
  }`


export const PROJECT_RETRIEVE_QUERY = `query($id: Int!) {
    project(id: $id) {
      id
      name
      projectSponsorId
      projectSponsor {
          id
          isCustomerAdmin
          fullName
      }
      businessOwnerId
      projectDriver
      driverDisplayName
      startDate
      endDate
      goLiveDate
      businessUnitId
      businessUnit {
          id
          name
      }
      businessOwner {
        id
        isCustomerAdmin
        fullName
      }
      impactedBusinessUnits {
          id
          name
      }
      changeLeaderId
      changeLeader {
          id
          fullName
      }
      projectPhases{
          id
          name
          startDate
          endDate
      }
      status
      changeStatus
      projectStatus
      isDraft
      stepCount
      commentary
      summary
    }
  }`
export const PROJECT_FOR_CHANGE_ASSESSMENTS_RETRIEVE_QUERY = `query($id: Int!) {
  projectChangeAssessment(id: $id) {
        id
        name
        projectSponsorId
        projectSponsor {
            id
            isCustomerAdmin
            fullName
        }
        businessOwnerId
        projectDriver
        startDate
        endDate
        goLiveDate
        businessUnitId
        businessUnit {
            id
            name
        }
        businessOwner {
          id
          isCustomerAdmin
          fullName
        }
        impactedBusinessUnits {
            id
            name
        }
        changeLeaderId
        changeLeader {
            id
            fullName
        }
        projectPhases{
            id
            name
            startDate
            endDate
        }
        projectForecasts{
            id
            week
            risk
            startDate
            endDate
            businessUnitId
            businessUnit{
                id
                name
            }
        }
        status
    }
  }`

export const PROJECT_FOR_CHANGE_ASSESSMENTS_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ProjectFilter!
  ) {
    allProjectsForChangeAssessments(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        name
        projectSponsorId
        projectSponsor {
            id
            isCustomerAdmin
            fullName
        }
        businessOwnerId
        projectDriver
        startDate
        endDate
        goLiveDate
        changeLeaderId
        businessUnitId
        changeLeader {
            id
            fullName
        }
        businessOwner {
          id
          isCustomerAdmin
          fullName
        }
        status
      }
      totalCount
    }
  }`

export const PROJECT_HEAT_MAP_QUERY = `query($id: Int!) {
    projectHeatMap(id: $id) {
      id
      name
      projectSponsorId
      businessOwnerId
      projectDriver
      startDate
      endDate
      businessUnitId
      businessUnit {
        id
        name
        forecasts {
          id
          week
          risk
          startDate
          endDate
        }
      }
      impactedBusinessUnits {
        id
        name
        forecasts {
          id
          week
          risk
          startDate
          endDate
        }
      }
      status
    }
  }`

export const ALL_PROJECTS_HEAT_MAP_QUERY = `query($page: Int!, $perPage: Int!, $sortField: String, $sortOrder: String, $filter: ProjectFilter) {
    allProjectsHeatMap(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
      totalCount
      items {
        id
        name
        projectSponsorId
        businessOwnerId
        projectDriver
        startDate
        endDate
        businessUnitId
        businessUnit {
          id
          name
          forecasts {
            id
            week
            risk
            startDate
            endDate
          }
        }
        impactedBusinessUnits {
          id
          name
          forecasts {
            id
            week
            risk
            startDate
            endDate
          }
        }
        status
        changeAssessments {
            id
            projectId
            businessUnitId
            overallChangeImpactValue
            overallChangeMgtImpactValues
        }
      }
    }
  }`

export const ALL_BUS_HEAT_MAP_QUERY = `query($businessUnitId: Int!, $startDate: Date!, $endDate: Date!) {
    allBuHeatMap(
      businessUnitId: $businessUnitId
      startDate: $startDate
      endDate: $endDate
    ) {
      startDate
      endDate
      risk
      week
    }
  }`

export const MONTHLY_BU_HEAT_MAP_QUERY = `query($businessUnitId: Int!, $startDate: Date!, $endDate: Date!) {
    allMonthlyBuHeatMap(
      businessUnitId: $businessUnitId
      startDate: $startDate
      endDate: $endDate
    ){
      risk
      startOfMonth
      endOfMonth
    }
  }`

export const PROJECT_UPDATE_MUTATION = `mutation(
  $id: Int!
  $name: String!
  $projectSponsorId: Int
  $businessOwnerId: Int
  $projectDriver: String
  $startDate: Date
  $endDate: Date
  $businessUnitId: Int
  $impactedBusinessUnits: [Int]
  $changeLeaderId: Int
  $projectPhases: [ProjectPhaseInput]
  $goLiveDate: Date
  $driverDisplayName: String
  $projectStatus: String
  $changeStatus: String
  $summary: String
  $commentary: String
  $isDraft: Boolean!
  ) {
    updateProject(
      id: $id
      name: $name
      projectSponsorId: $projectSponsorId
      businessOwnerId: $businessOwnerId
      projectDriver: $projectDriver
      startDate: $startDate
      endDate: $endDate
      businessUnitId: $businessUnitId
      impactedBusinessUnits: $impactedBusinessUnits
      changeLeaderId: $changeLeaderId
      projectPhases: $projectPhases
      goLiveDate: $goLiveDate
	  driverDisplayName: $driverDisplayName
	  projectStatus: $projectStatus
	  changeStatus: $changeStatus
	  summary: $summary
	  commentary: $commentary
	  isDraft: $isDraft

    ) {
      id
      name
      projectSponsorId
      businessOwnerId
      projectDriver
      startDate
      endDate
      businessUnitId
      businessUnit {
          id
          name
      }
      impactedBusinessUnits {
        id
        name
      }
      changeLeaderId
      changeLeader {
          id
          fullName
      }
      projectPhases {
        id
        name
        startDate
        endDate
      }
      status
      goLiveDate
    }
  }`
export const POJECT_STEP_COUNTER_MUTATION = `mutation(
  $id: Int!,
  $isDraft: Boolean!,
  $stepCount: Int!) {
  updateProjectStatusAndStep(
    id: $id,
    isDraft: $isDraft,
    stepCount: $stepCount) {
  id
  name
  isDraft
  stepCount
  }
}`
export const PROJECT_DELETE_MUTATION = `mutation($id: Int!) {
    deleteProject(id: $id)
  }`

export const EXPORT_PROJECT_QUERY = `
  query($projectsData: String!){
    exportProjects(projectsData: $projectsData)
  }`

export const EXPORT_CHANGE_IMPACT_QUERY = `
  query($changeImpactData: String!){
    exportChangeImpactOverview(changeImpactData: $changeImpactData)
  }`

export const EXPORT_NUM_OF_PROJECTS_QUERY = `
query($numOfProjects: String!){
  exportNumOfProjects(numOfProjects: $numOfProjects)
}`

export const EXPORT_PROJECT_MILESTONE = `
query($projectMileStoneData: String!){
  exportMileStone(projectMileStoneData: $projectMileStoneData)
}`

