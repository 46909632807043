import { Grid, Tooltip, Typography, useMediaQuery, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Alert, Box, CircularProgress, Divider, Snackbar, } from "@mui/material";
import React, { useContext } from "react";
import { Title, useDataProvider } from "react-admin";
import { getFormatExtension, impersonatedURL } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import { ThemeContext } from "../../framework/theme/themeContext";
var FileSaver = require("file-saver");

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "uppercase",
    },
    nonAccordionCard: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    title: {
        textTransform: "uppercase",
        // fontSize: "20px" // Adjust the font size as needed
    }
}));

const ToolsAndTemplates = () => {
    const classes = useStyles();
    const user = TokenStorage.getUserDetails();
    const { isImpersonating } = user ? user : {};
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const dataProvider = useDataProvider();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = (name, type) => {
      setPopupMessage({message: name, type: type})
      setSnackbarOpen(true);
    };
  
    const hanndleSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
  
      setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = React.useState({
      vertical: 'top',
      horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = React.useState({
      message: "",
      type: ""
    });
  
    const {vertical, horizontal} = anchorOrigin;
    const { themeObject } = useContext(ThemeContext)
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    impersonatedURL(isImpersonating, '#/cat_templates?impersonated');

    const handleToolsAndTemplateDownload = (file) => {
        const filename = file.title + getFormatExtension(file.format)
        setButtonLoader(file.title);
        dataProvider
            .postRequest("ExportToolsAndTemplates", {
                queryType: "retrieve",
                data: { filename }
            }).then(response => {
                downloadFile(JSON.parse(response.data))
            })
            .catch(error => {
                handleSnackbarOpen("No data found to export", "warning")
                setButtonLoader(false);
            });
    }

    const downloadFile = ({ filename, data }) => {
        const { ContentType, Body } = data;

        let arrayBuffer;
        if (Body instanceof Uint8Array) {
            arrayBuffer = Body.buffer;
        } else {
            arrayBuffer = Buffer.from(Body).buffer;
        }
        const blob = new Blob([arrayBuffer], { type: ContentType });
        FileSaver.saveAs(blob, filename);
        setButtonLoader(false);
    };

    return (
        <div>
            <Title title="Tools and Templates" className={classes.title} />
            <Box mb={2}>
                <span style={{ fontWeight: "700" }} >Description: </span>
                Use the following Tools and Templates to get you started in documenting and analysing your change journey.
            </Box>
            <Grid container spacing={2}>
                {
                    templateData.map((items, index) =>
                        <Grid className={classes.nonAccordionCard} item xs={12} key={items.projectPhase} >
                            <Box sx={{ display: "flex", flexDirection: isSmall ? "column" : "row" }}>
                                <Box sx={{ minWidth: 220, mt: isSmall ? 0 : 1, fontSize: "1.5rem", fontWeight: "700" }}>
                                    {items.projectPhase}
                                </Box>
                                <Box sx={{ display: 'flex', flexWrap: "wrap" }}>
                                    {
                                        items.fileDetails.map(file =>
                                            <HtmlTooltip key={file.id}
                                                title={
                                                    <Box >
                                                        <Typography color="inherit" >
                                                            {file.summary}
                                                        </Typography>
                                                    </Box>
                                                }
                                                placement="top">
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        margin: 1,
                                                        backgroundColor: themeObject.palette.primary.main,
                                                        borderRadius: 1.5,
                                                        color: "white",
                                                        "&:hover": {
                                                            backgroundColor: themeObject.palette.primary.buttonContrast,
                                                            color: "white",
                                                            cursor: 'pointer',
                                                        },
                                                    }}
                                                    onClick={() => handleToolsAndTemplateDownload(file)}
                                                >
                                                    <span style={{ padding: "10px 10px" }}>
                                                        {file.title}
                                                    </span>
                                                    <span style={{
                                                        width: "2rem",
                                                        marginLeft: ".25rem",
                                                        backgroundColor: "rgba(255,255,255,0.2)", borderTopRightRadius: 5,
                                                        borderBottomRightRadius: 5,
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                        {
                                                            buttonLoader === file.title ? <CircularProgress size={13} sx={{ color: "white", ml: ".15rem" }} /> : <FileDownloadOutlinedIcon
                                                                sx={{ color: "#F3F3F9", fontSize: "16px" }}
                                                            />
                                                        }
                                                    </span>
                                                </Box>
                                            </HtmlTooltip>)
                                    }
                                </Box>
                            </Box>
                            {(templateData.length - 1) !== index && <Divider sx={{ m: isSmall ? "1rem 0 0 0" : "1rem 0" }} />}
                        </Grid>
                    )
                }
            </Grid>

            <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                <Alert

                    onClose={hanndleSnackbar}
                    severity={popupMessage.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {popupMessage.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ToolsAndTemplates;

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: "black",
        fontSize: 11,
        border: '1px solid #dadde9 ',
    },
}))(Tooltip);

const templateData = [
    {
        "projectPhase": "1. Initiate & Assess",
        "fileDetails": [
            {
                "title": "Case for Change",
                "format": "Word",
                "scrubbed": "Yes",
                "summary": "This document can be used if you are right at the start of the project cycle and will set up the foundation for moving through to your Change Management Strategy and Plan. Often we don't get a chance to do this as we get brought in too late, but if you are brought in early this is a great starting place."
            },
            {
                "title": "Change Definition Document",
                "format": "Word",
                "scrubbed": "Yes",
                "summary": "To be successful, any organisational change must first be fully defined and understood. Use this template to provide further clarity when defining the change."
            },
            {
                "title": "Change Impact Evaluation Tool",
                "format": "PPT",
                "scrubbed": "Yes",
                "summary": "The Detailed Change Impact Assessment will support you in assessing the change impact of your project/initiative."
            },
            {
                "title": "Change Strategy",
                "format": "Word",
                "scrubbed": "Yes",
                "summary": "A Change Strategy is required for business change initiatives that are medium to high complexity and impact large segments of the business."
            },
            {
                "title": "Business Case",
                "format": "Word",
                "scrubbed": "Yes",
                "summary": "Use the Business Case document to outline how the benefits of a project will outweigh the potential costs and give reasoning as to why the project should be executed."
            },
            {
                "title": "Communications Strategy and Plan",
                "format": "Word",
                "scrubbed": "Yes",
                "summary": "The Communications Strategy and Plan will help you get ahead of your requirements by mapping out what the project/initiative is, why we need it, who is affected, when, where and how will it happen."
            }
        ]
    },
    {
        "projectPhase": "2. Design & Plan",
        "fileDetails": [
            {
                "title": "70-20-10 Learning Approach",
                "format": "PDF",
                "scrubbed": "Yes",
                "summary": "To drive change adoption and shift behaviours, there is requirement for individuals to be able to change. This model helps identify the various learning activities dependent on change complexity."
            },
            {
                "title": "Change Canvas",
                "format": "PPT",
                "scrubbed": "Yes",
                "summary": "Use the Change Canvas to put your change program on one page and engage people in conversation to understand the change impacts."
            },
            {
                "title": "Change Complexity Assessment",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "The Change Complexity Assessment tool will help you measure the scope, scale and complexity of your project/initiative, enabling you to determine the level of change resource capability and requirements you require to support successful delivery and benefits realisation."
            },
            {
                "title": "Change Complexity Profile Tool",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "The Change Complexity Tool will help you measure the scope, scale and complexity of your project/initiative, enabling you to determine the level of change resource capability and requirements you require to support successful delivery and benefits realisation."
            },
            {
                "title": "Change Plan",
                "format": "Word",
                "scrubbed": "Yes",
                "summary": "The Change Plan defines the approach to managing the impact of change for the particular initiative. When completing a Change Plan, consideration should be given to the Change approach, Stakeholder engagement, Training and benefits"
            },
            {
                "title": "Communications and Engagement Plan",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "A Communications and Engagement Plan provides a detailed plan with clear tasks, responsibilities and timelines for communication activities."
            },
            {
                "title": "Detailed Change Impact Assessment",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "The purpose of this tool is to support you in assessing the change impact of your project/initiative."
            },
            {
                "title": "Stakeholder Assessment and Engagement Plan",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "The Stakeholder Assessment and Engagement Plan is developed based on information gathered during a stakeholder assessment. It details how the project team should engage with stakeholders throughout the life of the project, and outputs used to inform communications and learning plans and activities."
            },
            {
                "title": "Stakeholder Empathy Map",
                "format": "PPT",
                "scrubbed": "Yes",
                "summary": "The Stakeholder Empathy Map provides a framework to explore each stakeholder within the program, which informs stakeholder engagement activities, Communication Plans and Learning Plans and Activities."
            }
        ]
    },
    {
        "projectPhase": "3. Build & Test",
        "fileDetails": [
            {
                "title": "Learning Needs Assessment",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "A Learning Needs Assessment (LNA) forms the basis for the design, development and delivery of training. It should be designed based on the understanding of the change impacts and capability requirements."
            },
            {
                "title": "Learning Plan",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "Based on the Learning Needs Assessment, a detailed Learning Plan should be prepared to outline the training design and delivery implementation plan."
            },
            {
                "title": "Scope Change Request",
                "format": "Word",
                "scrubbed": "Yes",
                "summary": "Whenever you face an adjustment in scope, use the Scope Change Request form to identify the basics of the new impacts, key stakeholders and related risks that may be apparent."
            }
        ]
    },
    {
        "projectPhase": "4. Launch & Embed",
        "fileDetails": [
            {
                "title": "Business Readiness Checklist",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "A Business Readiness Checklist is a key tool used to measure the readiness of the business to accept the change. The checklist is usually completed prior to go-live and usually requires approval from the project sponsor and/or the business owner."
            },
            {
                "title": "Change Readiness Assessment",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "A Change Readiness Assessment helps to understand individual/group stakeholders' level of readiness and acceptance of the change, including what issues and barriers to change people are facing and the required mitigation activities to address these."
            },
            {
                "title": "Embedding Change Checklist",
                "format": "Word",
                "scrubbed": "Yes",
                "summary": "Use the Embedding Change Checklist to assess whether the change has been successfully embedded into Business As Usual (BAU)."
            },
            {
                "title": "Training Assessment and Evaluation",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "The Training Assessment and Evaluation can be used to ensure that training is fit for purpose and achieves the desired outcomes."
            }
        ]
    },
    {
        "projectPhase": "5. Sustain & Close",
        "fileDetails": [
            {
                "title": "Change Evaluation",
                "format": "Excel",
                "scrubbed": "Yes",
                "summary": "The Change Management Evaluation is an excellent way to understand what worked well and what could be improved in your last project."
            },
            {
                "title": "Handover and Sustainability Plan",
                "format": "Word",
                "scrubbed": "Yes",
                "summary": "This document provides a detailed description of the implementation to date, including any gaps that still need to be addressed. It clearly defines how the Change project will be handed over to the business and outlines ways the change objectives will be incorporated into the business plan and long term strategy."
            },
            {
                "title": "Post Implementation Review",
                "format": "Word",
                "scrubbed": "Yes",
                "summary": "The Post-Implementation Review is an evaluation tool used to assess the actual results/outcomes of a project/program against the baseline expectations, as set out in the Business Case."
            }
        ]
    }
]
