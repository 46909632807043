// import * as React from 'react';
import { useEffect, useState } from 'react';
import { Admin, Loading, Resource } from 'react-admin';
import authProvider from "./framework/authProvider";
import customRoutes from './framework/customRoutes';
import dashboard from './framework/dashboard';
import dataProvider from './framework/dataProvider';
import layout from "./framework/layout";
import Skeleton from '@material-ui/lab/Skeleton';
// import { Loading } from './utils/AppUtils';
import NotFound from './utils/NotFound';
import businessUnit from "./views/businessUnit";
import Login from './views/login';
import permission from './views/permission';
import project from "./views/project";
import role from './views/role';
import user from "./views/user";
import { ThemeProvider, ThemeContext } from './framework/theme/themeContext';
import { themes } from './framework/theme';
const App = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleLoad = () => {
            setLoading(false);
        };

        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    if (loading) {
        return <div style={{ display: 'flex', backgroundColor: "lightgrey"}}>
            <div style={{borderRight: '6px solid lightgrey'}}>
                <Skeleton variant="rect" width={180} height={'100vh'} />
            </div>
            <div>
                <Skeleton variant="text" width={'100vw'} height={40} />
                <Skeleton variant="rect" width={'100vw'} height={'100vh'} />
            </div>
        </div>;
    }

    return (
        <>
            {
                !loading &&
                <ThemeProvider>
                    <ThemeContext.Consumer>
                        {({ theme, setTheme, themeObject }) => (
                            <Admin loginPage={Login} catchAll={NotFound} customRoutes={customRoutes} dataProvider={dataProvider} dashboard={dashboard} authProvider={authProvider} theme={themeObject} layout={layout}>
                                <Resource name="BusinessUnit" {...businessUnit} />
                                <Resource name="User" {...user} />
                                <Resource name="Role" {...role} />
                                <Resource name="Permission" {...permission} />
                                <Resource name="MasterData" />
                                <Resource name="MasterDataValue" />
                                <Resource name="Project" {...project} />
                                <Resource name="ProjectForCA" {...project} />
                            </Admin>
                        )}
                    </ThemeContext.Consumer>
                </ThemeProvider>
            }

        </>

    );
}

export default App;