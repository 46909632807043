import PowerBiReportEmbed from './powerBiReportEmbed';

function BusinessReadinessReport() {
    return (
        <PowerBiReportEmbed
            reportId='7e14804d-7eb7-4307-9ddc-2dbca2502d3d'
            resource='Readiness'
            url='change_business_readiness_report'
        />
    )
}

export default BusinessReadinessReport;
