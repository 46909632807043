import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import arrayMutators from "final-form-arrays";
import * as React from "react";
import {
  Error,
  Loading,
  TextInput,
  required,
  useDataProvider,
} from "react-admin";
import { Form } from "react-final-form";
import { onFailureMessage } from "../../utils/AppUtils";
import { useMediaQuery } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    // maxWidth: 360,
    // backgroundColor: 'pink',
  },
  dataList: {
    borderRadius: 12,
    backgroundColor: theme.palette.background.paper,
    marginRight: 20,
    width: 350,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  MainDataList: {
    width: "350px" /* Updated width based on the provided styles */,
    height: "100vh",
    backgroundClor:
      theme.palette.background.paper /* Updated background color */,
    borderRadius: 12 /* Added border-radius */,
    marginRight: 20,
    position: "sticky",
    overflowY: "hidden",
    /* Added responsive styling for small screens */
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginRight: 0,
    },
    "&:hover": {
      overflowY: "auto",
    },
  },
  dataValueList: {
    width: 883,
    marginLeft: 20,
    borderRadius: 12,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 0,
      marginTop: 15,
    },
  },
  dataValueName: {
    width: 350,
    paddingTop: 9,
    paddingBottom: 9,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  dataValueNameSingle: {
    width: 350,
    minHeight: 37,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  dataValueDescription: {
    width: "100%",
    marginTop: 16,
    marginLeft: 16,
  },
  toolbar: {
    display: "flex",
    width: 350,
    justifyContent: "space-between",
  },
  navPadding: {
    padding: 0,
  },
  listSelected: {
    backgroundColor: "#405189 !important",
  },
  listText: {
    fontWeight: 500,
    color: "#333333",
  },
  listTextSelected: {
    fontWeight: 500,
    color: "#fff",
  },
  secondboxTitle: {
    paddingLeft: 24,
    paddingTop: 14,
  },
  containerBox: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    paddingLeft: 40,
    paddingRight: 72,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  inputBorder: {
    borderRadius: 8,
    borderColor: "#BDBDBD",
  },
  containerBoxTwo: {
    display: "flex",
    paddingLeft: 40,
    paddingRight: 33,
    gap: 16,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  deleteButton: {
    marginTop: -16,
    marginLeft: 20,
    padding: 0,
    backgroundColor: "transparent !important",
  },
  addfieldButton: {
    backgroundColor: "transparent !important",
    paddingLeft: 40,
    marginBottom: 36,
  },
  buttonFieldsContainer: {
    paddingLeft: 40,
    marginBottom: 35,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexGrow: 1,
      paddingRight: 40,
    },
  },
  cancelButton: {
    marginLeft: 25,
  },
}));

const RiskMatrixDataList = (props) => {
  const { type,handleSnackbarOpen } = props;
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [data, setData] = React.useState();
  const [riskMatirxMasters, setRiskMatrixMasters] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [selectedImpact, setSelectedImpact] = React.useState();
  const [valueCardData, setValueCardData] = React.useState();
  const [valueCardLoading, setValueCardLoading] = React.useState(false);
  const [valueCardError, setValueCardError] = React.useState();

  const dataProvider = useDataProvider();
  
  const [selectedIndex, setSelectedIndex] = React.useState();

  const onSubmit = (values, form) => {
    dataProvider
      .postRequest("RiskMatrixMaster", {
        queryType: "update",
        data: {
          id: values.id,
          riskDescription: values.riskDescription,
        },
      })
      .then((response) => {
        const updatedData = [...data];
        updatedData[selectedIndex] = values;
        setData(updatedData);
        setValueCardData(values);
        handleSnackbarOpen("Master Data updated successfully", "success");
      })
      .catch((error) => {
        const error_messages = onFailureMessage(error);
        handleSnackbarOpen(error_messages, "error");
      });
  };

  const validate = (values) => {
    const errors = {};
    return errors;
  };

  React.useEffect(() => {
    dataProvider
      .postRequest("RiskMatrixMaster", {
        queryType: "list",
        data: {},
      })
      .then(({ data }) => {
        setData(data);
        setSelectedIndex(0);
        setValueCardData(data[0]);
        setSelectedImpact(data[0]);
        // else setError("No details found")
        setLoading(false);
      })
      .catch(() => {
        console.log("Something went wrong");
      });
  }, []);

  const handleListItemClick = (event, index, risk) => {
    setSelectedIndex(index);
    setValueCardData(risk);
    setSelectedImpact(risk);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error title={error} />;
  }

  if (!data || data.length === 0) {
    return (
      <div className={classes.root}>
        <Card className={classes.dataList} style={{ padding: 32 }}>
          No Data Available
        </Card>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {isSmall ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          getOptionLabel={(option) => `${option.type} - ${option.riskLabel}`}
          options={data}
          value={selectedImpact}
          sx={{ width: "100%" }}
          onChange={(event, newValue) =>
            handleListItemClick(event, data.indexOf(newValue), newValue)
          }
          renderInput={(params) => <TextField {...params} label="Impact" />}
        />
      ) : (
        <Card className={classes.MainDataList}>
          <List
            className={classes.navPadding}
            component="nav"
            aria-label="main mailbox folders"
          >
            {data.map((risk, index) => (
              <ListItem
                key={index}
                button
                selected={selectedIndex === index}
                classes={{ selected: classes.listSelected }}
                onClick={(event) => handleListItemClick(event, index, risk)}
              >
                <ListItemText
                  primary={`${risk.type} - ${risk.riskLabel}`}
                  className={
                    selectedIndex === index
                      ? classes.listTextSelected
                      : classes.listText
                  }
                />
              </ListItem>
            ))}
          </List>
        </Card>
      )}
      {Boolean(
        selectedIndex !== null && selectedIndex !== undefined && valueCardData
      ) && (
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
          }}
          validate={validate}
          initialValues={valueCardData}
          render={({
            handleSubmit,
            form: {
              mutators: { push, pop },
            }, // injected from final-form-arrays above
            pristine,
            form,
            submitting,
            values,
          }) => (
            <Card className={classes.dataValueList}>
              <CardHeader
                // title={
                //     valueCardData
                //         ? `${valueCardData.riskName}`
                //         : null
                // }
                classes={{
                  title: classes.secondboxTitle,
                }}
              />
              {/* <Divider style={{ backgroundColor: "#C4C4C4", marginBottom: 30 }} /> */}
              {valueCardData && (
                <Box className={classes.containerBox}>
                  <TextInput
                    multiline
                    validate={[required()]}
                    variant="outlined"
                    source="riskDescription"
                    label={``}
                    initialValue={valueCardData.riskDescription}
                    style={{ margin: 0 }}
                    InputProps={{
                      classes: {
                        input: classes.dataValueName,
                        notchedOutline: classes.inputBorder,
                      },
                    }}
                  />
                </Box>
              )}

              <form onSubmit={handleSubmit}>
                <div className={classes.buttonFieldsContainer}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={submitting || pristine}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={form.reset}
                    className={classes.cancelButton}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </Card>
          )}
        />
      )}
    </div>
  );
};

export default RiskMatrixDataList;
