export const MASTER_DATA_CREATE_MUTATION = `mutation(
    $name: String!
    $type: String!
    $category: String!
    $nameDescription: String!
    $categoryDescription: String!
    $masterDataValues: [MasterDataValueInput!]
  ) {
    createMasterData(
      name: $name
      type: $type
      category: $category
      nameDescription: $nameDescription
      categoryDescription: $categoryDescription
      masterDataValues: $masterDataValues
    ) {
      id
      name
      type
      category
      nameDescription
      categoryDescription
      masterDataValues{
        id
        name
        description
      }
    }
  }`

export const MASTER_DATA_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: MasterDataFilter!
  ) {
    allMasterData(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        name
        type
        category
        nameDescription
        categoryDescription
      }
      totalCount
    }
  }`
export const MASTER_DATA_DRIVER_QUERY = `query($id: Int! $clientId: Int!) {
    masterDataForDriver(
        id: $id
        clientId: $clientId) {
        id
        name
        type
        category
        nameDescription
        categoryDescription
        masterDataValues{
          id
          name
          description
          isDisabled
        }
    }
  }`

export const MASTER_DATA_RETRIEVE_QUERY = `query($id: Int!) {
    masterData(id: $id) {
        id
        name
        type
        category
        nameDescription
        categoryDescription
        masterDataValues{
          id
          name
          description
        }
    }
  }`
  export const DRIVER_MASTER_DATA_RETRIEVE_QUERY = `query($id: Int!) {
    driverMasterData(id: $id) {
        id
        name
        type
        category
        nameDescription
        categoryDescription
        masterDataValues{
          id
          name
          description
        }
    }
  }`
export const MASTER_DATA_UPDATE_MUTATION = `mutation(
    $id: Int!
    $name: String!
    $type: String!
    $category: String!
    $nameDescription: String!
    $categoryDescription: String!
    $masterDataValues: [MasterDataValueInput!]
  ) {
    updateMasterData(
      id: $id
      name: $name
      type: $type
      category: $category
      nameDescription: $nameDescription
      categoryDescription: $categoryDescription
      masterDataValues: $masterDataValues

    ) {
        id
        name
        type
        category
        nameDescription
        categoryDescription
        masterDataValues{
          id
          name
          description
        }
        
    }
  }`
export const MASTER_DATA_DRIVERS_UPDATE_MUTATION = `mutation(
    $id: Int!
    $name: String!
    $type: String!
    $category: String!
    $nameDescription: String!
    $categoryDescription: String!
    $masterDataValues: [MasterDataValueInput!]
    $clientId: Int!
  ) {
    updateMasterDataForDriver(
      id: $id
      name: $name
      type: $type
      category: $category
      nameDescription: $nameDescription
      categoryDescription: $categoryDescription
      masterDataValues: $masterDataValues
      clientId: $clientId

    ) {
        id
        name
        type
        category
        nameDescription
        categoryDescription
        masterDataValues{
          id
          name
          description
          isDisabled
        }
        
    }
  }`
export const MASTER_DATA_DELETE_MUTATION = `mutation($id: Int!) {
    deleteMasterData(id: $id)
  }`