import { Box, Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useState } from 'react';
import { AutocompleteInput, ReferenceInput, Title, useNotify, usePermissions } from "react-admin";
import { Form } from "react-final-form";
import powerBiApi, { checkAccessTokenExpiration } from '../../framework/pbiDataProvider';
import { impersonatedURL } from '../../utils/AppUtils';
import { TokenStorage } from '../../utils/TokenStorage';
import WithPermission from '../../utils/WithPermission';

const useStyles = makeStyles((theme) => ({
    referenceMargin: {
        margin: 0,
    },
    title: {
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    }
}));

function RiskAssessmentReport(props) {
    const classes = useStyles();
    const notify = useNotify();
    const [projectId, setProjectId] = useState();
    const user = TokenStorage.getUserDetails();
    const impersonateUser = TokenStorage.getImpersonateUserDetails();
    const { isImpersonating, isCustomerAdmin } = user ? user : {};
    impersonatedURL(isImpersonating, '#/change_risk_report?impersonated');
    const { permissions } = usePermissions();

    const [pbiEmbedConfig, setPbiEmbedConfig] = useState({
        type: 'report',
        id: '',
        embedUrl: "",
        accessToken: "",
        tokenType: models.TokenType.Aad,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                }
            },
            navContentPaneEnabled: false,
            // background: models.BackgroundType.Transparent
        },
        eventHooks: {
            accessTokenProvider: checkAccessTokenExpiration
        }
    })

    const handleProjectChange = async (id) => {
        setProjectId(id);
        if (pbiEmbedConfig.embedUrl) {
            const filtersArray = [
                {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: "Projects",
                        column: "id"
                    },
                    operator: "In",
                    values: [id]
                }
            ];

            await window.report.updateFilters(
                models.FiltersOperations.Replace,
                filtersArray
            );
        }
        else {
            powerBiApi("GET", "reports/b8d14b41-f9d9-4444-ae40-14fce2e445e0").then(res => {
                const { id, embedUrl, accessToken } = res;
                setPbiEmbedConfig({ ...pbiEmbedConfig, id, embedUrl, accessToken })
            });
        }

    };


    return (
        <WithPermission resource="Project Report" permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <Title title="Project Report" className={classes.title} />
            <div>
                <div style={{ maxWidth: "320px" }}>
                    <Form
                        onSubmit={() => { }}
                        render={() => (
                            <form>
                                <ReferenceInput
                                    variant="outlined"
                                    label="Project"
                                    source="projectId"
                                    reference="Project"
                                    onChange={(value) => {
                                        handleProjectChange(value);
                                    }}
                                    fullWidth
                                >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </form>
                        )}
                    />
                </div>
                {
                    (!pbiEmbedConfig.embedUrl && !projectId) ? <Box height="80vh" justifyContent='center' alignItems='center' display='flex'>  Please select a project </Box> : <PowerBIEmbed
                        embedConfig={pbiEmbedConfig}
                        eventHandlers={
                            new Map([
                                ['loaded', async function () {

                                    // await window.report.updateFilters(models.FiltersOperations.RemoveAll);
                                    const filtersArray = [
                                        {
                                            $schema: "http://powerbi.com/product/schema#basic",
                                            target: {
                                                table: "Projects",
                                                column: "id"
                                            },
                                            operator: "In",
                                            values: [projectId]
                                        },
                                        {
                                            $schema: "http://powerbi.com/product/schema#basic",
                                            target: {
                                                table: "Projects",
                                                column: "Users(clientId).id"
                                            },
                                            operator: "In",
                                            values: [impersonateUser ? impersonateUser.id : user.id]
                                        },
                                    ];
                                    await window.report.updateFilters(
                                        models.FiltersOperations.Add,
                                        filtersArray
                                    );
                                    // console.log('Report has loaded', filtersArray);
                                    // navigator.clipboard.writeText(JSON.stringify(window.report))
                                    // console.log('Report has loaded', window.report);
                                }],
                                ['rendered', function () {
                                    // const filtersArray = [{
                                    //     $schema: "http://powerbi.com/product/schema#basic",
                                    //     target: {
                                    //         table: "Projects",
                                    //         column: "id"
                                    //     },
                                    //     operator: "In",
                                    //     values: [projectId]
                                    // }
                                    // ];
                                    // window.report.updateFilters(
                                    //     models.FiltersOperations.Add,
                                    //     filtersArray
                                    // );
                                }],
                                ['error', function (event) { console.log(event.detail); }],
                            ])
                        }

                        cssClassName={"Embed-container"}

                        getEmbeddedComponent={(embeddedReport) => {
                            // console.log(embeddedReport)
                            window.report = embeddedReport;
                        }}
                    />
                }

            </div>
        </WithPermission>
    );
}

export default RiskAssessmentReport;

const ProjectList = ({ handleProjectChange }) => {
    return (
        <Form
            onSubmit={() => { }}
            render={() => (
                <form>
                    <Box style={{ maxWidth: 483 }}>
                        <Grid container spacing={2}>
                            <Grid item lg={8} xs={12}>
                                <ReferenceInput
                                    fullWidth
                                    variant="outlined"
                                    label="Project"
                                    source="projectId"
                                    reference="Project"
                                    onChange={(value) => {
                                        handleProjectChange(value);
                                    }}
                                // className={classes.referenceMargin}
                                >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            )}
        />
    );
};