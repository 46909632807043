import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useState } from 'react';
import { AutocompleteInput, Loading, ReferenceInput, Title, usePermissions } from "react-admin";
import { Form } from "react-final-form";
import powerBiApi, { checkAccessTokenExpiration } from '../../framework/pbiDataProvider';
import { TokenStorage } from '../../utils/TokenStorage';
import WithPermission from '../../utils/WithPermission';
import { Box } from '@mui/material';

function ChangeImpactAssessmentReport(props) {
    const user = TokenStorage.getUserDetails()
    const impersonateUser = TokenStorage.getImpersonateUserDetails();
    const [projectId, setProjectId] = useState();
    const [pbiEmbedConfig, setPbiEmbedConfig] = useState({
        type: 'report',
        id: '',
        embedUrl: "",
        accessToken: "",
        tokenType: models.TokenType.Aad,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                }
            },
            navContentPaneEnabled: false,
            // background: models.BackgroundType.Transparent
        },
        eventHooks: {
            accessTokenProvider: checkAccessTokenExpiration
        }
    })
    const { permissions } = usePermissions();
    const { isImpersonating, isCustomerAdmin } = user ? user : {};
    // useEffect(() => {
    //     powerBiApi("GET", "reports/d7509ab2-7eb4-4261-a891-c51645fbc3cb").then(res => {
    //         // powerBiApi("GET", "reports/725283e5-4a2a-4b90-b18b-cf132b8445e7").then(res => {
    //         // powerBiApi("GET", "reports/51f8c67e-28fb-48b6-9fa3-99488a859aff").then(res => {
    //         const { id, embedUrl, accessToken } = res;
    //         setPbiEmbedConfig({ ...pbiEmbedConfig, id, embedUrl, accessToken })
    //     });

    // }, []);

    const handleProjectChange = async (id) => {
        setProjectId(id);
        if (pbiEmbedConfig.embedUrl) {
            const filtersArray = [
                {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: "Projects",
                        column: "id"
                    },
                    operator: "In",
                    values: [id]
                }
            ];

            await window.report.updateFilters(
                models.FiltersOperations.Replace,
                filtersArray
            );
        }
        else {
            powerBiApi("GET", "reports/d7509ab2-7eb4-4261-a891-c51645fbc3cb").then(res => {
                // powerBiApi("GET", "reports/725283e5-4a2a-4b90-b18b-cf132b8445e7").then(res => {
                // powerBiApi("GET", "reports/51f8c67e-28fb-48b6-9fa3-99488a859aff").then(res => {
                const { id, embedUrl, accessToken } = res;
                setPbiEmbedConfig({ ...pbiEmbedConfig, id, embedUrl, accessToken })
            });
        }

    };
    return (
        <WithPermission resource="Impact Assessment Report" permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <Title title="Change Impact Assessment" />
            <div>
                <div style={{ maxWidth: "320px" }}>
                    <Form
                        onSubmit={() => { }}
                        render={() => (
                            <form>
                                <ReferenceInput
                                    variant="outlined"
                                    label="Project"
                                    source="projectId"
                                    reference="Project"
                                    onChange={(value) => {
                                        handleProjectChange(value);
                                    }}
                                    fullWidth
                                >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </form>
                        )}
                    />
                </div>
                {
                    (!pbiEmbedConfig.embedUrl && !projectId) ? <Box height="80vh" justifyContent='center' alignItems='center' display='flex'>  Please select a project </Box> : <PowerBIEmbed
                        embedConfig={pbiEmbedConfig}
                        eventHandlers={
                            new Map([
                                ['loaded', async function () {

                                    // await window.report.updateFilters(models.FiltersOperations.RemoveAll);
                                    const filtersArray = [
                                        {
                                            $schema: "http://powerbi.com/product/schema#basic",
                                            target: {
                                                table: "Projects",
                                                column: "id"
                                            },
                                            operator: "In",
                                            values: [projectId]
                                        },
                                        {
                                            $schema: "http://powerbi.com/product/schema#basic",
                                            target: {
                                                table: "Projects",
                                                column: "Users(clientId).id"
                                            },
                                            operator: "In",
                                            values: [impersonateUser ? impersonateUser.id : user.id]
                                        }
                                    ];

                                    await window.report.updateFilters(
                                        models.FiltersOperations.Add,
                                        filtersArray
                                    );
                                    // console.log('Report has loaded', window.report);
                                    // navigator.clipboard.writeText(JSON.stringify(window.report))
                                    // console.log('Report has loaded', window.report);
                                }],
                                ['rendered', function () { console.log(window.report) }],
                                ['error', function (event) { console.log(event.detail); }],
                            ])
                        }

                        cssClassName={"Embed-container"}

                        getEmbeddedComponent={(embeddedReport) => {
                            console.log(embeddedReport)
                            window.report = embeddedReport;
                        }}
                    />
                }

            </div>
        </WithPermission>
    );
}

export default ChangeImpactAssessmentReport;