import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import { AutocompleteInput, Loading, ReferenceInput, Title, useDataProvider, useNotify, usePermissions } from "react-admin";
import powerBiApi, { checkAccessTokenExpiration } from '../../framework/pbiDataProvider';
import { currentDate, downloadPDF, getClientId, impersonatedURL, onFailureMessage, pbiFilterNoDuplicate, transformToPowerBIFilter } from '../../utils/AppUtils';
import { TokenStorage } from '../../utils/TokenStorage';
import WithPermission from '../../utils/WithPermission';
import { Alert, Snackbar } from '@mui/material';
import { Form } from "react-final-form";
import { Box, Button, CircularProgress } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
var FileSaver = require("file-saver");

function PBIEmbed(props) {
    const { reportId, url, resource, title, pageName } = props;
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [defaultProjectId, setDefaultProjectId] = useState(null);
    const [project, setProject] = useState();
    const [pdfFilter, setPdfFilter] = useState([])
    const dataProvider = useDataProvider();
    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setSnackbarOpen(true);
    };
    const [buttonLoader, setButtonLoader] = useState(false);

    const handleExportPDF = () => {
        setButtonLoader(true);
        dataProvider
            .postRequest("ExportReportPdf", {
                queryType: "retrieve",
                data: {
                    accessToken: TokenStorage.getPbiAccessToken(),
                    reportId: reportId,
                    pageName: pageName,
                    filter: {
                        clientId: getClientId(),
                        id: defaultProjectId,
                        filterArray: pdfFilter.length > 0 ? transformToPowerBIFilter(pdfFilter) : []
                    }
                }
            }).then(response => {
                downloadPDF(response.data, project.name + " - " + title + " " + currentDate + ".pdf");
                setButtonLoader(false);
                handleSnackbarOpen("File downloaded", "success");
            })
            .catch(error => {
                setButtonLoader(false);
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error");
            });
    }

    const hanndleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = useState({
        message: "",
        type: ""
    });

    const { vertical, horizontal } = anchorOrigin;
    const user = TokenStorage.getUserDetails();

    const { isImpersonating, isCustomerAdmin } = user ? user : {};
    impersonatedURL(isImpersonating, `#/${url}?impersonated`);
    const { permissions } = usePermissions();

    const [pbiEmbedConfig, setPbiEmbedConfig] = useState({
        type: 'report',
        id: '',
        embedUrl: "",
        accessToken: "",
        tokenType: models.TokenType.Aad,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                },
                pageNavigation: {
                    visible: false
                }
            },
            // navContentPaneEnabled: false,
            // background: models.BackgroundType.Transparent
        },
        eventHooks: {
            accessTokenProvider: checkAccessTokenExpiration
        }
    })
    //  
    useEffect(() => {
        // let defProjectId = null;
        dataProvider.getList('Project', {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'name', order: 'ASC' },
            filter: { isDraft: false, status: true }
        })
            .then(({ data }) => {
                // Set the default project ID to the first project's ID
                if (data.length > 0) {
                    setDefaultProjectId(data[0].id);
                    // defProjectId = data[0].id
                    setProject(data[0])
                }
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
        powerBiApi("GET", `reports/${reportId}`).then(res => {
            const { id, embedUrl, accessToken } = res;

            // if (defProjectId) {
            // const andFilterProjectId = projectListEnabled ? ` and Projects/id eq ${defProjectId}` : '';
            setPbiEmbedConfig({ ...pbiEmbedConfig, id, embedUrl: `${embedUrl}&filter=Projects/Client eq ${getClientId()}`, accessToken })
            // }
        }).catch(error => {
            handleSnackbarOpen("Error in getting report", "error")
        })
    }, []);

    const handleProjectChange = async (id) => {
        // setProjectId(id);
        if (pbiEmbedConfig.embedUrl) {
            const filtersArray = [
                {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: "Projects",
                        column: "id"
                    },
                    operator: "In",
                    values: [id]
                }
            ];

            await window.report.updateFilters(
                models.FiltersOperations.Replace,
                filtersArray
            );
            setDefaultProjectId(id)
        }
        getProject(id)
            .then(data => {
                setProject(data)
                // setLoading(false);
            })
            .catch(error => {
                // setError("error in getting project data")
            })
    }

    const getProject = (id) => {
        return dataProvider
            .getOne("Project", { id })
            .then((response) => response.data);
    };
    return (
        <WithPermission resource={resource} permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <Title title={title} style={{ textTransform: "uppercase", fontSize: "20px" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ maxWidth: "320px" }}>
                    {
                        defaultProjectId && <Form
                            onSubmit={() => { }}
                            render={() => (
                                <form>
                                    <ReferenceInput
                                        variant="outlined"
                                        label="Project"
                                        source="projectId"
                                        reference="Project"
                                        onChange={(value) => {
                                            handleProjectChange(value);
                                            // console.log(value);
                                        }}
                                        fullWidth
                                        defaultValue={defaultProjectId}
                                        filter={{ isDraft: false, status: true }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                </form>
                            )}
                        />

                    }

                </div>
                {defaultProjectId &&
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>

                        <Button color="primary" style={{ width: 80, height: 30, margin: "auto" }} onClick={handleExportPDF}>
                            {
                                buttonLoader ? <CircularProgress size={16} /> : <><DownloadIcon fontSize="small" /> Export</>
                            }
                        </Button>
                    </Box>
                }

            </div>

            <div>
                {

                    !pbiEmbedConfig.embedUrl ? <Loading /> :

                        !defaultProjectId ? <Loading /> : <PowerBIEmbed
                            embedConfig={pbiEmbedConfig}
                            eventHandlers={
                                new Map([
                                    ['loaded', async function () {
                                        try {
                                            console.log(pageName, defaultProjectId);
                                            await window.report.setPage(pageName);

                                            const filtersArray = [
                                                {
                                                    $schema: "http://powerbi.com/product/schema#basic",
                                                    target: {
                                                        table: "Projects",
                                                        column: "id"
                                                    },
                                                    operator: "In",
                                                    values: [defaultProjectId]
                                                }
                                            ];


                                            await window.report.updateFilters(
                                                models.FiltersOperations.Replace,
                                                filtersArray
                                            );

                                        }
                                        catch (errors) {
                                            console.log(errors);
                                        }
                                    }],
                                    ["dataSelected", function (event) {
                                        if (event.detail.visual.type === 'slicer') {
                                            setPdfFilter(allFilter => {
                                                const selectedFilter = event.detail.dataPoints;
                                                const visualName = event.detail.visual.name;
                                                const selectedValues = pbiFilterNoDuplicate(allFilter, selectedFilter, visualName)
                                                return selectedValues;
                                            });
                                        }
                                    }],
                                    ['error', function (event) { console.log(event.detail); }],
                                ])
                            }

                            cssClassName={"Embed-container"}

                            getEmbeddedComponent={(embeddedReport) => {
                                window.report = embeddedReport;
                            }}
                        />
                }

            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                <Alert

                    onClose={hanndleSnackbar}
                    severity={popupMessage.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {popupMessage.message}
                </Alert>
            </Snackbar>
        </WithPermission>
    );
}

export default PBIEmbed;
