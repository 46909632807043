import PBIEmbed from "./pbiEmbed";

export const ChangeSaturationReport = (props) => {

    return (
        <PBIEmbed
            title="Change Saturation Report"
            reportId='4e534fc8-f1bb-4272-a41d-5473d28ea467'
            pageName="9c6af1e8d61cdaac9252"
            resource='Report'
            url='change_saturation_project'
        />
    )
}

export default ChangeSaturationReport;