import PBIEmbed from "./pbiEmbed";

export const BusinessReadinessProjectReport = (props) => {
    return (
        <PBIEmbed
            title="Business Readiness Report"
            reportId='31504e2a-80ad-44b4-9142-464fa6bf1031'
            pageName="e13d51896bdd20b98cce"
            resource='Report'
            url='business_readiness_report'
        />
    )
}

export default BusinessReadinessProjectReport;