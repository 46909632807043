import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from '@material-ui/icons/Delete';
import arrayMutators from "final-form-arrays";
import moment from 'moment';
import * as React from "react";
import {
    AutocompleteInput, DateInput, maxLength, required, TextInput, useDataProvider,
    useNotify,
    useRefresh
} from "react-admin";
import { Field, Form, useForm } from "react-final-form";
import { onFailureMessage } from "../../utils/AppUtils";
import CustomDrawer from "../customComponents/customDrawer";

const useStyles = makeStyles({

});

export const MilestoneEditDrawer = ({
    record,
    drawerOpen,
    onClose,
}) => {
    const [initialValues, setInitialValues] = React.useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useStyles();

    const resource = "Milestone";
    const onSubmit = (values, form) => {
        const milestoneData = {
            projectId: +record.id,
            milestones: values.milestones.filter(milestone => Boolean(milestone)).map(milestone => ({
                name: milestone.name,
                projectPhase: milestone.projectPhase,
                milestoneDate: milestone.milestoneDate,
            }))
        }
        dataProvider
            .postRequest(resource, {
                queryType: "update",
                data: milestoneData,
            })
            .then((response) => {
                form.initialize({});
                form.restart();
                onClose(false, true);
                notify("Milestones updated successfully","success");
                // refresh();
                updateMilestonesView();
            })
            .catch((error) => {
                console.log(error)
                const error_messages = onFailureMessage(error);
                notify(error_messages, "error");
            });
    };



    const validate = () => { };

    React.useEffect(() => {
        console.log("record", record);
        updateMilestonesView();
    }, [record]);
    const updateMilestonesView = React.useCallback(
        async () => {
            if (record && record.id) {
                const milestones = await dataProvider
                    .getList("Milestone", {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        filter: { projectId: record.id },
                    })
                milestones.data = milestones.data ? milestones.data.map(milestone => ({
                    name: milestone.name,
                    projectPhase: milestone.projectPhase,
                    milestoneDate: new Date(milestone.milestoneDate),
                })) : null;

                const projectPhases = record.projectPhases ? record.projectPhases : null;

                console.log('projectPhases', projectPhases);
                setInitialValues({ milestones: milestones.data, projectPhases: projectPhases })
            }
        },
        [dataProvider, record],
    )


    return (
        <CustomDrawer
            drawerAnchor="right"
            title="Add/Edit Milestones"
            drawerOpen={drawerOpen}
            onClose={onClose}
        >
            <Box mr={4}>
                <Form
                    onSubmit={onSubmit}
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators,
                    }}
                    validate={validate}
                    initialValues={initialValues}
                    render={({
                        handleSubmit,
                        form: {
                            mutators: { push, pop },
                        },
                    }) => (
                        <form className={classes.formWidth} onSubmit={handleSubmit}>
                            <MilestoneForm
                                push={push}
                                onClose={onClose}
                                projectStartDate={record ? record.startDate : null}
                                projectEndDate={record ? record.endDate : null}
                                projectPhases={initialValues ? initialValues.projectPhases : null}
                                milestonesValues={initialValues ? initialValues.milestones : null}
                            />
                        </form>
                    )}
                />
            </Box>
        </CustomDrawer>
    );
};

const MilestoneForm = ({ push, onClose, milestonesValues, projectStartDate, projectEndDate, projectPhases }) => {
    const [milestones, setMilestones] = React.useState();
    const form = useForm();

    const validateStartDate = (value, values, index) => {
        try {
            const startDate = value;
            const projectPhase = projectPhases.filter(e => e.name === values.milestones[index].projectPhase);

            if (!projectPhase || !(projectPhase.length > 0)) {
                return `Please select a valid Project phase`;
            }

            const phaseStartDate = moment(projectPhase[0].startDate).format("YYYY-MM-DD");
            const phaseEndDate = moment(projectPhase[0].endDate).format("YYYY-MM-DD");

            if (startDate && moment(startDate).isBefore(projectStartDate.slice(0, 10))) {
                return `should be greater than "${moment(projectStartDate).format("DD/MM/YY")}"`;
            }

            if (startDate && moment(startDate).isSameOrAfter(projectEndDate.slice(0, 10))) {
                return `should be lesser than "${moment(projectEndDate).format("DD/MM/YY")}"`;
            }

            if (startDate && !moment(startDate).isBetween(phaseStartDate, phaseEndDate, 'days', true)) {
                return `should be between "${moment(phaseStartDate).format("DD/MM/YY")}" and "${moment(phaseEndDate).format("DD/MM/YY")}"`;
            }

        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {
        setMilestones(milestonesValues);
    }, [milestonesValues])


    if (milestones)
        return (
            <Grid container spacing={2}>
                {milestones.map((milestone, index) => (
                    Boolean(milestone) && <Box key={`${milestone.name}_${index}`} width="100%">
                        <Grid item xs={12}>
                            <Field fullWidth name={`milestones[${index}].name`} component="input">
                                {(props) => (
                                    <TextInput
                                        fullWidth
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                        variant="outlined"
                                        label="Milestone Name"
                                        validate={[required(), maxLength(255)]}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Field fullWidth name={`milestones[${index}].projectPhase`} component="input">
                                {(props) => (
                                    <AutocompleteInput
                                        fullWidth
                                        variant="outlined"
                                        label="Project Phase"
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                        optionValue="name"
                                        optionText="name"
                                        source={props.input.name}
                                        choices={projectPhases}
                                        validate={[required()]}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Field fullWidth name={`milestones[${index}].milestoneDate`} component="input">
                                {(props) => (
                                    <DateInput
                                        fullWidth
                                        variant="outlined"
                                        label="Milestone Date"
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                        validate={[
                                            required(),
                                            (value, values) =>
                                                validateStartDate(value, values, index),
                                        ]}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="text"
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                    milestones[index] = undefined
                                    form.change(`milestones[${index}]`, undefined)
                                    setMilestones([...milestones])
                                }}
                            >
                                Remove
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mt={1} mb={2}><Divider /></Box>
                        </Grid>
                    </Box>
                ))}
                <Grid item xs={12}>
                    <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => {
                            push("milestones", undefined);
                            milestones.push({})
                        }}
                        color="primary"
                        variant="text"
                    >
                        Add Milestone
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box width="100%">
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                Update
                            </Button>
                        </Box>
                        <Box ml={1} width="100%">
                            <Button
                                fullWidth
                                type="button"
                                variant="outlined"
                                color="primary"
                                size="large"
                                onClick={() => onClose(false)}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        )

    return null;
}
export default MilestoneEditDrawer;
