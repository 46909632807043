import React from "react";
import Chart from "chart.js/auto";

export default class DoughnutChart extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.data = [
            {
                label: "low",
                value: 54,
            },
            {
                label: "medium",
                value: 52,
            },
            {
                label: "high",
                value: 74,
            },
        ];

        this.colors = ["#27AE60", "#F2994A", "#EB5757"];
    }

    componentDidUpdate() {
        // this.myChart.data.labels = this.data.map(d => d.label);
        this.myChart.data.datasets[0].data = this.data.map((d) => d.value);
        this.myChart.update();
    }

    componentDidMount() {
        this.myChart = new Chart(this.canvasRef.current, {
            type: "doughnut",
            options: {
                events: [],
                responsive: true,
                layout: {
                    padding: 0,
                    height: 200,
                },
                plugins: {
                    tooltip: {
                        enabled: false,
                    },
                },
                maintainAspectRatio: false,
                rotation: -90, // start angle in radians
                circumference: 180, // sweep angle in radians
                cutout: "80%",
                animation: {
                    onComplete: () => {
                        const valueToAngle =
                            (this.props.value / (this.props.minValue + this.props.maxValue)) *
                            180;
                        const radianAngle = ((valueToAngle - 180) * Math.PI) / 180;
                        const ctx = this.canvasRef.current.getContext("2d");
                        const cw = this.canvasRef.current.offsetWidth;
                        const ch = this.canvasRef.current.offsetHeight;
                        const cx = cw / 2;
                        const cy = ch - ch / 4 + 4;

                        const radius = cw / 2;

                        ctx.translate(cx, cy);
                        ctx.rotate(radianAngle);
                        ctx.beginPath();
                        ctx.moveTo(0, -2);
                        ctx.lineTo(radius, 0);
                        ctx.lineTo(0, 2);
                        ctx.fillStyle = "#333333";
                        ctx.fill();
                        ctx.rotate(-radianAngle);
                        ctx.translate(-cx, -cy);
                        ctx.beginPath();
                        // ctx.arc(cx, cy, 7, 0, Math.PI * 2);
                        ctx.fill();
                    },
                },
            },

            data: {
                // labels: this.data.map(d => d.label),
                datasets: [
                    {
                        data: this.data.map((d) => d.value),
                        backgroundColor: this.colors,
                        // borderWidth: 12
                    },
                ],
            },
        });
        console.log("canvasRef", this.canvasRef);
    }

    render() {
        return <canvas ref={this.canvasRef} />;
    }
}
