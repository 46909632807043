import { useMediaQuery } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Toolbar,
} from "@mui/material";
import * as React from "react";
import { useListContext } from "react-admin";
import { ThemeContext } from "../framework/theme/themeContext";
const PostPagination = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { page, perPage, total, setPage, setPerPage } = useListContext();
  const { themeObject } = React.useContext(ThemeContext)
  const nbPages = Math.ceil(total / perPage) || 1;
  const [rowsPerPage, setRowsPerPage] = React.useState(perPage);
  // const [rowNumber, setRowNumber] = React.useState(1);
  const handlePrevClick = () => {
    setPage(page - 1);
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPerPage(event.target.value); // Reset page to 1 when changing rows per page
  };

  return (
    <Toolbar
      style={{
        display: "flex",
        justifyContent: isSmall ? "center" : "flex-end",
      }}
    >
      <Box style={{ display: "flex", alignItems: "center" }}>
        {isSmall ? (
          ""
        ) : (
          <InputLabel htmlFor="rows-per-page" style={{ marginRight: "16px" }}>
            Rows per page
          </InputLabel>
        )}
        {isSmall ? (
          <></>
        ) : (
          <Select
            label="Rows per page"
            id="demo-simple-select-standard"
            variant="outlined"
            size="small"
            value={perPage}
            onChange={handleChangeRowsPerPage}
            style={{ margin: "0 5px" }}
            sx={{
              "& .MuiInput-root": {
                border: "none", // Remove border,
              },
            }}
          >
            {[5, 10, 25, 50].map((rowsPerPageOption) => (
              <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                {rowsPerPageOption}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
      {nbPages > 1 && (
        <>
          {isSmall ? (
            <></>
          ) : (
            <Button
              style={{ backgroundColor: themeObject.palette.primary.darkLight, color: themeObject.palette.primary.contrastText }}
              disabled={page <= 1}
              onClick={handlePrevClick}
            >
              <ChevronLeftIcon />
              Prev
            </Button>
          )}

          <Pagination
            count={nbPages}
            page={page}
            onChange={handlePageChange}
            color='primary'
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: "4px", // Change button shape

                border: "1px solid #dadde9",
                // padding: "18px 5px"
                padding: "18px",
                "&:hover": {
                  color: "#041e47", // Change button color
                  backgroundColor: themeObject.palette.primary.darkLight,
                },
                "&.Mui-selected": {
                  borderRadius: "4px", // Change button shape
                  backgroundColor: themeObject.palette.primary.paginationContrast,
                  color: "#fff", // Change selected button text color
                  padding: "18px",
                  "&:hover": {
                    color: "#041e47", // Change button color
                    backgroundColor: themeObject.palette.primary.darkLight,
                  },
                },
              },

            }}
            hidePrevButton
            hideNextButton
            size="small"
          />
          {isSmall ? (
            <></>
          ) : (
            <Button
              // color="primary"
              style={{ backgroundColor: themeObject.palette.primary.lighter, color: '#fff' }}
              disabled={page >= nbPages}
              onClick={handleNextClick}
            >
              Next
              <ChevronRightIcon />
            </Button>
          )}
        </>
      )}
    </Toolbar>
  );
};
export default PostPagination;
