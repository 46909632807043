export const BU_WISE_PROJECT_FORECASTS_RETRIEVE_QUERY = `query($startDate: Date!, $endDate: Date!) {
    projectForecastsByBu(startDate: $startDate, endDate: $endDate) {
      forecasts{
        name,id,buData{risk,month,projects{name,risk}}
      }
    }
  }`

export const PROJECT_DRIVER_WISE_PROJECT_FORECASTS_RETRIEVE_QUERY = `query($startDate: Date!, $endDate: Date!) {
    projectForecastsByProjectDriver(startDate: $startDate, endDate: $endDate) {
      high_risk
      medium_risk
      low_risk
      projectDriver
    }
  }`
  
export const PROJECT_COUNT_BY_DRIVER_RETRIEVE_QUERY = `query($startDate: Date!, $endDate: Date!) {
    projectCountByDriver(startDate: $startDate, endDate: $endDate) {
      projectDriver
      projectCount
    }
  }`