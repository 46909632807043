import { Divider, Typography, useMediaQuery } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import { Box, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { Error, Loading, Title, useDataProvider } from "react-admin";
import palette from "../../framework/theme/palette";
import { impersonatedURL } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import { WORDPRESS_ENDPOINT } from "../../framework/constants";
import axios from "axios";
import DOMPurify from 'dompurify';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
  boxes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.primary.contrastText,
    padding: "10px",
    // flexWrap: "wrap",
    borderRadius: 5,
    // flexShrink: 0
  },
  box: {
    // borderRadius: 5,
    // width: 'calc(49%)', // Subtracting padding for accurate width
    // marginBottom: '8px', // Adding margin between boxes
  },
  title: {
    textTransform: "uppercase",
    // fontSize: "20px", // Adjust the font size as needed
  },
}));
const Accordion = withStyles({
  root: {
    borderRadius: "20px 20px 0 0",
    // "&:not(:last-child)": {
    //   borderBottom: 0,
    // },

    "&:before": {
      // display: "none",
    },
    "&$expanded": {
      // margin: "auto",
      // margin: "20px",
      // border: '2px solid #f0f'
      // pading: "10px 0",
      "& .MuiAccordion-root.Mui-expanded:first-child .MuiAccordion-root.Mui-expanded:last-child":
      {
        marginTop: "10px",
        marginBottom: "10px",
      },
    },
    // margin: "20px",
    // marginRight:
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: palette.customsColor.creamium,
    //boxShadow: "0px 3px 9px rgba(0, 0, 0, 0.12)",
    // boxShadow: `0px 1px 2px ${palette.primary.light}`,
    // borderBottom: `3px solid ${palette.customsColor.gray}`,
    border: `1px solid #e9ebec`,
    fontSize: "0.8125rem",
    fontWeight: "500",
    // marginTop: 10,
    color: palette.primary.darker,
    // borderRadius: "20px 20px 0 0",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "#F5F6F9",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: palette.primary.contrastText,
    color: "#878a99",
    borderLeft: "1px solid #e9ebec",
    borderRight: "1px solid #e9ebec",
  },
}))(MuiAccordionDetails);

const Faqs = () => {
  const classes = useStyles();
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [faqs, setFaqs] = useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dataProvider = useDataProvider();
  const user = TokenStorage.getUserDetails();
  const { isImpersonating } = user ? user : {};
  const [posts, setPosts] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [query, setQuery] = useState('');
  impersonatedURL(isImpersonating, "#/faq?impersonated");

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  // React.useEffect(async () => {
  //   setLoading(true);
  //   await dataProvider
  //     .getList("Faq", {
  //       pagination: { page: 1, perPage: 100 },
  //       sort: { field: "id", order: "ASC" },
  //       filter: {},
  //     })
  //     .then((response) => {
  //       setFaqs(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setError(error.message);
  //       setLoading(false);
  //     });
  // }, []);

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setQuery(searchQuery);

    const filtered = faqs.filter(post =>
      post.title.rendered.toLowerCase().includes(searchQuery)
    );
    setFilteredFaqs(filtered);
  };

  React.useEffect(() => {
    const fetchFaqs = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${WORDPRESS_ENDPOINT}wp-json/wp/v2/posts?categories=3&orderby=id&order=asc`);
        setFaqs(response.data);
        setFilteredFaqs(response.data)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts', error);
        setLoading(false);
      }
    };

    fetchFaqs();
  }, [])

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error title={error} />;
  }

  if (filteredFaqs) {
    return (
      <div>
        <Title title="Frequently Asked Questions" className={classes.title} />
        <Box sx={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <TextField
            type="text"
            placeholder="Search"
            value={query}
            onChange={handleSearch}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "rgba(0,0,0,0.2)" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {isSmall ? (
          <div className={classes.boxes}>
            <Box
              style={{
                display: "flex",
                flexDirection: 'column'
              }}
            >
              {filteredFaqs.map((faq, index) => {
                return (
                  <div key={index} className={classes.box}>
                    <Accordion
                      expanded={expandedPanel === "panel" + index}
                      onChange={handleAccordionChange("panel" + index)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore style={{ color: "primary" }} />}
                      >
                        {faq.title.rendered}
                      </AccordionSummary>
                      <AccordionDetails>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.content.rendered) }} />
                      </AccordionDetails>
                      <Divider />
                    </Accordion>
                  </div>
                );
                // Render nothing for even-indexed items
              })}
            </Box>
          </div>
        ) : (
          <div>
            {
              !filteredFaqs.length ? "" : <div className={classes.boxes}>
                {/* Render odd-indexed items */}
                <Box
                  style={{
                    display: "flex",
                  }}
                >
                  <Box marginRight={1} style={{ width: "50%" }}>
                    {filteredFaqs.map((faq, index) => {
                      if (index % 2 === 0) {
                        return (
                          <div key={index} className={classes.box}>
                            <Accordion
                              expanded={expandedPanel === "panel" + index}
                              onChange={handleAccordionChange("panel" + index)}
                            >
                              <AccordionSummary
                                expandIcon={
                                  <ExpandMore style={{ color: "primary" }} />
                                }
                              >
                                {faq.title.rendered}
                              </AccordionSummary>
                              <AccordionDetails>
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.content.rendered) }} />
                              </AccordionDetails>
                              <Divider />
                            </Accordion>
                          </div>
                        );
                      }
                      return null; // Render nothing for even-indexed items
                    })}
                  </Box>
                  <Box marginLeft={1} style={{ width: "50%" }}>
                    {filteredFaqs.map((faq, index) => {
                      if (index % 2 !== 0) {
                        return (
                          <div key={index} className={classes.box}>
                            <Accordion
                              expanded={expandedPanel === "panel" + index}
                              onChange={handleAccordionChange("panel" + index)}
                            >
                              <AccordionSummary
                                expandIcon={
                                  <ExpandMore style={{ color: "primary" }} />
                                }
                              >
                                {faq.title.rendered}
                              </AccordionSummary>
                              <AccordionDetails>
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.content.rendered) }} />
                              </AccordionDetails>
                              <Divider />
                            </Accordion>
                          </div>
                        );
                      }
                      return null; // Render nothing for odd-indexed items
                    })}
                  </Box>
                </Box>

                {/* Render even-indexed items */}
              </div>
            }
          </div>
        )}

        {filteredFaqs.length > 0 ? (
          ""
        ) : (
          <Typography
            align="center"
            variant="body1"
            gutterBottom
            component="div"
          >
            No Frequently Asked Questions Found
          </Typography>
        )}
      </div>
    );
  }

  return <div></div>;
};

export default Faqs;
