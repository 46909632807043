import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Loading, Title } from 'react-admin';
import { WORDPRESS_ENDPOINT } from '../../framework/constants';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { ThemeContext } from '../../framework/theme/themeContext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        textTransform: "uppercase",
        // fontSize: "20px" // Adjust the font size as needed
    }
}));

const ViewLearningContent = () => {
    const { themeObject } = useContext(ThemeContext);
    const { slug } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [post, setPost] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`${WORDPRESS_ENDPOINT}wp-json/wp/v2/posts?slug=${slug}`);
                if (response.data.length) {
                    console.log(response);
                    setPost(response?.data[0]);
                }
            } catch (error) {
                console.error('Error fetching post', error);
            }
        };

        fetchPost();
    }, [slug]);

    if (!post) {
        return <Loading />
    }

    return (
        <Box>
            <Title title="LEARNING CENTRE" className={classes.title} />
            <Box sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 4,
            }}>
                <Box
                    sx={{
                        height: 30,
                        width: 100,
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 1,
                        borderRadius: 1.5,
                        // color: "#495057",
                        color: themeObject.palette.primary.main,
                        "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            cursor: 'pointer',
                        },
                    }}
                >
                    <Link to="/learning_centre">
                        <Typography  sx={{ textDecoration: "underline", color: themeObject.palette.primary.main}}> <ArrowBackIosNewIcon sx={{ fontSize: "12px" }} /> Go Back </Typography>
                    </Link>
                </Box>
                <Typography textAlign="center" variant='h5' style={{ fontWeight: "500" }} width="100%" >{post.title.rendered}</Typography>

            </Box>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content.rendered) }} />
        </Box>
    );
};

export default ViewLearningContent;
