const getEnv = (variableName) => {
    if (!process.env[variableName]) {
        const errMsg = `Invalid environment variable - "${variableName}"`;
        throw new Error(errMsg);
    }
    return process.env[variableName];
};

export const API_URL = getEnv("REACT_APP_API_URL");

export const REACT_APP_GTM_ID = getEnv("REACT_APP_GTM_ID");

export const API_URL_FOR_PDF = getEnv("REACT_APP_API_URL_FOR_PDF");

export const WORDPRESS_ENDPOINT = getEnv("REACT_APP_WORDPRESS_ENDPOINT");


export const NETWORK_ERROR_MESSAGE =
    "Network error occurred: Please check your internet connection";

export const INACTIVE_ACTIVE_CHOICES = [
    { id: false, name: "Inactive" },
    { id: true, name: "Active" },
    { id: false, name: "Draft" }
];
export const STATUS_INACTIVE_ACTIVE_CHOICES = [
    { id: false, name: "Inactive" },
    { id: true, name: "Active" },
];

export const YES_NO_CHOICES = [
    { id: false, name: "No" },
    { id: true, name: "Yes" },
];

export const ACTIONS_CHOICES = [
    { id: "create", name: "Create" },
    { id: "view", name: "View" },
    { id: "edit", name: "Edit" },
    { id: "delete", name: "Delete" },
];

export const PROJECT_PHASE_MASTER_DATA_ID = 2;
export const PROJECT_DRIVERS_MASTER_DATA_ID = 1;
export const CHANGE_COMPONENTS_ID = 2;

export const ASSESSMENT_SUB_TYPES = {
    Employee: 1,
    Customers: 2,
    "Job losses": 3,
    "Behaviour/Culture": 4,
    Suppliers: 5,
    "New Roles": 6,
    "Employees in scope": 7,
    "Customers in scope": 8,
    "Areas in scope": 9,
    Complexity: 10,
    Timeframe: 11,
    "Resistance (Employee)": 12,
    "IR/Legal/Compliance": 13,
    "Resistance (Line Managers)": 14,
    "Resistance (Customers)": 15,
    Rating: 16,
    "Morale Impact - Implications": 17,
    Interdependencies: 18,
    "Challenge to absorb change ": 19,
    "Implementation Approach": 20,
    "Rating - Scale/Effort": 21,
    "Rating - Challenge": 22,
};

export const ASSESSMENT_TYPES = {
    Impact: 1,
    Scope: 2,
    Challenge: 3,
    "Stakeholder Management": 4,
    "Change Leadership": 5,
    "Communications and Engagement ": 6,
    "Process and Systems Re-design": 7,
    "Business Strategy and Operating Model": 8,
    "Job Re-design": 9,
    "Capability: Skills Definition and Development ": 10,
    Restructuring: 11,
    "Behaviour / Culture Change ": 12,
    "Performance Management": 13,
    "Resourcing, Costing and Scheduling ": 14,
    "Risk Management (Change Management Risks)": 15,
    "Industrial Relations / Legal / Compliance": 16,
    "Business rules / Policy / Procedures": 17,
};

export const CHART_COLORS = [
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba',
    // new colors
    '#cb4335',
    '#e74c3c',
    '#a93226',
    '#c0392b',
    '#935116',
    '#ff7043',
    '#5499c7',
    '#a569bd',
    '#4a235a',
    '#633974',
    '#884ea0',
    '#af7ac5',
    '#145a32',
    '#1e8449',
    '#27ae60',
    '#0e6251',
    '#17a589',
    '#48c9b0',
    '#e67e22',
    '#9c640c',
    '#f5b041',
    '#7d6608',
    '#d4ac0d',
    '#f4d03f',
];

export const RISK_COLORS = { H: '#EB5757', M: '#F2994A', L: '#27AE60' }

export const KPI_CRITERIA_TYPE = {
    GONOGO: "GoNoGo",
    CLOSEOUT: "CloseOut"
}

export const kpiChoice = [
    {
        id: "Yes",
        name: "Yes",
    },
    {
        id: "No",
        name: "No"
    },
    {
        id: "N/A",
        name: "N/A",
    },
]

export const consequenceChoice = [
    {
        id: 1,
        name: "1. Insignificant"
    },
    {
        id: 2,
        name: "2. Minor"
    },
    {
        id: 3,
        name: "3. Moderate"
    },
    {
        id: 4,
        name: "4. Major"
    },
    {
        id: 5,
        name: "5. Significant"
    }
];

export const likelihoodChoice = [
    {
        id: 1,
        name: "1. Rare"
    },
    {
        id: 2,
        name: "2. Unlikely"
    },
    {
        id: 3,
        name: "3. Possible"
    },
    {
        id: 4,
        name: "4. Likely"
    },
    {
        id: 5,
        name: "5. Almost Certain"
    }
]

export const CHANGE_ASSESSMENT = {
    IMPACT: ["Impact"],
    ENVIRONMENT: ["Maturity", "Environment", "Saturation"]
}

export const CustomsThemePalette = [
    {
        name: "Blue",
        color: "#028FC2"
    },
    {
        name: "Yellow",
        color: "#FFA500"
    },
    {
        name: "Rose",
        color: "#800000"
    },
    {
        name: "Green",
        color: "#006400"
    },
    {
        name: "DarkBlue",
        color: "#000080"
    },
    {
        name: "PurpleDark",
        color: "#800080"
    },
    {
        name: "Gold",
        color: "#8B4513"
    },
    {
        name: "Bricks",
        color: "#A0522D"
    },
    {
        name: "GoldLighter",
        color: "#CD853F"
    },
    {
        name: "DarkGreen",
        color: "#556B2F"
    },
    {
        name: "Red",
        color: "#FF4500"
    },
    {
        name: "Purple",
        color: "#DA70D6"
    },
    {
        name: "Cyan",
        color: "#00CED1"
    },
    {
        name: "Violet",
        color: "#9400D3"
    },
    {
        name: "Orange",
        color: "#FF8C00"
    }
]
