import { Box, Typography, useMediaQuery } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import makeStyles from "@material-ui/styles/makeStyles";
import _ from "lodash";
import * as React from "react";
import { cloneElement } from "react";
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  useDataProvider,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { groupByAction, onFailureMessage } from "../../utils/AppUtils";
import ButtonWithPermission from "../../utils/ButtonWithPermission";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import MoreActions from "../customComponents/moreActions";
import RoleCreateDrawer from "./roleCreateDrawer";
import RoleEditDrawer from "./roleEditDrawer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, IconButton, Snackbar } from "@mui/material";
import PostPagination from "../../utils/PostPagination";
const useStyles = makeStyles((theme) => ({
  headerCell: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  rowCell: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
    },
  },
}));

const ListActions = (props) => {
  const classes = useStyles();
  const {
    className,
    exporter,
    filters,
    maxResults,
    handleCreateDrawerOpen,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateRoleBtn {...props} />
    </TopToolbar>
  );
};

const RoleFilter = (props) => (
  <Filter {...props}>
    <SearchInput variant="outlined" source="q" alwaysOn />
  </Filter>
);

const CreateRoleBtn = (props) => {
  const { isCustomerAdmin, permissions, isSuperAdmin, handleCreateDrawerOpen } =
    props;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <ButtonWithPermission
      resource="Role"
      permissions={permissions}
      bypassUser={isCustomerAdmin}
      endIcon={<AddIcon />}
      action={["create"]}
      variant="contained"
      onClick={() => handleCreateDrawerOpen(true)}
    >
      {isSmall ? "" : "Create Role"}
    </ButtonWithPermission>
  );
};

const Empty = (props) => {
  const { basePath, resource } = useListContext();
  return (
    <Box textAlign="center" m={1} p={1}>
      <Typography variant="h4" paragraph>
        No Roles yet.
      </Typography>
      <Typography variant="body1">Do you want to add one?</Typography>
      <Box mt={1}>
        <CreateRoleBtn {...props} />
      </Box>
    </Box>
  );
};

export const RoleList = (props) => {
  const [createDrawerOpen, setCreateDrawerOpen] = React.useState();
  const [editDrawerOpen, setEditDrawerOpen] = React.useState();
  const [record, setRecord] = React.useState({ viewPermissions: [], editPermissions: [], name: "" });
  const dataProvider = useDataProvider();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({message: name, type: type})
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  const [anchorOrigin, setAnchorOrigin] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = React.useState({
    message: "",
    type: ""
  });

  const {vertical, horizontal} = anchorOrigin;
  const refresh = useRefresh();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { loading: loadingPermissions, permissions } = usePermissions();

  const [permissionList, setPermissionList] = React.useState([])
  const user = TokenStorage.getUserDetails();
  const { isSuperAdmin, isCustomerAdmin } = user ? user : {};

  const handleCreateDrawerOpen = (open) => {
    setCreateDrawerOpen(open);
  };

  const handleCreateDrawerClose = () => {
    setCreateDrawerOpen(false);
  };

  const handleEditDrawerOpen = (open) => {
    setEditDrawerOpen(open);
  };

  const handleEditDrawerClose = (open) => {
    setRecord(null);
    setEditDrawerOpen(open);
  };

  React.useEffect(() => {
    dataProvider.getList("Permission", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: { isDisabled: true },
    }).then((res) => {
      setPermissionList(groupByAction(res.data))
    })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])


  const rowActionsChoices = [
    { id: "edit", name: "Edit", resource: "Role", action: ["edit"] },
    { id: "delete", name: "Delete", resource: "Role", action: ["delete"] },
  ];

  const handleEditRole = (editRecord) => {
    setRecord(editRecord);
    handleEditDrawerOpen(true);
  };
  const handleDeleteRole = (record) => {
    const proceed = window.confirm("You are deleting the entry. Are you sure?");
    if (proceed) {
      dataProvider
        .delete("Role", { id: record.id })
        .then((response) => {
          handleSnackbarOpen("Role deleted successfully", "success")
          refresh();
        })
        .catch((error) => {
          const error_messages = onFailureMessage(error);
          handleSnackbarOpen(error_messages, "error");
        });
    }
  };
  const handleMoreActionsClick = ({ action, record }) => {
    switch (action) {
      case "edit":
        setRecord(record);
        handleEditDrawerOpen(true);
        break;
      case "delete":
        const proceed = window.confirm(
          "You are deleting the entry. Are you sure?"
        );
        if (proceed) {
          dataProvider
            .delete("Role", { id: record.id })
            .then((response) => {
              handleSnackbarOpen("Role deleted successfully", "success");
              refresh();
            })
            .catch((error) => {
              const error_messages = onFailureMessage(error);
              handleSnackbarOpen(error_messages, "error");
            });
        }
        break;
    }
  };

  const PermissionsField = ({ record }) => {
    if (record && record.permissions) {
      return _.map(record.permissions, "name").join(", ");
    }

    return null;
  };
  const PermissionsFieldSC = () => {
    const records = useRecordContext();
    if (records && records.permissions) {
      const permissionsText = _.map(records.permissions, "name").join(", ");
      return (
        <span style={{ color: "#8d8f8e", fontSize: "13px" }}>
          {permissionsText}
        </span>
      );
    }

    return null;
  };

  const ActionButtonSM = () => {
    const records = useRecordContext();
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          aria-label="edit"
          className={classes.IconButtonOverride}
          size="small"
        >
          <EditIcon
            size="small"
            style={{
              color: "#00A0D9",
              cursor: "pointer",
              margin: "0 5px",
              fontSize: "20px",
            }}
            onClick={() => handleEditRole(records)}
          />
        </IconButton>
        <IconButton
          aria-label="delete"
          className={classes.IconButtonOverride}
          size="small"
        >
          <DeleteForeverIcon
            size="small"
            style={{ color: "#de0937", cursor: "pointer", fontSize: "20px" }}
            onClick={() => handleDeleteRole(records)}
          />
        </IconButton>
      </Box>
    );
  };
  return (
    <WithPermission
      resource="Role"
      permissions={permissions}
      bypassUser={isCustomerAdmin}
      action={["view"]}
      {...props}
    >
      <List
        title={
          <span style={{ textTransform: "uppercase" }}>Role Management</span>
        }
        hasCreate={false}
        empty={
          <Empty
            isCustomerAdmin={isCustomerAdmin}
            isSuperAdmin={isSuperAdmin}
            permissions={props.permissions}
            handleCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
        actions={
          <ListActions
            isCustomerAdmin={isCustomerAdmin}
            isSuperAdmin={isSuperAdmin}
            permissions={permissions}
            handleCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
        filters={<RoleFilter />}
        bulkActionButtons={false}
        pagination={<PostPagination />}
        {...props}
      >
        {isSmall ? (
          <Datagrid
            classes={{
              headerCell: classes.headerCell,
              rowCell: classes.rowCell,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                px: "5px",
              }}
            >
              <TextField label="Name" source="name" sortable={false} />
              <br />
              <PermissionsFieldSC
                label="Permissions"
                source="permissions"
                sortable={false}
              />
              <br />
              <ActionButtonSM />
            </Box>
          </Datagrid>
        ) : (
          <Datagrid>
            <TextField label="Name" source="name" sortable={false} />
            <PermissionsField
              label="Permissions"
              source="permissions"
              sortable={false}
            />
            <MoreActions
              bypassUser={isCustomerAdmin}
              permissions={permissions}
              choices={rowActionsChoices}
              handleClose={() => console.log("closing")}
              handleClick={handleMoreActionsClick}
              sortable={false}
            />
          </Datagrid>
        )}
      </List>
      <RoleCreateDrawer
        choicess={permissionList}
        record={record}
        drawerOpen={createDrawerOpen}
        onClose={handleCreateDrawerClose}
        handleSnackbarOpen={handleSnackbarOpen}
      />
      <RoleEditDrawer
        choicess={permissionList}
        record={record}
        drawerOpen={editDrawerOpen}
        onClose={handleEditDrawerClose}
        handleSnackbarOpen={handleSnackbarOpen}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
        <Alert

          onClose={hanndleSnackbar}
          severity={popupMessage.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {popupMessage.message}
        </Alert>
      </Snackbar>
    </WithPermission>
  );
};

export default RoleList;
