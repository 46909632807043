export const ASSESSMENT_TYPE_UPDATE_MUTATION = `mutation(
    $id: Int!
    $assessmentTypeDescription: String!
    $assessmentSubTypeId: Int!
    $assessmentSubTypeDescription: String!
    $assessmentValues: [AssessmentValueInput!]!
  ) {
    updateAssessmentType(
      id: $id
      assessmentTypeDescription: $assessmentTypeDescription
      assessmentSubTypeId: $assessmentSubTypeId
      assessmentSubTypeDescription: $assessmentSubTypeDescription
      assessmentValues: $assessmentValues
    ) {
      id
      name
      description
      assessmentSectionId
      assessmentSubTypes {
        id
        name
        description
        assessmentTypeId
        assessmentValues {
          id
          nature
          description
          signific
          rating
        }
      }
    }
  }`

export const ASSESSMENT_TYPE_RETRIEVE_QUERY = `query($id: Int!) {
    assessmentType(id: $id) {
      id
      name
      description
      assessmentSectionId
      assessmentSubTypes {
        id
        name
        description
        assessmentTypeId
        assessmentValues {
          id
          nature
          description
          signific
          rating
        }
      }
    }
  }`