import PBIEmbed from "./pbiEmbed";

export const ChangeHeatmapReport = (props) => {
    return (
        <PBIEmbed
            title="Change Heatmap Report"
            reportId='4e534fc8-f1bb-4272-a41d-5473d28ea467'
            pageName='ReportSection68f8e96159e0658664b8'
            resource='Report'
            url='change_heatmap_project'
        />
    )
}

export default ChangeHeatmapReport;