import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext } from 'react';
import palette from '../../framework/theme/palette';
import { hasPermissions } from '../../utils/AppUtils';
import { ThemeContext } from '../../framework/theme/themeContext';

const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
];
const useStyles = makeStyles((theme) => ({
    warningIcon: {
        color: theme.palette.customsColor.amber,
    },
    IconButtonOverride: {
       '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        borderRadius: "4px",
        backgroundColor: palette.customsColor.lighter,
        margin: "8px 6px"
    }
}));

const ITEM_HEIGHT = 48;

export default function MoreActions({ record, choices = [], handleClose, handleClick, permissions, bypassUser }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [items, setItems] = React.useState()
    const classes = useStyles();
    const { themeObject } = useContext(ThemeContext)
    const handleActionClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMoreActionClick = (e) => {
        handleMoreActionsClose();
        handleClick({ action: e.target.getAttribute("value"), record });
    }

    const handleMoreActionsClose = () => {
        setAnchorEl(null);
        handleClose();
    };

    React.useEffect(async () => {
        if (bypassUser) {
            setItems(choices.map((choice) => {
                return { ...choice, disabled: false }
            }))
        }
        else if (permissions) {
            const checkedPermissions = await Promise.all(choices.map((choice) => {
                return hasPermissions(permissions.permissions, { resource: choice.resource, action: choice.action })
            }))
            setItems(choices.map((choice, index) => {
                return { ...choice, disabled: !checkedPermissions[index] }
            }))
        }
    }, [permissions])

    if (!items) return null

    return (
        <div>
            <Tooltip title="Edit / Delete">
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    className={classes.IconButtonOverride}
                    
                    onClick={handleActionClick}
                    size="small"
                >
                    <MoreHorizIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleMoreActionsClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {items.map((item) => (
                    <MenuItem key={item.id} disabled={item.disabled} value={item.id} onClick={handleMoreActionClick}>
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}