
import { Box, Divider, useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from '@material-ui/icons/Delete';
import arrayMutators from "final-form-arrays";
import moment from "moment";
import * as React from "react";
import {
    AutocompleteInput,
    DateInput,
    Loading,
    TextInput,
    maxLength, required,
    useDataProvider,
    useRefresh
} from "react-admin";
import { Field, Form, useForm } from "react-final-form";
import palette from "../../framework/theme/palette";
import { impersonatedURL, onFailureMessage } from "../../utils/AppUtils";
import { TokenStorage } from '../../utils/TokenStorage';
import { ProjectFormButton } from "./ProjectFormButton";
import { Alert, Snackbar } from "@mui/material";
// import CustomDrawer from "../customComponents/customDrawer";

const useStyles = makeStyles({
    actionToolbar: {
        display: 'flex',
        marginTop: 40,
        justifyContent: 'flex-end'
    },
    deleteIcon: {
        color: palette.customsColor.amber,
        '&:hover': {
            color: '#f06548cc'
        }
    },
    removeMileston: {
        color: palette.customsColor.amber,
        '&:hover': {
            color: '#f06548cc'
        }
    },
});

export const CreateMilestone = ({ record, activeStep, handleBack, handleNext, steps, setIsUpdateProjectDetails }) => {
    const [initialValues, setInitialValues] = React.useState();
    const user = TokenStorage.getUserDetails();
    const dataProvider = useDataProvider();

    const refresh = useRefresh();
    const classes = useStyles();
    const localProjectDetails = TokenStorage.getProjectDetails()
    const [milestoneLoading, setMilestoneLoading] = React.useState(true);
    const [projectPhasesArray, setProjectPhasesArray] = React.useState([])
    const resource = "Milestone";
    const { isImpersonating } = user ? user : {};
    const [draftStatus, setDraftStatus] = React.useState(false)
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setSnackbarOpen(true);
    };

    const hanndleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = React.useState({
        message: "",
        type: ""
    });

    const { vertical, horizontal } = anchorOrigin;
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    React.useEffect(() => {
        if (localProjectDetails !== undefined && localProjectDetails !== null) {
            dataProvider
                .getOne("Project", { id: localProjectDetails?.id })
                .then((response) => {
                    setProjectPhasesArray(response.data);
                })
                .catch((error) => {
                    console.log(error)
                });
        }

    }, [])

    const handleSteper = async (isNext) => {
        const stepers = {
            id: localProjectDetails.id,
            isDraft: true,
            stepCount: 2
        }
        dataProvider
            .postRequest('Project', {
                queryType: "updateStep",
                data: stepers,
            })
            .then((response) => {
                // form.restart();
                console.log(response)
                // onClose(false, true);
                if (!isNext) {
                    TokenStorage.clearProjectData()
                    impersonatedURL(isImpersonating, '#/project?impersonated')
                }
                handleNext(response.data)
                // refresh();
                // createMilestoneView()
            })
            .catch((error) => {
                console.log(error)
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error")
            });
    }
    const onSubmit = (values, form, status) => {
        //  console.log(status, form)
        // console.log(values)
        // const mst = values.milestones.filter(itm => itm != null)
        const isNext = values.isNext;
        const milestoneData = {
            projectId: +localProjectDetails.id,
            milestones: values.milestones.filter(milestone => Boolean(milestone)).map(milestone => ({
                name: milestone.name,
                projectPhase: milestone.projectPhase,
                milestoneDate: milestone.milestoneDate,
            }))
        }
        dataProvider
            .postRequest(resource, {
                queryType: "update",
                data: milestoneData,
            })
            .then(async (response) => {
                // form.restart();
                console.log(response)
                // onClose(false, true);
                // console.log(status)
                // handleNext(response.data)
                if (!isNext) {
                    await handleSteper(isNext)
                }
                else {
                    handleNext(response.data)
                }

                // refresh();
                // createMilestoneView()
            })
            .catch((error) => {
                console.log(error)
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error")
            });
    };

    const validate = () => { };

    React.useEffect(() => {
        console.log('projectPhases1', record);
        /* if (record && record.id) {
            const projectPhases = record.projectPhases ? record.projectPhases : null;
            console.log('projectPhases_create', projectPhases);
            setInitialValues({ milestones: [{}], projectPhases: projectPhases })
        } */
        createMilestoneView();
    }, [projectPhasesArray]);

    const createMilestoneView = React.useCallback(
        async () => {
            if (localProjectDetails && localProjectDetails.id) {
                const milestones = await dataProvider
                    .getList("Milestone", {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        filter: { projectId: localProjectDetails.id },
                    })
                milestones.data = milestones.data ? milestones.data.map(milestone => ({
                    name: milestone.name,
                    projectPhase: milestone.projectPhase,
                    milestoneDate: new Date(milestone.milestoneDate),
                })) : null;

                // const projectPhases = record.projectPhases ? record.projectPhases : null;

                // console.log('projectPhases', projectPhases);
                console.log(projectPhasesArray)
                setInitialValues({ milestones: milestones.data, projectPhases: projectPhasesArray.projectPhases })
                setMilestoneLoading(false)
            }
        },
        [dataProvider, projectPhasesArray],
    )

    return (
        <>

            {
                !milestoneLoading ? <Box mr={!isSmall && 4}>
                    <Form
                        onSubmit={onSubmit}
                        mutators={{
                            // potentially other mutators could be merged here
                            ...arrayMutators,
                        }}
                        validate={validate}
                        initialValues={initialValues}
                        render={({
                            handleSubmit,
                            form: {
                                mutators: { push, pop },
                            },
                        }) => (
                            <form className={classes.formWidth} onSubmit={handleSubmit}>
                                <MilestoneForm
                                    push={push}
                                    // onClose={onClose}
                                    projectStartDate={projectPhasesArray ? projectPhasesArray.startDate : null}
                                    projectEndDate={projectPhasesArray ? projectPhasesArray.endDate : null}
                                    projectPhases={initialValues ? initialValues.projectPhases : null}
                                    milestonesValues={initialValues ? initialValues.milestones : null}
                                />

                                <ProjectFormButton activeStep={activeStep} handleSubmit={handleSubmit} handleBack={handleBack} steps={steps} isDraft={projectPhasesArray?.isDraft} />
                                <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                                    <Alert

                                        onClose={hanndleSnackbar}
                                        severity={popupMessage.type}
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        {popupMessage.message}
                                    </Alert>
                                </Snackbar>
                            </form>
                        )}
                    />
                </Box> : <Loading />
            }
        </>

    );
};
const MilestoneForm = ({ push, onClose, milestonesValues, projectStartDate, projectEndDate, projectPhases }) => {
    const [milestones, setMilestones] = React.useState();
    const form = useForm();
    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const validateStartDate = (value, values, index) => {
        try {
            const startDate = value;
            const projectPhase = projectPhases.filter(e => e.name === values.milestones[index].projectPhase);

            if (!projectPhase || !(projectPhase.length > 0)) {
                return `Please select a valid Project phase`;
            }

            const phaseStartDate = moment(projectPhase[0].startDate).format("YYYY-MM-DD");
            const phaseEndDate = moment(projectPhase[0].endDate).format("YYYY-MM-DD");

            if (startDate && moment(startDate).isBefore(projectStartDate.slice(0, 10))) {
                return `should be greater than "${moment(projectStartDate).format("DD/MM/YY")}"`;
            }

            if (startDate && moment(startDate).isSameOrAfter(projectEndDate.slice(0, 10))) {
                return `should be lesser than "${moment(projectEndDate).format("DD/MM/YY")}"`;
            }

            if (startDate && !moment(startDate).isBetween(phaseStartDate, phaseEndDate, 'days', true)) {
                return `should be between "${moment(phaseStartDate).format("DD/MM/YY")}" and "${moment(phaseEndDate).format("DD/MM/YY")}"`;
            }

        } catch (e) {
            console.log(e);
        }
    };
    const handleAddMileston = () => {
        push("milestones", undefined);
        milestones.push({})
        // console.log(push)
        // setMilestones([...milestones, {}])
        // setMilestones(prevMilestones => [...prevMilestones, {}]);
    }
    React.useEffect(() => {
        setMilestones(milestonesValues);
    }, [milestonesValues])
    if (milestones)
        return (
            <Box >
                {milestones.map((milestone, index) => (
                    Boolean(milestone) && <Box> <Grid key={`${milestone.name}_${index}`} container direction="row"
                        justifyContent="center"
                        alignItems="flex-start" spacing={!isSmall && 6}>

                        <Grid item xs={12} lg={3}>
                            <Field fullWidth name={`milestones[${index}].name`} component="input">
                                {(props) => (
                                    <TextInput
                                        fullWidth
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                        variant="outlined"
                                        label="Milestone Name"
                                        validate={[required(), maxLength(255)]}
                                    // style={{ width: '280px' }}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} lg={3} >
                            <Field fullWidth name={`milestones[${index}].projectPhase`} component="input">
                                {(props) => (
                                    <AutocompleteInput
                                        fullWidth
                                        variant="outlined"
                                        label="Project Phase"
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                        optionValue="name"
                                        optionText="name"
                                        source={props.input.name}
                                        choices={projectPhases}
                                        validate={[required()]}
                                    // style={{ width: '280px' }}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Field fullWidth name={`milestones[${index}].milestoneDate`} component="input">
                                {(props) => (
                                    <DateInput
                                        fullWidth
                                        variant="outlined"
                                        label="Milestone Date"
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                        validate={[
                                            required(),
                                            (value, values) =>
                                                validateStartDate(value, values, index),
                                        ]}
                                    // style={{ width: '280px' }}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} lg={2} style={{ margin: '6px 0' }}>
                            <Button
                                className={classes.removeMileston}
                                variant="text"
                                startIcon={<DeleteIcon className={classes.deleteIcon} />}
                                onClick={() => {
                                    milestones[index] = undefined
                                    form.change(`milestones[${index}]`, undefined)
                                    setMilestones([...milestones])
                                }}
                            >
                                Remove Milestone
                            </Button>
                        </Grid>



                    </Grid>
                        <Box mt={2} mb={2}><Divider /></Box>
                    </Box>
                ))}
                <Box style={{ display: 'flex', justifyContent: 'center', }}>
                    <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAddMileston}
                        color="primary"
                        variant="text"
                    >
                        Add Milestone
                    </Button>
                </Box>
            </Box>
        )

    return null;
}

export default CreateMilestone;
