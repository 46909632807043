import {
    Box, Grid, LinearProgress, Link as MuiLink, Paper, Table,
    TableBody,
    TableCell,
    TableContainer, TableRow, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import moment from "moment";
import React from "react";
import { AutocompleteInput, Loading, ReferenceInput, Title, useDataProvider, useNotify, usePermissions } from "react-admin";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";
import theme from "../../framework/theme";
import { impersonatedURL, onFailureMessage, startEndDatesOfWeeks } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import { RiskBox } from "./allProjectsHeatMap_modified";

const useStyles = makeStyles((theme) => ({
    inputWidthMargin: {
        marginRight: 30,
    },
    referenceInputWidth: {
        width: 350,
    },
    bottomCardTitle: {
        backgroundColor: "#E0E0E0",
    },
    rowHeading: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 16,
        color: "#4F4F4F",
    },
    forecastRiskBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        width: 60,
        height: 30,
        opacity: 0.3,
        borderRadius: 4,
        "&.active": {
            opacity: 1,
        },
        "&.risk-high": {
            backgroundColor: "#EB5757",
        },
        "&.risk-medium": {
            backgroundColor: "#F2994A",
        },
        "&.risk-low": {
            backgroundColor: "#27AE60",
        },
    },
    changeImpactBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        width: 200,
        height: 30,
        opacity: 0.3,
        borderRadius: 4,
        "&.active": {
            opacity: 1,
        },
        "&.risk-high": {
            backgroundColor: "#EB5757",
        },
        "&.risk-medium": {
            backgroundColor: "#F2994A",
        },
        "&.risk-low": {
            backgroundColor: "#27AE60",
        },
    },
    borderRight: {
        borderRight: "2px solid #C4C4C4",
    },
    borderBottom: {
        borderBottom: "2px solid #C4C4C4",
    },
    tableHeading1: {
        backgroundColor: "#E0E0E0",
    },
    tableHeading2: {
        backgroundColor: "#F2F2F2",
    },
    responsiveTableHeight: {
        [theme.breakpoints.down('sm')]: {
            height: '250px',
            width: `calc(100vw - 63px)`,
        },
    },
    responsiveTitleFrontSize: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            whiteSpace: "normal",
            textAlign: 'center',
        },
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    },
    columnFreezed: {
        position: 'sticky',
        left: 0,
        // zIndex: 999,
        borderRight: '2px solid #C4C4C4',
        backgroundColor: '#F2F2F2'
    },
    columnFreezed1: {
        position: 'sticky',
        left: 0,
        // zIndex: 2000,
        borderRight: '2px solid #C4C4C4',
        borderBottom: '2px solid #C4C4C4',
        backgroundColor: '#fff'
    },
    columnLowPriority: {
        borderRight: "2px solid #C4C4C4",
        zIndex: -1,
    },
    columnLowPriorityB: {
        zIndex: -1
    }

}));

export const ChangeHeatMap = (props) => {
    const months = 12;
    const startDate = moment().format("YYYY-MM-DD");
    const endDate = moment().add(months, "M").format("YYYY-MM-DD");
    const [businessUnitId, setBusinessUnitId] = React.useState();
    const { loading: loadingPermissions, permissions } = usePermissions();

    const user = TokenStorage.getUserDetails();
    const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};
    impersonatedURL(isImpersonating, '#/change_heat_map_bu_wise?impersonated');

    const classes = useStyles();
    React.useEffect(() => {
        const buId = props.location.state && props.location.state.businessUnitId
            ? props.location.state.businessUnitId
            : null;
        if (buId) setBusinessUnitId(buId);
    }, []);

    const handleBusinessUnitChange = (id) => {
        setBusinessUnitId(id)
    }

    return (
        <WithPermission resource="ChangeAssessment1" permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <Title className={classes.responsiveTitleFrontSize} title="Change Impact Heatmap - BU" />
            <Form
                onSubmit={() => { }}
                render={({ form, values }) => (
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={12} sm={12}>
                            <ReferenceInput
                                variant="outlined"
                                label="Business Unit"
                                source="businessUnitId"
                                reference="BusinessUnit"
                                defaultValue={businessUnitId}
                                resettable={true}
                                onChange={handleBusinessUnitChange}
                                fullWidth
                            >
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                )}
            />
            <Box fontSize={12}>
                {moment(startDate).format("MMM YYYY")} - {moment(endDate).format("MMM YYYY")}
            </Box>
            <Box className={classes.responsiveTableHeight} mt={2} width={`calc(100vw - 156px)`}>
                <HeatMapTable
                    businessUnitId={businessUnitId}
                    startDate={startDate}
                    endDate={endDate}
                />
            </Box>
        </WithPermission>
    )
}

export const HeatMapTable = ({ businessUnitId, startDate, endDate }) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const weeks = startEndDatesOfWeeks(moment(startDate), moment(endDate));
    const weekCount = weeks.length;
    const [businessUnitsWithLevel, setBusinessUnitsWithLevel] = React.useState();
    const [filteredBusinessUnits, setFilteredBusinessUnits] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();

    const allBusinessUnitsWithLevel = React.useCallback(() => {
        return dataProvider.postRequest("AllBusinessUnitsWithLevel", {
            queryType: "retrieve",
            data: {
                page: 1,
                perPage: 1000,
                sortField: "name",
                sortOrder: "ASC",
            }
        }).then(({ data }) => ({ data: data.items }));
    }, [dataProvider])

    React.useEffect(() => {
        setLoading(true);
        allBusinessUnitsWithLevel()
            .then(({ data }) => {
                if (data && data.length) {
                    setBusinessUnitsWithLevel(data);
                    const filterFirstLevelBUs = data.filter(({ level }) => level === 1) // filtering first level of BUs only after mounting / landing
                    setFilteredBusinessUnits(filterFirstLevelBUs);
                    setError(null);
                } else {
                    setError("No business units found");
                }
                setLoading(false)
            }).catch(error => {
                setError("Something went wrong");
                setLoading(false);
            })
    }, [allBusinessUnitsWithLevel])

    React.useEffect(() => {
        if (businessUnitId) {
            const selectedBusinessUnit = businessUnitsWithLevel.find(({ id }) => id === businessUnitId) // filtering out the selected BUs in the dropdown
            setFilteredBusinessUnits([selectedBusinessUnit]);
        }
        else if (businessUnitsWithLevel && businessUnitsWithLevel.length) {
            const filterFirstLevelBUs = businessUnitsWithLevel.filter(({ level }) => level === 1) // if any BU is selected we are taking only the first level BUs
            setFilteredBusinessUnits(filterFirstLevelBUs)
        }
    }, [businessUnitId, businessUnitsWithLevel])

    if (loading) return <Loading />

    if (error) return <Box p={1} fontWeight="bold" textAlign="center" color={theme.palette.error.main}>{error}</Box>;

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableBody className={classes.responsiveTableHeight}>
                    <TableRow className={classes.tableHeading2}>
                        <TableCell align="left" className={classes.columnFreezed}>
                            Weeks
                        </TableCell>
                        {weeks.map((week, i) => (
                            <TableCell align="left" key={i} className={i < weekCount - 1 ? classes.columnLowPriority : null}>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Box whiteSpace="nowrap">Week {i + 1}</Box>
                                    <Box whiteSpace="nowrap">
                                        {moment(week.startDate).format("DD-MMM")} - {moment(week.endDate).format("DD-MMM")}
                                    </Box>
                                </Box>
                            </TableCell>
                        ))}
                    </TableRow>
                    {filteredBusinessUnits.map(businessUnit => (
                        <HeatmapRow
                            key={businessUnit.id}
                            startDate={startDate}
                            endDate={endDate}
                            businessUnit={businessUnit}
                            level={0}
                            buLevel={businessUnit.level}
                            weeks={weeks}
                            weekCount={weekCount}
                            businessUnitsWithLevel={businessUnitsWithLevel}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
const HeatmapRow = ({ businessUnit, weeks, weekCount, level, buLevel, startDate, endDate, businessUnitsWithLevel }) => {
    const classes = useStyles();
    const [businessUnits, setBusinessUnits] = React.useState();
    const [error, setError] = React.useState();

    const handleBUClick = (businessUnitId) => {
        const data = businessUnitsWithLevel.filter(({ parentBusinessUnitId }) => parentBusinessUnitId === businessUnitId)
        if (data && data.length) {
            setBusinessUnits(data);
            setError(null);
        } else {
            setError("No sub-business units found");
        }
    }
    return (
        <>
            <TableRow>
                <TableCell align="left" className={classes.columnFreezed1}>
                    <Box whiteSpace="nowrap" ml={level} display="flex" flexDirection="row" alignItems="center" >
                        <MuiLink
                            href="#/change_heat_map_bu_wise"
                            underline="none"
                            onClick={() => handleBUClick(businessUnit.id)}
                        >
                            {businessUnit.name}
                        </MuiLink>
                        <Box pl={2}>
                            <Link
                                to={{
                                    pathname: '/change_heat_map',
                                    state: { businessUnitId: businessUnit.id }
                                }}
                            >
                                <Tooltip title="Drive Into Change Heat Map Page" placement="right">
                                    <OpenInNewIcon />
                                </Tooltip>
                            </Link>
                        </Box>
                    </Box>
                </TableCell>
                <ForecastRisks
                    startDate={startDate}
                    endDate={endDate}
                    businessUnitId={businessUnit.id}
                    weeks={weeks}
                    weekCount={weekCount}
                    buLevel={buLevel}
                />
            </TableRow>
            {Boolean(error) && <TableRow>
                <TableCell
                    colSpan={weekCount}
                    align="center"
                    className={[
                        classes.borderBottom,
                        classes.borderRight
                    ]}
                >
                    <Box color="red" textAlign="left" ml={buLevel + 1}><Typography>{error}</Typography></Box>
                </TableCell>
            </TableRow>}
            {Boolean(businessUnits && businessUnits.length) && businessUnits.map(businessUnit => (
                <HeatmapRow
                    key={businessUnit.id}
                    startDate={startDate}
                    endDate={endDate}
                    businessUnit={businessUnit}
                    buLevel={buLevel + 1}
                    level={level + 1}
                    weeks={weeks}
                    weekCount={weekCount}
                    businessUnitsWithLevel={businessUnitsWithLevel} />
            ))}
        </>
    )
}

const ForecastRisks = ({ businessUnitId, weeks, weekCount, startDate, endDate, buLevel }) => {
    const [forecasts, setForecasts] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();

    const getHeatmap = async (businessUnitId, startDate, endDate) => {
        setError(null);
        setLoading(true);
        return dataProvider
            .postRequest("AllBusHeatMap", {
                queryType: "retrieve",
                data: { businessUnitId, startDate, endDate },
            })
            .then(({ data }) => {
                if (data && data.length) {
                    setForecasts(data);
                    setError(null)
                } else {
                    setForecasts(null);
                    setError("No forecasts found");
                }
                setLoading(false);
            })
            .catch(error => {
                const error_message = onFailureMessage(error)
                notify(error_message);
                setForecasts(null)
                setError(error_message);
                setLoading(false);
            })
    };
    React.useEffect(() => {
        getHeatmap(businessUnitId, startDate, endDate);
    }, [businessUnitId, startDate, endDate])

    if (loading || error || !forecasts) {
        return (
            <TableCell
                colSpan={weekCount}
                align="center"
                className={[
                    classes.borderBottom,
                    classes.borderRight,
                    classes.columnLowPriorityB
                ]}
            >
                {loading && <LinearProgress />}
                {error && <Box color="red" textAlign="left"><Typography>{error}</Typography></Box>}
                {Boolean(!loading && !error && !forecasts) && "No forecasts found"}
            </TableCell>
        )
    }

    return weeks.map((week, i) => {
        const forecast = getForecastByStartDate(forecasts, week.startDate);
        return (
            <TableCell
                key={i}
                align="center"
                className={[
                    classes.borderBottom,
                    i < weekCount - 1 ? classes.borderRight : null
                ]}
            >
                {forecast ? <RiskBox risk={buLevel < 3 ? getRiskNameForTopBu(forecast.risk) : getRiskName(forecast.risk)} /> : null}
            </TableCell>
        )
    })
}

// deprecated component
/* const HeatMapRow = ({ loading, error, businessUnit, forecasts, weeks, weekCount, getHeatMap, buLevel, getBusinessUnits }) => {
    const classes = useStyles();
    // const [hasChildren, setHasChildren] = React.useState(false)
    const [childBusData, setChildBusData] = React.useState()
    const [childBusError, setChildBusError] = React.useState()
    const [childBusLoading, setChildBusLoading] = React.useState()
    const notify = useNotify()
    const history = useHistory()

    const handleBUClick = (parentBusinessUnitId) => {
        setChildBusLoading(true)
        getHeatMap(parentBusinessUnitId)
            .then(({ data }) => {
                if (data && data.length) {
                    setChildBusData(data);
                    setChildBusError(null)
                } else {
                    setChildBusData(null);
                    notify("No heatmap found")
                    setChildBusError("No Heatmap found");
                }
                setChildBusLoading(false);
            })
            .catch(error => {
                console.log(error)
                const error_message = onFailureMessage(error)
                notify(error_message);
                setChildBusData(null)
                setChildBusError(error_message);
                setChildBusLoading(false);
            })
    }

    if (loading) {
        return (
            <TableRow className={classes.tableHeading2} colSpan="100%">
                <TableCell
                    align="left"
                    className={[classes.borderBottom, classes.borderRight]}
                    colSpan="100%"
                >
                    <Loading />
                </TableCell>
            </TableRow>
        )
    }

    if (error) {
        return (
            <TableRow className={classes.tableHeading2} colSpan="100%">
                <TableCell
                    align="left"
                    className={[classes.borderBottom, classes.borderRight]}
                    colSpan="100%"
                >
                    <Box p={3} color="red">
                        <Typography>{error}</Typography>
                    </Box>
                </TableCell>
            </TableRow>
        )
    }

    if (!businessUnit) {
        return (
            <TableRow className={classes.tableHeading2} colSpan="100%">
                <TableCell
                    align="left"
                    className={[classes.borderBottom, classes.borderRight]}
                    colSpan="100%"
                >
                    <Box p={3} color="red">
                        <Typography>No forecasts found</Typography>
                    </Box>
                </TableCell>
            </TableRow>
        )
    }

    if (forecasts && forecasts.length) {
        return (
            Boolean(forecasts && forecasts.length) &&
            <React.Fragment>
                <TableRow>
                    <TableCell
                        align="left"
                        className={[classes.borderBottom, classes.borderRight]}
                    >
                        <Box whiteSpace="nowrap" ml={buLevel} display="flex" flexDirection="row" alignItems="center">
                            <MuiLink
                                href="#/change_heat_map_bu_wise"
                                underline="none"
                                onClick={() => handleBUClick(businessUnit.id)}
                            >
                                {businessUnit.name}
                            </MuiLink>
                            <Box pl={2}>
                                <Link
                                    to={{
                                        pathname: '/change_heat_map',
                                        state: { businessUnitId: businessUnit.id }
                                    }}
                                >
                                    <Tooltip title="Drive Into Change Heat Map Page" placement="right">
                                        <OpenInNewIcon />
                                    </Tooltip>
                                </Link>
                            </Box>
                        </Box>
                    </TableCell>
                    {weeks.map((week, i) => {
                        const forecast = forecasts.find((forecast, i) => {
                            // for the first week, compare ony dates 
                            // for other weeks, compare dates with start of week
                            const projectWeekStartDate = i === 0 ?
                                moment(forecast.startDate).format("YYYY-MM-DD") :
                                moment(forecast.startDate).startOf('isoWeek').format("YYYY-MM-DD");

                            const weekStartDate = week.startDate;

                            return weekStartDate === projectWeekStartDate;
                        });

                        const isForecastAvailable = forecast ? true : false;
                        if (!isForecastAvailable) {
                            return (
                                <TableCell align="center" className={[classes.borderBottom, i < weekCount - 1 ? classes.borderRight : null]}></TableCell>
                            )
                        }
                        return (
                            <TableCell key={forecast.week} align="center" className={[classes.borderBottom, i < weekCount - 1 ? classes.borderRight : null]}>
                                <RiskBox risk={buLevel === 0 ? getRiskNameForTopBu(forecast.risk) : getRiskName(forecast.risk)} />
                            </TableCell>
                        )
                    })}
                </TableRow>
                {Boolean(childBusData && childBusData.length)
                    && childBusData.map(({ businessUnit, forecasts }) => (
                        <HeatMapRow
                            getHeatMap={getHeatMap}
                            weeks={weeks}
                            weekCount={weekCount}
                            businessUnit={businessUnit}
                            forecasts={forecasts}
                            loading={childBusLoading}
                            error={childBusError}
                            buLevel={buLevel + 1}
                        />
                    ))
                }
            </React.Fragment>
        )
    } else {
        return (
            <Box p={1} fontWeight="bold" textAlign="center">There are no forecasts for this project</Box>
        )
    }
} */

// deprecated component
/* export const BuWiseHeatMapCard = ({ startDate, endDate, busHeatMap, getHeatMap, loading, error }) => {
    const classes = useStyles();
    const weeks = startEndDatesOfWeeks(moment(startDate), moment(endDate));
    const weekCount = weeks.length;

    return (
        <Card>
            <Box width={1280}>
                <TableContainer component={Paper}>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow className={classes.tableHeading2}>
                                <TableCell align="left" className={classes.borderRight}>
                                    Weeks
                                </TableCell>
                                {weeks.map((week, i) => {
                                    return (
                                        <TableCell align="left" key={i} className={i < weekCount - 1 ? classes.borderRight : null}>
                                            <Box display="flex" flexDirection="column" alignItems="center">
                                                <Box whiteSpace="nowrap">Week {i + 1}</Box>
                                                <Box whiteSpace="nowrap">
                                                    {moment(week.startDate).format("DD-MMM")} - {moment(week.endDate).format("DD-MMM")}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                            {loading && (
                                <TableRow className={classes.tableHeading2} colSpan="100%">
                                    <TableCell
                                        align="left"
                                        className={[classes.borderBottom, classes.borderRight]}
                                        colSpan="100%"
                                    >
                                        <Box height={100}>
                                            <Loading loadingSecondary="Loading BU Heatmap summary" />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                            {Boolean(busHeatMap && busHeatMap.length)
                                && busHeatMap.map(({ businessUnit, forecasts }) => (
                                    <HeatMapRow
                                        getHeatMap={getHeatMap}
                                        weeks={weeks}
                                        weekCount={weekCount}
                                        businessUnit={businessUnit}
                                        forecasts={forecasts}
                                        loading={loading}
                                        error={error}
                                        buLevel={0}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Card>
    )
} */

const getForecastByStartDate = (forecasts, weekStartDate) => {
    const forecast = forecasts.find((forecast, i) => {
        // for the first week, compare ony dates 
        // for other weeks, compare dates with start of week
        const projectWeekStartDate = i === 0 ?
            moment(forecast.startDate).format("YYYY-MM-DD") :
            moment(forecast.startDate).startOf('isoWeek').format("YYYY-MM-DD");

        return weekStartDate === projectWeekStartDate;
    });

    return forecast;
}
//  testing
export const getRiskName = (risk) => {
    if (risk >= 16) return "H";
    else if (risk > 4) return "M";
    else if (risk >= 0) return "L";
}

export const getRiskNameForTopBu = (risk) => {
    if (risk > 150) return "H";
    else if (risk > 49) return "M";
    else if (risk >= 0) return "L";
}

export default ChangeHeatMap;