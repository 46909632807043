import PBIPortfolioEmbed from "./pbiPortfolioEmbed";

export const ChangeImpactReport = (props) => {
    return (
        <PBIPortfolioEmbed
            title="Change Impact"
            reportId='421050e3-5165-4937-8598-a9e41a955361'
            pageName='ReportSectionfd3854d236626ec924e4'
            resource='Report'
            url='change_impact_portfolio'
        />
    )
}

export default ChangeImpactReport;