// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
// import ErrorCard from '../../utils/ErrorCard';
// import { TokenStorage } from "../../utils/TokenStorage";
import CustomerList from '../customer/customerList';
import UserList from './userList';


// const UserManagement = (props) => {
//     const user = TokenStorage.getUserDetails();
//     const { isSuperAdmin, isCustomerAdmin } = user ? user : {};
//     const isNonAdminUser = !user.isSuperAdmin && !user.isCustomerAdmin ? true : false;
//     console.log(props);
//     console.log(isNonAdminUser);
//     if (isSuperAdmin) {
//         return <CustomerList {...props} />
//     }

//     if (isCustomerAdmin) {
//         return <UserList {...props} />
//     }
//     if (isNonAdminUser) {
//         return <UserList {...props} />
//     }

//     return <ErrorCard message="You do not have permission to this page" align="center" />
// }

// export default {
//     list: UserManagement,
//     icon: AccountBalanceIcon,
// };

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Suspense, lazy } from 'react';
// import { Loading } from '../../utils/AppUtils';
import ErrorCard from '../../utils/ErrorCard';
import { TokenStorage } from "../../utils/TokenStorage";
import { Loading } from 'react-admin';
// import LoadingFallback from './LoadingFallback'; // Your loading fallback component

// Lazy-loaded components
// const CustomerList = lazy(() => import('../customer/customerList'));
// const UserList = lazy(() => import('./userList'));


const UserManagement = (props) => {
    const user = TokenStorage.getUserDetails();
    const { isSuperAdmin, isCustomerAdmin } = user ? user : {};
    const isNonAdminUser = !user.isSuperAdmin && !user.isCustomerAdmin ? true : false;

    if (isSuperAdmin) {
        return (
            // <Suspense fallback={<Loading />}>
                <CustomerList {...props} />
            // </Suspense>
        );
    }

    if (isCustomerAdmin || isNonAdminUser) {
        return (
            // <Suspense fallback={<Loading />}>
                <UserList {...props} />
            // </Suspense>
        );
    }

    return <ErrorCard message="You do not have permission for this page" align="center" />;
}

export default {
    list: UserManagement,
    icon: AccountBalanceIcon,
};
