import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from "react";
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    ReferenceArrayInput,
    ReferenceInput,
    TextInput,
    maxLength,
    required,
    useDataProvider, useNotify,
    useRefresh
} from "react-admin";
import { Form } from 'react-final-form';
import { onFailureMessage } from "../../utils/AppUtils";
import CustomDrawer from "../customComponents/customDrawer";

const useStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        bottom: 0,
        marginBottom: 40,
        width: 360
        // padding: 16
    },
    formWidth: {
        maxWidth: 350
    }
});

export const UserEditDrawer = ({ record, drawerOpen, onClose,handleSnackbarOpen}) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const [initialValues, setInitialValues] = React.useState();
    const classes = useStyles();

    const resource = "User"
    const onSubmit = (values, form) => {
        dataProvider
            .update(resource, {
                id: record.id,
                data: { ...values, status: true }
            })
            .then((response) => {
                form.initialize({});
                form.restart();
                onClose(false);
                handleSnackbarOpen("User updated successfully","success");
                refresh();
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error");
            });
    }

    const validate = () => {

    }

    React.useEffect(() => {
        if (record && record.id)
            dataProvider.getOne(resource, { id: record.id }).then(response => {
                setInitialValues({
                    ...response.data,
                    businessUnitId: response.data.businessUnit ? response.data.businessUnit.id : null,
                    roles: _.map(response.data.roles, 'id')
                })
            })
    }, [record])

    return (
        <CustomDrawer drawerAnchor="right" title="Edit User" drawerOpen={drawerOpen} onClose={onClose}>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                    <form className={classes.formWidth} onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="First Name"
                                    source="firstName"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Last Name"
                                    source="surname"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Email"
                                    source="email"
                                    type="email"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ReferenceInput
                                    fullWidth
                                    variant="outlined"
                                    label="Business Unit"
                                    source="businessUnitId"
                                    reference="BusinessUnit"
                                    validate={[required()]}>
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12}>
                                <ReferenceArrayInput
                                    fullWidth
                                    variant="outlined"
                                    label="Role"
                                    source="roles"
                                    reference="Role"
                                    validate={[required()]}>
                                    <AutocompleteArrayInput optionText="name" />
                                </ReferenceArrayInput>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.toolbar}>
                                    <Button type="submit" variant="contained" color="primary" size="large" >Submit</Button>
                                    <Button type="button" variant="outlined" color="primary" size="large" onClick={() => onClose(false)} >Cancel</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </CustomDrawer>
    )
}

export default UserEditDrawer;