import {
    Box, Button, CircularProgress,
    Paper,
    TextField
} from "@material-ui/core";
import { makeStyles, MuiThemeProvider, Theme, useTheme } from "@material-ui/core/styles";
import {Grid, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import {  useSafeSetState, useTranslate } from "ra-core";
import { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { Notification, PasswordInput, TextInput, useAuthProvider } from "react-admin";
import { Form } from "react-final-form";
import { Link, useHistory, useLocation } from 'react-router-dom';
// import cat_logo from "../../assets/images/cat_logo.png";
import loginImage from '../../assets/images/loginImage.png';
// import theme from '../../framework/theme';
import Carousel from 'react-material-ui-carousel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import page1 from '../../assets/images/Page1 Image.svg';
import page2 from '../../assets/images/Page2 Image.svg';
import page3 from '../../assets/images/Page3 Image.svg';
import page4 from '../../assets/images/Page4 Image.svg';
import page5 from '../../assets/images/Page5 Image.svg';
import cat_logo from "../../assets/images/cat_logo.png";
import backgroundImages from '../../assets/images/FrontPageBackgroundTexture.svg';
import { Height } from "@mui/icons-material";
import { ThemeContext } from "../../framework/theme/themeContext";
import cat_logo_white from "../../assets/images/cat_logo_white.png";

interface Props {
    redirectTo?: string;
}

interface FormData {
    username: string;
    password: string;
}

const images = [
    {
        label: 'What is the CMO Tool?',
        description: "The <b>Change Management Organisational</b> Tool (CMO) supports Organisations, Projects and Change and Project Management practitioners in planning, managing and reporting on change management projects.",
        imgPath: `${page1}`
    },
    {
        label: 'Change Maturity and Saturation',
        description: "A core component of the CMO is the ability to measure any Business Unit’s Maturity and Saturation across the organisation. Learn how ready you are for change.",
        imgPath: `${page2}`
    },
    {
        label: 'Capture your Entire Portfolio',
        description: "Capture, forecast and track each project your organisation is undertaking. Undertake Change Impact Assessments, build Change Heatmaps and manage your resources to plan your next steps.",
        imgPath: `${page3}`
    },
    {
        label: 'Share Data Quickly',
        description: "From an executive one-pager, down to detailed Change Impact reports, output your data in a format that works for your audience.",
        imgPath: `${page4}`
    },

    {
        label: 'Want to Learn More?',
        description: "The CMO has been developed by experienced change management profesionals, senior leaders and executives.",
        imgPath: `${page5}`
    },
];

const useStyles = makeStyles(
    (theme: Theme) => ({
        form: {
            padding: "0 1em 1em 1em",
        },
        input: {
            marginTop: "0em",
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        mainLogo: {
            fontSize: 72,
            color: "#828282",
            fontFamily: "Aldrich",
            fontWeight: "normal",
            margin: 0,
            marginBottom: 50,
        },
        fieldOptions: {
            width: 400,
            marginBottom: 16,
            borderColor: "#E1E1E1 !important",
            borderRadius: 8,
            "&::placeholder": {
                color: "#FFF",
            },
        },
        buttonOptions: {
            width: 400,
            borderRadius: 5,
        },
        fieldTags: {
            // color: "#B5B5B5",
            textAlign: "left !important",
            color: "#000",
            fontSize: 16,
            fontFamily: "Roboto",
            fontWeight: "500",
            margin: 0,
            marginBottom: 5,
        },
        buttonDiv: {
            display: 'flex',
            justifyContent: 'center'
        },
        loginRoot: {
            backgroundImage: `url(${backgroundImages})`,
            size: "cover",
            repeat: "no-repeat",
            position: "center",
            // width: "100vw",
            margin: "0 auto",
            height: '100vh',
            opacity: "1",
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                overflow: 'auto'
            }
        },
        loginBox: {
            // display: "flex",
            // justifyContent: "space-evenly",
            // alignItems: "center",
            // maxWidth: "70vw",
            // height: '70%',
            // maxWidth: "100%",
            width: '75%',
            paddingLeft: "10px",
            paddingRight: "10px",
            margin: "0 auto",
            boxShadow: ' 0px 0px 10px 0px rgba(0,0,0,0.2)',
            // maxHeight: "80%",
            [theme.breakpoints.down('sm')]: {
                marginBottom: "50px"
            }
            // height: "80vh"
        }
    }),
    { name: "RaLoginForm" }
);

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

/**
 * Log a user in by calling the authProvider.login() method
 *
 * @param {Object} params Login parameters to pass to the authProvider. May contain username/email, password, etc
 * @param {string} pathName The path to redirect to after login. By default, redirects to the home page, or to the last page visited after disconnection.
 *
 * @return {Promise} The authProvider response
 */
type LoginType = (params: any, pathName?: string) => Promise<any>;

const useLogin = (): LoginType => {
    const authProvider = useAuthProvider();
    const location = useLocation();
    const locationState = location.state as any;
    const history = useHistory();

    const login = useCallback(
        (params: any = {}) =>
            authProvider.login(params).then(user => {
                console.log(user);
                // First time logger redirect to change password
                if (user.isFirstTimeLogin) {
                    history.push("/change_password");
                }
                // Super admin should land to client list after sign in
                else if (user.isSuperAdmin) {
                    history.push("/user");
                }
                // other user should land to dashboard after sign in
                else {
                    history.push("/");
                }
                return user;
            }),
        [authProvider, history]
    );

    return login;
};

type ErrorType = { username?: string, password?: string };

const Login: FunctionComponent<Props> = (props) => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const [anchorOrigin, setAnchorOrigin] = useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const {vertical, horizontal} = anchorOrigin;
    const [popupMessage, setPopupMessage] = useState({
        message: "",
        type: ""
    });
    const login = useLogin();
    const translate = useTranslate();
    const {themeObject, setTheme} = useContext(ThemeContext)
    const classes = useStyles(props);
    // const theme = useTheme();

    const [open, setOpen] = useState(false);

    const handleClick = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    useEffect(() => {
        setTheme({ id: "Blue",
            img: cat_logo_white})
    },[])
    const screenBelowMd = useMediaQuery(themeObject.breakpoints.down('md'));

    const validate = (values: FormData) => {
        const errors: ErrorType = { username: undefined, password: undefined };

        if (!values.username) {
            errors.username = translate("ra.validation.required");
        }
        if (!values.password) {
            errors.password = translate("ra.validation.required");
        }
        return errors;
    };

    const submit = (values) => {
        setLoading(true);
        login(values, redirectTo)
            .then(() => {
                setLoading(false);
                // notify("login successful!", { type: 'success' })
                handleClick("Login successful!", "success")
            })
            .catch((error) => {
                setLoading(false);
                const message = typeof error === "string" ? error
                : typeof error === "undefined" || !error.message
                    ? "ra.auth.sign_in_error"
                    : error.message
                    handleClick(message,"warning");
            });
    };


    return (
        <MuiThemeProvider theme={themeObject}>
            <Box className={classes.loginRoot}>
                <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', height: '100%'}}>
                    <Paper className={classes.loginBox} sx={{ flexDirection: { xs: "column", md: "row" } }}>
                        <Grid container sx={{ alignItems: "center"}} alignItems="center" justifyContent="center" py={5}>
                            <Grid item md={6} py={{ xs: 10, md: 0, lg:0 }} sx={{ borderRight: { xs: "none", md: "1px solid #E1E1E1" },width: "100%", height: '100%', px: "10px" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                                    <Box width={200} mb={5}>
                                        <img src={cat_logo} width="100%" alt="Change Assessment Tool Logo" />
                                    </Box>
                                    <Form
                                        onSubmit={submit}
                                        validate={validate}
                                        render={({ handleSubmit }) => (
                                            <form onSubmit={handleSubmit} noValidate style={{ padding: screenBelowMd ? "20px" : "25px" }} >
                                                <Box>
                                                    <h5 className={classes.fieldTags} >Username</h5>
                                                    <Box >
                                                        <TextInput
                                                            style={{ width: screenBelowMd ? "100%" : "100%" }}
                                                            fullWidth
                                                            id="username"
                                                            name="username"
                                                            source="email"
                                                            label=""
                                                            placeholder={translate("ra.auth.username")}
                                                            disabled={loading}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: "#E1E1E1",
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: "normal",
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <h5 className={classes.fieldTags}>Password</h5>
                                                    <div >
                                                        <PasswordInput
                                                            style={{ width: screenBelowMd ? "100%" : "100%" }}
                                                            fullWidth
                                                            id="password"
                                                            name="password"
                                                            source="password"
                                                            label=""
                                                            component={Input}
                                                            placeholder={translate("ra.auth.password")}
                                                            type="password"
                                                            disabled={loading}
                                                            autoComplete="current-password"
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: "#E1E1E1",
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: "normal",
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </Box>
                                                <div>
                                                    <Button
                                                        style={{ width: screenBelowMd ? "100%" : "25rem" }}
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={loading}
                                                        disableElevation
                                                    >
                                                        {loading && (
                                                            <CircularProgress
                                                                className={classes.icon}
                                                                size={18}
                                                                thickness={2}
                                                            />
                                                        )}
                                                        {translate("Login")}
                                                    </Button>
                                                </div>
                                                <Box p={2} textAlign="right">
                                                    <Link style={{ color: '#00a0d9', textDecorationColor: '#00a0d9' }}
                                                        to={{
                                                            pathname: '/forgot_password',
                                                        }}
                                                    >
                                                        <small>Forgot your password?</small>
                                                    </Link>
                                                </Box>
                                            </form>
                                        )}
                                    />
                                </Box>
                            </Grid>

                            <Grid item md={6} px={{ xs: 2, md: 10, lg: 10 }} py={{ xs: 10, md: 0, lg:0 }} sx={{height: '100%', width: "100%" }}>
                                <Carousel
                                    fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
                                    navButtonsAlwaysInvisible
                                    navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                        style: {
                                            backgroundColor: '#00a0d9',
                                            borderRadius: 0,
                                            display: "hidden"
                                        }
                                    }}
                                    navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                                        style: {
                                            bottom: '0',
                                            top: 'unset'
                                        }
                                    }}
                                // NextIcon='next'             // Change the "inside" of the next button to "next"
                                // PrevIcon='prev'
                                >
                                    {
                                        images.map((item, i) => <Item key={i} item={item} />)
                                    }
                                </Carousel>
                            </Grid>
                            <Notification />
                        </Grid>
                    </Paper>
                </Box>
            </Box>


            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{vertical, horizontal }} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={popupMessage.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                   {popupMessage.message}
                </Alert>
            </Snackbar>
        </MuiThemeProvider>
    );
};

Login.propTypes = {
    redirectTo: PropTypes.string,
};

function Item(props) {
    return (
        <Box style={{ display: "flex", justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            {/* <h2>{props.item.name}</h2> */}
            <img src={`${props.item.imgPath}`} alt={props.item.label} style={{ height: "280px", width: "100%" }} />
            <h4>{props.item.label}</h4>
            <p style={{ height: "80px", textAlign: "center" }} dangerouslySetInnerHTML={{ __html: props.item.description }} />
        </Box>
    )
}

export default Login;

