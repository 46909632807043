import { hsv2rgb, rgbString } from '@kurkle/color';
import { Box, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import { TextInput, } from "react-admin";
import { TokenStorage } from "./TokenStorage";
var FileSaver = require("file-saver");

/**
 *
 * @param str
 * @returns {void | string | *}
 */
export const toTitleCase = (str) =>
  str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

/**
 *Saves the file to local machine with file extension provided by the content_type
 *
 * @param disposition returned from http response
 * @param content_type returned from http response
 * @param response The response mut be of type blob
 * @param filename [filename=file name from the {response}] - filename.
 */
export const saveFile = (disposition, content_type, response, filename) => {
  if (disposition && disposition.indexOf("attachment") !== -1) {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(disposition);
    if (!filename && matches != null && matches[1])
      filename = matches[1].replace(/['"]/g, "");
  }
  let type = content_type;

  let blob;
  if (typeof File === "function") {
    try {
      blob = new File([response], filename, { type: type });
    } catch (e) {
      /* Edge */
    }
  }
  if (typeof blob === "undefined") {
    blob = new Blob([response], { type: type });
  }

  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    let URL = window.URL || window.webkitURL;
    let downloadUrl = URL.createObjectURL(blob);

    if (filename) {
      // use HTML5 a[download] attribute to specify filename
      let a = document.createElement("a");
      // safari doesn't support this yet
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    } else {
      window.location.href = downloadUrl;
    }

    setTimeout(function () {
      URL.revokeObjectURL(downloadUrl);
    }, 100); // cleanup
  }
};

/**
 *
 * @param locales {string} [locales='en-IN']
 * @param currency {!string} A string, but never null.
 * @param amount {!number} [amount=0] A number, but never null.
 * @returns {string}
 */
export const toCurrency = ({
  locales = "en-IN",
  currency,
  amount = 0,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0,
}) => {
  //TODO use navigator.language for locales
  if (currency)
    return new Intl.NumberFormat(locales, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    }).format(amount);
  else
    return new Intl.NumberFormat(locales, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    }).format(amount);
};

/**
 *Saves the file to local machine with file extension provided by the content_type
 *
 * @param disposition returned from http response
 * @param content_type returned from http response
 * @param response The response mut be of type blob
 * @param filename [filename=file name from the {response}] - filename.
 */
export const openFile = (disposition, content_type, response, filename) => {
  let type = content_type;
  let blob;
  if (typeof File === "function") {
    try {
      blob = new File([response], filename, { type: type });
    } catch (e) {
      /* Edge */
    }
  }
  if (typeof blob === "undefined") {
    blob = new Blob([response], { type: type });
  }

  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    let URL = window.URL || window.webkitURL;
    let fileURL = URL.createObjectURL(blob);

    if (filename) {
      //Open the URL on new Window
      window.open(fileURL);
    } else {
      window.location.href = fileURL;
    }

    setTimeout(function () {
      URL.revokeObjectURL(fileURL);
    }, 100); // cleanup
  }
};

export const getUnAvailablePermissions = (requiredPermissions) => {
  const permissions = TokenStorage.getPermissions();
  if (permissions) {
    const permissionsSet = new Set(permissions);
    var unAvailablePermissions = requiredPermissions.filter(
      (x) => !permissionsSet.has(x)
    );

    return unAvailablePermissions;
  }

  return requiredPermissions;
};

export const getAvailablePermissions = (requiredPermissions) => {
  const permissions = TokenStorage.getPermissions();
  if (permissions) {
    const permissionsSet = new Set(permissions);
    var availablePermissions = requiredPermissions.filter((x) =>
      permissionsSet.has(x)
    );

    return availablePermissions;
  }

  return null;
};

/**
 * returns data in json format for the provided arraybuffer data
 * @param {*} arraybuffer
 */
export const ArrayBufferToJson = (arraybuffer) => {
  if (arraybuffer) {
    const uint8Array = new Uint8Array(arraybuffer);
    return JSON.parse(String.fromCharCode.apply(null, uint8Array));
  }
  return null;
};

/**
 *
 * @param {*} str
 */
export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const sanitizeJson = (object) => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object") {
      sanitizeJson(v);
    }
    if (
      (v && typeof v === "object" && !Object.keys(v).length) ||
      v === null ||
      v === undefined
    ) {
      delete object[k];
    }
  });
  return object;
};

/**
 *
 * @param {*} obj
 * @returns
 */
export const removeEmptyOrNull = (obj) => {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] && typeof obj[k] === "object" && removeEmptyOrNull(obj[k])) ||
      (!obj[k] && obj[k] !== undefined && delete obj[k])
  );
  return obj;
};

/**
 *
 * @param {*} error
 * @returns
 */
export const onFailureMessage = (error) => {
  let error_messages = "";
  if (
    error.response.status < 500 &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.length > 0
  ) {
    const errors = error.response.data.errors;
    error_messages = _.map(errors, (error) => error.message).join(", ");
  } else {
    error_messages = "Something went wrong!!..";
  }
  return error_messages;
};

/**
 *
 * @param {*} response
 * @param {*} responseStatusCode
 */
export const formatResponse = (response, responseStatusCode) => {
  switch (responseStatusCode) {
    case "BAD_USER_INPUT":
      response.status = 400;
      response.statusText = null;
      break;
    case "UNAUTHENTICATED":
      response.status = 401;
      response.statusText = null;
      break;
    case "INTERNAL_SERVER_ERROR":
      response.status = 500;
      response.statusText = null;
      break;
    default:
      response.status = 400;
      response.statusText = null;
      break;
  }
};

/**
 *
 * @param {*} array
 */
export function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

/**
 *
 * @param {*} dateStartString
 * @param {*} dateEndString
 * @returns
 */
export const getAllMonthsBetweenDates = (
  dateStartString,
  dateEndString,
  format = "YYYY-MM"
) => {
  let dateStart = moment(dateStartString);
  let dateEnd = moment(dateEndString);
  let interim = dateStart.clone();
  let timeValues = [];

  while (dateEnd > interim || interim.format("M") === dateEnd.format("M")) {
    timeValues.push(interim.format(format));
    interim.add(1, "month");
  }
  return timeValues;
};

/**
 *
 * @param {*} date
 * @returns
 */
const weekOfMonth = (date = moment()) => {
  const firstDayOfMonth = date.clone().startOf("month");
  const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");

  const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

  return Math.ceil((date.date() + offset) / 7);
};

export const startEndDatesOfWeeks = (startDate, endDate) => {
  let weeks = [];
  let tempStartDate = startDate.clone();

  let startDateOfWeek = tempStartDate.startOf("isoWeek").clone();
  let endDateOfWeek = tempStartDate.endOf("isoWeek").clone();

  if (startDate > startDateOfWeek) {
    startDateOfWeek = startDate;
  }

  weeks.push({
    startDate: startDateOfWeek.format("YYYY-MM-DD"),
    endDate: endDateOfWeek.format("YYYY-MM-DD"),
  });

  while (endDate.isAfter(endDateOfWeek)) {
    startDateOfWeek = tempStartDate.add(1, "weeks").startOf("isoWeek").clone();
    endDateOfWeek = tempStartDate.endOf("isoWeek").clone();

    if (endDate < endDateOfWeek) {
      endDateOfWeek = endDate;
    }

    weeks.push({
      startDate: startDateOfWeek.format("YYYY-MM-DD"),
      endDate: endDateOfWeek.format("YYYY-MM-DD"),
    });
  }

  return weeks;
};

export const hasPermissions = async (permissions, args = {}) => {
  const condition = {};

  if (args.name) condition.name = args.name;
  if (args.resource) condition.resource = args.resource;
  if (args.action) condition.action = args.action;
  condition.allow = true;

  const allowedPermissions = _.filter(permissions, condition);
  if (args.resource === "Change Assessment") {
    console.log(
      "*******88",
      allowedPermissions && allowedPermissions.length > 0 ? true : false
    );
    console.log("&&&&&&&&&&", permissions);
  }
  return allowedPermissions && allowedPermissions.length > 0 ? true : false;
};

export const getWeeksCount = (momentStartDate, momentEndDate) => {
  const startWeekNumber = momentStartDate.startOf("month").isoWeek();
  const endWeekNumber = momentEndDate.endOf("month").isoWeek();
  return endWeekNumber - startWeekNumber + 1;
};

export const saveFileFromBinary = (s, fileName) => {
  let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  let view = new Uint8Array(buf); //create uint8array as viewer
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  saveAs(
    new Blob([view], { type: "application/octet-stream" }),
    `${fileName}.xlsx`
  );
};

export const saveChangeReport = (props) => {
  // base64 to pdf
  const byteCharecters = atob(props);
  const byteNumbers = new Array(byteCharecters.length);
  for (let i = 0; i < byteCharecters.length; i++) {
    byteNumbers[i] = byteCharecters.charCodeAt(i);
  }
  const byteArr = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArr], { type: "application/octet-stream" });
  const fileName = "export.pdf";
  FileSaver.saveAs(blob, fileName);
};

// the following random color generator can generate 1024 random colors with i < 10
function* hueGen() {
  yield 0;
  for (let i = 1; i < 10; i++) {
    const d = 1 << i;
    for (let j = 1; j <= d; j += 2) {
      yield j / d;
    }
  }
}

export function* chartRandomColorGenerator(repeat = 1) {
  const hue = hueGen();
  let h = hue.next();
  while (!h.done) {
    let rgb = hsv2rgb(Math.round(h.value * 360), 0.6, 0.8);
    for (let i = 0; i < repeat; i++) {
      yield { background: rgbString({ r: rgb[0], g: rgb[1], b: rgb[2], a: 192 }), border: rgbString({ r: rgb[0], g: rgb[1], b: rgb[2], a: 144 }) };
    }
    rgb = hsv2rgb(Math.round(h.value * 360), 0.6, 0.5);
    for (let i = 0; i < repeat; i++) {
      yield { background: rgbString({ r: rgb[0], g: rgb[1], b: rgb[2], a: 192 }), border: rgbString({ r: rgb[0], g: rgb[1], b: rgb[2], a: 144 }) };
    }
    h = hue.next();
  }
}

export const handleProjectInfo = (projectInfo, page, setProjectInfo) => {

  const formatDate = (date) => {
    return moment(date).format('DD-MM-YYYY');
  };

  const { name, startDate, goLiveDate, endDate } = projectInfo;

  const projectInfoArray = [];

  if (name) {
    projectInfoArray.push(`Project: ${name}`);
  }

  if (startDate) {
    projectInfoArray.push(`Start Date: ${formatDate(startDate)}`);
  }

  if (endDate) {
    projectInfoArray.push(`End Date: ${formatDate(endDate)}`);
  }

  if (goLiveDate) {
    projectInfoArray.push(`Go Live Date: ${formatDate(goLiveDate)}`);
  }

  const projectInfoString = projectInfoArray.join(' | ');
  if (page >= 1) {
    setProjectInfo(projectInfoString)
  } else {
    setProjectInfo("")
  }
};
export const Loading = ({ }) => (
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress color="success" variant="determinate" value={75} style={{ color: '#108fb5' }} size={70} sx={{ mb: 3 }} />
      <Box>
        <Typography variant="h4" sx={{ color: '#3c3c3d' }} align='center'>Loading</Typography>
        <Typography variant="h6" sx={{ color: '#3c3c3d' }} align='center'>This page is loading a while</Typography>
      </Box>
    </Box>
  </Box>
)

export const impersonatedURL = (isImpersonating, url) => {
  if (isImpersonating) {
    window.location.href = window.location.origin + window.location.pathname + url;
  } else {
    const currentURL = window.location.href;
    const updatedURL = url.replace(/\?impersonated$/, '');
    window.location.href = window.location.origin + window.location.pathname + updatedURL;
  }
};

export const StyledTextInput = styled(TextInput)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: 'white',
    // width: '280px',
    padding: 0,
    fontWeight: 600,
    color: '#000',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&.MuiIconButton-root': {
    borderRadius: 5,
    // padding: theme.spacing(1),
  },
}));

export const currentDate = moment().format(' YYYYMMDD');

export const getFormatExtension = (format) => {
  const extensions = {
    "Word": ".docx",
    "PPT": ".pptx",
    "Excel": ".xlsx",
    "PDF": ".pdf"
  };
  return extensions[format] || '';
}

export function groupByAction(data) {
  const result = {
    ViewGroup: [],
    EditGroup: []
  };

  data.forEach(item => {
    if (item.action.length === 1 && item.action[0] === "view") {
      result.ViewGroup.push(item);
    } else {
      result.EditGroup.push(item);
    }
  });

  return result;
}

export const groupPermissions = (groupChoices, rolePermissions) => {
  const groupIds = groupChoices.map(permission => permission.id);
  const permissions = rolePermissions.filter(permission => groupIds.includes(permission.id)).map(permission => permission.id);
  return permissions;
};

export const getClientId = () => {
  const user = TokenStorage.getUserDetails();
  const impersonateUser = TokenStorage.getImpersonateUserDetails();
  return impersonateUser ? impersonateUser.id : user.isCustomerAdmin ? user.id : user.customerAdminId;
}

export const wordpressEndpoint = async (endpoint) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_WORDPRESS_URL}`, {
        method: 'POST',
        body: JSON.stringify(endpoint),
      }).then(async (response) => {
        const clonedResponse = response.clone();
        const responseData = await clonedResponse.json();
        resolve(responseData);
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

export const downloadPDF = (base64Data, fileName) => {
  // Decode the Base64 string
  const binaryString = window.atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the decoded data
  const blob = new Blob([bytes], { type: 'application/pdf' });
  FileSaver.saveAs(blob, fileName);
}

export const transformToPowerBIFilter = (input) => {
  const filterMap = new Map();

  input.forEach(item => {
    const target = item.identity[0].target;
    const table = target.table;
    const column = target.column;
    const equalsValue = item.identity[0].equals;

    // Create a unique key for the map
    const key = `${table}:${column}`;

    // If the key doesn't exist in the map, initialize it
    if (!filterMap.has(key)) {
      filterMap.set(key, {
        table,
        column,
        "values": []
      });
    }

    filterMap.get(key).values.push(equalsValue);
  });

  // Convert the map values to an array
  return Array.from(filterMap.values());
}

const yearVisual = ["1657a28683098678f3cd", "760169a9d0f6939d11fc",]

export const pbiFilterNoDuplicate = (allFilter, selectedFilter, visualName) => {
  if (!selectedFilter.length) {
    const isYearSelected = yearVisual.includes(visualName)
    if (isYearSelected) {
      const filter = allFilter.filter(item => !(item.identity[0].target.column === "Year"))
      return filter;
    } else {
      const filter = allFilter.filter(item => !(item.identity[0].target.column !== "Year"))
      return filter;
    }
  }
  // if (!selectedFilter.length) {
  //   return [];
  // }
  const target = selectedFilter[0].identity[0].target;
  const table = target.table;
  const column = target.column;

  const prev = allFilter.filter(item => !(item.identity[0].target.table === table && item.identity[0].target.column === column))
  const finalArr = [...prev, ...selectedFilter];
  // Convert back to array of objects
  return finalArr;
}