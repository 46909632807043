import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    chipfield_yes: {
        height: 20,
        borderRadius: 11,
        backgroundColor: theme.palette.customsColor.success,
        color: "#FFF",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
        width: "fit-content",
        // margin: "0 auto",
        fontSize: 10,
    },
    chipfield_status_yes: {
        height: 20,
        borderRadius: 11,
        backgroundColor: theme.palette.customsColor.secondary,
        color: "#FFF",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
        width: "fit-content",
        // margin: "0 auto",
        fontSize: 10,
    },
    chipfield_no: {
        height: 20,
        borderRadius: 11,
        backgroundColor: theme.palette.error.contrastText,
        color: theme.palette.error.main,
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
        width: "fit-content",
        // margin: "0 auto",
        border: "1px solid",
        borderColor: theme.palette.error.main,
        fontSize: 10,
        "&.disabled": {
            color: "#828282",
            borderColor: "#828282",
        },
    },
    centerChip: {
        display: 'flex',
        justifyContent: 'center',
    }
}));

export const ManageChip = ({ record, inactiveChip }) => {
    const classes = useStyles();
    const choice = record;
    return (
        <div className={classes.centerChip}>
            <Chip
                label={choice.name}
                // size="small"
                className={(choice.id === true || choice.id === "yes") ? classes.chipfield_yes : clsx(classes.chipfield_no, { "disabled": inactiveChip })}
            />
        </div>
    );
};
export const ManageChipForStatus = ({ record, inactiveChip }) => {
    const classes = useStyles();
    const choice = record;
    return (
        <div className={classes.centerChip}>
            <Chip
                label={choice.name}
                // size="small"
                className={(choice.id === true || choice.id === "yes") ? classes.chipfield_status_yes : clsx(classes.chipfield_no, { "disabled": inactiveChip })}
            />
        </div>
    );
};
