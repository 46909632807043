export const PERMISSION_CREATE_MUTATION = `mutation(
    $name: String!
    $description: String
    $resource: String!
    $action: [String!]!
  ) {
    createPermission(
      name: $name
      description: $description
      resource: $resource
      action: $action
    ) {
      id
      name
      description
      resource
      action
      allow
    }
  }`

export const PERMISSION_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: PermissionFilter!
  ) {
    allPermissions(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        name
        description
        resource
        action
        allow
      }
      totalCount
    }
  }`


export const PERMISSION_RETRIEVE_QUERY = `query($id: Int!) {
    permission(id: $id) {
        id
        name
        description
        resource
        action
        allow
    }
  }`

export const PERMISSION_UPDATE_MUTATION = `mutation(
    $id: Int!
    $name: String!
    $description: String
    $resource: String!
    $action: [String!]!
  ) {
    updatePermission(
      id: $id
      name: $name
      description: $description
      resource: $resource
      action: $action
    ) {
        id
        name
        description
        resource
        action
        allow
    }
  }`

export const PERMISSION_DELETE_MUTATION = `mutation($id: Int!) {
    deletePermission(id: $id)
  }`