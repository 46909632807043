import React from "react";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";
import { useHistory } from "react-router-dom";
import { Box, Button, CardMedia, Typography } from "@material-ui/core";
import not_found_logo from "../assets/images/not_found_logo.png";
import HistoryIcon from "@mui/icons-material/History";

const NotFound = () => {
  let history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <Title title="404: Page not found" />
        <CardMedia
          component="img"
          height="100%"
          width="100%"
          image={not_found_logo}
          alt="Not Found Page Image"
        />

        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h3"
            style={{ textAlign: "center", fontWeight: "bold", color: "gray" }}
          >
            Not Found
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              color: "gray",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            Either you typed a wrong URL, or you followed a bad link.
          </Typography>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            variant="secondary"
            color="secondary"
            startIcon={<HistoryIcon />}
            onClick={() => history.goBack(-1)}
          >
            Go Back
          </Button>
        </CardContent>
      </Box>
    </div>
  );
};

export default NotFound;
