import React from 'react';

import { Box, Divider, Typography, useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import arrayMutators from "final-form-arrays";
import {
    AutocompleteInput,
    Loading,
    RadioButtonGroupInput,
    ReferenceInput,
    TextInput,
    Title,
    maxLength, required,
    useDataProvider,
    useNotify,
    usePermissions,
    useRefresh
} from "react-admin";
import { Field, Form, useForm } from "react-final-form";
import { StyledTextInput, impersonatedURL, onFailureMessage } from '../../utils/AppUtils';
import { TokenStorage } from '../../utils/TokenStorage';
import WithPermission from '../../utils/WithPermission';
import moment from 'moment';
import { Alert, Snackbar } from '@mui/material';


const useStyles = makeStyles({
    root: {
        backgroundColor: "#FFFFFF",
        borderRadius: '5px',
        padding: '15px',
        // borderTop: '2px solid #B1b1b1'
    },
    actionToolbar: {
        display: 'flex',
        marginTop: 40,
        justifyContent: 'flex-end'
    },
    textAreaInput: {
        minHeight: '60px',
        lineHeight: 1.5
    },
    title: {
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    }
});

export const GovernanceAssessments = (props) => {
    const { record, activeStep, handleBack, handleNext, steps, setIsUpdateProjectDetails } = props
    const [initialValues, setInitialValues] = React.useState();
    const user = TokenStorage.getUserDetails();
    const dataProvider = useDataProvider();

    const refresh = useRefresh();
    const classes = useStyles();
    const localProjectDetails = TokenStorage.getProjectDetails()
    const [governanceLoading, setGovernanceLoading] = React.useState(false);
    const [businessReadlines, setBusinessReadliness] = React.useState([])
    const resource = "BusinessReadliness";
    const [draftStatus, setDraftStatus] = React.useState(false);
    const { isImpersonating, isCustomerAdmin } = user ? user : {};
    const { loading: loadingPermissions, permissions } = usePermissions();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const isXS = useMediaQuery((theme) => theme.breakpoints.down("xs"));
    impersonatedURL(isImpersonating, '#/governance?impersonated');

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setSnackbarOpen(true);
    };

    const hanndleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = React.useState({
        message: "",
        type: ""
    });

    const { vertical, horizontal } = anchorOrigin;
    React.useEffect(() => {

        dataProvider
            .postRequest("BusinessReadliness", {
                queryType: "list",
                data: {
                    "type": "Governance"
                }
            })
            .then((response) => {
                setBusinessReadliness(response.data);
            })
            .catch((error) => {
                console.log(error)
            });


    }, [])
    const handleProjectChange = async (projectId) => {
        setGovernanceLoading(true)
        const businessReadlinesListByuser = await dataProvider
            .postRequest("BusinessReadliness", {
                queryType: "retrieve",
                data: {
                    "projectId": projectId,
                    "type": "Governance"
                }
            })
        const governances = businessReadlinesListByuser.data.filter(read => read.type === 'Governance');
        const governanceOverall = businessReadlinesListByuser.data.filter(read => read.type === 'GovernanceOverall')
        // console.log( businessReadlinesListByuser.data)

        businessReadlinesListByuser.data = businessReadlinesListByuser.data.length > 0 ? governances : businessReadlines;
        setInitialValues({
            projectId: projectId, businessReadlinesListByuser: businessReadlinesListByuser
                .data,
            governanceOverall: governanceOverall.length === 0 ? [{ projectId, name: "Change Governance Assessment", assessment: "", commentary: "", type: `GovernanceOverall` }] : governanceOverall
            , assessmentChoice: [{ id: "Yes", name: "Yes" }, { id: "No", name: "No" }, { id: "N/A", name: "N/A" }]
        })
        setGovernanceLoading(false)
    }
    const onSubmit = (values, form, status) => {
        const item = values.governanceOverall[0];
        const { id, createdAt, ...rest } = item;
        rest['projectId'] = values.projectId;
        const businessReadlines = {
            projectId: values.projectId,
            type: "Governance",
            readinessGovernanceAssessments: values.businessReadlinesListByuser.filter(buread => Boolean(buread)).map(buread => ({
                name: buread.name,
                assessment: buread.assessment,
                commentary: buread?.commentary || "",
                type: "Governance",
                projectId: values?.projectId
            }))
        }
        businessReadlines.readinessGovernanceAssessments.push(rest)
        dataProvider
            .postRequest(resource, {
                queryType: "create",
                data: businessReadlines,
            })
            .then(async (response) => {
                console.log(response)
                handleSnackbarOpen("Governance assessment updated successfully", "success");
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error")
            });
    };
    const validate = () => { };
    return (
        <WithPermission resource="Change Assessment" permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <Title title="Change Governance Assessment" className={classes.title} style={{ fontSize: isSmall ? "16px" : "20px" }} />
            {
                <Box mr={!isXS && 4}>
                    <Form
                        onSubmit={onSubmit}
                        mutators={{
                            // potentially other mutators could be merged here
                            ...arrayMutators,
                        }}
                        validate={validate}
                        initialValues={initialValues}
                        render={({
                            handleSubmit,
                            values,
                            form: {
                                mutators: { push, pop },
                            },
                        }) => (
                            <form className={classes.formWidth} onSubmit={handleSubmit}>

                                <Box display="flex" sx={{ alignItems: isSmall ? "flex-start" : "center", justifyContent: "space-between", flexDirection: isSmall ? "column" : "row" }}>
                                    <Box style={{ width: isXS ? "98%" : 483 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={8} xs={12}>
                                                <ReferenceInput
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Project"
                                                    source="projectId"
                                                    reference="Project"
                                                    sort={{ field: 'name', order: 'ASC' }}
                                                    onChange={(value) => {
                                                        handleProjectChange(value);
                                                    }}
                                                    filter={{ isDraft: false, status: true }}
                                                    className={classes.referenceMargin}
                                                >
                                                    <AutocompleteInput optionText="name" />
                                                </ReferenceInput>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography style={{ color: "gray", marginBottom: isSmall && 10 }}>
                                            {initialValues && initialValues.businessReadlinesListByuser && initialValues.businessReadlinesListByuser[0].createdAt && `Updated at ${moment(initialValues?.businessReadlinesListByuser[0]?.createdAt).format("hh:mm:ss a, MM/DD/YYYY")} (UTC)`}
                                        </Typography>
                                    </Box>
                                </Box>
                                {
                                    governanceLoading && <Loading />
                                }
                                {values && values.projectId &&
                                    <div className={classes.root} style={{ width: isSmall ? "90vw" : "100%" }}>
                                        <GovernanceForm
                                            push={push}
                                            // onClose={onClose}
                                            assessmentChoice={initialValues ? initialValues.assessmentChoice : null}
                                            businessReadlinessList={initialValues ? initialValues.businessReadlinesListByuser : null}
                                        />
                                        <div className={classes.actionToolbar}>
                                            <Button
                                                onClick={() => onSubmit(values)}
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </form>
                        )}
                    />
                </Box>
            }
            <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                <Alert

                    onClose={hanndleSnackbar}
                    severity={popupMessage.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {popupMessage.message}
                </Alert>
            </Snackbar>
        </WithPermission>
    )
}


const GovernanceForm = ({ push, onClose, businessReadlinessList, assessmentChoice }) => {
    const classes = useStyles();
    const [businessReadlines, setBusinessReadliness] = React.useState();
    const form = useForm();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const handleAddDimension = () => {
        push("businessReadlines", undefined);
        businessReadlines.push({})
        console.log(push)
        // setMilestones([...milestones, {}])
        // setMilestones(prevMilestones => [...prevMilestones, {}]);
    }
    React.useEffect(() => {
        setBusinessReadliness(businessReadlinessList);
    }, [businessReadlinessList])
    if (businessReadlines)
        return (
            <div>
                <Box style={{ display: isSmall ? "none" : "block" }}>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center" spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <Typography variant='h5' >Dimension Question</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} >
                            <Typography variant='h5'>Assessment</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant='h5'>Commentary</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    {businessReadlines.map((buredline, index) => (
                        Boolean(businessReadlines) && <>
                            <Grid
                                key={`${buredline.name}_${index}`}
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={isSmall ? 0 : 2}
                            >
                                <Grid item xs={12} lg={6}>
                                    <Field fullWidth name={`businessReadlinesListByuser[${index}].name`} component="input">
                                        {(props) => (<Box display="flex">
                                            <Box paddingTop={index < 9 ? "7px" : "15px"} paddingRight="3px"> {index + 1 + "."}</Box>
                                            <StyledTextInput
                                                fullWidth
                                                name={props.input.name}
                                                disabled={index < 9 ? true : false}
                                                initialValue={buredline.name}
                                                value={buredline.name}
                                                onChange={props.input.onChange}
                                                variant="outlined"
                                                label=""
                                                multiline
                                                validate={[required(), maxLength(255)]}
                                                InputProps={{
                                                    style: {
                                                        color: '#000',
                                                        fontWeight: isSmall ? '500' : '400',
                                                        border: index < 9 ? "none" : "1px solid lightgrey",
                                                        padding: index < 9 ? 0 : "10px 7px",
                                                        // width: index < 9 ? "100%" : "300px"
                                                    },
                                                }}
                                            />
                                        </Box>

                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} lg={3} >
                                    <Field fullWidth name={`businessReadlinesListByuser[${index}].assessment`} component="input">
                                        {(props) => (
                                            <RadioButtonGroupInput
                                                variant="outlined"
                                                label=""
                                                source={props.input.name}
                                                row={true}
                                                choices={assessmentChoice}
                                                validate={[required(), maxLength(255)]}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Field fullWidth name={`businessReadlinesListByuser[${index}].commentary`} component="input">
                                        {(props) => (
                                            <TextInput
                                                fullWidth
                                                name={props.input.name}
                                                value={props.input.value}
                                                onChange={props.input.onChange}
                                                variant="outlined"
                                                label="Commentary (max 255 characters)"
                                                validate={[maxLength(255)]}
                                                multiline
                                                // style={{ width: '280px' }}
                                                placeholder=''
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                            {
                                isSmall && <Box sx={{ marginBottom: "12px" }} >
                                    <Divider />
                                </Box>
                            }
                        </>
                    ))}
                    <Box style={{ display: 'flex', justifyContent: 'center', }}>
                        <Button
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={handleAddDimension}
                            color="primary"
                            variant="text"
                        >
                            Add Dimension
                        </Button>
                    </Box>
                </Box>
                <Box style={{ margin: '10px 0' }}>
                    <Divider />
                </Box>
                <Box style={{ backgroundColor: "#E3E5EE", minHeight: "70px", padding: "20px 10px 0px", borderRadius: '10px', marginTop: '20px', border: "1px solid lightgrey" }}>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center" spacing={isSmall ? 0 : 6}>
                        <Grid item xs={12} lg={6} style={{ marginTop: '-15px' }}>
                            <Typography variant='h6'>Is Change Governance in place?</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} style={{ padding: "6px", marginTop: '5px' }}>
                            <RadioButtonGroupInput
                                variant="outlined"
                                label=""
                                source={`governanceOverall[0].assessment`}
                                row={true}
                                choices={assessmentChoice}
                                validate={[required(), maxLength(255)]}
                            // style={{backgroundColor: "#fff", height: '44px', padding: '0 10px'}}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3} style={{ padding: "6px 24px 0px 0px" }}>
                            <TextInput
                                variant="outlined"
                                source={`governanceOverall[0].commentary`}
                                label="Commentary (max 255 characters)"
                                className={classes.inputMargin}
                                fullWidth
                                multiline
                                validate={[maxLength(255)]}
                                InputProps={{
                                    style: {
                                        minHeight: "41px",
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </div>
        )

    return null;
}