import PBIEmbed from "./pbiEmbed";

export const GoNoGoReport = (props) => {
    return (
        <PBIEmbed
            title="Project Go / No-Go Report"
            reportId='31504e2a-80ad-44b4-9142-464fa6bf1031'
            pageName="413e4d27067c0d9009bc"
            resource='Report'
            url='go_no_go_report'
        />
    )
}

export default GoNoGoReport;