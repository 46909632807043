import PBIEmbed from "./pbiEmbed";

export const ChangeGovernanceProjectReport = (props) => {
    return (
        <PBIEmbed
            title="Change Governance Report"
            reportId='31504e2a-80ad-44b4-9142-464fa6bf1031'
            pageName="4428f4f002880d5722e9"
            resource='Report'
            url='change_governance_report'
        />
    )
}

export default ChangeGovernanceProjectReport;