import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import arrayMutators from "final-form-arrays";
import _ from "lodash";
import * as React from "react";
import {
  Error,
  Loading,
  TextInput,
  required,
  useDataProvider,
} from "react-admin";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import {
  FormControlLabel,
  Paper,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { hasDuplicates, onFailureMessage } from "../../utils/AppUtils";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    // maxWidth: 360,
    // backgroundColor: 'pink',
  },
  dataList: {
    width: 350,
    borderRadius: 12,
    backgroundColor: theme.palette.background.paper,
    marginRight: 20,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  dataValueList: {
    width: 832,
    marginLeft: 20,
    borderRadius: 12,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 0,
      marginTop: 15,
    },
  },
  dataValueName: {
    width: 350,
    marginTop: 16,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  dataValueNameSingle: {
    width: 350,
    minHeight: 37,
  },
  dataValueDescription: {
    width: 350,
    paddingTop: 9,
    paddingBottom: 9,
  },
  toolbar: {
    display: "flex",
    width: 350,
    justifyContent: "space-between",
  },
  navPadding: {
    padding: 0,
  },
  listSelected: {
    backgroundColor: "#405189 !important",
  },
  listText: {
    fontWeight: 500,
    color: "#333333",
  },
  listTextSelected: {
    fontWeight: 500,
    color: "#fff",
  },
  secondboxTitle: {
    paddingLeft: 24,
    paddingTop: 14,
  },
  containerBox: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    paddingLeft: 40,
    paddingRight: 72,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  inputBorder: {
    borderRadius: 8,
    borderColor: "#BDBDBD",
  },
  containerBoxTwo: {
    display: "flex",
    paddingLeft: 40,
    paddingRight: 33,
  },
  deleteButton: {
    marginTop: -16,
    marginLeft: 20,
    padding: 0,
    backgroundColor: "transparent !important",
  },
  addfieldButton: {
    backgroundColor: "transparent !important",
    paddingLeft: 40,
    marginBottom: 36,
  },
  buttonFieldsContainer: {
    paddingLeft: 40,
    marginBottom: 35,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexGrow: 1,
      paddingRight: 40,
    },
  },
  cancelButton: {
    marginLeft: 25,
  },
  autoCompleteBox: {
    margin: "10px 20px",
  },
  autocompleteInput: {
    backgroundColor: "#fff",
    // padding: '10px 15px',
    border: "1px solid gray",
    margin: "0 20px",
  },
  autocomplete: {
    margin: "0 20px",
    // width: '200px', // Set your desired width
  },
  input: {
    // border: '1px solid #ccc', // Set your desired border style
    borderRadius: "12px",
    borderColor: "#BDBDBD",
    // padding: '8px',
    // width: "790px",
    minHeight: 37,
  },
}));

const ProjectMasterDataList = (props) => {
  const {handleSnackbarOpen} = props;
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [data, setData] = React.useState();
  const [users, setUsers] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [selectedImpact, setSelectedImpact] = React.useState(1);
  const [error, setError] = React.useState();
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [valueCardData, setValueCardData] = React.useState();
  const [valueCardLoading, setValueCardLoading] = React.useState(false);
  const [valueCardError, setValueCardError] = React.useState();

  const dataProvider = useDataProvider();
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [projectDrivers, setProjectDrivers] = React.useState();
  const [driversId, setDriverId] = React.useState(0);
  const [isDrivers, setIsDriversId] = React.useState(false);
  const handleAutocompleteChange = (event, newValue) => {
    const variables = {
      id: driversId,
      data: {
        clientId: newValue?.id || null,
      },
    };
    dataProvider
      .getOne("MasterDataDriver", variables)
      .then((response) => {
        // setProjectDrivers()
        setIsDriversId(false);
        // console.log(response.data)
        setValueCardData(response.data);
      })
      .catch((error) => {
        handleSnackbarOpen(
          `master data values are unavailable: ${error.message}`,
          "warning"
        );
        setValueCardError(error.message);
        setValueCardLoading(false);
      });
    setSelectedOption(newValue);
  };
  const onSubmit = (values, form) => {
    const masterData = data[selectedIndex];
    // console.log(selectedOption)
    let apiNames = "MasterData";
    let formValues = {
      id: masterData.id,
      name: masterData.name,
      type: masterData.type,
      category: masterData.category,

      nameDescription: values.nameDescription,
      categoryDescription: values.categoryDescription,
      masterDataValues: _.map(values.masterDataValues, (masterDataValue) => ({
        name: masterDataValue.name,
        description: masterDataValue.name,
        isDisabled:
          valueCardData.category === "Phase"
            ? null
            : masterDataValue.isDisabled === true
              ? true
              : false,
      })),
    };
    if (valueCardData.category === "Drivers") {
      apiNames = "MasterDataDriver";
      formValues["clientId"] = selectedOption.id;
    }
    dataProvider
      .update(apiNames, {
        id: masterData.id,
        data: formValues,
      })
      .then((response) => {
        form.initialize(response.data);
        handleSnackbarOpen("Master Data updated successfully", "success");
      })
      .catch((error) => {
        const error_messages = onFailureMessage(error);
        handleSnackbarOpen(error_messages, "error");
      });
  };

  const validate = (values) => {
    const errors = {};

    // if(values.masterDataValues){
    //     let uni
    //     values.masterDataValues.map((masterDataValue, index)=>{
    //         return
    //     })
    // }

    return errors;
  };

  const getMasterData = (master_data_id) => {
    return dataProvider
      .getOne("MasterData", { id: master_data_id })
      .then((response) => {
        setValueCardData(response.data);
        setSelectedImpact(response.data);
        // console.log(response.data);
        setValueCardLoading(false);
      })
      .catch((error) => {
        handleSnackbarOpen(
          `Master data values are unavailable: ${error.message}`,
          "warning"
        );
        setValueCardError(error.message);
        setValueCardLoading(false);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("MasterData", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "name", order: "ASC" },
        filter: { type: "Project" },
      })
      .then((response) => {
        setData(response.data);
        // console.log("datataasfsdfsadfstatatatatat", response.data)
        // select first list item by default
        if (response.data && response.data.length > 0) {
          const default_index = 0;
          setSelectedIndex(default_index);
          getMasterData(response.data[default_index].id);
        }

        setLoading(false);
      })
      .catch((error) => {
        handleSnackbarOpen(`Master data are unavailable: ${error.message}`, "warning");
        setError(error.message);
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    // setLoading(true);
    dataProvider
      .getList("User", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "fullName", order: "ASC" },
        filter: {},
      })
      .then((response) => {
        // console.log(response.data)
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, []);
  const handleListItemClick = (event, newValue, index, master_data_id) => {
    setSelectedIndex(index);
    getMasterData(master_data_id);
    setDriverId(master_data_id);
    setSelectedImpact(newValue);
    if (master_data_id === 1) {
      setIsDriversId(true);
    } else {
      setIsDriversId(false);
    }

    // console.log(master_data_id)
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error title={error} />;
  }

  if (!data || data.length === 0) {
    return (
      <div className={classes.root}>
        <Card className={classes.dataList} style={{ padding: 32 }}>
          No Data Available
        </Card>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {isSmall ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          getOptionLabel={(option) => `${option?.name + ' - ' + option?.category}`}
          options={data}
          value={selectedImpact}
          sx={{ width: "100%" }}
          onChange={(event, newValue) =>
            handleListItemClick(
              event,
              newValue,
              data.indexOf(newValue),
              newValue.id
            )
          }
          renderInput={(params) => (
            <TextField {...params} label="Driver and Phase" />
          )}
        />
      ) : (
        <Card className={classes.dataList}>
          <List
            className={classes.navPadding}
            component="nav"
            aria-label="main mailbox folders"
          >
            {data.map((masterData, index) => (
              <ListItem
                key={index}
                button
                selected={selectedIndex === index}
                classes={{ selected: classes.listSelected }}
                onClick={(event) =>
                  handleListItemClick(event, event, index, masterData.id)
                }
              >
                <ListItemText
                  primary={`${masterData.name} - ${masterData.category}`}
                  className={
                    selectedIndex === index
                      ? classes.listTextSelected
                      : classes.listText
                  }
                />
              </ListItem>
            ))}
          </List>
        </Card>
      )}

      {Boolean(
        selectedIndex !== null && selectedIndex !== undefined && valueCardData
      ) && (
          <Form
            onSubmit={onSubmit}
            mutators={{
              ...arrayMutators,
            }}
            validate={validate}
            initialValues={valueCardData}
            render={({
              handleSubmit,
              form: {
                mutators: { push, pop },
              }, // injected from final-form-arrays above
              pristine,
              form,
              submitting,
              values,
            }) => (
              <Card className={classes.dataValueList}>
                <CardHeader
                  title={
                    valueCardData
                      ? `${valueCardData.name} - ${valueCardData.category}`
                      : null
                  }
                  classes={{
                    title: classes.secondboxTitle,
                  }}
                />

                <Divider style={{ backgroundColor: "#C4C4C4" }} />
                <form onSubmit={handleSubmit}>
                  {valueCardData.category === "Drivers" && (
                    <Box className={classes.autoCompleteBox}>
                      <Autocomplete
                        options={users}
                        getOptionLabel={(option) =>
                          `${option.companyName} - ${option.email} - ${option.location}`
                        }
                        className={classes.autocomplete}
                        value={selectedOption}
                        onChange={handleAutocompleteChange}
                        source="client"
                        id="combo-box-demo"
                        // freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Start typing to search user"
                            className={classes.input}
                            variant="outlined"
                            label="Client"
                            required={required}
                          // error={!selectedOption}
                          // InputProps={{
                          //     ...params.InputProps,
                          //     style: {
                          //       borderColor: !selectedOption ? 'red' : params.InputProps.style.borderColor,
                          //     },
                          //   }}
                          />
                        )}
                        PaperComponent={({ children }) => (
                          <Paper
                            style={{ width: "100%", background: "#f2f5f3" }}
                          >
                            {children}
                          </Paper>
                        )}
                      />
                      {isDrivers && (
                        <Typography variant="h6" style={{ margin: "10px 20px" }}>
                          Please select the client
                        </Typography>
                      )}
                    </Box>
                  )}
                  {!isDrivers &&
                    valueCardData &&
                    valueCardData.masterDataValues ? (
                    <MasterDataValuesInputs
                      loading={valueCardLoading}
                      error={valueCardError}
                      masterData={data[selectedIndex]}
                      data={valueCardData.masterDataValues}
                      category={valueCardData.category}
                      push={push}
                      pristine={pristine}
                      submitting={submitting}
                      form={form}
                    />
                  ) : null}
                </form>
              </Card>
            )}
          />
        )}
    </div>
  );
};

const MasterDataValuesInputs = ({
  loading,
  error,
  data,
  category,
  push,
  submitting,
  pristine,
  form,
}) => {
  const classes = useStyles();
  const [masterDataValues, setMasterDataValues] = React.useState();
  const [isDiabled, setIsDisabled] = React.useState(false);

  const uniqueName = (value, values) => {
    if (!value) return;

    const names = values.masterDataValues
      ? values.masterDataValues.filter(
        (masterDataValue) => masterDataValue && masterDataValue.name === value
      )
      : null;
    if (hasDuplicates(names)) {
      return `found duplicate value "${value}"`;
    }
  };

  React.useEffect(() => {
    setMasterDataValues(data);
  }, [data]);
  const handleToggle = () => {
    setIsDisabled(!isDiabled);
  };
  // console.log(data)
  if (loading) <Loading />;

  if (error) <Error title={error} />;

  if (masterDataValues)
    return (
      <Box paddingTop="30px">
        <FieldArray name="masterDataValues">
          {({ fields }) =>
            fields.map((name, index) => {
              // console.log(fields)
              return (
                <Box className={classes.containerBoxTwo} key={name}>
                  <Field fullWidth name={`${name}.name`}>
                    {(props) => (
                      <TextInput
                        validate={[required(), uniqueName]}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        style={{ margin: 0, marginRight: 10 }}
                        InputProps={{
                          classes: {
                            input: classes.dataValueNameSingle,
                            notchedOutline: classes.inputBorder,
                          },
                        }}
                        onChange={props.input.onChange}
                      />
                    )}
                  </Field>
                  <Box display="flex">
                    {/* {console.log(fields.value[index].isDisabled)} */}
                    {
                      category === "Phase" ? (
                        <IconButton
                          color="inherit"
                          aria-label="remove value"
                          className={classes.deleteButton}
                          onClick={() => {
                            const proceed = window.confirm(
                              "You are deleting the entry. Are you sure?"
                            );
                            if (proceed) {
                              fields.remove(index);
                            }
                          }}
                        >
                          <Tooltip title="Delete">
                            <DeleteIcon style={{ fill: "rgba(0,0,0,0.54" }} />
                          </Tooltip>
                        </IconButton>
                      ) : (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                fields.value[index]?.isDisabled === true
                                  ? false
                                  : true
                              }
                              onChange={() => {
                                fields.update(index, {
                                  ...fields.value[index],
                                  isDisabled: !fields.value[index]?.isDisabled,
                                });
                              }}
                            />
                          }
                          label={
                            fields.value[index]?.isDisabled === true
                              ? "Disabled"
                              : "Enabled"
                          }
                        />
                      )
                      // <Switch
                      //     checked={isDiabled}
                      //     onChange={handleToggle}
                      //     color="primary"
                      //     name="checkedB"
                      //     inputProps={{ 'aria-label': 'primary checkbox' }}
                      // />
                    }
                  </Box>
                </Box>
              );
            })
          }
        </FieldArray>
        <IconButton
          color="inherit"
          aria-label="add value"
          onClick={() => push("masterDataValues", undefined)}
          className={classes.addfieldButton}
        >
          <AddCircleOutlineIcon />
          <span style={{ fontSize: 16, marginLeft: 10 }}>Add Field</span>
        </IconButton>
        <Grid item xs={12}>
          <div className={classes.buttonFieldsContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={submitting || pristine}
            >
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              size="large"
              onClick={form.reset}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Box>
    );

  return <div>No data</div>;
};

export default ProjectMasterDataList;
