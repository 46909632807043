import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import { Loading, Title, useDataProvider, useNotify, usePermissions } from "react-admin";
import powerBiApi, { checkAccessTokenExpiration } from '../../framework/pbiDataProvider';
import { currentDate, downloadPDF, getClientId, impersonatedURL, onFailureMessage, pbiFilterNoDuplicate, transformToPowerBIFilter } from '../../utils/AppUtils';
import { TokenStorage } from '../../utils/TokenStorage';
import WithPermission from '../../utils/WithPermission';
import { Alert, Snackbar } from '@mui/material';
import { Box, Button, CircularProgress } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
var FileSaver = require("file-saver");

function PBIPortfolioEmbed(props) {
    const { reportId, url, resource, title, pageName } = props;
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [pdfFilter, setPdfFilter] = useState([])
    const [buttonLoader, setButtonLoader] = useState(false);
    const dataProvider = useDataProvider();


    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setSnackbarOpen(true);
    };

    const hanndleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = useState({
        message: "",
        type: ""
    });

    const { vertical, horizontal } = anchorOrigin;
    const user = TokenStorage.getUserDetails();
    const impersonateUser = TokenStorage.getImpersonateUserDetails();
    const { isImpersonating, isCustomerAdmin } = user ? user : {};
    impersonatedURL(isImpersonating, `#/${url}?impersonated`);
    const { permissions } = usePermissions();

    const [pbiEmbedConfig, setPbiEmbedConfig] = useState({
        type: 'report',
        id: '',
        embedUrl: "",
        accessToken: "",
        tokenType: models.TokenType.Aad,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                },
                pageNavigation: {
                    visible: false
                }
            },
            // navContentPaneEnabled: false,
            // background: models.BackgroundType.Transparent
        },
        eventHooks: {
            accessTokenProvider: checkAccessTokenExpiration
        }
    })
    //   and BuType/Name in ('Change Management')
    // and Calendar/Year eq 2024 and BuType/Mapping.name
    useEffect(() => {
        powerBiApi("GET", `reports/${reportId}`).then(res => {
            const { id, embedUrl, accessToken } = res;
            setPbiEmbedConfig({ ...pbiEmbedConfig, id, embedUrl: `${embedUrl}&filter=Projects/Client eq ${getClientId()}`, accessToken })
        }).catch(error => {
            console.log(error);
            handleSnackbarOpen("Error in getting report", "error")
        })
    }, []);


    const handleExportPDF = () => {
        setButtonLoader(true);
        dataProvider
            .postRequest("ExportReportPdf", {
                queryType: "retrieve",
                data: {
                    accessToken: TokenStorage.getPbiAccessToken(),
                    reportId: reportId,
                    pageName: pageName,
                    filter: {
                        clientId: getClientId(),
                        // id: defaultProjectId,
                        filterArray: pdfFilter.length > 0 ? transformToPowerBIFilter(pdfFilter) : []
                    }
                }
            }).then(response => {
                downloadPDF(response.data, title + " " + currentDate + ".pdf")
                setButtonLoader(false);
                handleSnackbarOpen("File downloaded", "success");
            })
            .catch(error => {
                setButtonLoader(false);
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error");
            });
    }
    return (
        <WithPermission resource={resource} permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <Title title={title} style={{ textTransform: "uppercase", fontSize: "20px" }} />
            <Box sx={{ textAlign: "right", height: 40, }}>
                <Button color="primary" style={{ width: 80, height: 30, }} onClick={handleExportPDF}>
                    {
                        buttonLoader ? <CircularProgress size={16} /> : <><DownloadIcon fontSize="small" /> Export</>
                    }
                </Button>

            </Box>
            <div>
                {
                    !pbiEmbedConfig.embedUrl ? <Loading /> : <PowerBIEmbed
                        embedConfig={pbiEmbedConfig}
                        eventHandlers={
                            new Map([
                                ['loaded', async function () {
                                    try {
                                        await window.report.setPage(pageName);
                                        // const filtersArray = [
                                        //     {
                                        //         $schema: "http://powerbi.com/product/schema#basic",
                                        //         target: {
                                        //             table: "BuType",
                                        //             column: "Name"
                                        //         },
                                        //         operator: "In",
                                        //         values: ["Corn Production", "Field Crops"]
                                        //     }
                                        // ];

                                        // await window.report.updateFilters(
                                        //     models.FiltersOperations.Replace,
                                        //     []
                                        // );
                                    }
                                    catch (errors) {
                                        console.log(errors);
                                    }
                                }],
                                ["dataSelected", function (event) {
                                    if (event.detail.visual.type === 'slicer') {
                                        setPdfFilter(allFilter => {
                                            const selectedFilter = event.detail.dataPoints;
                                            const visualName = event.detail.visual.name;
                                            const selectedValues = pbiFilterNoDuplicate(allFilter, selectedFilter, visualName)
                                            return selectedValues;
                                        });
                                    }
                                }],
                                ['error', function (event) { console.log(event.detail); }],
                            ])
                        }

                        cssClassName={"Embed-container"}

                        getEmbeddedComponent={(embeddedReport) => {
                            window.report = embeddedReport;
                        }}
                    />
                }

            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                <Alert

                    onClose={hanndleSnackbar}
                    severity={popupMessage.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {popupMessage.message}
                </Alert>
            </Snackbar>
        </WithPermission>
    );
}

export default PBIPortfolioEmbed;
