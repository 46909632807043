import { useMediaQuery } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import {
  createTheme,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from '@mui/material/Tooltip';
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { Loading, Title, useDataProvider, usePermissions } from "react-admin";
import { Tree, TreeNode } from "react-organizational-chart";
import { TokenStorage } from "../../utils/TokenStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(255,255,255,0.1)",
    display: "inline-block",
    borderRadius: 16
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "#ECECF4"
  },
  horizontalScroll: {
    overflowX: "scroll"
  },
  title: {
    textTransform: "uppercase",
    fontSize: "20px" // Adjust the font size as needed
  }
}));

function Organization({ org, onCollapse, collapsed }) {
  const classes = useStyles();

  const isActive = true;
  let backgroundColor = "rgba(255,255,255,0.1)";
  if (isActive) {
    backgroundColor = "rgba(255,255,255,0.1)";
  }
  return (
    <Card
      variant="outlined"
      className={classes.root}
      style={{ backgroundColor: "#e1e2e3" }}
    >
      <CardHeader
        avatar={
          <Badge
            style={{ cursor: "pointer" }}
            color="secondary"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            showZero
            invisible={!collapsed}
            overlap="circle"
            badgeContent={_?.size(org?.childBU)}
            onClick={onCollapse}
          >
            <Avatar className={classes.avatar}>
              <BusinessIcon color="primary" />
            </Avatar>
          </Badge>
        }
        title={org?.buName}
      />
      <Tooltip title="Expand">
        <IconButton
          size="small"
          onClick={onCollapse}
          className={clsx(classes.expand, {
            [classes.expandOpen]: !collapsed
          })}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Tooltip>
    </Card>
  );
}

function Node({ o, parent }) {
  const [collapsed, setCollapsed] = React.useState(o?.collapsed);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  React.useEffect(() => {
    o.collapsed = collapsed;
  });
  const T = parent
    ? TreeNode
    : (props) => (
      <Tree
        {...props}
        lineWidth={"2px"}
        lineColor={"#bbc"}
        lineBorderRadius={"12px"}
      >
        {props?.children}
      </Tree>
    );
  return collapsed ? (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    />
  ) : (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    >
      {_.map(o?.childBU, (c) => (
        <Node o={c} parent={o} />
      ))}
    </T>
  );
}
const theme = createTheme({
  palette: {
    background: "#ECECF4"
  },
  fontFamily: "Roboto, sans-serif"
});


export default function BusinessUnitTreeView(props) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [data, setData] = React.useState();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const dataProvider = useDataProvider();
  const { loading: loadingPermissions, permissions } = usePermissions();
  const classes = useStyles();

  const user = TokenStorage.getUserDetails();
  const { isSuperAdmin, isCustomerAdmin } = user ? user : {};

  React.useEffect(() => {
    setLoading(true)
    dataProvider
      .postRequest("AllBusinessUnitsTree", {
        queryType: "retrieve",
        data: {},
      })
      .then(({ data }) => {
        if (data && data?.length) {
          setData(data);
          setError(null);
        } else {
          setData(null);
          setError("no data found");
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setError("error in getting data")
      });
  }, [dataProvider])


  let buHolder = {};

  if (data !== undefined) {

    data.map((element) => {

      //element[0] is parent BU and element[1] is child BU
      let parentBU = {};
      if (buHolder[element[0]]) {
        parentBU = buHolder[element[0]];
      } else {
        parentBU = { "buName": element[0] };
        buHolder[element[0]] = parentBU;
      }

      if (element[0] !== element[1]) {
        let childBU = [];
        if (parentBU["childBU"]) {
          childBU = parentBU["childBU"];
        } else {
          parentBU["childBU"] = childBU;
        }

        let childObject = { "buName": element[1] }
        childBU.push(childObject);
        buHolder[element[1]] = childObject;
      }
    });
  }

  if (loading) return <Loading />

  if (error) return (
    <Card>
      <Box color={theme.palette.error.main} p={2}>{error}</Box>
    </Card>
  )

  let horizontalScroll;
  if (isSmall) {
    horizontalScroll = classes.horizontalScroll;
  }

  return (
    <ThemeProvider theme={theme}>
      <Title title="Business Units" className={classes.title} />

      <Box bgcolor="#fff" padding={4} >
        <Card className={horizontalScroll} >
          <Node o={buHolder[`${data[0][0]}`]} />
        </Card>
      </Box>
    </ThemeProvider>
  );
}
