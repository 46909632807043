export const ROLE_CREATE_MUTATION = `mutation(
    $name: String!
    $description: String
    $parentId: Int
    $permissions: [Int!]!
  ) {
    createRole(
      name: $name
      description: $description
      parentId: $parentId
      permissions: $permissions
    ) {
      id
      name
      description
      parentId
      permissions{
          id
          name
      }
    }
  }`

export const ROLE_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: RoleFilter!
  ) {
    allRoles(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        name
        description
        parentId
        permissions{
            id
            name
        }
      }
      totalCount
    }
  }`


export const ROLE_RETRIEVE_QUERY = `query($id: Int!) {
    role(id: $id) {
      id
      name
      description
      permissions{
          id
          name
      }
    }
  }`

export const ROLE_UPDATE_MUTATION = `mutation(
    $id: Int!
    $name: String!
    $description: String
    $parentId: Int
    $permissions: [Int!]!
  ) {
    updateRole(
      id: $id
      name: $name
      description: $description
      parentId: $parentId
      permissions: $permissions
    ) {
      id
      name
      description
      parentId
      permissions{
          id
          name
      }
    }
  }`

export const ROLE_DELETE_MUTATION = `mutation($id: Int!) {
    deleteRole(id: $id)
  }`