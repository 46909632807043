import { Box, Typography, useMediaQuery } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import * as React from "react";
import { cloneElement } from "react";
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  useDataProvider,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  currentDate,
  impersonatedURL,
  onFailureMessage,
  saveFileFromBinary,
} from "../../utils/AppUtils";
import ButtonWithPermission from "../../utils/ButtonWithPermission";
import PostPagination from "../../utils/PostPagination";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import MoreActions from "../customComponents/moreActions";
import BusinessUnitCreateDrawer from "./businessUnitCreateDrawer";
import BusinessUnitEditDrawer from "./businessUnitEditDrawer";
import BusinessUnitTreeView from "./businessUnitTreeView";
import { Alert, IconButton, Snackbar } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ThemeContext } from "../../framework/theme/themeContext";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  headerCell: {
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "blue",
      display: "none",
    },
  },
}));

const ListActions = (props) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { themeObject, setTheme } = React.useContext(ThemeContext)
  const {
    className,
    exporter,
    filters,
    maxResults,
    handleCreateDrawerOpen,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  const dataProvider = useDataProvider();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({ message: name, type: type })
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  const [anchorOrigin, setAnchorOrigin] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = React.useState({
    message: "",
    type: ""
  });

  const { vertical, horizontal } = anchorOrigin;

  const handleExportBtnClick = () => {
    dataProvider
      .postRequest("ExportBUs", {
        queryType: "retrieve",
      })
      .then((response) => {
        saveFileFromBinary(response.data, "Business Units" + currentDate);
      })
      .catch((error) => {
        handleSnackbarOpen("No data found to export", "warning")
      });
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* <Button style={{ marginRight: 8 }} label="Export" variant="contained" color="primary" onClick={handleExportBtnClick}>{<DownloadIcon fontSize='small' />}{isSmall ? '' : `Export`}</Button> */}

      <Button
        variant="contained"
        onClick={handleExportBtnClick}
        sx={{
          width: isSmall ? "0px" : "150px",
          marginRight: "8px",
          backgroundColor: themeObject.palette.primary.main,
          boxShadow: "0",
          height: "41px",
          paddingX: "0",
          "&:hover": {
            backgroundColor: themeObject.palette.primary.buttonContrast,
            color: "white",
            cursor: "pointer",
            boxShadow: "0",
          },
        }}
      >
        {isSmall ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0",
              paddingLeft: "2px",
              borderBottomRightRadius: "5px",
              borderTopRightRadius: "5px",
              backgroundColor: "rgba(255,255,255,0.2)",
              fontSize: "16px",
              width: "100%",
              height: "41px",
            }}
          >
            <FileDownloadOutlinedIcon style={{ marginLeft: "-4px" }} />
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              {"Export"}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                paddingLeft: "2px",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
                backgroundColor: "rgba(255,255,255,0.2)",
                fontSize: "16px",
                width: "41px",
                height: "41px",
              }}
            >
              <FileDownloadOutlinedIcon style={{ marginLeft: "-4px" }} />
            </Box>
          </Box>
        )}
      </Button>
      <CreateBusinessUnitBtn {...props} />
      <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
        <Alert

          onClose={hanndleSnackbar}
          severity={popupMessage.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {popupMessage.message}
        </Alert>
      </Snackbar>
    </TopToolbar>
  );
};

const BusinessUnitFilter = (props) => (
  <Filter {...props}>
    <SearchInput variant="outlined" source="q" alwaysOn />
  </Filter>
);

const CreateBusinessUnitBtn = (props) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { isCustomerAdmin, permissions, isSuperAdmin, handleCreateDrawerOpen } =
    props;
  return (
    <ButtonWithPermission
      permissions={permissions}
      resource="BusinessUnit"
      bypassUser={isCustomerAdmin}
      action={["create"]}
      variant="contained"
      onClick={() => handleCreateDrawerOpen(true)}
      endIcon={<AddIcon style={{ marginLeft: "-4px" }} />}
    >
      {"Add Business Unit"}
    </ButtonWithPermission>
  );
};

const Empty = (props) => {
  const { basePath, resource } = useListContext();
  return (
    <Box textAlign="center" m={1} p={1}>
      <Typography variant="h4" paragraph>
        No Business Unit yet.
      </Typography>
      <Typography variant="body1">Do you want to add one?</Typography>
      <Box mt={1}>
        <CreateBusinessUnitBtn {...props} />
      </Box>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTabs = withStyles({
  root: {
    maxWidth: "85vw",
    // marginBottom: "15px",
    // padding: "10px 0 0 10px",
    // backgroundColor: "#fff"
    // borderBottom: '2px solid #ccc', // Border for all tabs
  },
  indicator: {
    display: "none",
    justifyContent: "center",
    // backgroundColor: "transparent",
    // height: 5,
    // "& > span": {
    //     maxWidth: 80,
    //     width: "100%",
    //     backgroundColor: "#EFF2F7"
    // },
  },
  "& .Mui-selected": {
    borderBottom: "10px solid #f0f", // Remove border for the active tab
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#000",
    fontWeight: theme.typography.fontWeightRegular,
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "14px",
    padding: "0 20px",
    "&:focus": {
      opacity: 1,
    },
    minWidth: 97,
    // paddingLeft: 0,
    // paddingRight: 0,
    borderBottom: "2px solid #cdd0d1",
    "&:not($selected)": {
      // borderBottomColor: '#ccc', // Add border for unselected tabs
    },
    "&$selected": {
      // borderTop: "2px solid #0Ab39C",
      // marginBottom: '2px',
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      // paddingBottom: "-10px",
      fontWeight: "600",
      borderBottom: "none",
      // borderTop: "2px solid #cdd0d1",
      // borderRight: "2px solid #cdd0d1",
      // borderLeft: "2px solid #cdd0d1",
      // backgroundColor: "#EFF2F7", // Change this to the color you desire
      backgroundColor: "#FFFFFF", // Change this to the color you desire
      color: "#000", // Change this to the color you desire for the text
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
export const BusinessUnitTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="BU Different View Types"
      >
        <StyledTab label="List View" {...a11yProps(0)} />
        <StyledTab label="Tree View" {...a11yProps(1)} />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <BusinessUnitList {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box height={200}>
          {" "}
          <BusinessUnitTreeView {...props} />
        </Box>
      </TabPanel>
    </div>
  );
};

export const BusinessUnitList = (props) => {
  const [createDrawerOpen, setCreateDrawerOpen] = React.useState();
  const [editDrawerOpen, setEditDrawerOpen] = React.useState();
  const [record, setRecord] = React.useState();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { loading: loadingPermissions, permissions } = usePermissions();
  const user = TokenStorage.getUserDetails();
  const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};
  impersonatedURL(isImpersonating, "#/BusinessUnit?impersonated");


  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({ message: name, type: type })
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  const [anchorOrigin, setAnchorOrigin] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = React.useState({
    message: "",
    type: ""
  });

  const { vertical, horizontal } = anchorOrigin;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const handleCreateDrawerOpen = (open) => {
    setCreateDrawerOpen(open);
  };

  const handleCreateDrawerClose = () => {
    setCreateDrawerOpen(false);
  };

  const handleEditDrawerOpen = (open) => {
    setEditDrawerOpen(open);
  };

  const handleEditDrawerClose = (open) => {
    setRecord(null);
    setEditDrawerOpen(open);
  };

  const rowActionsChoices = [
    { id: "edit", name: "Edit", resource: "BusinessUnit", action: ["edit"] },
    {
      id: "delete",
      name: "Delete",
      resource: "BusinessUnit",
      action: ["delete"],
    },
  ];
  const handleEditBU = (record) => {
    setRecord(record);
    handleEditDrawerOpen(true);
  };
  const handleDeleteBU = (record) => {
    const proceed = window.confirm("You are deleting the entry. Are you sure?");
    if (proceed) {
      if (!record.parentBusinessUnitId) {
        handleSnackbarOpen("This business unit can't be deleted", "error")
        return;
      }
      dataProvider
        .delete("BusinessUnit", { id: record.id })
        .then((response) => {
          handleSnackbarOpen("Business Unit deleted successfully", "success")
          refresh();
        })
        .catch((error) => {
          const error_messages = onFailureMessage(error);
          handleSnackbarOpen(error_messages, "error")
        });
    }
  };
  const handleMoreActionsClick = ({ action, record }) => {
    switch (action) {
      case "edit":
        setRecord(record);
        handleEditDrawerOpen(true);
        break;
      case "delete":
        const proceed = window.confirm(
          "You are deleting the entry. Are you sure?"
        );
        if (proceed) {
          if (!record.parentBusinessUnitId) {
            handleSnackbarOpen("This business unit can't be deleted", "error")
            break;
          }
          dataProvider
            .delete("BusinessUnit", { id: record.id })
            .then((response) => {
              handleSnackbarOpen("Business Unit deleted successfully", "success")
              refresh();
            })
            .catch((error) => {
              const error_messages = onFailureMessage(error);
              handleSnackbarOpen(error_messages, "error")
            });
        }
        break;
    }
  };
  const ActionButtonSM = () => {
    const records = useRecordContext();
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton
          aria-label="edit"
          className={classes.IconButtonOverride}
          size="small"
        >
          <EditIcon
            size="small"
            style={{
              color: "#00A0D9",
              cursor: "pointer",
              margin: "0 5px",
            }}
            onClick={() => handleEditBU(records)}
          />
        </IconButton>
        <IconButton
          aria-label="delete"
          className={classes.IconButtonOverride}
          size="small"
        >
          <DeleteForeverIcon
            size="small"
            style={{ color: "#de0937", cursor: "pointer" }}
            onClick={() => handleDeleteBU(records)}
          />
        </IconButton>
      </Box>
    );
  };
  return (
    <WithPermission
      resource="BusinessUnit"
      permissions={permissions}
      bypassUser={isCustomerAdmin}
      action={["view"]}
      {...props}
    >
      <List
        {...props}
        title={
          <span style={{ fontSize: "20px", textTransform: "uppercase" }}>
            Business Units
          </span>
        }
        hasCreate={false}
        empty={
          <Empty
            isCustomerAdmin={isCustomerAdmin}
            isSuperAdmin={isSuperAdmin}
            permissions={props.permissions}
            handleCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
        actions={
          <ListActions
            isCustomerAdmin={isCustomerAdmin}
            isSuperAdmin={isSuperAdmin}
            permissions={props.permissions}
            handleCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
        filters={<BusinessUnitFilter />}
        bulkActionButtons={false}
        pagination={<PostPagination />}
      >
        {isSmall ? (
          <Datagrid classes={{ headerCell: classes.headerCell }}>
            {/*<TextField source="id" sortable={false}/>*/}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField source="name" sortable={true} />
              <TextField
                color="secondary"
                source="geography"
                sortable={false}
              />
              <ActionButtonSM />
            </div>
          </Datagrid>
        ) : (
          <Datagrid>
            {/*<TextField source="id" sortable={false}/>*/}
            <TextField source="name" sortable={true} />
            <TextField label="Location" source="geography" sortable={false} />
            <TextField
              label="Parent BU"
              source="parentBusinessUnit.name"
              sortable={false}
            />
            {/* <TextField label="FTE" source="fte" sortable={false} /> */}
            <MoreActions
              choices={rowActionsChoices}
              bypassUser={isCustomerAdmin}
              permissions={props.permissions}
              handleClose={() => console.log("closing")}
              handleClick={handleMoreActionsClick}
              sortable={false}
            />
          </Datagrid>
        )}
      </List>

      <BusinessUnitCreateDrawer
        record={record}
        drawerOpen={createDrawerOpen}
        onClose={handleCreateDrawerClose}
        handleSnackbarOpen={handleSnackbarOpen}
      />
      <BusinessUnitEditDrawer
        record={record}
        drawerOpen={editDrawerOpen}
        onClose={handleEditDrawerClose}
        handleSnackbarOpen={handleSnackbarOpen}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
        <Alert

          onClose={hanndleSnackbar}
          severity={popupMessage.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {popupMessage.message}
        </Alert>
      </Snackbar>
    </WithPermission>
  );
};

export default BusinessUnitTabs;
