import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import {
    SelectArrayInput,
    TextInput,
    maxLength,
    required,
    useDataProvider
} from "react-admin";
import { Form } from 'react-final-form';
import { ACTIONS_CHOICES } from "../../framework/constants";
import { onFailureMessage } from "../../utils/AppUtils";
import CustomDrawer from "../customComponents/customDrawer";


const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        // padding: 16
    },
});

export const PermissionEditDrawer = ({ record, drawerOpen, onClose,handleSnackbarOpen}) => {
    const dataProvider = useDataProvider();
    const [initialValues, setInitialValues] = React.useState();
    const classes = useStyles();

    const resource = "Permission"
    const onSubmit = (values, form) => {
        dataProvider
            .update(resource, {
                id: record.id,
                data: values
            })
            .then((response) => {
                form.initialize({});
                form.restart();
                onClose(false);
                handleSnackbarOpen("Permission updated successfully","success");
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error");
            });
    }

    const validate = () => {

    }

    React.useEffect(() => {
        if (record && record.id)
            dataProvider.getOne(resource, { id: record.id }).then(response => {
                setInitialValues(response.data)
            })
    }, [record])

    return (
        <CustomDrawer drawerAnchor="right" title="Edit Permission" drawerOpen={drawerOpen} onClose={onClose}>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    source="name"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Description"
                                    source="description"
                                    multiline
                                    validate={[maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Resource"
                                    source="resource"
                                    multiline
                                    validate={[maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectArrayInput
                                    fullWidth
                                    variant="outlined"
                                    label="Actions"
                                    source="action"
                                    choices={ACTIONS_CHOICES}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.toolbar}>
                                    <Button type="submit" variant="contained" color="primary" size="large" >Submit</Button>
                                    <Button type="button" variant="outlined" color="primary" size="large" onClick={() => onClose(false)} >Cancel</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </CustomDrawer>
    )
}

export default PermissionEditDrawer;