import { Button, IconButton, makeStyles } from "@material-ui/core";
import { Alert, Box, Snackbar } from "@mui/material";
import { useState, useRef, useEffect, DependencyList, useContext } from "react";
import { Title, useNotify } from "react-admin";
import { CustomsThemePalette } from "../../framework/constants";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css';
import { ThemeContext } from "../../framework/theme/themeContext";
import dataProvider from "../../framework/dataProvider";
import { getClientId, onFailureMessage } from "../../utils/AppUtils";
import cat_logo_white from "../../assets/images/cat_logo_white.png";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "uppercase",
    },
    nonAccordionCard: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    title: {
        textTransform: "uppercase",
        // fontSize: "20px" // Adjust the font size as needed
    },
    input: {
        display: 'none',
    },
    uploadButton: {
        // height: '60px',
        // width: '140px',
        padding: "10px 5px",
        borderRadius: "2px"
    }
}));


const ThemeCustomization = () => {
    const classes = useStyles();
    const [themeConfig, setThemeConfig] = useState({})
    const [isShowButton, setIsShowButton] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSnackbarOpen = (name, type) => {
      setPopupMessage({message: name, type: type})
      setSnackbarOpen(true);
    };
  
    const hanndleSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
  
      setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = useState({
      vertical: 'top',
      horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = useState({
      message: "",
      type: ""
    });
  
    const {vertical, horizontal} = anchorOrigin;
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const hiddenAnchorRef = useRef(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(16 / 9)
    const [defaultTheme, setDefaultTheme] = useState({});
    const [defaultLogo, setDefaultLogo] = useState("");
    const { themeObject, setTheme, theme } = useContext(ThemeContext)
    useEffect(() => {
        const variables = {
            data: {
                clientId: getClientId(),
            },
        };
        dataProvider
            .getOne("ThemeCustomize", variables)
            .then((response) => {
                const oldState = { ...theme };
                oldState['id'] = response.data.theme;
                if (response.data.url) {
                    oldState['img'] = `data:image/png;base64,${response.data.url}`;
                }
                // console.log(res)
                setTheme(oldState)
                setDefaultTheme(response.data);
                setThemeConfig(response.data.theme)
                setIsShowButton(false)
                // setTheme({ id: response.data.theme, img: `data:image/png;base64,${response.data.url}` })
                if (response.data.url) {
                    console.log(response.data)
                    setDefaultLogo(`data:image/png;base64,${response.data.url}`)
                }
            })
            .catch((error) => {
                console.log(error.message)
                setThemeConfig('Blue')
            });
    }, [])


    const handleChooseTheme = (item) => {
        setThemeConfig(item.name)
        setIsShowButton(true)
    }


    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setIsShowButton(true)
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || '')
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    async function onDownloadCropClick() {
        const image = imgRef.current;


        let canvas;
        let base64Image;
        if (image) {

            // making blob to canvas images 
            canvas = document.createElement('canvas');
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = completedCrop.width;
            canvas.height = completedCrop.height;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(
                image,
                completedCrop.x * scaleX,
                completedCrop.y * scaleY,
                completedCrop.width * scaleX,
                completedCrop.height * scaleY,
                0,
                0,
                completedCrop.width,
                completedCrop.height
            );

            base64Image = canvas.toDataURL('image/png').split(',')[1];
        }
        // console.log(base64Image)

        if (defaultTheme?.url || defaultTheme?.theme) {

            dataProvider.update('ThemeCustomize', {
                data: {
                    id: defaultTheme.id,
                    clientId: getClientId(),
                    url: base64Image ? base64Image : defaultTheme?.url,
                    theme: themeConfig
                }
            }).then((res) => {
                const oldState = { ...theme };
                handleSnackbarOpen("Theme update successfully", "success");
                oldState['id'] = res.data.theme;
                if (res.data.url) {
                    oldState['img'] = `data:image/png;base64,${res.data.url}`;
                }
                // console.log(res)
                setTheme(oldState)
                setImgSrc(null)
                setDefaultTheme(res.data)
                setIsShowButton(false)
                if (res.data.url) {
                    setDefaultLogo(`data:image/png;base64,${res.data.url}`)
                }

            }).catch((err) => {
                console.log(err.message)
            })
        }
        else {
            dataProvider.create('ThemeCustomize', {
                data: {
                    clientId: getClientId(),
                    url: base64Image,
                    theme: themeConfig
                }
            }).then((res) => {
                // console.log(res)
                const oldState = { ...theme };
                oldState['id'] = res.data.theme;
                if (res.data.url) {
                    oldState['img'] = `data:image/png;base64,${res.data.url}`;
                }
                // console.log(res)
                setTheme(oldState)
                setImgSrc(null)
                setDefaultTheme(res.data)
                setIsShowButton(false)
                handleSnackbarOpen("Theme update successfully", "success");
                if (res.data.url) {
                    setDefaultLogo(`data:image/png;base64,${res.data.url}`)
                }
            }).catch((err) => {
                console.log(err.message)
            })
        }

        // return new Promise((resolve, reject) => {
        //     canvas.toBlob((blob) => {
        //         if (!blob) {
        //             console.error('Canvas is empty');
        //             return;
        //           }

        //           const url = URL.createObjectURL(blob);
        //           const a = document.createElement('a');
        //           a.href = url;
        //           a.download = 'cropped.jpg';
        //           document.body.appendChild(a);
        //           a.click();
        //           document.body.removeChild(a);
        //           URL.revokeObjectURL(url);
        //     }, 'image/jpeg');

        // });

    }

    const handleDeleteLogo = (id) => {
        dataProvider
            .delete("ThemeCustomize", { id: id })
            .then((response) => {
                handleSnackbarOpen("Logo deleted successfully", "success");
                console.log(response.data.data.delteThemeCustomization)
                setDefaultTheme(response.data.data.delteThemeCustomization)
                setDefaultLogo("")
                //   refresh();
                setTheme({ id: response.data.data.delteThemeCustomization.theme, img: cat_logo_white })
            })
            .catch((error) => {
                handleSnackbarOpen(error.message, "error");
            });
    }


    return (
        <div>
            <Title title="Theme Customization" className={classes.title} />
            <Box display="flex" justifyContent="left" alignItems="start" flexDirection={'column'} mb={2}>
                <Box display="flex" justifyContent={"left"} alignItems={"start"} flexDirection={"column"}>
                    <p style={{ paddingRight: "8px", marginBottom: "4px" }}>Your Company logo</p>
                    {
                        defaultLogo &&
                        <Box>
                            <img src={defaultLogo} style={{ width: "120px", height: "120px", margin: "5px 0" }} />
                        </Box>
                    }
                    {
                        defaultTheme?.url ? <Button onClick={() => handleDeleteLogo(defaultTheme?.id)} className={classes.uploadButton} variant="text" color="primary" component="span" startIcon={<DeleteForeverIcon />}>
                            Delete Logo
                        </Button> : <>  <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={onSelectFile}
                        />
                            <label htmlFor="contained-button-file">

                                <Button className={classes.uploadButton} variant="contained" color="primary" component="span" startIcon={<PhotoCamera />}>
                                    Upload Logo
                                </Button>
                            </label>
                        </>
                    }
                </Box>

                <Box>

                    {!!imgSrc && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            // aspect={aspect}
                            // minWidth={400}
                            minHeight={100}
                            style={{ margin: "15px 0 10px 0" }}
                        // circularCrop
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: "400px", maxWidth: "400px", margin: "5px 0" }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                </Box>

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: "column", flexWrap: 'wrap' }}>
                <div style={{ display: "flex", alignItems: 'start' }}>
                    <p>Choose Theme Color</p>
                    {/* <div style={{ margin: "0 12px", borderRadius: "50%", width: "45px", height: "45px", border: '3px solid #d3d3d3', display: "flex", alignItems: 'center', justifyContent: "center" }}>
                        <div style={{ backgroundColor: "#028FC2", cursor: 'pointer', width: "30px", height: "30px", padding: "10px",borderRadius: "50%", }}></div>
                    </div> */}
                </div>
                <div style={{ display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                    {
                        CustomsThemePalette.map((item, index) => (
                            <Box sx={{ borderRadius: "50%", width: "45px", height: "45px", border: themeConfig == item.name ? '3px solid #d3d3d3' : "0px", display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                <Box key={index} sx={{
                                    margin: "8px 5px", cursor: 'pointer',
                                    backgroundColor: `${item.color}`, width: "30px", height: "30px", borderRadius: "50%", padding: themeConfig == item.name ? "10px" : "0",
                                }} onClick={() => handleChooseTheme(item)}>

                                </Box>
                            </Box>
                        ))
                    }
                </div>
            </Box>
            {isShowButton && <Box display={"flex"} justifyContent={"start"} mt={5}>
                <Button variant="contained" color="primary" onClick={onDownloadCropClick}>Save Changes</Button>
            </Box>}

            <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                <Alert

                    onClose={hanndleSnackbar}
                    severity={popupMessage.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {popupMessage.message}
                </Alert>
            </Snackbar>
        </div>
    );
};



// function useDebounceEffect(
//     fn,
//     waitTime,
//     deps
// ) {
//     useEffect(() => {
//         const t = setTimeout(() => {
//             fn.apply(undefined, deps)
//         }, waitTime)

//         return () => {
//             clearTimeout(t)
//         }
//     }, deps)
// }

export default ThemeCustomization;
