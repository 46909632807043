import { IconButton } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { RiskAssessmentModalTable } from '../impactAssesment/RiskAssessmentModalTable';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "500px",
  },
  paperImage: {

    backgroundColor: theme.palette.background.paper,
    border: '1px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "95%",

  },
}));
export const RiskassementModal = ({ open, content, title, handleClose, tableResponse }) => {
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}

      >
        {/* <Fade in={open}> */}
        {
          content != undefined ? <div className={classes.paper}>
            <Box style={{ display: 'flex', justifyContent: "space-between" }}>
              <h3 id="transition-modal-title">{title}</h3>
              <IconButton disableFocusRipple={true} disableRipple={true} aria-label="info" onClick={handleClose} style={{ backgroundColor: "transparent" }}>
                <CloseOutlinedIcon className={classes.outlineIcon} sx={{ "&:hover": { color: "#000" } }} />
              </IconButton>
            </Box>

            <p id="transition-modal-description">{content}</p>
          </div>
            :
            <div className={classes.paperImage}>
              <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                <IconButton aria-label="info" onClick={handleClose} disableFocusRipple={true} disableRipple={true} style={{ backgroundColor: "transparent", }} >
                  <CloseOutlinedIcon className={classes.outlineIcon} sx={{ "&:hover": { color: "#000" } }} />
                </IconButton>
              </div>
              {/* <img src={backgroundLogo} alt='Forget Password background logo' style={{ width: "100%" }} border="0" /> */}
              <RiskAssessmentModalTable tableResponse={tableResponse} />
            </div>
        }
        {/* </Fade> */}
      </Modal>
    </div>
  )
}
