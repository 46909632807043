export const EXPORT_REPORT_PDF = `query (
  $accessToken: String!,
  $reportId: String!,
  $pageName: String!, 
  $filter: ReportFilter!
) {
    exportPDF(
      accessToken: $accessToken,
      reportId: $reportId,
      pageName:$pageName,
      filter: $filter)
  }`;