import {
  Box,
  Button,
  Divider,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Loading, useDataProvider } from "react-admin";
import { impersonatedURL, onFailureMessage } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import { Alert, Snackbar } from "@mui/material";
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  actionToolbar: {
    display: "flex",
    marginTop: 40,
    justifyContent: "flex-end",
  },
  forecastTitle: {
    backgroundColor: "#F2F2F2",
    display: "flex",
  },
  forecastCard: {
    marginBottom: 30,
    margin: "0 auto",
    width: "90%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  forecastCardRow: {
    display: "flex",
  },
  forecastCardWeek: {
    width: "17%",
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      flexDirection: "column",
    },
    textAlign: "center",
  },
  forecastRiskContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    placeItems: "start",
    margin: "5px 4px",
    alignItems: "flex-start",
  },
  forecastRiskBox: {
    width: "150px",
    height: 51,
    [theme.breakpoints.down("md")]: {
      minWidth: 64,
      height: 40,
    },
    opacity: 0.3,
    borderRadius: 4,
    "&.active": {
      opacity: 0.7,
    },
    "&.risk-high": {
      backgroundColor: "#EB5757",
    },
    "&.risk-medium": {
      backgroundColor: "#F2994A",
    },
    "&.risk-low": {
      backgroundColor: "#27AE60",
    },
    "&.border": {
      opacity: 1,
    },
  },
  forecastTitleText: {
    fontSize: 16,
    fontWeight: 500,
    fontStyle: "normal",
    margin: 0,
  },
  commentary: {
    width: "100%",
    height: "150px",
  },
  selectedAssessment: {
    margin: "0 10px",
    padding: "4px 4px",
    boprderRadius: "10px",
    textAlign: "center",
    "&.activeRed": {
      border: "2px solid #EB5757",
    },
    "&.activeGreen": {
      border: "2px solid #27AE60",
    },
    "&.activeAmber": {
      border: "2px solid #F2994A",
    },
  },
  overallImpact: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const ImpactAssesment = ({
  record,
  activeStep,
  handleBack,
  handleNext,
  steps,
  setIsUpdateProjectDetails,
}) => {
  const [impacts, setImpacts] = useState([]);
  const [impactForcast, setImpactForcast] = useState([]);
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dataProvider = useDataProvider();
  const user = TokenStorage.getUserDetails();
  // const form = useForm();
  // const formValues = form.getState().values;
  const [activeIndex, setActiveIndex] = useState([]);
  const [calculation, setCalculation] = useState([]);
  const [commentary, setCommentary] = useState([]);
  const [overallCommentry, setOverallCommentry] = useState({ commentary: "" });
  const [calculations, setCalculations] = useState([]);
  const [impactResponse, setImpactResponse] = useState([]);
  const [isUpdatedAssessment, setIsUpdatedAssesment] = useState([]);
  const [isImpactsLoading, setImpactsLoading] = useState(true);
  const localProjectDetails = TokenStorage.getProjectDetails();
  const { isImpersonating } = user ? user : {};
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({ message: name, type: type })
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  const [anchorOrigin, setAnchorOrigin] = useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = useState({
    message: "",
    type: ""
  });

  const { vertical, horizontal } = anchorOrigin;
  // const resource = "Milestone";

  useEffect(() => {
    dataProvider
      .postRequest("ChangeAssessmentMaster", {
        // id: null,
        queryType: "retrieve",
        data: { type: ["Impact"] },
      })
      .then((response) => {
        setImpacts(response.data);
        setImpactsLoading(false);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    dataProvider
      .postRequest("ChangeAssessmentDetails", {
        // id: null,
        queryType: "retrieve",
        data: {
          projectId: localProjectDetails?.id,
          type: ["Impact"],
        },
      })
      .then((response) => {
        setActiveIndex(
          response.data.assessmentDetails.map((item) => item.impactLevel)
        );
        setCommentary(
          response.data.assessmentDetails.map((item) => item.commentary)
        );
        setIsUpdatedAssesment(response.data);
        setImpactForcast(response.data.assessmentDetails);
        setCalculation(
          response.data.assessmentDetails.map((item) => item.score)
        );
        setOverallCommentry(
          response.data.assessmentScores[0] || { commentary: "" }
        );
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleRiskBtnClick = async (index, imp, activeIndx) => {
    const calc =
      Number(imp?.dimensionWeight) * Number(activeIndx?.dimensionScore);
    const updateCalc = [...calculation];
    updateCalc[index] = calc;
    setCalculation(updateCalc);
    // setCalculation()
    const udpateIndex = [...activeIndex];
    udpateIndex[index] = activeIndx?.name;
    // console.log(index, activeIndx)
    setActiveIndex(udpateIndex);
    const params = {
      projectId: localProjectDetails?.id,
      changeAssessmentMasterId: Number(imp.id),
      impactLevel: activeIndx?.name,
      score: calc,
      type: "Impact",
      id: impactForcast[index]?.id || null,
    };
    const updateImpacts = [...impactForcast];
    updateImpacts[index] = params;
    setImpactForcast(updateImpacts);
  };
  const handleCommentary = (index, id, value) => {
    const updateCommentary = [...commentary];
    updateCommentary[index] = value;
    setCommentary(updateCommentary);
  };
  const handleOverallCommentary = (value) => {
    const oldObject = { ...overallCommentry };
    oldObject["commentary"] = value;
    setOverallCommentry(oldObject);
  };
  const handleSteper = async (values, status) => {
    const stepers = {
      id: localProjectDetails.id,
      isDraft: true,
      stepCount: 3,
    };
    dataProvider
      .postRequest("Project", {
        queryType: "updateStep",
        data: stepers,
      })
      .then((response) => {
        // form.restart();
        console.log(response);
        if (status == "draft") {
          TokenStorage.clearProjectData();
          impersonatedURL(isImpersonating, "#/project?impersonated");
        } else {
          handleNext(response.data);
        }
        // handleNext(response.data)
        // refresh();
        // createMilestoneView()
      })
      .catch((error) => {
        console.log(error);
        const error_messages = onFailureMessage(error);
        handleSnackbarOpen(error_messages, "error")
      });
  };
  const validation = (value) => {
    let hasImpactLevel = true
    if (value.length === 15) {
      value.map((item) => {
        if (!item) {
          handleSnackbarOpen(`Please fill the all change impact assessment`, "warning")
          hasImpactLevel = false
        }
      })
      return hasImpactLevel
    }
    else {
      handleSnackbarOpen(`Please fill the all change impact assessment`, "warning")
    }

    return false
  }
  const handleSubmit = (status) => {
    // const uniqueArray = Array.from(new Set(impactForcast.map(item => item.changeAssessmentMasterId)))
    // .map(changeAssessmentMasterId => impactForcast.find(item => item.changeAssessmentMasterId === changeAssessmentMasterId));
    // console.log(uniqueArray)
    // console.log(impactForcast)
    
    const isValidated = validation(impactForcast)

    if (isValidated) {
    // if (impactForcast.length === 15) {
    const requestBody = impactForcast.map((item, index) => {
      const com = commentary[index];
      if (com !== undefined) {
        return {
          ...item,
          commentary: com,
        };
      } else {
        return {
          ...item,
          commentary: "",
        };
      }
    });
    const overallAssessments = [
      {
        id: overallCommentry?.id || null,
        projectId: localProjectDetails.id,
        overallScore: calculation.reduce(
          (acc, current) => acc + (current || 0),
          0
        ),
        commentary: overallCommentry.commentary,
        type: "Impact",
      },
    ];

    const values = {
      projectId: localProjectDetails.id,
      assessmentDetails: requestBody,
      assessmentScores: overallAssessments,
      type: ["Impact"],
    };

    dataProvider
      .postRequest("ChangeAssessmentDetails", {
        queryType: "update",
        data: values,
      })
      .then(async (response) => {
        setImpactResponse(response.data);
        // handleNext(response.data)
        console.log(response);
        if (status === "draft" || localProjectDetails?.isDraft == true) {
          await handleSteper(response.data, status);
        } else {
          handleNext(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        handleSnackbarOpen(`Impact assessment changes: ${error.message}`, "error")
      });
    }
  };
  return (
    <div>
      {!isImpactsLoading ? (
        impacts?.map((impact, index) => (
          <div>
            {isSmall ? (
              <div>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography className={classes.heading}>
                    {index + 1}
                    {". "} {impact?.name}
                  </Typography>
                  <LightTooltip
                    title={impact?.description}
                    placement="left-start"
                  >
                    <IconButton aria-label="info">
                      <InfoOutlinedIcon className={classes.outlineIcon} />
                    </IconButton>
                  </LightTooltip>
                </Box>
                <Box
                  style={{
                    display: "flexx",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    className={clsx(classes.selectedAssessment, {
                      activeRed:
                        impact.impactLevels[2]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                  >
                    <Button
                      className={clsx(classes.forecastRiskBox, {
                        active: impact
                          ? impact.impactLevels[2]?.name === "High" ||
                          impact.impactLevels[2]?.name === "Challenging"
                          : false,
                        "risk-high": true,
                        border:
                          impact.impactLevels[2]?.name ===
                          impactForcast[index]?.impactLevel,
                      })}
                      style={{ width: "100%" }}
                      onClick={() =>
                        handleRiskBtnClick(
                          index,
                          impact,
                          impact.impactLevels[2]
                        )
                      }
                    >
                      {impact.impactLevels[2]?.name}
                    </Button>
                  </Box>
                  <Box
                    className={clsx(classes.selectedAssessment, {
                      activeAmber:
                        impact.impactLevels[1]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                  >
                    <Button
                      className={clsx(classes.forecastRiskBox, {
                        active: impact
                          ? impact.impactLevels[1]?.name === "Medium" ||
                          impact.impactLevels[1]?.name === "Marginal"
                          : false,
                        "risk-medium": true,
                        border:
                          impact.impactLevels[1]?.name ===
                          impactForcast[index]?.impactLevel,
                      })}
                      style={{ width: "100%" }}
                      onClick={() =>
                        handleRiskBtnClick(
                          index,
                          impact,
                          impact.impactLevels[1]
                        )
                      }
                    >
                      {impact.impactLevels[1]?.name}
                    </Button>
                  </Box>
                  <Box
                    className={clsx(classes.selectedAssessment, {
                      activeGreen:
                        impact.impactLevels[0]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                  >
                    <Button
                      className={clsx(classes.forecastRiskBox, {
                        active: impact
                          ? impact.impactLevels[0]?.name === "Low" ||
                          impact.impactLevels[0]?.name === "Acceptable"
                          : false,
                        "risk-low": true,
                        border:
                          impact.impactLevels[0]?.name ===
                          impactForcast[index]?.impactLevel,
                      })}
                      style={{ width: "100%" }}
                      onClick={() =>
                        handleRiskBtnClick(
                          index,
                          impact,
                          impact.impactLevels[0]
                        )
                      }
                    >
                      {impact.impactLevels[0]?.name}
                    </Button>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <TextareaAutosize
                      aria-label="Commentary (max 255 characters)"
                      minRows={3}
                      placeholder="Commentary (max 255 characters)"
                      style={{
                        width: "90%",
                        margin: "10px 0",
                        padding: "4px 4px",
                      }}
                      onChange={(e) =>
                        handleCommentary(index, impact.id, e.target.value)
                      }
                      value={commentary[index] || ""}
                    />
                  </Box>
                  <Box sx={{ margin: "10px" }}>
                    <Divider />
                  </Box>
                </Box>
              </div>
            ) : (
              <div className={classes.forecastCardRow}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    flexDirection: "column",
                    width: "20%",
                    padding: "18px",
                    borderRight: "1px solid #C4C4C4",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.heading}>
                      {index + 1}
                      {". "} {impact?.name}
                    </Typography>
                    <LightTooltip
                      title={impact?.description}
                      placement="left-start"
                    >
                      <IconButton aria-label="info">
                        <InfoOutlinedIcon className={classes.outlineIcon} />
                      </IconButton>
                    </LightTooltip>
                  </Box>
                </Box>
                <div className={classes.forecastRiskContainer}>
                  <Box
                    className={clsx(classes.selectedAssessment, {
                      activeRed:
                        impact.impactLevels[2]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                  >
                    <HtmlTooltip
                      title={
                        <Box>
                          <Typography color="inherit">
                            {" "}
                            {impact.impactLevels[2]?.description &&
                              impact.impactLevels[2]?.description
                                .split("\n")
                                .map((text, index) => (
                                  <React.Fragment key={index}>
                                    {text}
                                    <br />
                                  </React.Fragment>
                                ))}
                          </Typography>
                        </Box>
                      }
                    >
                      <Button
                        className={clsx(classes.forecastRiskBox, {
                          active: impact
                            ? impact.impactLevels[2]?.name === "High" ||
                            impact.impactLevels[2]?.name === "Challenging"
                            : false,
                          "risk-high": true,
                          border:
                            impact.impactLevels[2]?.name ===
                            impactForcast[index]?.impactLevel,
                        })}
                        onClick={() =>
                          handleRiskBtnClick(
                            index,
                            impact,
                            impact.impactLevels[2]
                          )
                        }
                      >
                        {impact.impactLevels[2]?.name}
                      </Button>
                    </HtmlTooltip>
                  </Box>
                  <Box
                    className={clsx(classes.selectedAssessment, {
                      activeAmber:
                        impact.impactLevels[1]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                  >
                    <HtmlTooltip
                      title={
                        <Box>
                          <Typography color="inherit">
                            {" "}
                            {impact.impactLevels[1]?.description &&
                              impact.impactLevels[1]?.description
                                .split("\n")
                                .map((text, index) => (
                                  <React.Fragment key={index}>
                                    {text}
                                    <br />
                                  </React.Fragment>
                                ))}
                          </Typography>
                        </Box>
                      }
                    >
                      <Button
                        className={clsx(classes.forecastRiskBox, {
                          active: impact
                            ? impact.impactLevels[1]?.name === "Medium" ||
                            impact.impactLevels[1]?.name === "Marginal"
                            : false,
                          "risk-medium": true,
                          border:
                            impact.impactLevels[1]?.name ===
                            impactForcast[index]?.impactLevel,
                        })}
                        onClick={() =>
                          handleRiskBtnClick(
                            index,
                            impact,
                            impact.impactLevels[1]
                          )
                        }
                      >
                        {impact.impactLevels[1]?.name}
                      </Button>
                    </HtmlTooltip>
                  </Box>
                  <Box
                    className={clsx(classes.selectedAssessment, {
                      activeGreen:
                        impact.impactLevels[0]?.name ===
                        impactForcast[index]?.impactLevel,
                    })}
                  >
                    <HtmlTooltip
                      title={
                        <Box>
                          <Typography color="inherit">
                            {" "}
                            {impact.impactLevels[0]?.description &&
                              impact.impactLevels[0]?.description
                                .split("\n")
                                .map((text, index) => (
                                  <React.Fragment key={index}>
                                    {text}
                                    <br />
                                  </React.Fragment>
                                ))}
                          </Typography>
                        </Box>
                      }
                    >
                      <Button
                        className={clsx(classes.forecastRiskBox, {
                          active: impact
                            ? impact.impactLevels[0]?.name === "Low" ||
                            impact.impactLevels[0]?.name === "Acceptable"
                            : false,
                          "risk-low": true,
                          border:
                            impact.impactLevels[0]?.name ===
                            impactForcast[index]?.impactLevel,
                        })}
                        onClick={() =>
                          handleRiskBtnClick(
                            index,
                            impact,
                            impact.impactLevels[0]
                          )
                        }
                      >
                        {impact.impactLevels[0]?.name}
                      </Button>
                    </HtmlTooltip>
                  </Box>
                  <Box>
                    <TextareaAutosize
                      aria-label="Commentary (max 255 characters)"
                      minRows={3}
                      placeholder="Commentary (max 255 characters)"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleCommentary(index, impact.id, e.target.value)
                      }
                      value={commentary[index] || ""}
                    />
                  </Box>
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <Loading />
      )}
      <Box style={{ width: "100%", backgroundColor: "#c4cdd5" }}>
        <Divider variant="fullWidth" />
      </Box>
      {calculation.length > 0 && (
        <Box>
          <p
            style={{ fontSize: "20px", fontWeight: "600", textAlign: "center" }}
          >
            Overall Impact Assessment
          </p>
          <Box className={classes.overallImpact}>
            {calculation.reduce((acc, current) => acc + (current || 0), 0) >=
              15 &&
              calculation.reduce((acc, current) => acc + (current || 0), 0) <=
              22 && (
                <Button
                  style={{
                    padding: "20px 16px",
                    borderRadius: "5px",
                    backgroundColor: "#27AE60",
                    width: isSmall ? "90%" : "180px",
                  }}
                >
                  Low Impact
                </Button>
              )}
            {calculation.reduce((acc, current) => acc + (current || 0), 0) >
              22 &&
              calculation.reduce((acc, current) => acc + (current || 0), 0) <=
              30 && (
                <Button
                  style={{
                    padding: "20px 16px",
                    borderRadius: "5px",
                    backgroundColor: "#F2994A",
                    width: isSmall ? "90%" : "180px",
                  }}
                >
                  Medium Impact
                </Button>
              )}
            {calculation.reduce((acc, current) => acc + (current || 0), 0) >
              30 && (
                <Button
                  style={{
                    padding: "20px 16px",
                    borderRadius: "5px",
                    backgroundColor: "#EB5757",
                    width: isSmall ? "90%" : "180px",
                  }}
                >
                  High Impact
                </Button>
              )}
            <TextareaAutosize
              aria-label="commentary"
              minRows={3}
              placeholder="Enter overall impact commentary"
              style={{
                width: isSmall ? "90%" : "320px",
                margin: isSmall ? "10px 0" : "0 5px",
                padding: "6px 2px",
              }}
              onChange={(e) => handleOverallCommentary(e.target.value)}
              value={overallCommentry?.commentary || ""}
            />
          </Box>
        </Box>
      )}
      <div className={classes.actionToolbar}>
        {activeStep > 0 && (
          <Button
            type="button"
            variant="outlined"
            color="primary"
            size="large"
            style={{ marginRight: 25 }}
            onClick={handleBack}
          >
            Previous
          </Button>
        )}
        {!(activeStep === steps.length) && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            style={{ marginRight: 25 }}
            onClick={() => handleSubmit("notDraft")}
          >
            Next
          </Button>
        )}
        {!(activeStep === steps.length) &&
          localProjectDetails?.isDraft == true && (
            <Button
              type="button"
              variant="contained"
              color="secondary"
              size="large"
              style={{ marginRight: 25 }}
              onClick={() => handleSubmit("draft")}
            // onClick={this.handleSubmit}
            >
              Save To Draft
            </Button>
          )}
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
        <Alert

          onClose={hanndleSnackbar}
          severity={popupMessage.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {popupMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
