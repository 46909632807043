import PBIEmbed from "./pbiEmbed";

export const ChangeEnvironmentReport = (props) => {
    return (
        <PBIEmbed
            title="Change Environment Report"
            reportId='4e534fc8-f1bb-4272-a41d-5473d28ea467'
            pageName="6627b7813ec938b48870"
            resource='Report'
            url='change_environment_project'
        />
    )
}

export default ChangeEnvironmentReport;