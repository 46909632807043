import { Card } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from '@mui/material/Tooltip';
import arrayMutators from "final-form-arrays";
import _ from "lodash";
import * as React from "react";
import {
    Error,
    Loading,
    TextInput,
    required,
    useDataProvider,
} from "react-admin";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        [theme.breakpoints.down("md")]: {
            display: "block"
        }
    },
    dataList: {
        width: 350,
        borderRadius: 12,
        backgroundColor: theme.palette.background.paper,
        marginRight: 20,
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    dataValueList: {
        width: 832,
        marginLeft: 20,
        borderRadius: 12,
        [theme.breakpoints.down("md")]: {
            width: "100%",
            marginLeft: 0,
            marginTop: 15,
        }
    },
    dataValueName: {
        width: 350,
        marginTop: 16,
        [theme.breakpoints.down("md")]: {
            width: "100%",
        }
    },
    dataValueNameSingle: {
        width: 350,
        minHeight: 37,
    },
    dataValueDescription: {
        width: 350,
        paddingTop: 9,
        paddingBottom: 9,
    },
    toolbar: {
        display: "flex",
        width: 350,
        justifyContent: "space-between",
    },
    navPadding: {
        padding: 0,
    },
    listSelected: {
        backgroundColor: "#405189 !important",
    },
    listText: {
        fontWeight: 500,
        color: "#333333",
    },
    listTextSelected: {
        fontWeight: 500,
        color: "#fff",
    },
    secondboxTitle: {
        paddingLeft: 24,
        paddingTop: 14,
    },
    containerBox: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "20px",
        paddingLeft: 40,
        paddingRight: 72,
        [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
            paddingLeft: 15,
            paddingRight: 15,
        }
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: "#BDBDBD",
    },
    containerBoxTwo: {
        display: "flex",
        paddingLeft: 40,
        paddingRight: 33,
        gap: 16,
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            paddingLeft: 15,
            paddingRight: 15,
        }
    },
    deleteButton: {
        marginTop: -16,
        marginLeft: 20,
        padding: 0,
        backgroundColor: "transparent !important",
    },
    addfieldButton: {
        backgroundColor: "transparent !important",
        paddingLeft: 40,
        marginBottom: 36,
    },
    buttonFieldsContainer: {
        paddingLeft: 40,
        marginBottom: 35,
        [theme.breakpoints.down("md")]: {
            display: "flex",
            flexGrow: 1,
            paddingRight: 40
        }
    },
    cancelButton: {
        marginLeft: 25,
    },
}));

const FaqList = (props) => {
    const classes = useStyles();
    const {handleSnackbarOpen} = props
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();
    const [list, setList] = React.useState([1]);

    const [valueCardData, setValueCardData] = React.useState();
    const [valueCardLoading, setValueCardLoading] = React.useState(false);
    const [valueCardError, setValueCardError] = React.useState();

    const dataProvider = useDataProvider();
    const [selectedIndex, setSelectedIndex] = React.useState();

    const onSubmit = (values, form) => {
        dataProvider
            .postRequest("Faq", {
                queryType: "update",
                data: {
                    faqs: _.map(values.faqs, (faq) => ({
                        question: faq.question,
                        answer: faq.answer,
                    })),
                },
            })
            .then((response) => {
                handleSnackbarOpen("FAQs modified successfully","success");
            })
            .catch((error) => {
                setError("Error in modifying FAqs");
            });
    };

    const validate = (values) => {
        const errors = {};
        return errors;
    };

    React.useEffect(async () => {
        setLoading(true);
        await dataProvider
            .getList("Faq", {
                pagination: { page: 1, perPage: 100 },
                sort: { field: "id", order: "ASC" },
                filter: {},
            })
            .then((response) => {
                setData(response.data);

                // select first list item by default
                if (response.data && response.data.length > 0) {
                    const default_index = 0;
                    setSelectedIndex(default_index);
                    let faqData = {};
                    faqData.faqs = response.data;
                    setValueCardData(faqData);
                    setValueCardLoading(false);
                }
                setLoading(false);
            })
            .catch((error) => {
                handleSnackbarOpen(`Faq data is unavailable: ${error.message}`, "warning");
                setError(error.message);
                setLoading(false);
                setValueCardError(error.message);
                setValueCardLoading(false);
            });
    }, []);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <Error title={error} />;
    }

    return (
        <div className={classes.root}>
            <Card className={classes.dataList}>
                <List
                    className={classes.navPadding}
                    component="nav"
                    aria-label="main mailbox folders"
                >
                    {list.map((item, index) => (
                        <ListItem
                            key={index}
                            button
                            selected={selectedIndex === index}
                            classes={{ selected: classes.listSelected }}
                            onClick={(event) => handleListItemClick(event, index)}
                        >
                            <ListItemText primary={`FAQs`} className={
                                    selectedIndex === index
                                        ? classes.listTextSelected
                                        : classes.listText
                                } />
                        </ListItem>
                    ))}
                </List>
            </Card>
            <Form
                onSubmit={onSubmit}
                mutators={{
                    ...arrayMutators,
                }}
                validate={validate}
                initialValues={valueCardData}
                render={({
                    handleSubmit,
                    form: {
                        mutators: { push, pop },
                    }, // injected from final-form-arrays above
                    pristine,
                    form,
                    submitting,
                    values,
                }) => (
                    <Card className={classes.dataValueList}>
                        <CardHeader
                            title="FAQs"
                            classes={{
                                title: classes.secondboxTitle,
                            }}
                        />

                        <Divider style={{ backgroundColor: "#C4C4C4" }} />

                        <form onSubmit={handleSubmit}>
                            <FaqInputs
                                loading={valueCardLoading}
                                error={valueCardError}
                                data={valueCardData}
                                push={push}
                                pristine={pristine}
                                submitting={submitting}
                                form={form}
                            />
                        </form>
                    </Card>
                )}
            />
        </div>
    );
};

const FaqInputs = ({
    loading,
    error,
    data,
    push,
    submitting,
    pristine,
    form,
}) => {
    const classes = useStyles();
    const [faqs, setFaqs] = React.useState();

    const uniqueName = (value, values, index) => {
        if (!value) return;

        for (let i = 0; i < values.faqs.length; i++) {
            for (let j = 0; j < values.faqs.length; j++) {
                if (values.faqs[i] === undefined || values.faqs[j] === undefined) {
                    continue;
                }
                if (
                    i !== j &&
                    j === index &&
                    values.faqs[i].question === values.faqs[j].question
                ) {
                    return `found duplicate question "${values.faqs[i].question}"`;
                }
            }
        }
    };

    React.useEffect(() => {
        setFaqs(data);
    }, [data]);

    if (loading) <Loading />;

    if (error) <Error title={error} />;

    return (
        <Box paddingTop="30px">
            <FieldArray name="faqs">
                {({ fields }) =>
                    fields.map((name, index) => {
                        return (
                            <Box className={classes.containerBoxTwo} key={name}>
                                <Field fullWidth name={`${name}.question`}>
                                    {(props) => (
                                        <TextInput
                                            validate={[
                                                required(),
                                                (value, values) => uniqueName(value, values, index),
                                            ]}
                                            label={`Question`}
                                            variant="outlined"
                                            name={props.input.name}
                                            value={props.input.value}
                                            style={{ marginRight: { md: 0, lg: 10 } }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.dataValueNameSingle,
                                                    notchedOutline: classes.inputBorder,
                                                },
                                            }}
                                            onChange={props.input.onChange}
                                        />
                                    )}
                                </Field>
                                <Field fullWidth name={`${name}.answer`}>
                                    {(props) => (
                                        <TextInput
                                            multiline
                                            label={`Answer`}
                                            validate={[required()]}
                                            variant="outlined"
                                            name={props.input.name}
                                            value={props.input.value}
                                            style={{ marginRight: { md: 0, lg: 10 } }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.dataValueName,
                                                    notchedOutline: classes.inputBorder,
                                                },
                                            }}
                                            onChange={props.input.onChange}
                                        />
                                    )}
                                </Field>
                                <Box display="flex">
                                    <IconButton
                                        color="inherit"
                                        aria-label="remove value"
                                        className={classes.deleteButton}
                                        onClick={() => {
                                            const proceed = window.confirm('You are deleting the entry. Are you sure?');
                                            if (proceed) {
                                                fields.remove(index)
                                            }
                                        }
                                        }
                                    >
                                        <Tooltip title="Delete FAQ">
                                            <DeleteIcon style={{ fill: "rgba(0,0,0,0.54" }} />
                                        </Tooltip>
                                    </IconButton>
                                </Box>
                            </Box>
                        );
                    })
                }
            </FieldArray>
            <IconButton
                color="inherit"
                aria-label="add value"
                onClick={() => push("faqs", undefined)}
                className={classes.addfieldButton}
            >
                <AddCircleOutlineIcon />
                <span style={{ fontSize: 16, marginLeft: 10 }}>Add Question</span>
            </IconButton>
            <Grid item xs={12}>
                <div className={classes.buttonFieldsContainer}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={submitting || pristine}
                    >
                        Save
                    </Button>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={form.reset}
                        className={classes.cancelButton}
                    >
                        Cancel
                    </Button>
                </div>
            </Grid>
        </Box>
    )
};

export default FaqList;
