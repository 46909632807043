export const BUSINESS_UNIT_LIST_QUERY = `query($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: BusinessUnitFilter!){
    allBusinessUnits(page:$page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter:$filter){
      items{
        id
        name
        geography
        fte
        description
        status
        parentBusinessUnitId
        parentBusinessUnit{
            id
            name
        }
      }
      totalCount
    }
  }`

export const BUSINESS_UNIT_RETRIEVE_QUERY = `query($id: Int!) {
    businessUnit(id: $id) {
        id
        name
        geography
        fte
        description
        status
        parentBusinessUnitId
    }
  }`

export const BUSINESS_UNIT_UPDATE_MUTATION = `mutation(
  $id: Int!
  $name: String
  $geography: String
  $fte: Int
  $description: String
  $status: Boolean
  $parentBusinessUnitId: Int
) {
  updateBusinessUnit(
    id: $id
    name: $name
    geography: $geography
    fte: $fte
    description: $description
    status: $status
    parentBusinessUnitId: $parentBusinessUnitId
  ) {
    id
    name
    geography
    fte
    description
    status
    parentBusinessUnitId
  }
}`

export const BUSINESS_UNIT_CREATE_MUTATION = `mutation(
    $name: String
    $geography: String
    $fte: Int
    $description: String
    $status: Boolean
    $parentBusinessUnitId: Int
  ) {
    createBusinessUnit(
      name: $name
      geography: $geography
      fte: $fte
      description: $description
      status: $status
      parentBusinessUnitId: $parentBusinessUnitId
    ) {
      id
      name
      geography
      fte
      description
      status
      parentBusinessUnitId
    }
  }`

export const BUSINESS_UNIT_DELETE_MUTATION = `mutation($id: Int!) {
    deleteBusinessUnit(id: $id)
  }`


export const EXPORT_BU_QUERY = `
query{
  exportBUs
}`

export const ALL_BUSINESS_UNITS_TREE_RETRIEVE_QUERY = `query{
    allBusinessUnitChildren
  }`

export const ALL_BUSINESS_UNITS_WITH_LEVEL_RETRIEVE_QUERY = `
query(
  $page: Int
  $perPage: Int
  $sortField: String
  $sortOrder: String
  $filter: BusinessUnitLevelFilter
) {
  allBusinessUnitsWithLevel(
    page: $page
    perPage: $perPage
    sortField: $sortField
    sortOrder: $sortOrder
    filter: $filter
  ) {
    items {
      id
      name
      geography
      fte
      description
      status
      level
      parentBusinessUnitId
    }
    totalCount
  }
}`