import PBIPortfolioEmbed from "./pbiPortfolioEmbed";

export const SMEReport = (props) => {
    return (
        <PBIPortfolioEmbed
            title="SME Requirement"
            reportId='421050e3-5165-4937-8598-a9e41a955361'
            pageName='ReportSection461f9e6bcf5894eb1995'
            resource='Report'
            url='sme_requirement_portfolio'
        />
    )
}

export default SMEReport;