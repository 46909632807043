import {
    Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, makeStyles, Tooltip, Typography, withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from "react";
import { useForm } from "react-final-form";
import ReviewCard from "./reviewCard";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: 16,
        [theme.breakpoints.down("md")]: {
            fontSize: 11,
        },
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "uppercase",
    },
    accordionBorder: {
        "&:first-child": {
            borderTopLeftRadius: '6px !important',
            borderTopRightRadius: '6px !important'
        },
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
        [theme.breakpoints.up("md")]: {
            paddingLeft: 50,
            paddingRight: 50,
        },
        paddingTop: 24,
        marginBottom: 30,
        borderRadius: 6,
        "&:before": {
            backgroundColor: 'none'
        }
    },
    accordionSummary: {
        paddingBottom: 20,
        height: 0
    },
    accordionCard: {
        "&:nth-child(odd)": {
            paddingRight: 57
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
            paddingBottom: 30,
            "&:nth-child(odd)": {
                paddingRight: 0
            },
        },
        paddingBottom: 30,
        boxShadow: 'none'
    },
    outlineIcon: {
        width: 16,
        height: 16,
        marginTop: 3
    }
}));

// const StyledSelectInput = withStyles({
//     label: {
//         backgroundColor: "#FFFFFF",
//     },
// })(SelectInput);


export default function AccordionActuals({ isUpdateAssessment, assessmentSectionId, assessmentTypes }) {
    const classes = useStyles();
    const form = useForm();

    // set the initial expanded to accordion to the fist assessment type name
    const initialPanelIndex = isUpdateAssessment ? null : 0;
    const initialAssessmentExpanded = isUpdateAssessment ? false : assessmentTypes[initialPanelIndex].name;
    const initialAssessmentType = isUpdateAssessment ? null : assessmentTypes[initialPanelIndex];

    const [expanded, setExpanded] = React.useState(initialAssessmentExpanded);
    const [currentAssessmentType, setCurrentAssessmentType] = React.useState(initialAssessmentType);
    const [currentIndex, setCurrentIndex] = React.useState(initialPanelIndex);

    const getAccordionTitle = (index) => assessmentTypes[index].name;

    const getAccordionDescription = (index) => assessmentTypes[index].description;

    const getAccordionContent = (index) => assessmentTypes[index].assessmentSubTypes;

    const handleChange = (panelIndex) => (event, isExpanded) => {
        const panel = getAccordionTitle(panelIndex);
        setExpanded(isExpanded ? panel : false);
        setCurrentAssessmentType(isExpanded ? assessmentTypes[panelIndex] : false)
        setCurrentIndex(isExpanded ? panelIndex : false)
    };

    const handleContinueBtnClick = (panelIndex) => {
        const nextAccordionIndex = panelIndex + 1;
        if (nextAccordionIndex === assessmentTypes.length) {
            return;
        }
        const nextAccordionPanel = getAccordionTitle(nextAccordionIndex);
        setExpanded(nextAccordionPanel);
        setCurrentAssessmentType(assessmentTypes[nextAccordionIndex])
        setCurrentIndex(nextAccordionIndex)
    };

    // automatically expand the accordion to the next one if all the inputs are valid
    const handleReviewChange = async (assessmentSectionId, assessmentTypeId, assessmentSubTypeId) => {

        if (!isUpdateAssessment && currentAssessmentType && currentAssessmentType.id) {
            for (const assessmentSubType of currentAssessmentType.assessmentSubTypes) {
                const fieldSource = `changeAssessmentValues[_${assessmentSectionId}][_${currentAssessmentType.id}][_${assessmentSubType.id}].assessmentValueId`;
                const fieldState = form.getFieldState(fieldSource);
                if (fieldState.invalid) {
                    return;
                }
            }
            handleContinueBtnClick(currentIndex);
        }
    };

    return (
        <div className={classes.root}>
            {assessmentTypes.map((assessmentType, index) => (
                <Accordion
                    expanded={expanded === getAccordionTitle(index)}
                    onChange={handleChange(index)}
                    key={assessmentType.id}
                    className={classes.accordionBorder}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ width: 30, height: 30 }} />}
                        aria-controls={`${getAccordionTitle(index)}-panel-content`}
                        id={`${getAccordionTitle(index)}-panel-header`}
                        className={classes.accordionSummary}
                    >
                        <Box display="flex" alignItems="center">
                            <Typography className={classes.heading}>
                                {getAccordionTitle(index)}
                            </Typography>
                            <LightTooltip title={getAccordionDescription(index)} placement="left-start">
                                <IconButton aria-label="info">
                                    <InfoOutlinedIcon className={classes.outlineIcon} />
                                </IconButton>
                            </LightTooltip>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {getAccordionContent(index).map(subType => (
                                <Grid item className={classes.accordionCard} lg={6} key={subType.id}>
                                    <ReviewCard
                                        title={subType.name}
                                        assessmentSectionId={parseInt(assessmentSectionId)}
                                        assessmentTypeId={parseInt(assessmentType.id)}
                                        assessmentSubTypeId={parseInt(subType.id)}
                                        description={subType.description}
                                        select_choices={subType.assessmentValues}
                                        onChange={handleReviewChange}
                                    />
                                </Grid>
                            ))}
                            {/*<Grid item lg={12}>
                                <Button variant="contained" color="primary" onClick={() => handleContinueBtnClick(index)}
                                    disabled={form.pristine}>
                                    continue
                                </Button>
                            </Grid>*/}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}