import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme } from '@material-ui/core/styles';
import palette from "./palette";
import shadows, { customShadows } from "./shadows";




const blueTheme = {
    lighter: '#97b9f0',
    darkLight: "#97b9f0",
    light: '#19aadd',
    main: "#028FC2",
    dark: '#0693c6',
    darker: '#495057',
    buttonContrast: "#027aa6",
    paginationContrast: "#3f51b5",
    contrastText: '#ffffff',
};
const DarkBlueTheme = {
    lighter: '#45458f',
    darkLight: "#37377a",
    light: '#1c1c85',
    main: "#000080",
    dark: '#02026e',
    darker: '#030357',
    buttonContrast: "#45458f",
    paginationContrast: "#45458f",
    contrastText: '#ffffff',
}
const violeteTheme = {
    main: "#800080",
    light: "#800085",
    lighter: "#800099",
    dark: "#800079",
    darker: "#800060",
    buttonContrast: "#cc45cc",
    paginationContrast: "#d45fd4",
    contrastText: '#ffffff'
}
const YellowTheme = {
    main: "#FFA500",
    light: "#edb245",
    lighter: "#f2b950",
    dark: "#db8f04",
    darker: "#c98404",
    buttonContrast: "##dbb56e",
    paginationContrast: "##dbb56e",
    contrastText: '#ffffff'
};
const RoseTheme = {
    main: "#800000",
    light: "#f74545",
    lighter: "#f05454",
    dark: "#800505",
    darker: "#6e0303",
    buttonContrast: "#f05454",
    paginationContrast: "#f05454",
    contrastText: '#ffffff'
};
const GreenTheme = {
    main: "#006400",
    light: "#5beb5b",
    lighter: "#6eeb6e",
    dark: "#024f02",
    darker: "#033b03",
    buttonContrast: "#6eeb6e",
    paginationContrast: "#6eeb6e",
    contrastText: '#ffffff'
};
const PurpleDarkTheme = {
    main: "#800080",
    light: "#f55df5",
    lighter: "#fa7dfa",
    dark: "#660466",
    darker: "#520452",
    buttonContrast: "#fa7dfa",
    paginationContrast: "#fa7dfa",
    contrastText: '#ffffff'
}
const GoldTheme = {
    main: "#8B4513",
    light: "#f59e5f",
    lighter: "#f7a972",
    dark: "#733509",
    darker: "#612c07",
    buttonContrast: "#f7a972",
    paginationContrast: "#f7a972",
    contrastText: '#ffffff'
}
const BricksTheme = {
    main: "#A0522D",
    light: "#e3916b",
    lighter: "#eba07c",
    dark: "#874424",
    darker: "#6b351c",
    buttonContrast: "#eba07c",
    paginationContrast: "#eba07c",
    contrastText: '#ffffff'
}
const GoldLighterTheme = {
    main: "#CD853F",
    light: "#f59e5f",
    lighter: "#f7a972",
    dark: "#733509",
    darker: "#612c07",
    buttonContrast: "#f7a972",
    paginationContrast: "#f7a972",
    contrastText: '#ffffff'
}
const DarkGreenTheme = {
    main: "#556B2F",
    light: "#88a15d",
    lighter: "#899e64",
    dark: "#455726",
    darker: "#38471e",
    buttonContrast: "#899e64",
    paginationContrast: "#899e64",
    contrastText: '#ffffff'
}
const RedTheme = {
    main: "#FF4500",
    light: "#f0541a",
    lighter: "#f75c23",
    dark: "#d93c02",
    darker: "#c43702",
    buttonContrast: "#f75c23",
    paginationContrast: "#f75c23",
    contrastText: '#ffffff'
}
const PurpleTheme = {
    main: "#DA70D6",
    light: "#d96fd5",
    lighter: "#d175ce",
    dark: "#bf5ebc",
    darker: "#ad55aa",
    buttonContrast: "#ad62b3",
    paginationContrast: "#d175ce",
    contrastText: '#ffffff'
}
const CyanTheme = {
    main: "#00CED1",
    darkLight: "#bdf0ec",
    light: "#0bbdbf",
    lighter: "#1dbfc2",
    dark: "#02b2b5",
    darker: "#03999c",
    buttonContrast: "#1dbfc2",
    paginationContrast: "#1dbfc2",
    contrastText: '#ffffff'
}
const OrangeTheme = {
    main: "#FF8C00",
    light: "#eb8f1e",
    lighter: "#e3912d",
    dark: "#cf7304",
    darker: "#ab5f02",
    buttonContrast: "#e3912d",
    paginationContrast: "#e3912d",
    contrastText: '#ffffff'
}
const commonThemeConfig = {
    shadows,
    customShadows,
    typography: {
        body2: {
            color: "#2E3B52",
            fontWeight: 400,
            fontSize: 14,
        },
        fontSize: 12,
        fontFamily: [
            "Roboto",
            "Helvetica",
            "Arial",
            "sans-serif"
        ].join(','),
        subtitle1: {
            fontWeight: 600,
        }
    },
}

export const themes = 
{
    Blue:  createTheme({
        palette: {
            ...palette,
            primary: {...blueTheme}
        },
        ...commonThemeConfig
    }),
    Violet: createTheme({
        palette: {
            ...palette,
            primary: {...violeteTheme}
        },
        ...commonThemeConfig
    }),
    Yellow: createTheme({
        palette: {
            ...palette,
            primary: {...YellowTheme}
        },
        ...commonThemeConfig
    }),
    Rose: createTheme({
        palette: {
            ...palette,
            primary: {...RoseTheme}
        },
        ...commonThemeConfig
    }),
    Green: createTheme({
        palette: {
            ...palette,
            primary: {...GreenTheme}
        },
        ...commonThemeConfig
    }),
    PurpleDark: createTheme({
        palette: {
            ...palette,
            primary: {...PurpleDarkTheme}
        },
        ...commonThemeConfig
    }),
    Gold: createTheme({
        palette: {
            ...palette,
            primary: {...GoldTheme}
        },
        ...commonThemeConfig
    }),
    Bricks: createTheme({
        palette: {
            ...palette,
            primary: {...BricksTheme}
        },
        ...commonThemeConfig
    }),
    GoldLighter: createTheme({
        palette: {
            ...palette,
            primary: {...GoldLighterTheme}
        },
        ...commonThemeConfig
    }),
    DarkGreen: createTheme({
        palette: {
            ...palette,
            primary: {...DarkGreenTheme}
        },
        ...commonThemeConfig
    }),
    Red: createTheme({
        palette: {
            ...palette,
            primary: {...RedTheme}
        },
        ...commonThemeConfig
    }),
    Purple: createTheme({
        palette: {
            ...palette,
            primary: {...PurpleTheme}
        },
        ...commonThemeConfig
    }),
    Cyan: createTheme({
        palette: {
            ...palette,
            primary: {...CyanTheme}
        },
        ...commonThemeConfig
    }),
    Orange: createTheme({
        palette: {
            ...palette,
            primary: {...OrangeTheme}
        },
        ...commonThemeConfig
    }),
    DarkBlue: createTheme({
        palette: {
            ...palette,
            primary: {...DarkBlueTheme}
        },
        ...commonThemeConfig
    }),
}
Object.keys(themes).map((item) => {
// ['Blue', 'Yellow', 'Rose', 'Green', 'PurpleDark', 'Gold', 'Bricks', 'GoldLighter', 'DarkGreen', 'Red', 'Purple', 'Cyan', 'Violet', 'Orange'].map((item) => {
    themes[`${item}`].overrides = {
        MuiButton: {
            containedPrimary: {
                height: themes[`${item}`].spacing(5),
                width: themes[`${item}`].spacing(22),
            },
            outlinedPrimary: {
                height: themes[`${item}`].spacing(5),
                width: themes[`${item}`].spacing(22),
            },
            textPrimary: {
                color: themes[`${item}`].palette.primary.dark
            }
        },
        MuiIconButton: {
            root: {
                color: themes[`${item}`].palette.secondary.main,
                borderRadius: 5,
            }
        },
        
        MuiTablePagination: {
            root: {
                color: themes[`${item}`].palette.secondary.main,
            },
           
        },
        MuiTabs: {
            indicator: {
                backgroundColor: themes[`${item}`].palette.primary.darker,
                height: 4,
            }
        },
        MuiTab: {
            selected: {
                color: themes[`${item}`].palette.primary.darker,
            },
            textColorInherit: {
                color: themes[`${item}`].palette.primary.dark,
            }
        },
        RaFormInput: {
            input: {
                width: "auto",
            },
        },
        RaListToolbar: {
            toolbar: {
                backgroundColor: themes[`${item}`].palette.lighter,
                [themes[`${item}`].breakpoints.up('md')]: {
                    paddingLeft: themes[`${item}`].spacing(4),
                    paddingRight: themes[`${item}`].spacing(4),
                },
                [themes[`${item}`].breakpoints.down('md')]: {
                    flexDirection: "column",
                    alignItems: "center",
                    paddingRight: themes[`${item}`].spacing(2),
                    paddingLeft: themes[`${item}`].spacing(2),
                    "& .filter-field": {
                        width: "100%",
                    }
                },
            },
        },
        RaFilterForm: {
            form: {
                width: "100%",
            },
        },
        RaTopToolbar: {
            root: {
                [themes[`${item}`].breakpoints.down("md")]: {
                    width: "100%",
                    paddingRight: 0,
                    paddingLeft: 0,
                    "& button": {
                        backgroundColor: themes[`${item}`].palette.primary.main,
                        color: themes[`${item}`].palette.common.white,
                        borderRadius: '5px',
                        flexGrow: 1,
                    },
                }
            }
        },
        RaFilterFormInput: {
            spacer: {
                display: "none"
            }
        },
        RaSearchInput: {
            input: {
                boxSizing: "border-box",
                width: "300px",
                [themes[`${item}`].breakpoints.down("sm")]: {
                    width: "100%",
                },
                "& .MuiOutlinedInput-root": {
                    // borderRadius: 20,
                    border: "1px solid #F2F2F2",
                    // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#F3F3F9",
                    "&:focus": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #F2F2F2",
                        },
                    },
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #F3F3F9",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #F2F2F2",
    
                },
                "@media (hover: none)": {
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #F2F2F2", // Adjust the border as needed
                    }
                }
            }
        },
        MuiInputBase: {
            root: {
                backgroundColor: "#FFFFFF",
            },
        },
        RaList: {
            content: {
                boxShadow: "none",
            },
            root: {
                // backgroundColor: "#DDF6FF",
                backgroundColor: "#FFF",
                borderRadius: 5,
                boxShadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;",
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: "none",
            },
            sizeSmall: {
                "&:last-child": {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
                textAlign: 'center'
                // padding: "6px 0px 6px 60px",
            },
        },
        RaDatagrid: {
            headerCell: {
                backgroundColor: "#FFF",
                color: themes[`${item}`].palette.primary.darker,
                borderBottom: '1px solid #E9EbEC',
                // borderLeft: "1px solid #E9EbEC",
                fontWeight: 700,
                "&:first-child": {
                    borderLeft: "none"
                },
                fontSize: 14,
                letterSpacing: '0.11562em'
            },
            rowCell: {
                borderBottom: "1px solid #E9EbEC",
                borderRight: "1px solid #E9EbEC"
            },
            rowOdd: {
                backgroundColor: "#ffffff",
            },
            rowEven: {
                backgroundColor: "#FBFBFB",
                "&:hover": {
                    backgroundColor: "inherit",
                },
            },
            rowBorder: {
                border: '1px solid #000'
            },
            expandIcon: {
                transform: 'rotate(0deg)',
                marginLeft: 20,
                border: '1px solid #4F4F4F',
                padding: 0
            },
            expandIconCell: {
                borderBottom: "1px solid #E9EbEC"
            },
            expanded: {
                transform: 'rotate(180deg)',
            }
        },
        MuiAccordion: {
            root: {
                "&::before": {
                    backgroundColor: 'unset'
                }
            }
        },
        MuiChip: {
            label: {
                whiteSpace: 'normal'
            }
        },
        MuiTypography: {
            noWrap: {
                "@media only screen and (max-width: 600px)": {
                    whiteSpace: "normal",
                    textAlign: 'center',
                }
            }
        },
        MuiCssBaseline: {
            '@global': {
                '*': {
                    'scrollbar-width': 'thin',
                },
                '*::-webkit-scrollbar': {
                    width: '4px',
                    height: '4px',
                }
            }
        }
    };
})


export default themes;