import { Box, Button, Divider, Grid, IconButton, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import arrayMutators from "final-form-arrays";
import * as React from "react";
import {
    AutocompleteInput,
    Loading,
    RadioButtonGroupInput, ReferenceInput,
    TextInput,
    Title,
    maxLength,
    required,
    useDataProvider,
    useNotify,
    usePermissions
} from "react-admin";
import { Field, Form, useForm } from "react-final-form";
import { kpiChoice } from "../../framework/constants";
import { impersonatedURL, onFailureMessage } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import moment from "moment";
import { Alert, Snackbar } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "5px",
        backgroundColor: "#FFFFFF",
        borderRadius: '5px',
        padding: '15px',
        // borderTop: '2px solid #B1b1b1'
    },
    actionToolbar: {
        display: 'flex',
        marginTop: 40,
        justifyContent: 'flex-end'
    },
    referenceMargin: {
        margin: 0,
    },
    gridsmeCard: {
        paddingLeft: 40,
        paddingBottom: 40
    },
    unitTextHeading: {
        fontStyle: "normal",
        color: "#333333",
        fontSize: 24,
        margin: 0,
        marginTop: 31,
        marginLeft: 40,
        marginBottom: 35,
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: "#BDBDBD",
    },
    gridItemPadding: {
        padding: "0px !important",
        marginRight: 30,
        marginBottom: 25,
    },
    smeButton: {
        padding: 0,
        marginLeft: "-10px",
    },
    saveButton: {
        width: 155,
        height: 48,
        backgroundColor: "#ACACAC",
        borderRadius: 8,
        fontSize: 16,
        fontWeight: 500,
        fontStyle: "normal",
        textTransform: "capitalize",
    },
    responsiveTitle: {
        [theme.breakpoints.down('md')]: {
            whiteSpace: "break-spaces",
            fontSize: 19
        },
    },
    title: {
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    }
}));

const KpiCriteria = (props) => {
    const { type } = props;
    const [loading, setLoading] = React.useState();
    const [initialValues, setInitialValues] = React.useState();
    const dataProvider = useDataProvider();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setSnackbarOpen(true);
    };

    const hanndleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = React.useState({
        message: "",
        type: ""
    });

    const { vertical, horizontal } = anchorOrigin;
    // const localProjectDetails = TokenStorage.getProjectDetails()
    const classes = useStyles();
    const user = TokenStorage.getUserDetails();
    const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};
    const { loading: loadingPermissions, permissions } = usePermissions();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const isXS = useMediaQuery((theme) => theme.breakpoints.down("xs"));
    impersonatedURL(isImpersonating, `#/${type === "GoNoGo" ? "go_no_go_criteria" : "close_out_criteria"}?impersonated`);
    const getKpiCriterias = async (projectId) => {
        setLoading(true);
        return dataProvider
            .postRequest("KpiCriteria", {
                queryType: "list",
                data: {
                    projectId,
                    type
                }
            })
            .then((response) => {
                return response.data
            });
    };

    const onSubmit = (values, form) => {
        const kpiCriterias = values.kpiCriterias.filter(Boolean).map(kpiItem => ({
            assessment: kpiItem.assessment,
            commentary: kpiItem.commentary,
            kpi: kpiItem.kpi,
            projectId: kpiItem.projectId,
            type: kpiItem.type
        }))
        const { createdAt, ...kpiOverall } = values.kpiOverall[0];

        kpiCriterias.push(kpiOverall)
        dataProvider
            .postRequest("KpiCriteria", {
                queryType: "create",
                data: {
                    projectId: values.projectId,
                    type,
                    kpiCriterias: kpiCriterias
                },
            })
            .then((response) => {
                handleSnackbarOpen(`KPI ${type} Criteria Operation Completed Successfully`, "success");
                form.initialize(values);
                form.restart();
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error")
            });
    };

    const validate = (values) => {
        const errors = {};
        if (
            values &&
            values.kpiCriterias &&
            values.kpiCriterias.length === 0
        ) {
            errors.kpiCriteria_errors = "Please add at least one KPI criteria";
        }

        // if (
        //     values?.kpiOverall[0]?.assessment === ""
        // ) {
        //     errors.kpiCriteria_errors = "Please add overall KPI";
        // }

        return errors;
    };

    // React.useEffect(() => {
    const handleProjectChange = (projectId) => {
        getKpiCriterias(projectId)
            .then((kpiCriterias) => {
                const kpiOverall = kpiCriterias.filter(kpi => kpi.type === `${type}Overall`)
                const kpi = kpiCriterias.filter(kpi => kpi.type === type)

                setInitialValues((prev) => {
                    return {
                        ...prev,
                        projectId,
                        kpiCriterias: kpi.length === 0 ? [{ projectId, kpi: "", assessment: "", commentary: "", type: type }] : kpi,
                        kpiOverall: kpiOverall.length === 0 ? [{ projectId, kpi: `${type === "GoNoGo" ? "Go/No-Go" : "Close-Out"} Criteria`, assessment: "", commentary: "", type: `${type}Overall` }] : kpiOverall
                    }
                })

                setLoading(false);

            }).catch(error => {
                console.log(error);
                handleSnackbarOpen("error in getting KPI criterias", "error")
            })

    }
    // }, [])

    return (
        <>
            <WithPermission resource="Change Assessment" permissions={permissions} bypassUser={isCustomerAdmin} action={["view", "edit"]} {...props} >
                <Title title={`${type === "GoNoGo" ? "Go/No-Go" : "Close-Out"} Criteria`} className={classes.title} />
                <Form
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators,
                    }}
                    subscription={{ values: true }}
                    validate={validate}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values,
                        form: {
                            mutators: { push, pop },
                        },
                    }) => (
                        <form>
                            <Box display="flex" sx={{ alignItems: isSmall ? "flex-start" : "center", justifyContent: "space-between", flexDirection: isSmall ? "column" : "row" }}>
                                <Box style={{ width: isXS ? "100%" : 483 }}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={8} xs={12}>
                                            <ReferenceInput
                                                fullWidth
                                                variant="outlined"
                                                label="Project"
                                                source="projectId"
                                                reference="Project"
                                                sort={{ field: 'name', order: 'ASC' }}
                                                onChange={(value) => {
                                                    handleProjectChange(value, form);
                                                }}
                                                filter={{ isDraft: false, status: true }}
                                                className={classes.referenceMargin}
                                            >
                                                <AutocompleteInput optionText="name" />
                                            </ReferenceInput>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Typography style={{ color: "gray", marginBottom: isSmall && 12 }}>
                                        {initialValues && initialValues.kpiCriterias && initialValues.kpiCriterias.length > 0 && initialValues.kpiCriterias[0].createdAt && `Updated at ${moment(initialValues.kpiCriterias[0].createdAt).format("hh:mm:ss a, MM/DD/YYYY")} (UTC)`}
                                    </Typography>
                                </Box>
                            </Box>
                            {Boolean(values.projectId) &&
                                (loading ? (
                                    <Loading />
                                ) : (
                                    <Box className={classes.root}>
                                        <Box mb={2}>
                                            <Box p={2}>
                                                <KpiCriterias
                                                    // index={index}
                                                    push={push}
                                                    projectId={values.projectId}
                                                    type={type}
                                                    kpiCriteriasData={initialValues.kpiCriterias}
                                                />
                                            </Box>
                                        </Box>
                                        <Field name="kpiCriteria_errors">
                                            {({ input, meta }) => (
                                                <Box color="red" mb={2}>
                                                    {meta.error && <span>{meta.error}</span>}
                                                </Box>
                                            )}
                                        </Field>
                                        <div className={classes.actionToolbar}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                onClick={handleSubmit}
                                                disabled={submitting || pristine}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Box>
                                ))}
                        </form>
                    )}
                />
                <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                    <Alert

                        onClose={hanndleSnackbar}
                        severity={popupMessage.type}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {popupMessage.message}
                    </Alert>
                </Snackbar>
            </WithPermission>
        </>
    );
};

const KpiCriterias = ({ push, projectId, type, kpiCriteriasData }) => {
    const form = useForm();
    const [kpiCriterias, setKpiCriterias] = React.useState([{ projectId, kpi: "", assessment: "", commentary: "", type: type }]);
    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    React.useEffect(() => {
        if (Array.isArray(kpiCriteriasData)) {
            if (kpiCriteriasData.length > 0) {
                setKpiCriterias(kpiCriteriasData)
            }
        }
    }, [kpiCriteriasData])

    return (
        <div >
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    style={{ display: isSmall ? "none" : "flex" }}
                >
                    <Grid item xs={12} md={4} >
                        <Typography variant='h5'>KPI</Typography>
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Typography variant='h5' >Assessment</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Typography variant='h5'>Commentary</Typography>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        {/* <Typography variant='h5'>Commentary</Typography> */}
                    </Grid>
                </Grid>

            </Box>
            {Boolean(kpiCriterias) && kpiCriterias.map((requirement, i) => (
                Boolean(requirement) &&
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={isSmall ? 0 : 2}
                    // sx={{ border: "1px solid red" }}
                    >
                        <Grid item md={4} style={{ width: "100%" }}>
                            <TextInput
                                variant="outlined"
                                source={`kpiCriterias[${i}].kpi`}
                                label=""
                                placeholder="KPI (max 80 characters)"
                                className={classes.inputMargin}
                                fullWidth
                                validate={[required(), maxLength(80)]}
                            />

                        </Grid>
                        <Grid item md={3} style={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", }} >
                            <RadioButtonGroupInput
                                variant="outlined"
                                label=""
                                source={`kpiCriterias[${i}].assessment`}
                                row={true}
                                choices={kpiChoice}
                                validate={[required(), maxLength(255)]}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} style={{ width: isSmall ? "100%" : "80%" }} >
                            <TextInput
                                variant="outlined"
                                source={`kpiCriterias[${i}].commentary`}
                                label="Commentary (max 255 characters)"
                                className={classes.inputMargin}
                                fullWidth
                                multiline
                                validate={[maxLength(255)]}
                            />
                        </Grid>
                        <Grid item md={1} xs={2} style={{ width: "20%", marginTop: "-15px" }}>
                            {
                                isSmall ? <Box sx={{ justifyContent: "center", display: "flex" }}>
                                    <Button variant="contained"
                                        style={{ backgroundColor: "#f06548cc", color: "#fff", }}
                                        onClick={() => {
                                            const proceed = window.confirm('You are deleting the entry. Are you sure?');
                                            if (proceed) {
                                                kpiCriterias[i] = undefined
                                                form.change(`kpiCriterias[${i}]`, undefined)
                                                setKpiCriterias([...kpiCriterias])
                                            };
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Box> :
                                    <IconButton
                                        color="inherit"
                                        aria-label="remove value"
                                        onClick={() => {
                                            const proceed = window.confirm('You are deleting the entry. Are you sure?');
                                            if (proceed) {
                                                kpiCriterias[i] = undefined
                                                form.change(`kpiCriterias[${i}]`, undefined)
                                                setKpiCriterias([...kpiCriterias])
                                            };
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                            }
                        </Grid>

                    </Grid>
                    {
                        isSmall && <Box sx={{ marginY: "12px" }} >
                            <Divider />
                        </Box>
                    }
                </>
            ))}
            <Grid container item xs={12} alignItems="center" justifyContent="center">
                <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => {
                        push(`kpiCriterias`, { projectId, kpi: "", assessment: "", commentary: "", type: type });
                        if (kpiCriterias) {
                            kpiCriterias.push({ projectId, kpi: "", assessment: "", commentary: "", type: type });
                            setKpiCriterias([...kpiCriterias])
                        }

                        else
                            setKpiCriterias([{ projectId, kpi: "", assessment: "", commentary: "", type: type }])
                    }}
                    color="primary"
                    variant="text"
                >
                    Add Criteria
                </Button>
            </Grid>
            <Box marginTop={2} marginBottom={2}>
                <Divider />
            </Box>
            <Box style={{ backgroundColor: "#E3E5EE", minHeight: "70px", padding: "20px 10px 0px", borderRadius: '10px', marginTop: '20px', border: "1px solid lightgrey" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={isSmall ? 0 : 6}
                >
                    <Grid item xs={12} md={4} style={{ marginTop: '-15px' }}>
                        <Typography variant='h6'>
                            {type === "GoNoGo" ? "Project meets Go criteria" : "Project meets Close-out criteria"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} style={{ padding: "6px", marginTop: '5px' }}>
                        <RadioButtonGroupInput
                            variant="outlined"
                            label=""
                            source={`kpiOverall[0].assessment`}
                            row={true}
                            choices={kpiChoice}
                            validate={[required(), maxLength(255)]}
                        // style={{backgroundColor: "#fff", height: '44px', padding: '0 10px'}}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} style={{ padding: "6px" }}>
                        <TextInput
                            variant="outlined"
                            source={`kpiOverall[0].commentary`}
                            label="Commentary (max 255 characters)"
                            className={classes.inputMargin}
                            fullWidth
                            multiline
                            validate={[maxLength(255)]}
                            InputProps={{
                                style: {
                                    minHeight: "41px",
                                    width: "95%"
                                }
                            }}
                        />
                    </Grid>
                    {/* <Grid item lg={1}></Grid> */}
                </Grid>
            </Box>
        </div>
    )

}

export default KpiCriteria;
