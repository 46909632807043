import {
    Grid, makeStyles
} from "@material-ui/core";
import ReviewCard from "./reviewCard";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "uppercase",
    },
    nonAccordionCard: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
}));

export default function ChangeEnvironment({ assessmentSectionId, assessmentTypes }) {
    const classes = useStyles();
    const assessmentTypeIndex = 0;

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {assessmentTypes[assessmentTypeIndex].assessmentSubTypes.map(subType => (
                    <Grid className={classes.nonAccordionCard} item lg={6} key={subType.id}>
                        <ReviewCard
                            title={subType.name}
                            assessmentSectionId={parseInt(assessmentSectionId)}
                            assessmentTypeId={parseInt(assessmentTypes[assessmentTypeIndex].id)}
                            assessmentSubTypeId={parseInt(subType.id)}
                            description={subType.description}
                            select_choices={subType.assessmentValues}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
