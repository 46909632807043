import React, { createContext, useState } from 'react';
import themes from './index';

import cat_logo_white from "../../assets/images/cat_logo_white.png";


const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {

    const [theme, setTheme] = useState({
        id: "Blue",
        img: cat_logo_white
    });
    const [contextUser, setContextUser] = useState({});
    return (
        <ThemeContext.Provider value={{ theme, setTheme, themeObject: themes[theme.id], contextUser, setContextUser}}>
            {children}
        </ThemeContext.Provider>
    );
};

export { ThemeContext, ThemeProvider };
