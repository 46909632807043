import { Box, Button, Divider, Grid, IconButton, Tooltip, Typography, useMediaQuery, withStyles, TextareaAutosize, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Loading,
  ReferenceInput,
  SelectInput,
  TextInput,
  Title,
  maxLength, required,
  useDataProvider,
  useNotify,
  usePermissions,
} from 'react-admin';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import arrayMutators from "final-form-arrays";
import { Field, Form } from "react-final-form";
import { consequenceChoice, likelihoodChoice } from "../../framework/constants";
import { impersonatedURL, onFailureMessage } from "../../utils/AppUtils";
import { TokenStorage } from '../../utils/TokenStorage';
import WithPermission from "../../utils/WithPermission";
import { RiskassementModal } from "../project/riskassementModal";
import moment from "moment";
import { RiskAssessmentResponsiveModal } from "./RiskAssessmentResponsiveModal";
import { Alert, Snackbar } from "@mui/material";


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: '5px',
    padding: '6px',
    // borderTop: '2px solid #B1b1b1'
  },
  actionToolbar: {
    display: 'flex',
    marginTop: 40,
    justifyContent: 'flex-end'
  },
  forecastTitle: {
    backgroundColor: "#F2F2F2",
    display: "flex",
  },
  forecastCard: {
    marginBottom: 30,
    margin: "0 auto",
    width: "90%",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  forecastCardRow: {
    display: "flex",
  },
  forecastCardWeek: {
    width: "17%",
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      flexDirection: "column",
    },
    textAlign: "center",
  },
  text_design: {
    textAlign: 'center !important',
    fontWeight: '600'
  },
  forecastRiskContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    placeItems: "start",
    margin: '5px 4px',
    alignItems: "flex-start"
  },
  forecastRiskBox: {
    width: '150px',
    height: 51,
    [theme.breakpoints.down("md")]: {
      minWidth: 64,
      height: 40,
    },
    opacity: 0.3,
    borderRadius: 4,
    "&.active": {
      opacity: 0.7,
    },
    "&.risk-high": {
      backgroundColor: "#EB5757",
    },
    "&.risk-medium": {
      backgroundColor: "#F2994A",
    },
    "&.risk-low": {
      backgroundColor: "#27AE60",
    },
    "&.border": {
      opacity: 1,
    },
  },
  forecastTitleText: {
    fontSize: 16,
    fontWeight: 500,
    fontStyle: "normal",
    margin: 0,
  },
  commentary: {
    width: '100%',
    height: '150px'
  },
  formControl: {
    width: '100%',
  },
  title: {
    textTransform: "uppercase",
    fontSize: "20px" // Adjust the font size as needed
  },
  overallImpact: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));
const choice = [
  { id: 1, value: '1' },
  { id: 2, value: '2' },
  { id: 3, value: '3' },
  { id: 4, value: '4' },
  { id: 5, value: '5' }
]

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const RiskManagement = (props) => {
  const { record, activeStep, handleBack, handleNext, steps, setIsUpdateProjectDetails } = props
  const classes = useStyles();
  const user = TokenStorage.getUserDetails();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [riskMaster, setRiskMaster] = useState([]);
  const dataProvider = useDataProvider();
  const [singleRisk, setSingleRisk] = useState({});
  const [commentary, setCommentary] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);


  const [anchorOrigin, setAnchorOrigin] = useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = useState({
    message: "",
    type: ""
  });

  const { vertical, horizontal } = anchorOrigin;
  const [riskModal, setRiskModal] = useState([]);
  const [impactModal, setImpactModal] = useState();
  const [content, setContent] = useState('');
  const [riskData, setRiskData] = useState({});
  const [title, setTitle] = useState('');
  const { isImpersonating, isCustomerAdmin } = user ? user : {};
  const localProjectDetails = TokenStorage.getProjectDetails();
  const [consequenceIndex, setConsequenceIndex] = useState([])
  const [likelihood, setLikelihood] = useState([])
  const [selectedValues, setSelectedValues] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [overallRisk, setOverallRisk] = useState([]);
  const [overallCommentry, setOverallCommentry] = useState({ commentary: "" });
  const [optionLoading, setOptionLoading] = useState(true)
  const [scores, setScores] = useState([]);
  const [initialValues, setInitialValues] = useState([])
  const { loading: loadingPermissions, permissions } = usePermissions();
  const [riskManagemnetLoading, setRiskManagementLoading] = useState(false)
  const [riskMatirxMasters, setRiskMatrixMasters] = useState([])
  impersonatedURL(isImpersonating, '#/risk_assessment?impersonated');
  const isXS = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({ message: name, type: type })
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  // const handleRadioChange = (index, type, value) => {
  //   console.log(index, type, value)
  //   // Update the selected values based on the row index and radio type
  //   setSelectedValues(prevValues => {
  //     const newValues = [...prevValues];
  //     newValues[index] = {
  //       ...newValues[index],
  //       [type]: value,
  //     };
  //     return newValues;
  //   });
  // };



  useEffect(() => {
    dataProvider
      .postRequest("RiskMatrixMaster", {
        queryType: "list",
        data: {},
      })
      .then(({ data }) => {
        setRiskMatrixMasters(data);
        // else setError("No details found")
        // setLoading(false)
      })
      .catch(() => {
        console.log("Something went wrong");
      })
  }, [])
  const handleProjectChange = async (projectId) => {
    setRiskManagementLoading(true)


    const riskMaster = await dataProvider
      .postRequest("RiskAssessment", {
        queryType: "list",
        data: {
          projectId: projectId
        },
      })
    setRiskMaster(riskMaster.data);


    dataProvider
      .postRequest("RiskAssessment", {
        queryType: "retrieve",
        data: {
          projectId: projectId
        }
      }).then((response) => {
        console.log(response)
        const responseModifed = response.data.map((item) => {
          if (item.score > 13) {
            item['scoreLevel'] = 'Extreme'
          }
          else if (item.score > 7) {
            item['scoreLevel'] = 'High'
          }
          else if (item.score > 3) {
            item['scoreLevel'] = 'Medium'
          }
          else if (item.score >= 1) {
            item['scoreLevel'] = 'Low'
          }
          else {
            item['scoreLevel'] = ''
          }
          return item
        })
        responseModifed.map((item, index) => {
          if (item.riskName == undefined) {
            item['riskName'] = riskMaster.data[index].riskName
          }
          return item
        })
        console.log(responseModifed)
        setInitialValues({ projectId: projectId, riskAssesments: responseModifed })
        setRiskManagementLoading(false)
        // setDefaultRiskAssessment(response.data)
        // setConsequenceIndex(response.data.map(item => Number(item.consequence)))
        // setLikelihood(response.data.map(item => Number(item.likelihood)))
        // setCommentary(response.data.map(item => item.commentary))
        // setOverallRisk(response.data.map(({ id, ...rest }) => rest))
        // setOptionLoading(false)
      }).catch((err) => {
        console.log(err.message)
      })
  }
  const handleOverallCommentary = (value) => {
    const oldObject = { ...overallCommentry };
    oldObject["commentary"] = value;
    setOverallCommentry(oldObject);
  };

  const handleChange = (index, value, risk) => {

    const udpateIndex = [...consequenceIndex]
    udpateIndex[index] = value
    // console.log(index, activeIndx)
    setConsequenceIndex(udpateIndex)

    setOverallRisk((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        consequence: value,
        score: value * Number(newData[index]?.likelihood || 0),
        projectId: localProjectDetails.id,
        riskAssessmentMasterId: Number(risk.id)
      };
      return newData;
    });
    // const preValue = scores[index];
    // const calc = 
  }
  const handleLikelihoodChange = (index, value, risk) => {

    const udpateIndex = [...consequenceIndex]
    udpateIndex[index] = value
    // console.log(index, activeIndx)
    setLikelihood(udpateIndex)

    setOverallRisk((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        likelihood: value,
        score: value * Number(newData[index]?.consequence || 0),
        projectId: localProjectDetails.id,
        riskAssessmentMasterId: Number(risk.id)
      };
      return newData;
    });
  }
  const handleClose = () => {
    setRiskModal([])
  };
  const handleCloseImageModal = () => {
    setModalOpen(false)
  }
  const handleTooltipOpen = (index, risk) => {
    const udpateIndex = [...riskModal]
    udpateIndex[index] = risk.id
    // console.log(index, activeIndx)
    setTitle(risk.riskName)
    setRiskModal(udpateIndex)
    setContent(risk.riskDescription);

    // setModalOpen(true)
  };

  const handleImpactModal = (index, risk) => {
    const udpateIndex = [...riskModal]
    udpateIndex[index] = risk.id
    // console.log(index, activeIndx)
    setRiskModal(udpateIndex)
    setTitle(risk.riskName)
    setContent(risk.impactDescription);
    // setModalOpen(true)
  };


  const handleResponsiveModal = (index, risk) => {
    const udpateIndex = [...riskModal]
    udpateIndex[index] = risk.id
    // console.log(index, activeIndx)
    setRiskModal(udpateIndex)
    // setTitle(risk.riskName)
    setRiskData(risk);
    // setModalOpen(true)
  };

  const handleHeaderModal = () => {
    setModalOpen(true)
  }
  const handleCommentary = (index, id, value) => {
    const updateCommentary = [...commentary]
    updateCommentary[index] = value
    setCommentary(updateCommentary)
  }

  const onSubmit = (values, form) => {
    // console.log(values)
    // const risk_form_values = values.riskAssesments;
    // console.log(commentary)
    const requestBody = values.riskAssesments.slice(0, 14).map((item) => {
      const params = {
        score: Number(item.consequence) * Number(item.likelihood),
        riskAssessmentMasterId: Number(item.riskAssessmentMasterId),
        commentary: item.commentary || "",
        consequence: Number(item.consequence),
        likelihood: Number(item.likelihood),
        projectId: Number(values.projectId)
      }
      return params
    })
    const otherRequestBody = values.riskAssesments.slice(14).map((item) => {
      const params = {
        riskName: item.riskName,
        score: Number(item.consequence) * Number(item.likelihood),
        commentary: item.commentary || "",
        consequence: Number(item.consequence),
        likelihood: Number(item.likelihood),


      }
      return params
    })
    // console.log(values.riskAssesments)
    const r_values = {
      projectId: values.projectId,
      riskAssessments: requestBody,
      otherRiskAssessments: otherRequestBody
    };
    console.log(values)
    dataProvider
      .postRequest("RiskAssessment", {
        queryType: "create",
        data: r_values,
      })
      .then(async (response) => {
        // setImpactResponse(response.data)
        // handleNext(response.data)
        console.log(response)
        // await handleSteper(response.data, values.status)
        handleSnackbarOpen("Risk assessment udpated successfully", "success");
      })
      .catch((error) => {
        handleSnackbarOpen(error.message, "error");
      });
    // console.log(overallRisk)

  };
  const handleConsequencesScore = (index, form, value, values, masterId) => {
    console.log((values.riskAssesments[index]))
    let score = "";
    if (Number(value) * (Boolean(values.riskAssesments[index]) ? values.riskAssesments[index].likelihood : 0) > 13) {
      score = "Extreme"
    }
    else if (Number(value) * (Boolean(values.riskAssesments[index]) ? values.riskAssesments[index].likelihood : 0) > 7) {
      score = "High"
    }
    else if (Number(value) * (Boolean(values.riskAssesments[index]) ? values.riskAssesments[index].likelihood : 0) > 3) {
      score = "Medium"
    }
    else if (Number(value) * (Boolean(values.riskAssesments[index]) ? values.riskAssesments[index].likelihood : 0) >= 1) {
      score = "Low"
    }
    form.change(`riskAssesments[${index}].scoreLevel`, score)
    form.change(`riskAssesments[${index}].riskAssessmentMasterId`, masterId)
    console.log(values)
  }
  //handle likelihodd
  const handleLikelihoodScore = (index, form, value, values, masterId) => {
    console.log((values.riskAssesments[index]))
    let score = "";
    if (Number(value) * (Boolean(values.riskAssesments[index]) ? values.riskAssesments[index].consequence : 0) > 13) {
      score = "Extreme"
    }
    else if (Number(value) * (Boolean(values.riskAssesments[index]) ? values.riskAssesments[index].consequence : 0) > 7) {
      score = "High"
    }
    else if (Number(value) * (Boolean(values.riskAssesments[index]) ? values.riskAssesments[index].consequence : 0) > 3) {

      score = "Medium"
    }
    else if (Number(value) * (Boolean(values.riskAssesments[index]) ? values.riskAssesments[index].consequence : 0) >= 1) {
      score = "Low"
    }
    form.change(`riskAssesments[${index}].scoreLevel`, score)
    form.change(`riskAssesments[${index}].riskAssessmentMasterId`, masterId)
    console.log(values)
  }

  const handlePush = (form) => {
    form.mutators.push('riskAssesments', undefined);
    // console.log(form.getState().values)
    riskMaster.push({
      riskName: "",
      riskDescription: ""
    })
  }
  return (
    <WithPermission resource="Change Assessment" permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
      <Title title="Change Risk Assessment" className={classes.title} />
      <div>
        <Form onSubmit={onSubmit}
          mutators={{
            // potentially other mutators could be merged here
            ...arrayMutators,
          }}
          initialValues={initialValues}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" sx={{ alignItems: isSmall ? "flex-start" : "center", justifyContent: "space-between", flexDirection: isSmall ? "column" : "row", }}>
                <Box style={{ width: isXS ? "100%" : 483 }}>
                  <Grid container spacing={2}>
                    <Grid item lg={8} xs={12}>
                      <ReferenceInput
                        fullWidth
                        variant="outlined"
                        label="Project"
                        source="projectId"
                        reference="Project"
                        sort={{ field: 'name', order: 'ASC' }}
                        onChange={(value) => {
                          handleProjectChange(value);
                        }}
                        filter={{ isDraft: false, status: true }}
                        className={classes.referenceMargin}
                      >
                        <AutocompleteInput optionText="name" />
                      </ReferenceInput>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Typography style={{ color: "gray", marginBottom: isSmall && 12 }}>
                    {initialValues && initialValues.riskAssesments && initialValues.riskAssesments.length > 0 && `Updated at ${moment(initialValues?.riskAssesments[0]?.createdAt).format("hh:mm:ss a, MM/DD/YYYY")} (UTC)`}
                  </Typography>
                </Box>
              </Box>
              {
                riskManagemnetLoading && <Loading />
              }
              {
                values && values.projectId && <div className={classes.root}>
                  <div >
                    {
                      !isSmall && <Grid container spacing={2} style={{ textAlign: 'center', padding: "4px 0" }}>
                        <Grid lg={3}></Grid>
                        <Grid lg={2}>
                          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="h6">Consequence</Typography>
                            <IconButton aria-label="info" onClick={handleHeaderModal} size="small">
                              <InfoOutlinedIcon className={classes.outlineIcon} sx={{ color: "rgb(10,179,156)" }} />
                            </IconButton>
                          </Box>
                        </Grid>
                        <Grid lg={2}>
                          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="h6">Likelihood</Typography>
                            <IconButton aria-label="info" onClick={handleHeaderModal} size="small">
                              <InfoOutlinedIcon className={classes.outlineIcon} sx={{ color: "rgb(10,179,156)" }} />
                            </IconButton>
                          </Box>
                        </Grid>
                        <Grid lg={2}>
                          <Typography variant="h6">Rating</Typography>
                        </Grid>
                        <Grid lg={3}>
                          <Typography variant="h6">Comments</Typography>
                        </Grid>
                      </Grid>
                    }

                    <RiskassementModal open={modalOpen} tableResponse={riskMatirxMasters} content={undefined} title={title} handleClose={handleCloseImageModal} />
                    {
                      riskMaster.map((risk, index) => (
                        <>
                          <Grid container spacing={isSmall ? 0 : 2} alignItems="center">
                            <Grid item xs={12} lg={3}>
                              <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "left",
                                flexDirection: "column",
                                padding: !isSmall && "18px",
                                borderRight: isSmall ? "none" : "1px solid #C4C4C4"
                              }}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                  {
                                    risk?.riskDescription === "" ? <TextInput
                                      fullWidth

                                      source={`riskAssesments[${index}].riskName`}

                                      variant="outlined"
                                      label="Risk Name"
                                      validate={[maxLength(255)]}
                                      multiline
                                      InputProps={{
                                        style: {
                                          minHeight: "51px"
                                        }
                                      }}
                                      placeholder=''
                                    />
                                      :
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <Typography className={classes.heading} style={{ fontWeight: isSmall ? '500' : '400', }}>
                                          {index + 1 + ". "}{risk?.riskName}
                                        </Typography>
                                        <LightTooltip title={risk?.riskInfo} placement="left-start">
                                          {
                                            isSmall ? <IconButton aria-label="info" onClick={() => handleResponsiveModal(index, risk)}>
                                              <InfoOutlinedIcon className={classes.outlineIcon} sx={{ color: "rgb(10,179,156)" }} />
                                            </IconButton> :
                                              <IconButton aria-label="info" >
                                                <InfoOutlinedIcon className={classes.outlineIcon} sx={{ color: "rgb(10,179,156)" }} />
                                              </IconButton>
                                          }

                                        </LightTooltip>
                                      </div>
                                  }

                                </Box>
                                {
                                  !isSmall && risk?.riskDescription && <Box display="flex" flexDirection="column" alignItems="flex-start">
                                    <Typography variant="subtitle3" style={{ textDecoration: "underline", color: "", cursor: 'pointer' }} onClick={() => handleTooltipOpen(index, risk)}>Risk Description</Typography>
                                    <Typography variant="subtitle3" style={{ textDecoration: "underline", color: "", cursor: 'pointer' }} onClick={() => handleImpactModal(index, risk)}>Impact Description</Typography>
                                  </Box>
                                }


                                {
                                  isSmall ? <RiskAssessmentResponsiveModal
                                    open={riskModal[index] == risk.id}
                                    riskData={riskData}
                                    handleClose={handleClose}
                                    tableResponse={riskMatirxMasters}
                                  /> :
                                    <RiskassementModal open={riskModal[index] == risk.id && risk?.riskName !== ''} content={content} title={title} handleClose={handleClose} />
                                }

                              </Box>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <SelectInput
                                variant="outlined"
                                source={`riskAssesments[${index}].consequence`}
                                label="Consequence"
                                choices={consequenceChoice}
                                className={classes.inputMargin}
                                onChange={(e) => {
                                  handleConsequencesScore(index, form, e.target.value, values, risk.id)
                                }}
                                fullWidth
                                validate={[required(), maxLength(255)]}
                                InputProps={{
                                  style: {
                                    height: "51px"
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <SelectInput
                                variant="outlined"
                                source={`riskAssesments[${index}].likelihood`}
                                label="Likelihood"
                                choices={likelihoodChoice}
                                className={classes.inputMargin}
                                onChange={(e) => {
                                  handleLikelihoodScore(index, form, e.target.value, values, risk.id)
                                }}
                                fullWidth
                                validate={[required(), maxLength(255)]}
                                InputProps={{
                                  style: {
                                    height: "51px"
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              {/* <Box style={{backgroundColor: }}>
                        {form.getState()?.values?.riskAssesments[index]?.score}
                        </Box> */}
                              <Field fullWidth name={`riskAssesments[${index}].scoreLevel`} component="input">
                                {(props) => (
                                  <>
                                    <TextInput
                                      fullWidth
                                      name={props.input.name}
                                      // initialValue={(Number(props.input.value) > 13 || props.input.value == "Extreme") ? "Extreme" : (Number(props.input.value) > 7 || props.input.value == 'High') ? "High" : (Number(props.input.value) > 3 || props.input.value == "Medium") ? "Medium" : (Number(props.input.value) > 1 || props.input.value == "Low") ? "Low" : ""}
                                      // value={props.input.value}
                                      disabled={true}
                                      // onChange={props.input.onChange}
                                      variant="outlined"
                                      label=""
                                      validate={[maxLength(255)]}
                                      // multiline

                                      InputProps={{
                                        style: {
                                          color: '#000',
                                          backgroundColor: (Number(props.input.value) > 13 || props.input.value == "Extreme") ? '#EB5750' : (Number(props.input.value) > 7 || props.input.value == "High") ? '#FFC7CE' : (Number(props.input.value) > 3 || props.input.value == "Medium") ? "#FFFF00" : (Number(props.input.value) >= 1 || props.input.value == "Low") ? "#A9D08E" : "transparent",
                                          height: '51px'
                                        },
                                        classes: {
                                          input: classes.text_design
                                        }
                                      }}
                                    // inputComponent="textarea"
                                    // textareaComponent={{
                                    //   style: {
                                    //     textAlign: 'center',
                                    //     // other styles...
                                    //   },
                                    // }}
                                    />
                                    {/* {console.log(props.input.value)} */}
                                  </>
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                              <TextInput
                                fullWidth
                                // initialValue={risk.commentary}
                                // value={props.input.value}
                                source={`riskAssesments[${index}].commentary`}

                                variant="outlined"
                                label="Commentary (max 255 characters)"
                                validate={[maxLength(255)]}
                                multiline
                                InputProps={{
                                  style: {
                                    minHeight: "51px"
                                  }
                                }}
                                placeholder=''
                              />
                              {/* <TextareaAutosize aria-label="commentary" minRows={3} placeholder="Enter risk commentary" style={{ width: '100%' }} onChange={(e) => handleCommentary(index, risk.id, e.target.value)} value={commentary[index] || ''} /> */}

                            </Grid>
                          </Grid>
                          {
                            isSmall && <Box sx={{ marginBottom: "12px" }} >
                              <Divider />
                            </Box>
                          }
                        </>
                      ))
                    }
                    <Box style={{ display: 'flex', justifyContent: 'center', }}>
                      <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => handlePush(form)}
                        color="primary"
                        variant="text"
                      >
                        Add Risk
                      </Button>
                    </Box>
                  </div>
                  <div className={classes.actionToolbar}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    // disabled={submitting}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              }

            </form>
          )}
        />
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
        <Alert

          onClose={hanndleSnackbar}
          severity={popupMessage.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {popupMessage.message}
        </Alert>
      </Snackbar>
    </WithPermission >
  )
}

const AddRisk = () => {
  return (
    <div>
      <button>add more</button>
    </div>
  )
}