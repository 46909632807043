import { IconButton } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box } from '@mui/material';
import ResponsiveTable from './ResponsiveMatrixTable'
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #fff',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "100%",
        height: "100vh",
        overflowY: "auto"
    },
    paperImage: {

        backgroundColor: theme.palette.background.paper,
        border: '1px solid #fff',
        boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 3),
        maxWidth: "100%",

    },
}));
export const RiskAssessmentResponsiveModal = ({ open, riskData, handleClose, tableResponse }) => {
    const classes = useStyles();
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}

            >
                <div className={classes.paper}>
                    <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                        <h3 id="transition-modal-title">{riskData?.riskName}</h3>
                        <IconButton disableFocusRipple={true} disableRipple={true} aria-label="info" onClick={handleClose} style={{ backgroundColor: "transparent" }}>
                            <CloseOutlinedIcon className={classes.outlineIcon} sx={{ "&:hover": { color: "#000" } }} />
                        </IconButton>
                    </Box>
                    <h4>Risk Description</h4>
                    <p id="transition-modal-description">{riskData?.riskDescription}</p>
                    <h4>Impact Description</h4>
                    <p id="transition-modal-description">{riskData?.impactDescription}</p>

                    <div className={classes.paperImage}>
                        <ResponsiveTable tableResponse={tableResponse} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}
