
export const RISK_ASSESSMENT_MASTERS_BY_TYPE_LIST_QUERY = `query( $projectId: Int) {
    getRiskAssessmentMasters(projectId: $projectId) {
      id
      riskName
      riskInfo
      riskDescription
      impactDescription
    }
  }`

export const RISK_ASSESSMENT_MASTER_UPDATE_MUTATION = `mutation(
  $id: ID!
  $riskInfo: String!
  $riskDescription: String!
  $impactDescription: String!
) {
  updateRiskAssessmentMaster(
    id: $id
    riskInfo: $riskInfo
    riskDescription: $riskDescription
    impactDescription: $impactDescription
  ) {
    id
    riskName
    riskInfo
    riskDescription
    impactDescription
  }
}
`