import { Box, Typography, useMediaQuery } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import makeStyles from "@material-ui/styles/makeStyles";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Button from '@mui/material/Button';
import * as React from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { cloneElement } from "react";
import {
    Datagrid, Filter, List,
    SearchInput,
    SelectField, TextField,
    TopToolbar,
    sanitizeListRestProps,
    useDataProvider, useListContext, useNotify,
    useRecordContext,
    useRefresh
} from "react-admin";
import {
    INACTIVE_ACTIVE_CHOICES,
    YES_NO_CHOICES
} from "../../framework/constants";
import palette from "../../framework/theme/palette";
import { Loading, currentDate, onFailureMessage, saveFileFromBinary } from "../../utils/AppUtils";
import { ManageChip, ManageChipForStatus } from "../../utils/ManageChip";
import PostPagination from "../../utils/PostPagination";
import { TokenStorage } from "../../utils/TokenStorage";
import MoreActions from "../customComponents/moreActions";
import UserCreateDrawer from "./customerCreateDrawer";
import UserEditDrawer from "./customerEditDrawer";
import theme from "../../framework/theme";

import { ThemeContext } from "../../framework/theme/themeContext";
import { Alert, Snackbar } from "@mui/material";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    warningIcon: {
        color: theme.palette.customsColor.amber,
    },
    IconButtonOverride: {
        '&:hover': {
            // borderRadius: "4px",
        },
        margin: "8px 6px",
        padding: "4px 3px"
    }
}));

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        handleCreateDrawerOpen,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();
    const dataProvider = useDataProvider();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setSnackbarOpen(true);
    };

    const hanndleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = React.useState({
        message: "",
        type: ""
    });

    const { vertical, horizontal } = anchorOrigin;
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();
    const { themeObject, setTheme, setContextUser } = React.useContext(ThemeContext)
    const handleExportBtnClick = () => {

        dataProvider
            .postRequest("ExportClients", {
                queryType: "retrieve",
            }).then(response => {
                saveFileFromBinary(response.data, "Clients" + currentDate)
            })
            .catch(error => {
                handleSnackbarOpen("No data found to export", "warning")
            });

    }

    const CreateUserBtn = () => (
        <Button variant="contained" onClick={() => handleCreateDrawerOpen(true)}
            sx={{
                width: isSmall ? "0px" : "210px",
                // backgroundColor: theme.palette[`${clientTheme.name}`].secondary,
                backgroundColor: themeObject.palette.primary.main,
                boxShadow: "0",
                height: '41px',
                paddingX: '0',
                "&:hover": {
                    backgroundColor: themeObject.palette.primary.buttonContrast,
                    color: "white",
                    cursor: 'pointer',
                    boxShadow: "0"
                },
            }}>
            {
                isSmall ? <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: "0",
                    paddingLeft: "2px",
                    borderBottomRightRadius: "5px",
                    borderTopRightRadius: "5px",
                    backgroundColor: 'rgba(255,255,255,0.2)',
                    fontSize: "16px",
                    width: "100%",
                    height: '41px',
                }}>
                    <AddIcon style={{ marginLeft: '-4px' }} />
                </Box> : <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%"
                }}>
                    <Box sx={{
                        textAlign: "center",
                        width: '100%'
                    }}>
                        {isSmall ? "" : "Add Client"}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: "0",
                        paddingLeft: "2px",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        fontSize: "16px",
                        width: "41px",
                        height: '41px',
                    }}>
                        <AddIcon style={{ marginLeft: '-4px' }} />
                    </Box>
                </Box>
            }


        </Button>
    );

    return (
        <TopToolbar
            {...sanitizeListRestProps(rest)}
        >
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: "button",
                })}
            {/* <Button style={{ marginRight: 8 }} label="Export" variant="contained" color="primary" onClick={handleExportBtnClick}>{<DownloadIcon fontSize='small' />}</Button> */}
            <Button variant="contained" onClick={handleExportBtnClick}

                sx={{
                    // fontSize: "12px",
                    // fontWeight: "400",
                    width: isSmall ? "0px" : "150px",
                    marginRight: "8px",
                    backgroundColor: themeObject.palette.primary.main,
                    // backgroundColor: theme.palette[`${clientTheme.name}`].pink,
                    boxShadow: "0",
                    height: '41px',
                    paddingX: '0',
                    "&:hover": {
                        backgroundColor: themeObject.palette.primary.buttonContrast,
                        color: "white",
                        cursor: 'pointer',
                        boxShadow: "0"
                    },
                }}>
                {isSmall ? <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: "0",
                    paddingLeft: "2px",
                    borderBottomRightRadius: "5px",
                    borderTopRightRadius: "5px",
                    backgroundColor: 'rgba(255,255,255,0.2)',
                    fontSize: "16px",
                    width: "100%",
                    height: '41px',
                }}>
                    <FileDownloadOutlinedIcon style={{ marginLeft: "-4px" }} />
                </Box> : <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%"
                }}>
                    <Box sx={{
                        textAlign: "center",
                        width: '100%'
                    }}>
                        {isSmall ? "" : "Export"}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: "0",
                        paddingLeft: "2px",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        fontSize: "16px",
                        width: "41px",
                        height: '41px',
                    }}>
                        <FileDownloadOutlinedIcon style={{ marginLeft: "-4px" }} />
                    </Box>
                </Box>}
            </Button>
            <CreateUserBtn />
            <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                <Alert

                    onClose={hanndleSnackbar}
                    severity={popupMessage.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {popupMessage.message}
                </Alert>
            </Snackbar>
        </TopToolbar>
    );
};

const UserFilter = (props) => (
    <Filter {...props}>
        <SearchInput
            variant="outlined"
            source="q"
            alwaysOn
        />
    </Filter>
);

const Empty = ({ handleCreateDrawerOpen }) => (
    <Box textAlign="center" m={1} p={1}>
        <Typography variant="h4" paragraph>
            No Clients yet.
        </Typography>
        <Typography variant="body1">Do you want to add one?</Typography>
        <Box mt={1}>
            <Button
                label="Create Client"
                variant="contained"
                children={<AddIcon />}
                onClick={() => handleCreateDrawerOpen(true)}
            />
        </Box>
    </Box>
);



export const UserList = (props) => {
    const classes = useStyles();
    const [createDrawerOpen, setCreateDrawerOpen] = React.useState();
    const [editDrawerOpen, setEditDrawerOpen] = React.useState();
    const [record, setRecord] = React.useState();
    const dataProvider = useDataProvider();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const {setContextUser} = React.useContext(ThemeContext)
    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setSnackbarOpen(true);
    };

    const hanndleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = React.useState({
        message: "",
        type: ""
    });

    const { vertical, horizontal } = anchorOrigin;
    const refresh = useRefresh();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const user = TokenStorage.getUserDetails();
    const { isSuperAdmin, isCustomerAdmin } = user ? user : {};

    const handleCreateDrawerOpen = (open) => {
        setCreateDrawerOpen(open);
    };

    const handleCreateDrawerClose = () => {
        setCreateDrawerOpen(false);
    };

    const handleEditDrawerOpen = (open) => {
        setEditDrawerOpen(open);
    };

    const handleEditDrawerClose = (open) => {
        setRecord(null);
        setEditDrawerOpen(open);
    };

    const rowActionsChoices = [
        { id: "edit", name: "Edit", resource: "Customer", action: ["edit"] },
        { id: "delete", name: "Delete", resource: "Customer", action: ["Delete"] },
    ];

    const handleMoreActionsClick = ({ action, record }) => {
        switch (action) {
            case "edit":
                setRecord(record);
                handleEditDrawerOpen(true);
                break;
            case "delete":
                const proceed = window.confirm('You are deleting the entry. Are you sure?');
                if (proceed) {
                    dataProvider
                        .delete("User", { id: record.id })
                        .then((response) => {
                            handleSnackbarOpen("User deleted successfully", "success");
                            refresh();
                        })
                        .catch((error) => {
                            const error_messages = onFailureMessage(error);
                            handleSnackbarOpen(error_messages, "error");
                        });
                };
                break;
        }
    };
    const handleEditClient = (record) => {
        setRecord(record);
        handleEditDrawerOpen(true);
    }
    const handleDeleteClient = (record) => {
        const proceed = window.confirm('You are deleting the entry. Are you sure?');
        if (proceed) {
            dataProvider
                .delete("User", { id: record.id })
                .then((response) => {
                    handleSnackbarOpen("User deleted successfully", "success");
                    refresh();
                })
                .catch((error) => {
                    const error_messages = onFailureMessage(error);
                    handleSnackbarOpen(error_messages, "error");
                });
        };
    }
    const InfoField = ({ record, source }) => {
        const value = record ? record[source] : null;
        return value ? (
            <LightTooltip title={value} placement="left-start">
                <IconButton aria-label="info" className={classes.IconButtonOverride} size="small">
                    <InfoOutlinedIcon style={{ color: palette.customsColor.main }} />
                </IconButton>
            </LightTooltip>
        ) : null;
    };

    const ImpersonateUser = ({ record }) => {
        const handleClick = () => {
            dataProvider.getOne("User", { id: record.id }).then((response) => {
                TokenStorage.storeImpersonateUserDetails(
                    JSON.stringify({
                        ...response.data,
                        isImpersonating: true,
                    })
                );
                window.location.href = "/";
                // console.log('before loading...');
                // setTimeout(function () {
                    // window.location.reload(false);
                    // setContextUser({...response.data,
                    //     isImpersonating: true})
                    console.log('reloading...');
                // }, 10);
            });
        };

        return (
            <LightTooltip title="Go to Client Site" placement="left-start">
                <IconButton aria-label="impersonate user" onClick={handleClick} className={classes.IconButtonOverride} size="small">
                    <ExitToAppIcon className={classes.warningIcon} />
                </IconButton>
            </LightTooltip>
        );
    };

    const ActionButtons = ({ record }) => {
        return (
            <Box display="flex" style={{ float: "right" }}>
                <InfoField record={record} label="" source="description" />
                {record && record.status && record.isManagedService && (
                    <ImpersonateUser record={record} label="" />
                )}
                <MoreActions
                    bypassUser={isSuperAdmin}
                    permissions={props.permissions}
                    record={record}
                    choices={rowActionsChoices}
                    handleClose={() => console.log("closing")}
                    handleClick={handleMoreActionsClick}
                />
            </Box>
        );
    };

    const ActionButtonSmallScreen = () => {
        const records = useRecordContext();
        return (
            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <InfoField record={records} label="" source="description" />
                {records && records.status && records.isManagedService && (
                    <ImpersonateUser record={records} label="" />
                )}
                <IconButton aria-label="edit" className={classes.IconButtonOverride} size="small">
                    <EditIcon size="small" style={{ color: "#00A0D9", cursor: "pointer", margin: '0 5px', fontSize: "20px" }} onClick={() => handleEditClient(records)} />
                </IconButton>
                <IconButton aria-label="delete" className={classes.IconButtonOverride} size="small">
                    <DeleteForeverIcon size="small" style={{ color: "#de0937", cursor: "pointer", fontSize: "20px" }} onClick={() => handleDeleteClient(records)} />
                </IconButton>
            </Box>
        );
    };

    return (
        <React.Suspense fallback={<Loading />}>
            <React.Fragment>
                <List
                    {...props}
                    title={<span style={{ textTransform: 'uppercase' }}>Client</span>}
                    hasCreate={false}
                    empty={<Empty handleCreateDrawerOpen={handleCreateDrawerOpen} />}
                    actions={
                        <ListActions handleCreateDrawerOpen={handleCreateDrawerOpen} />
                    }
                    filters={<UserFilter />}
                    bulkActionButtons={false}
                    pagination={<PostPagination />}
                >
                    {isSmall ? (
                        <Datagrid>
                            <Box display="flex" style={{ flexDirection: 'column', width: '100%' }}>

                                <TextField source="fullName" sortable={true} />
                                {/* <TextField label="INDUSTRY" source="industry" sortable={false} /> */}
                                {/* <TextField label="LOCATION" source="location" sortable={false} /> */}
                                <TextField label="EMAIL" color="secondary" source="email" sortable={false} />
                                <ActionButtonSmallScreen label="" sortable={false} />
                            </Box>

                        </Datagrid>
                    ) : (
                        <Datagrid>
                            <TextField
                                label="CLIENT NAME"
                                source="companyName"
                                sortable={true}
                            // style={{textAlign: 'center'}}
                            />
                            <TextField
                                label="ADMIN NAME"
                                source="fullName"
                                sortable={true}
                            />
                            <TextField label="EMAIL" source="email" sortable={false} />
                            <TextField label="LOCATION" source="location" sortable={false} />
                            <TextField label="INDUSTRY" source="industry" sortable={false} />
                            <SelectField
                                label="MANAGED SERVICE"
                                source="isManagedService"
                                choices={YES_NO_CHOICES}
                                optionText={<ManageChipForStatus />}
                                sortable={false}
                            />
                            <SelectField
                                label="STATUS"
                                source="status"
                                choices={INACTIVE_ACTIVE_CHOICES}
                                // optionText={<ChipField source="name" />}
                                optionText={<ManageChip inactiveChip={true} />}
                                sortable={false}
                            />
                            <ActionButtons label="" sortable={false} />
                        </Datagrid>
                    )}
                </List>
                <UserCreateDrawer
                    record={record}
                    drawerOpen={createDrawerOpen}
                    onClose={handleCreateDrawerClose}
                    handleSnackbarOpen={handleSnackbarOpen}
                />
                <UserEditDrawer
                    record={record}
                    drawerOpen={editDrawerOpen}
                    onClose={handleEditDrawerClose}
                    handleSnackbarOpen={handleSnackbarOpen}
                />
                <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                    <Alert

                        onClose={hanndleSnackbar}
                        severity={popupMessage.type}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {popupMessage.message}
                    </Alert>
                </Snackbar>
            </React.Fragment>
        </React.Suspense>

    );
};

export default UserList;
