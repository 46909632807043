import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import { Loading, Title, useNotify, usePermissions } from "react-admin";
import powerBiApi, { checkAccessTokenExpiration } from '../../framework/pbiDataProvider';
import { impersonatedURL } from '../../utils/AppUtils';
import { TokenStorage } from '../../utils/TokenStorage';
import WithPermission from '../../utils/WithPermission';

function PowerBiReportEmbed(props) {
    const { reportId, url, resource, title } = props;
    const notify = useNotify();
    const user = TokenStorage.getUserDetails();
    const impersonateUser = TokenStorage.getImpersonateUserDetails();
    const { isImpersonating, isCustomerAdmin } = user ? user : {};
    impersonatedURL(isImpersonating, `#/${url}?impersonated`);
    const { permissions } = usePermissions();

    const [pbiEmbedConfig, setPbiEmbedConfig] = useState({
        type: 'report',
        id: '',
        embedUrl: "",
        accessToken: "",
        tokenType: models.TokenType.Aad,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                }
            },
            navContentPaneEnabled: false,
            // background: models.BackgroundType.Transparent
        },
        eventHooks: {
            accessTokenProvider: checkAccessTokenExpiration
        }
    })

    useEffect(() => {
        powerBiApi("GET", `reports/${reportId}`).then(res => {
            const { id, embedUrl, accessToken } = res;
            setPbiEmbedConfig({ ...pbiEmbedConfig, id, embedUrl, accessToken })
        }).catch(error => {
            console.log(error);
            notify("Error in getting report", "error")
        })
    }, []);

    return (
        <WithPermission resource={resource} permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <Title title={title} style={{ textTransform: "uppercase",fontSize: "20px"}}/>
            <div>
                {
                    !pbiEmbedConfig.embedUrl ? <Loading /> : <PowerBIEmbed
                        embedConfig={pbiEmbedConfig}
                        eventHandlers={
                            new Map([
                                ['loaded', async function () {
                                    const filtersArray = [
                                        {
                                            $schema: "http://powerbi.com/product/schema#basic",
                                            target: {
                                                table: "Projects",
                                                column: "Users(clientId).id"
                                            },
                                            operator: "In",
                                            values: [impersonateUser ? impersonateUser.id : user.id]
                                        },
                                    ];
                                    await window.report.updateFilters(
                                        models.FiltersOperations.Add,
                                        filtersArray
                                    );
                                }],
                                ['rendered', function () { }],
                                ['error', function (event) { console.log(event.detail); }],
                            ])
                        }

                        cssClassName={"Embed-container"}

                        getEmbeddedComponent={(embeddedReport) => {
                            window.report = embeddedReport;
                        }}
                    />
                }

            </div>
        </WithPermission>
    );
}

export default PowerBiReportEmbed;
