import PBIEmbed from "./pbiEmbed";

export const SMEReqProjectReport = (props) => {
    return (
        <PBIEmbed
            title="SME Requirement Report"
            reportId='31504e2a-80ad-44b4-9142-464fa6bf1031'
            pageName="1e23314e286a7cc0b728"
            resource='Report'
            url='sme_requirement_project_report'
        />
    )
}

export default SMEReqProjectReport;