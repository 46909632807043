import {
    Box, Button, Card, Grid, makeStyles, Table,
    TableBody,
    TableCell,
    TableContainer, TableRow, Typography, useMediaQuery
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import DownloadIcon from "@mui/icons-material/Download";
import React from "react";
import { AutocompleteInput, Loading, ReferenceInput, TextInput, Title, useDataProvider, useNotify, usePermissions } from "react-admin";
import { Form } from "react-final-form";
import catLogo from "../../assets/images/cat_logo_white.png";
import theme from "../../framework/theme";
import { impersonatedURL, saveChangeReport } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import useWindowDimensions from "../../utils/useWindowDimensions";
import WithPermission from "../../utils/WithPermission";
import { HeatMapTableInCSReport } from "../changeHeatMap/allProjectsHeatMap_modified";

const useStyles = makeStyles((theme) => ({
    drawerTitle: {
        fontWeight: 500,
        fontSize: 16,
        fontStyle: "normal",
        backgroundColor: "#E0E0E0",
    },
}));

export const ChangeStatusReport = (props) => {
    const [projectId, setProjectId] = React.useState();
    const [project, setProject] = React.useState();
    const dataProvider = useDataProvider();
    const ref = React.useRef();
    const { loading: loadingPermissions, permissions } = usePermissions();
    const user = TokenStorage.getUserDetails();
    const notify = useNotify();

    // states to get the value of the input fields in the change status report
    const [projectChangeOverviewState, setProjectChangeOverviewState] = React.useState("");
    const [keyMilestonesState, setKeyMilestonesState] = React.useState("");
    const [changeReadinessState, setChangeReadinessState] = React.useState("");
    const [changeImpactCommentState, setChangeImpactCommentState] = React.useState("");
    const [goNoGoState, setGoNoGoState] = React.useState("");
    const [subjectExpersReqCommentState, setSubExperstReqCommentState] = React.useState("");
    const [assessmentValuesInCSR, setAssessmentValuesInCSR] = React.useState();

    const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};
    impersonatedURL(isImpersonating, '#/change_status_report?impersonated');

    const handleProjectChange = (id) => {
        setProjectId(id);
    };

    const getProject = (id) => {
        return dataProvider
            .getOne("Project", { id })
            .then((response) => response.data);
    };

    React.useEffect(() => {
        getProject(projectId)
            .then((data) => {
                setProject(data);
            })
        setProjectChangeOverviewState("");
        setKeyMilestonesState("");
        setChangeReadinessState("");
        setChangeImpactCommentState("");
        setGoNoGoState("");
        setSubExperstReqCommentState("");
        setAssessmentValuesInCSR();
    }, [projectId]);

    const handleExportProjectData = () => {
        const changeStatusProjectDataArr = [];
        changeStatusProjectDataArr.push(project);
        changeStatusProjectDataArr.push(projectChangeOverviewState);
        changeStatusProjectDataArr.push(keyMilestonesState);
        changeStatusProjectDataArr.push(changeReadinessState);
        changeStatusProjectDataArr.push(changeImpactCommentState);
        changeStatusProjectDataArr.push(goNoGoState);
        changeStatusProjectDataArr.push(subjectExpersReqCommentState);
        changeStatusProjectDataArr.push(assessmentValuesInCSR);

        const changeStatusProjectData = JSON.stringify(changeStatusProjectDataArr);
        dataProvider
            .postRequestForPDF("ExportAllProjectChangeStatus", {
                queryType: "retrieve",
                data: { changeStatusProjectData },
            })
            .then((response) => {
                saveChangeReport(response.data); //here you will saved the cooked pdf from the backend using response.data
            })
            .catch((error) => {
                notify("No change status data found to export","warning");
            });
    };

    return (
        <WithPermission resource="changeStatusReport" permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <div>
                <Title title="Change Status Report" style={{ textTransform: "uppercase",fontSize: "20px"}} />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12} sm={12}>
                        <Form
                            onSubmit={() => { }}
                            render={() => (
                                <form>
                                    <ReferenceInput
                                        variant="outlined"
                                        label="Project"
                                        source="projectId"
                                        reference="Project"
                                        onChange={(value) => {
                                            handleProjectChange(value);
                                        }}
                                        fullWidth
                                    >
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                </form>
                            )}
                        />
                    </Grid>
                    <Grid item md={7} xs={12} sm={12}>

                    </Grid>
                    <Grid item md={2} xs={12} sm={12} style={{ marginTop: 8 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleExportProjectData}
                            disabled={!projectId}
                        >
                            {<DownloadIcon fontSize="small" />}
                            Export
                        </Button>
                    </Grid>
                </Grid>
                <div id="toDownload">
                    {projectId &&
                        <Report
                            projectId={projectId}
                            exportRef={ref}
                            projectChangeState={setProjectChangeOverviewState}
                            keyMilestonesState={setKeyMilestonesState}
                            changeReadinessState={setChangeReadinessState}
                            changeImpactCommentState={setChangeImpactCommentState}
                            goNoGoState={setGoNoGoState}
                            subjectExpersReqCommentState={setSubExperstReqCommentState}
                            assessmentValuesInCSR={setAssessmentValuesInCSR}
                        />
                    }
                </div>
            </div>
        </WithPermission>
    );
};

const Report = ({
    projectId,
    exportRef,
    projectChangeState,
    keyMilestonesState,
    changeReadinessState,
    changeImpactCommentState,
    goNoGoState,
    subjectExpersReqCommentState,
    assessmentValuesInCSR,
}) => {
    const dataProvider = useDataProvider();
    const [project, setProject] = React.useState();
    const [loading, setLoading] = React.useState();
    const [error, setError] = React.useState();
    const { width: windowWidth } = useWindowDimensions();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const handleSubmit = (values) => {
        console.log(values);
    };
    const handleCharacterLimit = (e, characterLimit) => {
        if (e.target.value.length > characterLimit) {
            e.target.value = e.target.value.substr(0, characterLimit);
        }
    };
    const getProject = (id) => {
        return dataProvider
            .getOne("Project", { id })
            .then((response) => response.data);
    };

    React.useEffect(() => {
        setLoading(true);
        getProject(projectId)
            .then((data) => {
                setProject(data);
                setLoading(false);
            })
            .catch((error) => {
                setError("Something went wrong");
                setLoading(false);
            });
    }, [projectId]);

    if (loading) return <Loading />;

    if (error) return <Box>{error}</Box>;

    if (!project) return null;
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = dd + "/" + mm + "/" + yyyy;

    return (
        <>
            <Box
                style={{
                    backgroundColor: "#44546a",
                    color: "white",
                    padding: "5px 10px 5px 20px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box>
                    <Typography variant="h6" style={{ margin: 0 }}>
                        Change Status Report - {project.name}
                    </Typography>
                    <Typography variant="subtitle1">Report Date: {today}</Typography>
                </Box>
                <img style={{ height: "50px" }} src={catLogo} />
            </Box>
            <Form
                onSubmit={handleSubmit}
                render={() => (
                    <form>
                        <div ref={exportRef}>
                            <Grid
                                container
                                spacing={3}
                                style={{ marginTop: "15px", marginBottom: "15px" }}
                            >
                                <Grid item md={2} xs={6} sm={6}>
                                    <LabelField
                                        label="Project Sponsor"
                                        value={project.projectSponsor.fullName}
                                    />
                                </Grid>
                                <Grid item md={2} xs={6} sm={6}>
                                    <LabelField
                                        label="Project Manager"
                                        value={project.changeLeader.fullName}
                                    />
                                </Grid>
                                <Grid item md={2} xs={6} sm={6}>
                                    <LabelField
                                        label="Change Leader"
                                        value={project.changeLeader.fullName}
                                    />
                                </Grid>
                                <Grid item md={2} xs={6} sm={6}>
                                    <LabelField
                                        label="Business Owner"
                                        value={project.businessOwner.fullName}
                                    />
                                </Grid>
                                <Grid item md={2} xs={6} sm={6}>
                                    <LabelField
                                        label="Strategic Driver"
                                        value={project.projectDriver}
                                    />
                                </Grid>
                                <Grid item md={2} xs={6} sm={6}>
                                    <LabelField
                                        label="Go Live Date"
                                        value={new Date(project.endDate).toLocaleDateString(
                                            "en-GB"
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={10}>
                                    <Grid container spacing={0}>
                                        {isSmall ? (
                                            <>
                                                <Grid item sm={12}>
                                                    <Box fontWeight="bold">Change Impact Overview</Box>
                                                    <Box width="80vw">
                                                        <HeatMapTableInCSReport
                                                            projectId={project.id}
                                                            businessUnits={[
                                                                { ...project.businessUnit, isMainBu: true },
                                                                ...project.impactedBusinessUnits,
                                                            ]}
                                                            startDate={project.startDate}
                                                            endDate={project.endDate}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <Box fontWeight="bold">
                                                        Subject Matter Experts Requirements
                                                    </Box>
                                                    <Box sx={{ width: "80vw" }}>
                                                        <SmeReqTable
                                                            projectId={projectId}
                                                            startDate={project.startDate}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={12} style={{ width: "100%" }}>
                                                    <Box fontWeight="bold">Project / Change Overview</Box>
                                                    <Box fullWidth>
                                                        <TextInput
                                                            variant="outlined"
                                                            multiline
                                                            label="Description"
                                                            source="description"
                                                            rows={4}
                                                            fullWidth
                                                        />
                                                    </Box>
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item md={12}>
                                                    <Box
                                                        fontWeight="500"
                                                        style={{
                                                            backgroundColor: "#0070c0",
                                                            display: "block",
                                                            color: "white",
                                                            padding: "0 5px",
                                                            width: "25%",
                                                        }}
                                                    >
                                                        Project / Change Overview
                                                    </Box>
                                                    <Box>
                                                        <TextInput
                                                            margin="none"
                                                            style={{ padding: 0 }}
                                                            variant="outlined"
                                                            multiline
                                                            label={false}
                                                            placeholder="1000 characters limit"
                                                            source="description"
                                                            rows={4}
                                                            onKeyUp={(e) => handleCharacterLimit(e, 1000)}
                                                            onKeyDown={(e) => handleCharacterLimit(e, 1000)}
                                                            fullWidth
                                                            onChange={(e) => {
                                                                projectChangeState(e.target.value);
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Box
                                                        fontWeight="500"
                                                        style={{
                                                            backgroundColor: "#0070c0",
                                                            display: "block",
                                                            color: "white",
                                                            padding: "0 5px",
                                                            marginBottom: "2px",
                                                            width: "25%",
                                                        }}
                                                    >
                                                        Change Impact Overview
                                                    </Box>
                                                    <Box width={windowWidth - windowWidth * 0.16 - 142}>
                                                        <HeatMapTableInCSReport
                                                            projectId={project.id}
                                                            businessUnits={[
                                                                { ...project.businessUnit, isMainBu: true },
                                                                ...project.impactedBusinessUnits,
                                                            ]}
                                                            startDate={project.startDate}
                                                            endDate={project.endDate}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="subtitle1">
                                                            Comments:
                                                        </Typography>
                                                        <TextInput
                                                            variant="outlined"
                                                            multiline
                                                            margin="none"
                                                            label={false}
                                                            placeholder="500 characters limit"
                                                            source="commentsCIO"
                                                            rows={2}
                                                            onKeyUp={(e) => handleCharacterLimit(e, 500)}
                                                            onKeyDown={(e) => handleCharacterLimit(e, 500)}
                                                            fullWidth
                                                            onChange={(e) =>
                                                                changeImpactCommentState(e.target.value)
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Box
                                                        fontWeight="500"
                                                        style={{
                                                            backgroundColor: "#0070c0",
                                                            display: "block",
                                                            color: "white",
                                                            padding: "0 5px",
                                                            width: "25%",
                                                        }}
                                                    >
                                                        Subject Matter Experts Requirements
                                                    </Box>
                                                    <Box>
                                                        <SmeReqTable
                                                            projectId={projectId}
                                                            startDate={project.startDate}
                                                        />
                                                        <Box>
                                                            <Typography variant="subtitle1">
                                                                Comments:
                                                            </Typography>

                                                            <TextInput
                                                                margin="none"
                                                                variant="outlined"
                                                                multiline
                                                                label={false}
                                                                placeholder="500 characters limit"
                                                                source="commentsSMER"
                                                                rows={2}
                                                                onKeyUp={(e) => handleCharacterLimit(e, 500)}
                                                                onKeyDown={(e) => handleCharacterLimit(e, 500)}
                                                                fullWidth
                                                                onChange={(e) =>
                                                                    subjectExpersReqCommentState(e.target.value)
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item md={2}>
                                    <Grid container>
                                        <Box
                                            fontWeight="500"
                                            style={{
                                                backgroundColor: "#0070c0",
                                                display: "inline",
                                                color: "white",
                                                padding: "0 5px",
                                                width: "90%",
                                            }}
                                        >
                                            Key Milestones
                                        </Box>
                                        <TextInput
                                            margin="none"
                                            label={false}
                                            variant="outlined"
                                            source="keyMilestones"
                                            rows={4}
                                            multiline
                                            fullWidth
                                            onChange={(e) => keyMilestonesState(e.target.value)}
                                        />
                                        <Box
                                            fontWeight="500"
                                            style={{
                                                backgroundColor: "#0070c0",
                                                display: "inline",
                                                color: "white",
                                                padding: "0 5px",
                                                width: "90%",
                                            }}
                                        >
                                            Change Readiness
                                        </Box>
                                        <TextInput
                                            variant="outlined"
                                            margin="none"
                                            label={false}
                                            placeholder="300 characters limit"
                                            source="changeReadiness"
                                            rows={3}
                                            multiline
                                            onKeyUp={(e) => handleCharacterLimit(e, 300)}
                                            onKeyDown={(e) => handleCharacterLimit(e, 300)}
                                            fullWidth
                                            onChange={(e) => changeReadinessState(e.target.value)}
                                        />
                                        <Box
                                            fontWeight="500"
                                            style={{
                                                backgroundColor: "#0070c0",
                                                display: "inline",
                                                color: "white",
                                                padding: "0 5px",
                                                width: "90%",
                                            }}
                                        >
                                            Go / No Go
                                        </Box>
                                        <TextInput
                                            variant="outlined"
                                            label={false}
                                            margin="none"
                                            source="goNoGo"
                                            placeholder="300 characters limit"
                                            rows={3}
                                            multiline
                                            onKeyUp={(e) => handleCharacterLimit(e, 300)}
                                            onKeyDown={(e) => handleCharacterLimit(e, 300)}
                                            fullWidth
                                            onChange={(e) => goNoGoState(e.target.value)}
                                        />
                                        <LabelField
                                            style={{ width: "100%" }}
                                            value={
                                                <ChangeAssessmentReport
                                                    projectId={project.id}
                                                    businessUnit={project.businessUnit}
                                                    impactedBusinessUnits={project.impactedBusinessUnits}
                                                    assessmentValuesInCSR={assessmentValuesInCSR}
                                                />
                                            }
                                            impactBu={true}
                                            md={12}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                )}
            />
        </>
    );
};

const ChangeAssessmentReport = ({
    projectId,
    businessUnit,
    impactedBusinessUnits,
    assessmentValuesInCSR
}) => {
    const dataProvider = useDataProvider();
    const [assessmentValues, setAssessmentValues] = React.useState();
    const [loading, setLoading] = React.useState();
    const [error, setError] = React.useState();

    const getRisk = (value) => {
        switch (true) {
            case value >= 270:
                return { value: "H", color: "#EB5757" };
            case value >= 138:
                return { value: "M", color: "#F2994A" };
            case value < 138:
                return { value: "L", color: "#27AE60" };
        }
    };

    React.useEffect(async () => {
        setLoading(true);
        setAssessmentValues(null);
        setError(null);
        const businessUnits = [];
        businessUnits.push(businessUnit);
        businessUnits.push(...impactedBusinessUnits);

        const assessments = await Promise.all(
            businessUnits.map((businessUnit) =>
                dataProvider
                    .getOne("AssessmentOverview", {
                        id: null,
                        data: { projectId, businessUnitId: businessUnit.id },
                    })
                    .then((response) => {
                        return { ...response.data, businessUnit };
                    })
                    .catch((error) => {
                        // console.log(error)
                        return null;
                    })
            )
        );

        const filteredAssessments = assessments.filter((x) => Boolean(x));
        if (filteredAssessments && filteredAssessments.length > 0) {
            const data = filteredAssessments.map(
                ({ overallChangeImpactValue, businessUnit }) => ({
                    risk: getRisk(overallChangeImpactValue),
                    businessUnit,
                })
            );

            setAssessmentValues(data);
        } else {
            setError("No assessments found");
        }
        setLoading(false);
    }, []);

    if (loading)
        return (
            <Box height={100}>
                <Loading />
            </Box>
        );

    if (error) return error;

    if (!assessmentValues) return null;
    assessmentValuesInCSR(assessmentValues);
    return (
        <Box display="flex" flexDirection="column">
            {assessmentValues.map((assessmentValue) => (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={2}
                >
                    <Box>{assessmentValue.businessUnit.name}</Box>
                    <Box ml={2}>
                        <Avatar
                            style={{
                                color: "#ffffff",
                                backgroundColor: assessmentValue.risk.color,
                                width: 25,
                                height: 25,
                                fontSize: theme.typography.fontSize,
                            }}
                        >
                            {assessmentValue.risk.value}
                        </Avatar>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

const LabelField = ({ label, value, impactBu, ...rest }) => (
    <Grid item flexDirection="column" {...rest}>
        <Card style={{ border: "1px solid 	#C8C8C8" }}>
            <Box>
                {impactBu ? (
                    <Box
                        fontWeight="500"
                        style={{
                            backgroundColor: "#0070c0",
                            display: "inline",
                            color: "white",
                            padding: "0 5px",
                            width: "90%",
                        }}
                    >
                        Business Units Impacted
                    </Box>
                ) : (
                    <Box style={{ paddingLeft: "5px" }}>{label}</Box>
                )}

                <Box
                    color={theme.palette.primary.main}
                    style={{ padding: "0 5px 5px 5px" }}
                    fontWeight="bold"
                >
                    {value}
                </Box>
            </Box>
        </Card>
    </Grid>
);

const SmeReqTable = ({ projectId, startDate }) => {
    const classes = useStyles();
    const [projectSmeRequirements, setProjectSmeRequirements] =
        React.useState(false);
    const [loading, setLoading] = React.useState();
    const [error, setError] = React.useState();
    const dataProvider = useDataProvider();

    const getAllProjectsSmeReq = async (months) => {
        return dataProvider
            .postRequest("AllProjectsSmeReq", {
                queryType: "retrieve",
                data: { startDate, projectId },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                setError("Error in fetching project SME requirements");
            });
    };

    React.useEffect(async () => {
        setLoading(true);
        const allProjectsSmeReq = await getAllProjectsSmeReq();
        setLoading(false);
        setProjectSmeRequirements(allProjectsSmeReq);
    }, []);

    if (loading) return <Loading />;

    if (error) {
        return (
            <Box mb={3}>
                <Card>
                    <Box p={3} color={theme.palette.error.main}>
                        {error}
                    </Box>
                </Card>
            </Box>
        );
    }

    if (
        !projectSmeRequirements ||
        (projectSmeRequirements && projectSmeRequirements.length === 0)
    ) {
        return (
            <Box mb={3}>
                <Card>
                    <Box p={3} color={theme.palette.error.main}>
                        No SME requirements found
                    </Box>
                </Card>
            </Box>
        );
    }
    return (
        <Card style={{ border: "1px solid 	#C8C8C8" }}>
            <Box display="flex" flexDirection="column" overflow="auto">
                <TableContainer>
                    <Table aria-label="sme table">
                        <TableBody>
                            {projectSmeRequirements.map((row, i) => (
                                <TableRow
                                    key={row}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    {row.map(
                                        (cell, i) =>
                                            i > 0 && (
                                                <TableCell padding="none" align="center">
                                                    <Box fontWeight={i == 0 ? 500 : null}>
                                                        {cell.slice(0, 4)}
                                                    </Box>
                                                </TableCell>
                                            )
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Card>
    );
};

export default ChangeStatusReport;
