import PBIEmbed from "./pbiEmbed";

export const CloseOutReport = (props) => {
    return (
        <PBIEmbed
            title="Project Close-Out Report"
            reportId='31504e2a-80ad-44b4-9142-464fa6bf1031'
            pageName="2ab9173e06e185a6a657"
            resource='Report'
            url='close_out_report'
        />
    )
}

export default CloseOutReport;