import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export const RiskAssessmentModalTable = ({ tableResponse }) => {
    const classes = useStyles();

    const [riskMasters, setRiskMasters] = useState([]);

    return (
        <div style={{ height: '90vh', overflowY: 'auto' }}>
            <Grid container>
                <Grid item lg={2} style={{ border: '1px solid #000000' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                        <Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`1.${tableResponse[0]?.riskLabel}`}</Typography>
                                <Typography style={{ fontSize: '13px' }}>{`${tableResponse[0]?.riskDescription}`}</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`2.${tableResponse[1]?.riskLabel}`}</Typography>
                                <Typography style={{ fontSize: '13px' }}>{`${tableResponse[1]?.riskDescription}`}</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`3.${tableResponse[2]?.riskLabel}`}</Typography>
                                <Typography style={{ fontSize: '13px' }}>{`${tableResponse[2]?.riskDescription}`}</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`4.${tableResponse[0]?.riskLabel}`}</Typography>
                                <Typography style={{ fontSize: '13px' }}>{`${tableResponse[3]?.riskDescription}`}</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`5.${tableResponse[4]?.riskLabel}`}</Typography>
                                <Typography style={{ fontSize: '13px' }}>{`${tableResponse[4]?.riskDescription}`}</Typography>
                            </Box>
                        </Box>
                        {/* <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "#B3B3B3" }}>
                            <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>{tableResponse[0]?.type} </Typography>
                        </Box> */}
                    </Box>
                </Grid>
                <Grid item lg={10} style={{ borderRight: '1px solid #000000' }}>

                    <Box style={{ display: 'flex', }}>
                        <Box style={{ flex: 1 }}>

                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#C6EFCE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#75bf84', fontWeight: 'bold', fontSize: '18px' }}>Low</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#C6EFCE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#75bf84', fontWeight: 'bold', fontSize: '18px' }}>Low</Typography>
                            </Box >
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#C6EFCE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#75bf84', fontWeight: 'bold', fontSize: '18px' }}>Low</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFEB9C', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#d1c184', fontWeight: 'bold', fontSize: '18px' }}>Medium</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFEB9C', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#d1c184', fontWeight: 'bold', fontSize: '18px' }}>Medium</Typography>
                            </Box>
                            {/* <Box style={{ padding: '4px 6px' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`1.${tableResponse[5]?.riskLabel}`}</Typography>
                                <Typography style={{ fontSize: '13px' }}>{`${tableResponse[5]?.riskDescription}`}</Typography>
                            </Box> */}
                        </Box>
                        <Box style={{ flex: 1 }}>

                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#C6EFCE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#75bf84', fontWeight: 'bold', fontSize: '18px' }}>Low</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFEB9C', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#d1c184', fontWeight: 'bold', fontSize: '18px' }}>Medium</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFEB9C', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#d1c184', fontWeight: 'bold', fontSize: '18px' }}>Medium</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#bd910d', fontWeight: 'bold', fontSize: '18px' }}>High</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#bd910d', fontWeight: 'bold', fontSize: '18px' }}>High</Typography>
                            </Box>
                            {/* <Box style={{ border: '1px solid #000', padding: '4px 6px' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`2.${tableResponse[6]?.riskLabel}`}</Typography>
                                <Typography style={{ fontSize: '13px' }}>{`${tableResponse[6]?.riskDescription}`}</Typography>
                            </Box> */}
                        </Box>
                        <Box style={{ flex: 1 }}>

                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#C6EFCE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#75bf84', fontWeight: 'bold', fontSize: '18px' }}>Low</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFEB9C', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#d1c184', fontWeight: 'bold', fontSize: '18px' }}>Medium</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#bd910d', fontWeight: 'bold', fontSize: '18px' }}>High</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#bd910d', fontWeight: 'bold', fontSize: '18px' }}>High</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC7CE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#c47e86', fontWeight: 'bold', fontSize: '18px' }}>Extreme</Typography>
                            </Box>
                            {/* <Box style={{ border: '1px solid #000', padding: '4px 6px' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`3.${tableResponse[7]?.riskLabel}`}</Typography>
                                <Typography style={{ fontSize: '13px' }}>{`${tableResponse[7]?.riskDescription}`}</Typography>
                            </Box> */}
                        </Box>
                        <Box style={{ flex: 1 }}>

                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFEB9C', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#d1c184', fontWeight: 'bold', fontSize: '18px' }}>Medium</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#bd910d', fontWeight: 'bold', fontSize: '18px' }}>High</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#bd910d', fontWeight: 'bold', fontSize: '18px' }}>High</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC7CE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#c47e86', fontWeight: 'bold', fontSize: '18px' }}>Extreme</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC7CE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#c47e86', fontWeight: 'bold', fontSize: '18px' }}>Extreme</Typography>
                            </Box>
                            {/* <Box style={{ border: '1px solid #000', padding: '4px 6px' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`4.${tableResponse[8]?.riskLabel}`}</Typography>
                                <Typography style={{ fontSize: '13px' }}>{`${tableResponse[8]?.riskDescription}`}</Typography>
                            </Box> */}
                        </Box>
                        <Box style={{ flex: 1 }}>

                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFEB9C', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#d1c184', fontWeight: 'bold', fontSize: '18px' }}>Medium</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#bd910d', fontWeight: 'bold', fontSize: '18px' }}>High</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC7CE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#c47e86', fontWeight: 'bold', fontSize: '18px' }}>Extreme</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC7CE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#c47e86', fontWeight: 'bold', fontSize: '18px' }}>Extreme</Typography>
                            </Box>
                            <Box style={{ height: "90px", border: '1px solid #000', padding: '4px 6px', backgroundColor: '#FFC7CE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography align="center" style={{ color: '#c47e86', fontWeight: 'bold', fontSize: '18px' }}>Extreme</Typography>
                            </Box>
                            {/* <Box style={{ border: '1px solid #000', padding: '1px 6px' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`5.${tableResponse[9]?.riskLabel}`}</Typography>
                                <Typography style={{ fontSize: '13px' }}>{`${tableResponse[9]?.riskDescription}`}</Typography>
                            </Box> */}
                        </Box>
                    </Box>
                    {/* <Box style={{ backgroundColor: '#B3B3B3', borderBottom: '1px solid #000' }}>
                        <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>{`${tableResponse[5]?.type}`}</Typography>
                    </Box> */}
                </Grid>
                <Box style={{ display: 'flex' }}>
                    <Box style={{ display: 'flex', flex: 1, padding: "0 6px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#B3B3B3", borderRight: "2px solid #000" }}>
                        <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>{tableResponse[0]?.type} </Typography>
                    </Box>
                    <Box style={{ borderRight: '2px solid #000', padding: '0 6px', flex: 1 }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`1.${tableResponse[5]?.riskLabel}`}</Typography>
                        <Typography style={{ fontSize: '13px' }}>{`${tableResponse[5]?.riskDescription}`}</Typography>
                    </Box>
                    <Box style={{ borderRight: '2px solid #000', padding: '0 6px', flex: 1 }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`2.${tableResponse[6]?.riskLabel}`}</Typography>
                        <Typography style={{ fontSize: '13px' }}>{`${tableResponse[6]?.riskDescription}`}</Typography>
                    </Box>
                    <Box style={{ borderRight: '2px solid #000', padding: '0 6px', flex: 1 }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`3.${tableResponse[7]?.riskLabel}`}</Typography>
                        <Typography style={{ fontSize: '13px' }}>{`${tableResponse[7]?.riskDescription}`}</Typography>
                    </Box>
                    <Box style={{ borderRight: '2px solid #000', padding: '0 6px', flex: 1 }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`4.${tableResponse[8]?.riskLabel}`}</Typography>
                        <Typography style={{ fontSize: '13px' }}>{`${tableResponse[8]?.riskDescription}`}</Typography>
                    </Box>
                    <Box style={{ borderRight: '2px solid #000', padding: '0 6px', flex: 1 }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{`5.${tableResponse[9]?.riskLabel}`}</Typography>
                        <Typography style={{ fontSize: '13px' }}>{`${tableResponse[9]?.riskDescription}`}</Typography>
                    </Box>
                </Box>
                <Box style={{ backgroundColor: '#B3B3B3', padding: "0 8px", borderBottom: '1px solid #000', flex: 1 }}>
                    <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>{`${tableResponse[5]?.type}`}</Typography>
                </Box>
            </Grid>
        </div>
    );
}
