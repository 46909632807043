import axios, { AxiosRequestConfig } from 'axios';
import { Promise } from 'es6-promise';
import { API_URL } from '../framework/constants';


interface User {
    id: number;
    surname?: String;
    firstName?: String;
    fullName: String;
    email?: String;
    companyName?: String;
    location?: String;
    isManagedService?: Boolean;
    customerAdminName?: String;
    isSuperAdmin: Boolean;
    isCustomerAdmin: Boolean;
    description?: String;
    industry?: String;
    status: Boolean;
    businessUnit?: any;
    roles?: Array<any>;
    lastLoggedInAt?: Date;
    permissions?: Array<any>;
}

export class TokenStorage {

    private static readonly LOCAL_STORAGE_TOKEN = 'access';
    private static readonly LOCAL_STORAGE_PROJECT = 'projectDetails';
    private static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refresh';
    private static readonly REFRESH_TOKEN_RESOURCE = "auth/token/refresh";
    private static readonly LOCAL_STORAGE_USER = "user";
    private static readonly LOCAL_STORAGE_IMPERSONATE_USER = "impersonate_user";
    private static readonly LOCAL_STORAGE_PROJECT_DATA = "projectData";
    private static readonly LOCAL_STORAGE_PBI_ACCESS_TOKEN = "pbi_access_token";
    private static readonly LOCAL_STORAGE_PBI_REFRESH_TOKEN = "pbi_refresh_token";

    public static isAuthenticated(): boolean {
        return this.getToken() !== null;
    }

    public static getAuthentication(): AxiosRequestConfig {
        return {
            headers: { 'Authorization': 'Bearer ' + this.getToken() }
        };
    }

    public static getNewToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${API_URL}/${TokenStorage.REFRESH_TOKEN_RESOURCE}/`, { refresh: this.getRefreshToken() })
                .then(response => {

                    this.storeToken(response.data.access);

                    // TODO get refresh token and store in local storage
                    // this.storeRefreshToken(response.data.data.refresh);

                    resolve(response.data.access);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public static getNewPbiToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${API_URL}`,
                    {
                        query: `mutation(
                        $refreshToken: String!
                      ) {
                        pbiToken(
                          refreshToken: $refreshToken
                        ) {
                          pbi_access_token
                          pbi_refresh_token
                        }
                      } `,
                        variables: {
                            refreshToken: this.getPbiRefreshToken()
                        }
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${this.getToken()}`
                        }
                    })
                .then(response => {
                    this.pbiStoreToken(response.data.data.pbiToken.pbi_access_token, response.data.data.pbiToken.pbi_refresh_token);

                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public static storeToken(token: string): void {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
    }
    public static storeProjectDetails(project: string): void {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_PROJECT, project);
    }
    public static pbiStoreToken(pbi_access_token: string, pbi_refresh_token: string): void {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_PBI_ACCESS_TOKEN, pbi_access_token);
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_PBI_REFRESH_TOKEN, pbi_refresh_token);
    }
    public static storeRefreshToken(refreshToken: string): void {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
    }

    public static storeUserDetails(user_details: string): void {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_USER, user_details);
    }

    public static storeImpersonateUserDetails(user_details: string): void {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_IMPERSONATE_USER, user_details);
    }

    public static clearImpersonateUserDetails(): void {
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_IMPERSONATE_USER);
    }

    public static clear(): void {
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_PBI_REFRESH_TOKEN);
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_PBI_ACCESS_TOKEN);
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_USER);
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_IMPERSONATE_USER);
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_PROJECT_DATA);
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_PROJECT)
    }

    public static getRefreshToken(): string | null {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    }

    public static getToken(): string | null {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    }
    public static getProjectDetails(): string | null {
        
        const project = localStorage.getItem(TokenStorage.LOCAL_STORAGE_PROJECT);
        return project ? JSON.parse(project): null
    }
    public static getPbiAccessToken(): string | null {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_PBI_ACCESS_TOKEN);
    }

    public static getPbiRefreshToken(): string | null {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_PBI_REFRESH_TOKEN);
    }

    public static getRoles(): Array<any> | null | undefined {
        const user = this.getUserDetails();
        const roles = user ? user.roles : null;
        return roles;
    }

    public static getPermissions(): Array<any> | null | undefined {
        const user = this.getUserDetails();
        const permissions = user ? user.permissions : null;
        return permissions;
    }

    public static getUserDetails(): User | null {
        const impersonateUser = this.getImpersonateUserDetails();
        if (impersonateUser) {
            return impersonateUser;
        }

        const userStr = localStorage.getItem(TokenStorage.LOCAL_STORAGE_USER);
        return userStr ? JSON.parse(userStr) as User : null;
    }

    public static getImpersonateUserDetails(): User | null {
        const userStr = localStorage.getItem(TokenStorage.LOCAL_STORAGE_IMPERSONATE_USER);
        return userStr ? JSON.parse(userStr) as User : null;
    }

    public static storeProjectData(projectData: string): void {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_PROJECT_DATA, projectData);
    }

    public static getProjectData(): string | null {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_PROJECT_DATA);
    }

    public static clearProjectData(): void {
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_PROJECT);
    }
}