export const CHANGE_ASSESSMENT_LIST_QUERY = `query(
  $page: Int
  $perPage: Int
  $sortField: String
  $sortOrder: String
  $filter: ChangeAssessmentFilter!
) {
  allChangeAssessments(
    page: $page
    perPage: $perPage
    sortField: $sortField
    sortOrder: $sortOrder
    filter: $filter
  ) {
    items {
      id
      projectId
      businessUnitId
      createdAt
      updatedAt
    }
    totalCount
  }
}`;

export const CHANGE_ASSESSMENT_RETRIEVE_QUERY = `query($id: Int!) {
  changeAssessment(id: $id) {
    id
    projectId
    businessUnitId
    createdAt
    updatedAt
    changeAssessmentValues {
      assessmentSectionId
      assessmentTypeId
      assessmentSubTypeId
      assessmentValueId
      assessmentSection {
        id
        name
      }
      assessmentType {
        id
        name
      }
      assessmentSubType {
        id
        name
      }
      assessmentValue {
        id
        nature
        signific
        description
        rating
      }
    }
  }
}`;

export const CHANGE_ASSESSMENT_UPDATE_MUTATION = `mutation(
  $id: Int!
  $projectId: Int!
  $businessUnitId: Int!
  $changeAssessmentValues: [ChangeAssessmentValueInput!]
) {
  updateChangeAssessment(
    id: $id
    projectId: $projectId
    businessUnitId: $businessUnitId
    changeAssessmentValues: $changeAssessmentValues
  ) {
    id
  }
}`;

export const CHANGE_ASSESSMENT_CREATE_MUTATION = `mutation(
    $projectId: Int!
    $businessUnitId: Int!
    $changeAssessmentValues: [ChangeAssessmentValueInput!]
  ) {
    createChangeAssessment(
      projectId: $projectId
      businessUnitId: $businessUnitId
      changeAssessmentValues: $changeAssessmentValues
    ) {
      id
    }
  }`;

export const CHANGE_ASSESSMENT_DELETE_MUTATION = `mutation($id: Int!) {
    deleteChangeAssessment(id: $id)
  }`;

export const ASSESSMENT_OVERVIEW_RETRIEVE_QUERY = `query($projectId: Int!, $businessUnitId: Int!) {
    assessmentOverview(projectId: $projectId, businessUnitId: $businessUnitId) {
      id
      projectId
      project {
        id
        name
        startDate
        endDate
        changeLeader {
          id
          firstName
          surname
          fullName
        }
        projectSponsor {
          id
          fullName
        }
      }
      businessUnitId
      overallImpact
      overallScope
      overallChallenge
      createdAt
      updatedAt
      overallChangeImpactValue
      overallChangeMgtImpactValues
      changeManagementImpacts {
        id
        assessmentSectionId
        assessmentTypeId
        assessmentType {
          id
          name
        }
        assessmentSubTypeId
        assessmentValueId
        assessmentSubType {
          id
          name
        }
        assessmentValue {
          id
          nature
          signific
          description
          rating
        }
      }
    }
  }`;

export const OVERALL_ASSESSMENT_QUERY = `query($projectId: Int!) {
    overallAssessment(projectId: $projectId) {
      id
      projectId
      businessUnitId
      overallImpact
      overallScope
      overallChallenge
    }
  }`;

export const EXPORT_PROJECT_CHANGE_IMPACT_ASSESMENT_REPORT = `
query($changeAssesmentReportData: String!){
  exportchangeImpactAssesmentReport(changeAssesmentReportData: $changeAssesmentReportData)
}`;

export const EXPORT_ALL_PROJECT_CHANGE_STATUS = `
query($changeStatusProjectData: String!){
  exportAllProjectChangeStatus(changeStatusProjectData: $changeStatusProjectData)
}`;

export const EXPORT_CHANGE_ASSESSMENT_DATA_FOR_PDF = `
query($changeAssesmentReportDataForPDF: String!){
  exportChangeAssessmentDataForPDF(changeAssesmentReportDataForPDF: $changeAssesmentReportDataForPDF)
}`;