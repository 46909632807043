export const FAQ_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: FaqFilter
  ) {
    allFaqs(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        question
        answer
      }
      totalCount
    }  
  }`;

export const FAQ_UPDATE_MUTATION = `mutation($id: Int!, $question: String, $answer: String) {
    updateFaq(id: $id, question: $question, answer: $answer) {
        id
        question
        answer
      }
  }`;

export const FAQ_CREATE_MUTATION = `mutation($question: String!, $answer: String!) {
    createFaq(question: $question, answer: $answer) {
      id
      question
      answer
    }  
  }`;

export const FAQ_DELETE_MUTATION = `mutation($id: Int!) {
    deleteFaq(
      id: $id
    )
  }`;

export const FAQ_UPDATE_BULK_MUTATION = `mutation($faqs: [FaqInput!]) {
    createOrUpdateFaqs(faqs: $faqs) {      
      question
      answer
    }
  }`;
