import { Button } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
import { useContext } from 'react';
import { ThemeContext } from '../framework/theme/themeContext';
// import theme from '../framework/theme';

const ExportButton = ({ handleExport, filename, node2Export, btnName, varient, width, exportFile }) => {
    const {themeObject} = useContext(ThemeContext)
    return (
        <Button
            variant={varient}
            style={{ padding: themeObject.spacing(0, 0, 0, 0), width: width }}
            onClick={exportFile}
            color="primary"
        ><DownloadIcon fontSize="small" /> {btnName}
        </Button>
    )
}

export default ExportButton;