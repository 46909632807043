export const SME_REQUIREMENT_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: SmeRequirementFilter!
  ) {
    allSmeRequirements(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        projectId
        businessUnitId
        businessUnit{
            id
            name
        }
        month
        forecastEmployees
        employees
        commentary
        updatedAt
      }
      totalCount
    }
  }`

export const SME_REQUIREMENT_RETRIEVE_QUERY = `query($id: Int!) {
    smeRequirement(id: $id) {
      id
      projectId
      businessUnitId
      startDate
      endDate
      employees
    }
  }`

export const SME_REQUIREMENT_UPDATE_MUTATION = `mutation(
    $projectId: Int!
    $smeRequirements: [SmeRequirementUpdInput!]!
  ) {
    updateSmeRequirements(
      projectId: $projectId
      smeRequirements: $smeRequirements
    ) {
      id
      projectId
      businessUnitId
      month
      forecastEmployees
      employees
      commentary
    }
  }`

export const SME_REQUIREMENT_CREATE_MUTATION = `mutation(
    $projectId: Int!
    $smeRequirements: [SmeRequirementInput!]!
  ) {
    createSmeRequirements(
      projectId: $projectId
      smeRequirements: $smeRequirements
    ) {
      id
      projectId
      businessUnitId
      month
      forecastEmployees
      employees
      commentary
    }
  }`

export const SME_REQUIREMENT_DELETE_MUTATION = `mutation($id: Int!) {
    deleteSmeRequirement(id: $id)
  }`

export const MONTHLY_SME_REQUIREMENTS_RETRIEVE_QUERY = `query($projectId: Int, $startDate: Date, $months: Int!) {
    monthlySmeRequirements(
      projectId: $projectId
      startDate: $startDate
      months: $months
    ) {
      businessUnitId
      businessUnit {
        id
        name
      }
      reqMonth
      sme
    }
  }`

export const ALL_PROJECT_SME_REQ_RETRIEVE_QUERY = `query( $startDate: Date!, $projectId: Int) {
    allProjectsSmeReq(startDate: $startDate, projectId: $projectId)
}`

export const ALL_BU_SME_REQ_RETRIEVE_QUERY = `query( $startDate: Date!) {
    allBusinessUnitsSmeReq(startDate: $startDate)
}`


export const EXPORT_SME_REQUIRED = `
query($smeReqData: String!){
  exportSmeRequired(smeReqData: $smeReqData)
}`

export const EXPORT_SME_REQUIRED_REPORT = `
query($smeReqReportData: String!){
  exportSmeRequiredReport(smeReqReportData: $smeReqReportData)
}`

export const EXPORT_All_BUS_SME_REQ = `
query($buSmeReqData: String!){
  exportAllBusSmeReq(buSmeReqData: $buSmeReqData)
}`

export const EXPORT_All_PROJECT_SME_REQ = `
query($projectSmeReqData: String!){
  exportAllProjectSmeReq(projectSmeReqData: $projectSmeReqData)
}`

