import {
    Box,
    Card,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React from "react";
import {
    AutocompleteArrayInput,
    AutocompleteInput, Loading,
    ReferenceArrayInput,
    ReferenceInput, Title, useDataProvider, useNotify,
    usePermissions,
} from "react-admin";
import { Form } from "react-final-form";
import { PROJECT_DRIVERS_MASTER_DATA_ID } from "../../framework/constants";
import theme from "../../framework/theme";
import { impersonatedURL, onFailureMessage, startEndDatesOfWeeks } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";


const useStyles = makeStyles((theme) => ({
    root: {
        "&.MuiTableCell-root": {
            padding: 50,
        }
    },
    inputWidthMargin: {
        marginRight: 30,
    },
    referenceInputWidth: {
        width: 350,
    },
    bottomCardTitle: {
        backgroundColor: "#E0E0E0",
    },
    rowHeading: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 16,
        color: "#4F4F4F",
    },
    forecastRiskBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        width: 60,
        height: 30,
        opacity: 0.3,
        borderRadius: 4,
        "&.active": {
            opacity: 1,
        },
        "&.risk-high": {
            backgroundColor: "#EB5757",
        },
        "&.risk-medium": {
            backgroundColor: "#F2994A",
        },
        "&.risk-low": {
            backgroundColor: "#27AE60",
        },
    },
    forecastRiskBoxCSR: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        width: "100%",
        height: 20,
        opacity: 0.3,
        // borderRadius: 4,
        "&.active": {
            opacity: 1,
        },
        "&.risk-high": {
            backgroundColor: "#EB5757",
        },
        "&.risk-medium": {
            backgroundColor: "#F2994A",
        },
        "&.risk-low": {
            backgroundColor: "#27AE60",
        },
    },
    changeImpactBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        width: 200,
        height: 30,
        opacity: 0.3,
        borderRadius: 4,
        "&.active": {
            opacity: 1,
        },
        "&.risk-high": {
            backgroundColor: "#EB5757",
        },
        "&.risk-medium": {
            backgroundColor: "#F2994A",
        },
        "&.risk-low": {
            backgroundColor: "#27AE60",
        },
    },
    borderRight: {
        borderRight: "2px solid #C4C4C4",
    },
    borderBottom: {
        borderBottom: "2px solid #C4C4C4",
    },
    borderRightCSR: {
        borderRight: "2px solid white",
        zIndex: -1
    },
    borderBottomCSR: {
        borderBottom: "2px solid white",
    },
    tableHeading1: {
        backgroundColor: "#4473c4",
    },
    tableHeading2: {
        backgroundColor: "#F2F2F2",
    },
    responsiveBox: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            marginBottom: '5px',
            marginLeft: '0px',
        },
    },
    responsiveTable: {
        [theme.breakpoints.down('sm')]: {
            width: `calc(100vw - 63px)`,
        },
    },
    columnFreezed: {
        position: 'sticky',
        left: 0,
        // zIndex: 999,
        borderRight: '2px solid #C4C4C4',
        backgroundColor: '#F2F2F2'
    },
    columnFreezed1: {
        position: 'sticky',
        left: 0,
        // zIndex: 2000,
        borderRight: '2px solid #C4C4C4',
        borderBottom: '2px solid #C4C4C4',
        backgroundColor: '#fff'
    },
    columnLowPriority: {
        borderRight: "2px solid #C4C4C4",
        zIndex: -1,
    },
    title: {
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    }
}));

export const ChangeHeatMap = (props) => {
    const [project, setProject] = React.useState();
    const [overallAssessment, setOverallAssessment] = React.useState();
    const [projectFilters, setProjectFilters] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();
    const [businessUnitIds, setBusinessUnitIds] = React.useState();
    const [impactedBusinessUnitIds, setImpactedBusinessUnitIds] = React.useState();
    const [projectDriver, setProjectDriver] = React.useState();
    const [projectDriverChoices, setProjectDriverChoices] = React.useState([]);
    const [loadingProjectDriverChoices, setLoadingProjectDriverChoices] = React.useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const { loading: loadingPermissions, permissions } = usePermissions();

    const user = TokenStorage.getUserDetails();
    const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};
    impersonatedURL(isImpersonating, '#/change_heat_map?impersonated');

    const getProject = (id) => {
        return dataProvider
            .getOne("Project", { id })
            .then((response) => response.data);
    };

    const getOverallAssessment = (projectId) => {
        return dataProvider
            .postRequest("OverallAssessment", {
                queryType: "retrieve",
                data: { projectId },
            })
            .then(({ data }) => {
                if (data) {
                    const overallChangeImpactTxt = getOverallChangeImpact(data)
                    const overallChangeScopeTxt = getOverallScope(data)
                    const overallChallengeTxt = getOverallChallenge(data)

                    setOverallAssessment({
                        overallImpact: overallChangeImpactTxt,
                        overallScope: overallChangeScopeTxt,
                        overallChallenge: overallChallengeTxt,
                    })
                } else {
                    setOverallAssessment(null)
                }

            }).catch(error => {
                setOverallAssessment(null)
            })
    };

    const handleProjectChange = async (projectId) => {
        if (projectId) {
            setLoading(true);
            getOverallAssessment(projectId);
            getProject(projectId)
                .then(data => {
                    setProject(data)
                    setLoading(false);
                })
                .catch(error => {
                    setError("error in getting project data")
                    setLoading(false);
                })
        } else {
            setProject(null);
            setOverallAssessment(null);
        }
    }

    const handleMainBUsChange = async (ids) => {
        setBusinessUnitIds(ids);
        if (ids && ids.length) setProjectFilters(preState => ({ ...preState, businessUnitIds: ids }))
        else {
            setProjectFilters(preState => {
                delete preState.businessUnitIds
                return { ...preState }
            })
        }
    }

    const handleImpactedBUsChange = async (ids) => {
        console.log(ids);
        console.log(projectFilters);

        setImpactedBusinessUnitIds(ids);
        if (ids && ids.length) {
            setProjectFilters(preState => ({ ...preState, impactedBusinessUnitIds: ids }))
            console.log(ids.length);
            console.log(projectFilters);
        }
        else {
            setProjectFilters(preState => {
                delete preState.impactedBusinessUnitIds
                return { ...preState }
            })
        }
    }

    const handleProjectDriverChange = async (name) => {
        console.log(name);
        setProjectDriver(name);
        if (name) {
            setProjectFilters(preState => ({ ...preState, projectDriver: name }))

            console.log(projectFilters);
        }

    }

    /*     React.useEffect(() => {
            setLoadingProjectDriverChoices(true)
            dataProvider
                .getList("MasterDataValue", {
                    pagination: { page: 1, perPage: 25 },
                    sort: { field: "name", order: "ASC" },
                    filter: { masterDataId: PROJECT_DRIVERS_MASTER_DATA_ID },
                })
                .then(({ data }) => {
                    setProjectDriverChoices(data)
                    setLoadingProjectDriverChoices(false)
                })
                .catch(error => {
                    console.log(error);
                    setLoadingProjectDriverChoices(false)
                    setProjectDriverChoices([])
                    notify("could not able to fetch project driver list")
                })
    
            const projectDriver = props.location.state ? props.location.state.projectDriver : null;
            const businessUnitIds = props.location.state && props.location.state.businessUnitId
                ? [props.location.state.businessUnitId]
                : null;
            setProjectDriver(projectDriver)
            setBusinessUnitIds(businessUnitIds)
            if (projectDriver) setProjectFilters(preState => ({ ...preState, projectDriver }))
            if (businessUnitIds && businessUnitIds.length) setProjectFilters(preState => ({ ...preState, businessUnitIds }))
        }, []) */

    React.useEffect(() => {
        setLoadingProjectDriverChoices(true)
        dataProvider
            .getList("MasterDataValue", {
                pagination: { page: 1, perPage: 25 },
                sort: { field: "name", order: "ASC" },
                filter: { masterDataId: PROJECT_DRIVERS_MASTER_DATA_ID },
            })
            .then(({ data }) => {
                setProjectDriverChoices(data)
                setLoadingProjectDriverChoices(false)
            })
            .catch(error => {
                console.log(error);
                setLoadingProjectDriverChoices(false)
                setProjectDriverChoices([])
                notify("Could not able to fetch project driver list", "error")
            })

        const projectDriver = props.location.state ? props.location.state.projectDriver : null;
        const impactedBusinessUnitIds = props.location.state && props.location.state.businessUnitId
            ? [props.location.state.businessUnitId]
            : null;
        setProjectDriver(projectDriver)
        setImpactedBusinessUnitIds(impactedBusinessUnitIds)
        if (projectDriver) setProjectFilters(preState => ({ ...preState, projectDriver }))
        if (impactedBusinessUnitIds && impactedBusinessUnitIds.length) setProjectFilters(preState => ({ ...preState, impactedBusinessUnitIds }))
    }, [])


    return (
        <WithPermission resource="changeImpactHeatMap" permissions={permissions} bypassUser={isCustomerAdmin} action={["view"]} {...props} >
            <Title title="Change Impact Heatmap" className={classes.title}/>
            <Box display="flex" flexDirection="column">
                <Form
                    onSubmit={() => { }}
                    render={() => (
                        <>
                            <Grid container>
                                {/*      <Grid item md={4} xs={12} sm={12} sx={{border: '5px solid red'}}>
                                    <ReferenceArrayInput
                                        variant="outlined"
                                        label="Main Business Unit"
                                        source="businessUnitIds"
                                        reference="BusinessUnit"
                                        initialValue={businessUnitIds}
                                        onChange={(value) => handleMainBUsChange(value)}
                                        resettable={true}
                                        fullWidth
                                    >
                                        <AutocompleteArrayInput optionText="name" />
                                    </ReferenceArrayInput>
                                </Grid> */}

                                <Grid item md={5} xs={12} sm={12}>
                                    <ReferenceArrayInput
                                        variant="outlined"
                                        label="Business Unit"
                                        source="impactedBusinessUnitIds"
                                        reference="BusinessUnit"
                                        initialValue={impactedBusinessUnitIds}
                                        onChange={(value) => handleImpactedBUsChange(value)}
                                        resettable={true}
                                        fullWidth
                                    >
                                        <AutocompleteArrayInput optionText="name" />
                                    </ReferenceArrayInput>
                                </Grid>
                                <Grid item md={1}> </Grid>
                                <Grid item md={5} xs={12} sm={12}>
                                    <AutocompleteInput
                                        variant="outlined"
                                        source="projectDriver"
                                        optionValue="name"
                                        optionText="name"
                                        loading={loadingProjectDriverChoices}
                                        initialValue={projectDriver}
                                        onChange={(value) => handleProjectDriverChange(value)}
                                        fullWidth
                                        loading={loadingProjectDriverChoices}
                                        resettable={true}
                                        choices={projectDriverChoices} />
                                </Grid>
                                <Grid item md={1}> </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={2}>
                                <Grid item md={5} xs={12} sm={12}>
                                    <ReferenceInput
                                        variant="outlined"
                                        label="Project"
                                        source="projectId"
                                        reference="Project"
                                        onChange={(value) => handleProjectChange(value)}
                                        // filter={{ businessUnitId: 15 }}
                                        filter={projectFilters}
                                        resettable={true}
                                        fullWidth
                                    >
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        </>
                    )}
                />
                {loading ? <Box height={100}><Loading loadingSecondary="Loading project wise heatmap" /></Box> : null}
                {!loading && project && <Box>
                    <Box mt={2}>
                        <Card>
                            <Box className={classes.responsiveBox} p={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                <Box className={classes.responsiveBox}>
                                    <Box className={classes.responsiveBox}>
                                        <Box display="flex" flexDirection="row">
                                            <Box>Project:</Box>
                                            <Box fontWeight="bold" ml={1}>{project.name}</Box>
                                        </Box>
                                    </Box>
                                    <Box className={classes.responsiveBox}>
                                        <Box mt={1} display="flex" flexDirection="row">
                                            <Box>Project Strategic Driver:</Box>
                                            <Box fontWeight="bold" ml={1}>{project.projectDriver}</Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {Boolean(overallAssessment && overallAssessment.overallImpact !== "NA") && <Box display="flex" flexDirection="row" className={classes.responsiveBox}>
                                    <Box className={classes.responsiveBox}>
                                        <Box color={theme.palette.primary.darker}>Overall Change Impact</Box>
                                        <Box
                                            className={clsx(classes.changeImpactBox, {
                                                "active": true,
                                                "risk-low": overallAssessment.overallImpact === "LOW",
                                                "risk-medium": overallAssessment.overallImpact === "MEDIUM",
                                                "risk-high": overallAssessment.overallImpact === "HIGH",
                                            })}
                                        >
                                            {overallAssessment.overallImpact}
                                        </Box>
                                    </Box>
                                    <Box ml={3} className={classes.responsiveBox}>
                                        <Box color={theme.palette.primary.darker}>Overall Scope</Box>
                                        <Box
                                            className={clsx(classes.changeImpactBox, {
                                                "active": true,
                                                "risk-low": overallAssessment.overallScope === "LOW",
                                                "risk-medium": overallAssessment.overallScope === "MEDIUM",
                                                "risk-high": overallAssessment.overallScope === "HIGH",
                                            })}
                                        >
                                            {overallAssessment.overallScope}
                                        </Box>
                                    </Box>
                                    <Box ml={3} className={classes.responsiveBox}>
                                        <Box color={theme.palette.primary.darker}>Overall Challenge</Box>
                                        <Box
                                            className={clsx(classes.changeImpactBox, {
                                                "active": true,
                                                "risk-low": overallAssessment.overallChallenge === "LOW",
                                                "risk-medium": overallAssessment.overallChallenge === "MEDIUM",
                                                "risk-high": overallAssessment.overallChallenge === "HIGH",
                                            })}
                                        >
                                            {overallAssessment.overallChallenge}
                                        </Box>
                                    </Box>
                                </Box>}
                            </Box>
                            <Box className={classes.responsiveTable} width={`calc(100vw - 156px)`}>
                                <HeatMapTable
                                    projectId={project.id}
                                    businessUnits={[
                                        { ...project.businessUnit, isMainBu: true },
                                        ...project.impactedBusinessUnits
                                    ]}
                                    startDate={project.startDate}
                                    endDate={project.endDate}
                                />
                            </Box>
                        </Card>
                    </Box>
                </Box>}
                {error && (
                    <Card>
                        <Box p={1} color="red">
                            {error}
                        </Box>
                    </Card>
                )}
            </Box>
        </WithPermission>
    )
}

export const HeatMapTable = ({ projectId, businessUnits, startDate, endDate }) => {
    const classes = useStyles();
    const weeks = startEndDatesOfWeeks(moment(startDate), moment(endDate));
    const weekCount = weeks.length;

    if (businessUnits && businessUnits.length) {
        return (
            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableBody className={classes.responsiveTableHeight}>
                        <TableRow className={classes.tableHeading2}>
                            <TableCell align="left" className={classes.columnFreezed}>
                                Weeks
                            </TableCell>
                            {weeks.map((week, i) => {
                                return <TableCell align="left" key={i} className={i < weekCount - 1 ? classes.columnLowPriority : null}>
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Box whiteSpace="nowrap">Week {i + 1}</Box>
                                        <Box whiteSpace="nowrap">
                                            {moment(week.startDate).format("DD-MMM")} - {moment(week.endDate).format("DD-MMM")}
                                        </Box>
                                    </Box>
                                </TableCell>
                            })}
                        </TableRow>
                        {businessUnits.map(businessUnit => (
                            <TableRow key={businessUnit.id}>
                                <TableCell align="left" className={classes.columnFreezed1}>
                                    <Box whiteSpace="nowrap">{businessUnit.name}</Box>
                                </TableCell>
                                <ForecastRisks
                                    projectId={projectId}
                                    isMainBu={businessUnit.isMainBu}
                                    businessUnitId={businessUnit.id}
                                    weekCount={weekCount}
                                />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    } else {
        return (
            <Box p={1} fontWeight="bold" textAlign="center">There are no forecasts for this project</Box>
        )
    }
}
export const HeatMapTableInCSReport = ({ projectId, businessUnits, startDate, endDate }) => {
    const classes = useStyles();
    const totalWeeks = startEndDatesOfWeeks(moment(startDate), moment(endDate));
    const weeks = totalWeeks.slice(0, 12);
    const weekCount = weeks.length;
    if (businessUnits && businessUnits.length) {
        return (
            <TableContainer component={Paper} >
                <Table className={classes.table}  >
                    <TableBody className={classes.responsiveTableHeight}>
                        <TableRow className={classes.tableHeading1}>
                            {/* <TableCell align="left" className={classes.borderRight}>
                                Weeks
                            </TableCell> */}
                            {weeks.map((week, i) => {
                                return <TableCell padding='none' align="left" key={i} className={i < weekCount - 1 ? [classes.borderRightCSR, classes.borderBottomCSR] : null}>
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Box whiteSpace="nowrap" color='white'>Week {i + 1}</Box>
                                        {/* <Box whiteSpace="nowrap">
                                            {moment(week.startDate).format("DD-MMM")} - {moment(week.endDate).format("DD-MMM")}
                                        </Box> */}
                                    </Box>
                                </TableCell>
                            })}
                        </TableRow>
                        {/* {businessUnits.map(businessUnit => ( */}
                        <TableRow key={businessUnits[0].id}>
                            {/* <TableCell align="left" className={[classes.borderBottom, classes.borderRight]}>
                                    <Box whiteSpace="nowrap">{businessUnit.name}</Box>
                                </TableCell> */}

                            <ForecastRisks
                                projectId={projectId}
                                isMainBu={businessUnits[0].isMainBu}
                                businessUnitId={businessUnits[0].id}
                                weekCount={weekCount}
                                changeStatusReport={true}
                            />
                        </TableRow>
                        {/* // ))} */}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    } else {
        return (
            <Box p={1} fontWeight="bold" textAlign="center">There are no forecasts for this project</Box>
        )
    }
}
const ForecastRisks = ({ projectId, isMainBu, businessUnitId, weekCount, changeStatusReport }) => {
    const [forecasts, setForecasts] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const recordsPerPage = 1000;

    const getForecasts = async (projectId, businessUnitId) => {
        setError(null);
        setLoading(true);
        return dataProvider
            .getList("ProjectForecast", {
                pagination: { page: 1, perPage: recordsPerPage },
                sort: { field: "endDate", order: "ASC" },
                filter: { projectId, businessUnitId },
            })
            .then(({ data }) => {
                if (data && data.length) {
                    setForecasts(data);
                    setError(null)
                } else {
                    setForecasts(null);
                    setError("No forecasts found");
                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error)
                const error_message = onFailureMessage(error)
                notify(error_message, "error");
                setForecasts(null)
                setError(error_message);
                setLoading(false);
            })
    };
    React.useEffect(() => {
        getForecasts(projectId, businessUnitId);
    }, [projectId, businessUnitId])

    if (loading || error || !forecasts) {
        return (
            <TableCell
                colSpan={weekCount}
                align="center"
                className={[
                    classes.borderBottom,
                    classes.borderRight
                ]}
            >
                {loading && <LinearProgress />}
                {error && <Box color="red" textAlign="left"><Typography>{error}</Typography></Box>}
                {Boolean(!loading && !error && !forecasts) && "No project details found"}
            </TableCell>
        )
    }
    if (changeStatusReport) {

        return forecasts.slice(0, 12).map((forecast, i) => (
            <TableCell
                padding='none'
                key={forecast.id}
                align="center"
                className={[
                    classes.borderBottomCSR,
                    i < weekCount - 1 ? classes.borderRightCSR : null
                ]}
            >
                <RiskBox risk={forecast.risk} csr={true} />
            </TableCell>
        ))
    }

    return forecasts.map((forecast, i) => (
        <TableCell

            key={forecast.id}
            align="center"
            className={[
                classes.borderBottom,
                i < weekCount - 1 ? classes.borderRight : null
            ]}
        >
            <RiskBox risk={forecast.risk} />
        </TableCell>
    ))
}

export const RiskBox = ({ risk, csr }) => {
    const classes = useStyles();
    if (csr) {
        return (
            <Box width="100%" display="flex" justifyContent="center">
                <Box
                    className={clsx(classes.forecastRiskBoxCSR, {
                        "active": true,
                        "risk-high": risk === "H",
                        "risk-medium": risk === "M",
                        "risk-low": risk === "L",
                    })}
                />
            </Box>
        )
    }
    return (
        <Box width="100%" display="flex" justifyContent="center">
            <Box
                className={clsx(classes.forecastRiskBox, {
                    "active": true,
                    "risk-high": risk === "H",
                    "risk-medium": risk === "M",
                    "risk-low": risk === "L",
                })}
            />
        </Box>
    )
}

const getOverallChangeImpact = (changeAssessments) => {
    let impactValue = 0;
    let impactTxt;
    if (changeAssessments && changeAssessments.length) {
        impactValue = _.meanBy(changeAssessments, (changeAssessment) => changeAssessment.overallImpact)
    }

    switch (true) {
        case impactValue == 0:
            impactTxt = "NA"
            break;
        case impactValue <= 42:
            impactTxt = "LOW"
            break;
        case impactValue <= 98:
            impactTxt = "MEDIUM"
            break;
        case impactValue > 98:
            impactTxt = "HIGH"
            break;
        default:
            impactTxt = "NA"
    }
    return impactTxt;
}

const getOverallScope = (changeAssessments) => {
    let impactValue = 0;
    let impactTxt;
    if (changeAssessments && changeAssessments.length) {
        impactValue = _.meanBy(changeAssessments, (changeAssessment) => changeAssessment.overallScope)
    }

    switch (true) {
        case impactValue == 0:
            impactTxt = "NA"
            break;
        case impactValue <= 42:
            impactTxt = "LOW"
            break;
        case impactValue <= 98:
            impactTxt = "MEDIUM"
            break;
        case impactValue > 98:
            impactTxt = "HIGH"
            break;
        default:
            impactTxt = "NA"
    }
    return impactTxt;
}

const getOverallChallenge = (changeAssessments) => {
    let impactValue = 0;
    let impactTxt;
    if (changeAssessments && changeAssessments.length) {
        impactValue = _.meanBy(changeAssessments, (changeAssessment) => changeAssessment.overallChallenge)
    }

    switch (true) {
        case impactValue == 0:
            impactTxt = "NA"
            break;
        case impactValue <= 42:
            impactTxt = "LOW"
            break;
        case impactValue <= 98:
            impactTxt = "MEDIUM"
            break;
        case impactValue > 98:
            impactTxt = "HIGH"
            break;
        default:
            impactTxt = "NA"
    }
    return impactTxt;
}

export default ChangeHeatMap;