import PBIEmbed from "./pbiEmbed";

export const CIAReport = (props) => {
    return (
        <PBIEmbed
            title="Change Impact Assessment Report"
            reportId='4e534fc8-f1bb-4272-a41d-5473d28ea467'
            pageName="51298e986428130189b1"
            resource='Report'
            url='change_impact_project'
        />
    )
}

export default CIAReport;