/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { MenuItemLink, usePermissions } from 'react-admin';
import { useLocation } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import _ from "lodash";
import { hasPermissions } from "../../utils/AppUtils";

const useSidebarStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverBody: {
        backgroundColor: "#3e4d66",
        width: "100%",
    },
    popoverContent: {
        pointerEvents: "auto"
    },
    menuItem: {
        color: "white",
    },
    listItemIcon: {
        color: theme.palette.primary.contrastText,
        minWidth: 56,
        paddingLeft: 7,
    },
    subMenuRoot: {
        color: theme.palette.primary.contrastText,
        paddingLeft: 40,
    },
    subSubMenuRoot: {
        color: theme.palette.primary.contrastText,
        paddingLeft: 60,
    },
    menuActive: {
        backgroundColor: theme.palette.primary.dark,
    },
    menuIcon: {
        width: 56,
        paddingLeft: 7,
    },
}));

export const MainMenuItem = (props) => {
    const { pathname } = useLocation();
    const { key, to, primaryText, exact, leftIcon, isDrawerOpen, handleDrawerOpen } = props;
    const classes = useSidebarStyles();
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const { loading: loadingPermissions, permissions } = usePermissions();
    const hasChildren = props.children ? true : false;

    const allowedPermissions = hasChildren ? _.map(props.children, "props.allowedPermissions").flat() : props.allowedPermissions;
    const bypassUsers = hasChildren ? _.map(props.children, "props.bypassUser").flat() : null;
    const { bypassUser } = props;

    const handleClick = () => {
        setOpen(!open);
        if (props.children && !isDrawerOpen) handleDrawerOpen();
    };

    React.useEffect(async () => {
        if (bypassUser || (!hasChildren && !allowedPermissions)) {
            setShow(true);
        } else if (hasChildren && bypassUsers && bypassUsers.filter(Boolean).length) {
            setShow(true)
        } else if (!loadingPermissions) {
            const checkedPermissions = await Promise.all(allowedPermissions.map(
                permission => hasPermissions(permissions.permissions, permission)
            ));
            const allowed = checkedPermissions.filter(Boolean).length > 0;
            setShow(allowed)
        }
    }, [loadingPermissions]);

    return (
        show && <React.Fragment>
            {props.children ?
                <React.Fragment>
                    <ListItem button onClick={handleClick} classes={{ root: classes.menuItem, selected: classes.menuActive }} selected={pathname.startsWith(to)}>
                        <ListItemIcon className={classes.listItemIcon}>
                            {leftIcon}
                        </ListItemIcon>
                        <ListItemText primary={primaryText} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isDrawerOpen && open} timeout="auto" unmountOnExit>
                        <List dense={true}>
                            {props.children}
                        </List>
                    </Collapse>
                </React.Fragment> :
                <MenuItemLink
                    classes={{ icon: classes.menuIcon, root: classes.menuItem, active: classes.menuActive }}
                    key={key}
                    to={to}
                    primaryText={primaryText}
                    onClick={props.onMobileMenuClick}
                    exact={exact}
                    leftIcon={React.cloneElement(leftIcon, { className: classes.menuItem })}>
                </MenuItemLink>
            }
        </React.Fragment>
    );
}

export const SubMenuItem = ({ key, to, primaryText, allowedPermissions, bypassUser, leftIcon, onMobileMenuClick, children, isSubSubMenuOpen = false, handleDrawerOpen }) => {
    const classes = useSidebarStyles();
    const [show, setShow] = React.useState(false);
    const [open, setOpen] = React.useState(isSubSubMenuOpen);
    const { loading: loadingPermissions, permissions } = usePermissions();
    const hasChildren = children ? true : false;

    React.useEffect(async () => {
        if (bypassUser || !allowedPermissions) {
            setShow(true);
        } else if (!loadingPermissions) {
            const checkedPermissions = await Promise.all(allowedPermissions.map(
                permission => hasPermissions(permissions.permissions, permission)
            ));
            const allowed = checkedPermissions.filter(Boolean).length > 0;
            setShow(allowed)
        }
    }, [loadingPermissions]);

    const handleClick = () => {
        handleDrawerOpen();
        setOpen(!open);
    };

    return (
        show && <React.Fragment>
            {children ?
                <React.Fragment>
                    <ListItem button onClick={handleClick} classes={{ root: classes.subMenuRoot }}>
                        <ListItemIcon className={classes.menuItem}>
                            {leftIcon}
                        </ListItemIcon>
                        {/* <ListItemText primary={primaryText + 1} style={{ color: "red !important" }} /> */}
                        <span style={{ color: "white", width: "100%" }}>{primaryText}</span>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List dense={true}>
                            {children}
                        </List>
                    </Collapse>
                </React.Fragment> :
                <MenuItemLink
                    classes={{
                        root: classes.subMenuRoot,
                        active: classes.menuActive,
                    }}
                    key={key}
                    to={to}
                    leftIcon={leftIcon}
                    primaryText={primaryText}
                    onClick={onMobileMenuClick}
                />
            }
        </React.Fragment>
    );
}

export const SubSubMenuItem = ({ key, to, primaryText, leftIcon, allowedPermissions, bypassUser, children, onMobileMenuClick }) => {
    const classes = useSidebarStyles();
    const [show, setShow] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const { loading: loadingPermissions, permissions } = usePermissions();
    const hasChildren = children ? true : false;

    React.useEffect(async () => {
        if (bypassUser || !allowedPermissions) {
            setShow(true);
        } else if (!loadingPermissions) {
            const checkedPermissions = await Promise.all(allowedPermissions.map(
                permission => hasPermissions(permissions.permissions, permission)
            ));
            const allowed = checkedPermissions.filter(Boolean).length > 0;
            setShow(allowed)
        }
    }, [loadingPermissions]);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        show && <React.Fragment>
            {children ?
                <React.Fragment>
                    <ListItem button onClick={handleClick} classes={{ root: classes.subSubMenuRoot }}>
                        <ListItemIcon className={classes.menuItem}>
                            {leftIcon}
                        </ListItemIcon>
                        <ListItemText primary={primaryText} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List dense={true}>
                            {children}
                        </List>
                    </Collapse>
                </React.Fragment> :
                <MenuItemLink
                    classes={{
                        root: classes.subSubMenuRoot,
                        active: classes.menuActive,
                    }}
                    key={key}
                    to={to}
                    leftIcon={leftIcon}
                    primaryText={primaryText}
                    onClick={onMobileMenuClick}
                />
            }
        </React.Fragment>
    );
}

export default MainMenuItem;
