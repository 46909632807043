export const MASTER_DATA_VALUES_LIST_QUERY = `query(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: MasterDataValueFilter!
  ) {
    allMasterDataValues(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
        items {
            id
            name
          }
        totalCount
    }
  }`

export const MASTER_DATA_VALUE_RETRIEVE_QUERY = `query($id: Int!) {
    masterDataValue(id: $id) {
      id
      name
      description
      masterDataId
    }
  }`