import { Button } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-final-form";

const useStyles = (theme) => ({
    stepperHeader: {
        background: "none",
        padding: 0,
        marginBottom: 60,
    },
    actionToolbar: {
        display: 'flex',
        marginTop: 40,
        justifyContent: 'flex-end'
    },
});

class FromWizard extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };
    static Step = ({ children }) => children;

    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            values: props.initialValues || {},
            isDesktop: false 
        };
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
      }
    
      componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
      }
    
      updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 768 });
      }
    
    next = (values) => {
        this.setState((state) => ({
            step: Math.min(state.step + 1, this.props.children.length - 1),
            values,
        }));

        // move to top of the page on previous or next button is clicked
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };

    previous = () => {
        this.setState((state) => ({
            step: Math.max(state.step - 1, 0),
        }));

        // move to top of the page on previous or next button is clicked
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }


    /**
     * NOTE: Both validate and handleSubmit switching are implemented
     * here because 🏁 Redux Final Form does not accept changes to those
     * functions once the form has been defined.
     */

    validate = (values) => {
        const activeStep = React.Children.toArray(this.props.children)[
            this.state.step
        ];
        return activeStep.props.validate ? activeStep.props.validate(values) : {};
    };

    onSuccess = () => {
        this.setState((state) => ({
            step: 0,
            values: null,
        }));
    };

    handleSubmit = (values, form) => {
        const { children, onSubmit } = this.props;
        const { step } = this.state;
        const isLastStep = step === React.Children.count(children) - 1;
        if (isLastStep) {
            return onSubmit(values, form, this.onSuccess);
        } else {
            this.next(values);
        }
    };

    render() {
        const { classes } = this.props;
        const { children, previousBtnEnabled = false, steps } = this.props;
        const { step, values } = this.state;
        const activeStep = React.Children.toArray(children)[step];
        const isLastStep = step === React.Children.count(children) - 1;
        const isDesktop = this.state.isDesktop;


        return (
            <div>
                <div style={{ overflowX: isDesktop ? 'hidden' : 'scroll', width: "100%" }}>
                <Stepper
                    activeStep={step}
                    className={classes.stepperHeader}
                    alternativeLabel
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                </div>
                <Form
                    initialValues={this.props.initialValues}
                    validate={this.validate}
                    onSubmit={this.handleSubmit}
                    mutators={this.props.mutators}
                >
                    {({ handleSubmit, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            {activeStep}
                            <div className={classes.actionToolbar}>
                                {previousBtnEnabled && step > 0 && (
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        style={{ marginRight: 25 }}
                                        onClick={this.previous}
                                    >
                                        Previous
                                    </Button>
                                )}
                                {!isLastStep && (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        Next
                                    </Button>
                                )}
                                {isLastStep && (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={submitting}
                                    >
                                        Submit
                                    </Button>
                                )}
                            </div>

                            {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                        </form>
                    )}
                </Form>
            </div>
        );
    }
}

export default withStyles(useStyles)(FromWizard);
