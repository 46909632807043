import { useMediaQuery } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";

import _ from "lodash";
import PropTypes from "prop-types";
import * as React from "react";
import { cloneElement } from "react";
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  SearchInput,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  useDataProvider,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  currentDate,
  impersonatedURL,
  onFailureMessage,
  saveFileFromBinary,
} from "../../utils/AppUtils";
import ButtonWithPermission from "../../utils/ButtonWithPermission";
import PostPagination from "../../utils/PostPagination";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import MoreActions from "../customComponents/moreActions";
import RoleList from "../role/roleList";
import UserCreateDrawer from "./userCreateDrawer";
import UserEditDrawer from "./userEditDrawer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { ThemeContext } from "../../framework/theme/themeContext";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  headerCell: {
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "blue",
      display: "none",
    },
  },
}));
const StyledTabs = withStyles({
  root: {
    maxWidth: "85vw",
    // marginBottom: "15px",
    // backgroundColor: "#fff"
    // borderBottom: '2px solid #ccc', // Border for all tabs
  },
  indicator: {
    display: "none",
    // justifyContent: "center",
    // backgroundColor: "transparent",
    // height: 5,
    // "& > span": {
    //     maxWidth: 80,
    //     width: "100%",
    //     backgroundColor: "#EFF2F7"
    // },
  },
  "& .Mui-selected": {
    borderBottom: "10px solid #f0f", // Remove border for the active tab
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#000",
    fontWeight: theme.typography.fontWeightRegular,
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "14px",
    padding: "0 20px",
    "&:focus": {
      opacity: 1,
    },
    minWidth: 97,
    // paddingLeft: 0,
    // paddingRight: 0,
    borderBottom: "2px solid #cdd0d1",
    "&:not($selected)": {
      // borderBottomColor: '#ccc', // Add border for unselected tabs
    },
    "&$selected": {
      // borderTop: "2px solid #0Ab39C",
      // marginBottom: '2px',
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      // paddingBottom: "-10px",
      fontWeight: "600",
      borderBottom: "none",
      // borderTop: "2px solid #cdd0d1",
      // borderRight: "2px solid #cdd0d1",
      // borderLeft: "2px solid #cdd0d1",
      // backgroundColor: "#EFF2F7", // Change this to the color you desire
      backgroundColor: "#FFFFFF", // Change this to the color you desire
      color: "#000", // Change this to the color you desire for the text
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
export default function UserAndRoleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const user = TokenStorage.getUserDetails();
  const { isImpersonating } = user ? user : {};
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  impersonatedURL(isImpersonating, "#/user?impersonated");

  return (
    <div className={classes.root}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <StyledTab label="Users" {...a11yProps(0)} />
        <StyledTab label="Roles" {...a11yProps(1)} />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <UserList {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RoleList resource="Role" basePath="/" />
      </TabPanel>
    </div>
  );
}

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    isCustomerAdmin,
    maxResults,
    handleCreateDrawerOpen,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { themeObject, setTheme } = React.useContext(ThemeContext)
  const handleExportBtnClick = () => {
    dataProvider
      .postRequest("ExportUsers", {
        queryType: "retrieve",
      })
      .then((response) => {
        saveFileFromBinary(response.data, "Users" + currentDate);
      })
      .catch((error) => {
        notify("No data found to export", "warning");
      });
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}

      <Button
        variant="contained"
        onClick={handleExportBtnClick}
        sx={{
          width: isSmall ? "0px" : "150px",
          marginRight: "8px",
          backgroundColor: themeObject.palette.primary.main,
          boxShadow: "0",
          height: "41px",
          paddingX: "0",
          "&:hover": {
            backgroundColor: themeObject.palette.primary.buttonContrast,
            color: "white",
            cursor: "pointer",
            boxShadow: "0",
          },
        }}
      >
        {isSmall ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0",
              paddingLeft: "2px",
              borderBottomRightRadius: "5px",
              borderTopRightRadius: "5px",
              backgroundColor: "rgba(255,255,255,0.2)",
              fontSize: "16px",
              width: "100%",
              height: "41px",
            }}
          >
            <FileDownloadOutlinedIcon style={{ marginLeft: "-4px" }} />
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              {isSmall ? "" : "Export"}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                paddingLeft: "2px",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
                backgroundColor: "rgba(255,255,255,0.2)",
                fontSize: "16px",
                width: "41px",
                height: "41px",
              }}
            >
              <FileDownloadOutlinedIcon style={{ marginLeft: "-4px" }} />
            </Box>
          </Box>
        )}
      </Button>
      <CreateUserBtn {...props} />
    </TopToolbar>
  );
};

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput variant="outlined" source="q" alwaysOn />
  </Filter>
);

const CreateUserBtn = (props) => {
  const { isCustomerAdmin, permissions, isSuperAdmin, handleCreateDrawerOpen } =
    props;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <ButtonWithPermission
      permissions={permissions}
      resource="User"
      bypassUser={isCustomerAdmin}
      action={["create"]}
      variant="contained"
      onClick={() => handleCreateDrawerOpen(true)}
      endIcon={<AddIcon />}
    >
      {" "}
      {isSmall ? "" : "Create User"}
    </ButtonWithPermission>
  );
};

const Empty = (props) => {
  const { basePath, resource, isCustomerAdmin } = useListContext();
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        No Users yet.
      </Typography>
      <Typography variant="body1">Do you want to add one?</Typography>
      <Box mt={1}>
        <CreateUserBtn {...props} />
      </Box>
    </Box>
  );
};

export const UserList = (props) => {
  const [createDrawerOpen, setCreateDrawerOpen] = React.useState();
  const [editDrawerOpen, setEditDrawerOpen] = React.useState();
  const [record, setRecord] = React.useState();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { loading: loadingPermissions, permissions } = usePermissions();

  const user = TokenStorage.getUserDetails();
  const { isSuperAdmin, isCustomerAdmin } = user ? user : {};

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({ message: name, type: type })
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  const [anchorOrigin, setAnchorOrigin] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = React.useState({
    message: "",
    type: ""
  });

  const { vertical, horizontal } = anchorOrigin;

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const handleCreateDrawerOpen = (open) => {
    setCreateDrawerOpen(open);
  };

  const handleCreateDrawerClose = () => {
    setCreateDrawerOpen(false);
  };

  const handleEditDrawerOpen = (open) => {
    setEditDrawerOpen(open);
  };

  const handleEditDrawerClose = (open) => {
    setRecord(null);
    setEditDrawerOpen(open);
  };
  const handleEditUser = (record) => {
    setRecord(record);
    handleEditDrawerOpen(true);
  };
  const handleDeleteUser = (record) => {
    const proceed = window.confirm("You are deleting the entry. Are you sure?");
    if (proceed) {
      dataProvider
        .delete("User", { id: record.id })
        .then((response) => {
          handleSnackbarOpen("User deleted successfully", "success")
          refresh();
        })
        .catch((error) => {
          const error_messages = onFailureMessage(error);
          handleSnackbarOpen(error_messages, "error")
        });
    }
  };
  const rowActionsChoices = [
    { id: "edit", name: "Edit", resource: "User", action: ["edit"] },
    { id: "delete", name: "Delete", resource: "User", action: ["delete"] },
  ];

  const handleMoreActionsClick = ({ action, record }) => {
    switch (action) {
      case "edit":
        setRecord(record);
        handleEditDrawerOpen(true);
        break;
      case "delete":
        const proceed = window.confirm(
          "You are deleting the entry. Are you sure?"
        );
        if (proceed) {
          dataProvider
            .delete("User", { id: record.id })
            .then((response) => {
              handleSnackbarOpen("User deleted successfully", "success");
              refresh();
            })
            .catch((error) => {
              const error_messages = onFailureMessage(error);
              handleSnackbarOpen(error_messages, "error");
            });
        }
        break;
    }
  };
  const ActionButtonSM = () => {
    const records = useRecordContext();
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton
          aria-label="edit"
          className={classes.IconButtonOverride}
          size="small"
        >
          <EditIcon
            size="small"
            style={{
              color: "#00A0D9",
              cursor: "pointer",
              margin: "0 5px",
              fontSize: "20px",
            }}
            onClick={() => handleEditUser(records)}
          />
        </IconButton>
        <IconButton
          aria-label="delete"
          className={classes.IconButtonOverride}
          size="small"
        >
          <DeleteForeverIcon
            size="small"
            style={{ color: "#de0937", cursor: "pointer", fontSize: "20px" }}
            onClick={() => handleDeleteUser(records)}
          />
        </IconButton>
      </Box>
    );
  };
  // console.log(permissions);
  return (
    <WithPermission
      resource="User"
      bypassUser={isCustomerAdmin}
      permissions={permissions}
      action={["view"]}
      {...props}
    >
      <List
        title={<span style={{ textTransform: "uppercase" }}>User</span>}
        hasCreate={false}
        empty={
          <Empty
            isCustomerAdmin={isCustomerAdmin}
            isSuperAdmin={isSuperAdmin}
            permissions={props.permissions}
            handleCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
        actions={
          <ListActions
            isCustomerAdmin={isCustomerAdmin}
            isSuperAdmin={isSuperAdmin}
            permissions={props.permissions}
            handleCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
        filters={<UserFilter />}
        bulkActionButtons={false}
        pagination={<PostPagination />}
        {...props}
      >
        {isSmall ? (
          // <SimpleList
          //     primaryText={record => `${record.firstName} ${record.surname}`}
          //     secondaryText={record => record.email}
          //     tertiaryText={record => record && record.roles ? _.map(record.roles, 'name').join(",") : null}
          //     linkType={false}

          // />
          <Datagrid classes={{ headerCell: classes.headerCell }}>
            <Box
              display="flex"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <Box>
                {/* <TextField source="firstName" sortable={true} />{" "}
                <TextField source="surname" sortable={true} /> */}
                <FunctionField
                  label="Full Name"
                  render={(record) => `${record.firstName} ${record.surname}`}
                  sortable={true}
                />
                <br />
                <TextField
                  style={{ wordWrap: "break-word" }}
                  color="secondary"
                  source="email"
                  sortable={false}
                />
                <br />
                <FunctionField
                  render={(record) =>
                    record && record.roles
                      ? _.map(record.roles, "name").join(",")
                      : null
                  }
                  source="roles.name"
                  sortable={false}
                />
                <br />
                <TextField
                  color="secondary"
                  source="businessUnit.name"
                  sortable={false}
                />
                <ActionButtonSM />
              </Box>
            </Box>
            {/* <DateField label="Last login" source="lastLoggedInAt" showTime={false} sortable={false} /> */}
          </Datagrid>
        ) : (
          <Datagrid classes={{ headerCell: classes.headerCell }}>
            <TextField label="First Name" source="firstName" sortable={true} />
            <TextField label="Last Name" source="surname" sortable={true} />
            <TextField label="Email" source="email" sortable={false} />
            <TextField
              label="Business Unit"
              source="businessUnit.name"
              sortable={false}
            />
            <FunctionField
              label="Role"
              render={(record) =>
                record && record.roles
                  ? _.map(record.roles, "name").join(",")
                  : null
              }
              source="roles.name"
              sortable={false}
            />
            <DateField
              label="Last login"
              source="lastLoggedInAt"
              showTime={false}
              sortable={false}
            />
            <MoreActions
              bypassUser={isCustomerAdmin}
              permissions={props.permissions}
              choices={rowActionsChoices}
              handleClose={() => console.log("closing")}
              handleClick={handleMoreActionsClick}
              sortable={false}
            />
          </Datagrid>
        )}
      </List>
      <UserCreateDrawer
        record={record}
        drawerOpen={createDrawerOpen}
        onClose={handleCreateDrawerClose}
        handleSnackbarOpen={handleSnackbarOpen}
      />
      <UserEditDrawer
        record={record}
        drawerOpen={editDrawerOpen}
        onClose={handleEditDrawerClose}
        handleSnackbarOpen={handleSnackbarOpen}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
        <Alert

          onClose={hanndleSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Project  Forecast updated!
        </Alert>
      </Snackbar>
    </WithPermission>
  );
};

// export default UserList;
