export const CHANGE_ASSESSMENT_MASTERS_BY_TYPE_UPDATE_MUTATION = `mutation(
  $projectId: Int!
  $assessmentDetails: [ChangeAssessmentDetailsInput!]
  $assessmentScores: [AssessmentScoresInput]
  $type: [ChangeAssessmentType!]
) {
  updateChangeAssessmentDetails(
   projectId: $projectId
   assessmentDetails: $assessmentDetails
   assessmentScores: $assessmentScores
   type: $type
  ) {
    projectId
    assessmentDetails {
      id
      projectId
      changeAssessmentMasterId
      impactLevel
      commentary
      type
      score
    }
    assessmentScores{
      id
      projectId
      overallScore
      commentary
      type
    }
   }
 }`

export const CHANGE_ASSESSMENT_MASTERS_BY_PROJECT_TYPE_QUERY = `query(
  $projectId: Int!
  $type: [ChangeAssessmentType!]
) {
  getChangeAssessmentDetails(
    projectId: $projectId
    type: $type
  ) {
    projectId
    assessmentDetails {
      id
      projectId
      changeAssessmentMasterId
      impactLevel
      commentary
      type
      score
    }
    assessmentScores{
      id
      projectId
      overallScore
      commentary
      type
   }
 }
}`