import { Box, Grid, Typography } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import * as React from "react";
import {
    AutocompleteArrayInput, AutocompleteInput, DateInput, Error, FormDataConsumer, Loading,
    ReferenceArrayInput, ReferenceInput,
    TextInput,
    maxLength,
    required,
    useDataProvider,
    useNotify,
    useRefresh
} from "react-admin";
import { Field, useForm } from "react-final-form";
import { PROJECT_DRIVERS_MASTER_DATA_ID, PROJECT_PHASE_MASTER_DATA_ID } from "../../framework/constants";
import { onFailureMessage, startEndDatesOfWeeks } from "../../utils/AppUtils";
import Wizard from "../customComponents/wizard";
import ProjectCustomDrawer from "./projectCustomDrawer";
import ProjectForecastsInputsContainer from "./projectForecastsInputsContainer";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    subTitle: {
        marginBottom: 16,
    },
    forecastTitle: {
        backgroundColor: "#F2F2F2",
        display: "flex",
    },
    forecastCard: {
        marginBottom: 30,
        margin: "0 auto",
        width: "90%",
        [theme.breakpoints.down("md")]: {
            width: "100%"
        }
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: "#BDBDBD",
    },
    inputWidthChange: {
        width: 322,
        [theme.breakpoints.down("xs")]: {
            width: '83vw',
        }
    },
    inputWidthMargin: {
        // marginRight: 100,
    },
    referenceInputWidth: {
        width: 350,
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        }
    },
    gridBoxMargin: {
        marginBottom: 12,
        width: 350,
    },
    phaseDateWidth: {
        width: 132,
        [theme.breakpoints.down("xs")]: {
            width: '35vw'
        },
    },
    gridContainer: {
        display: "grid",
        gridColumnGap: 100,
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
        },
        [theme.breakpoints.between("sm", "md")]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
        },
    },
    forecastTopDiv: {
        width: "95%",
    },
    projectNoteText: {
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 400,
        marginBottom: 30,
    },
    tabsComponentDiv: {
        marginTop: 20,
    },
}));

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        height: 5,
        "& > span": {
            maxWidth: 80,
            width: "100%",
            backgroundColor: "#4F4F4F",
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        color: "#000",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: 60,
        "&:focus": {
            opacity: 1,
        },
        minWidth: 97,
        paddingLeft: 0,
        paddingRight: 0,
    },
}))((props) => <Tab disableRipple {...props} />);

export const ProjectCreateDrawer = ({
    record,
    initialValues,
    drawerOpen,
    onClose,
}) => {
    const [projectPhases, setProjectPhases] = React.useState();
    const [projectPhasesLoading, setProjectPhasesLoading] = React.useState();
    const [projectPhasesError, setProjectPhasesError] = React.useState();
    const [projectInfo, setProjectInfo] = React.useState("");
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useStyles();
    const resource = "Project";

    const onSubmit = (values, form) => {
        const phases =
            values.projectPhases && values.projectPhases.length > 0
                ? values.projectPhases
                    .filter((x) => x && x.endDate != null && x.endDate != "")
                    .map((projectPhase, index) => ({
                        name: projectPhases[index].name,
                        startDate: projectPhase.startDate,
                        endDate: projectPhase.endDate,
                    }))
                : null;
        // const forecast2 =
        //   values.projectForecasts && values.projectForecasts.length > 0
        //     ? values.projectForecasts.map((projectForecast, index) => ({
        //         ...projectForecast,
        //         businessUnitId: 1,
        //       }))
        //     : null;
        // const forecast3 =
        //   values.projectForecasts && values.projectForecasts.length > 0
        //     ? values.projectForecasts.map((projectForecast, index) => ({
        //         ...projectForecast,
        //         businessUnitId: 2,
        //       }))
        //     : null;
        // values.projectForecasts.push(...forecast2);
        // values.projectForecasts.push(...forecast3);
        // values.impactedBusinessUnitIds = [1, 2];
        const newForecastsValues = values.projectForecasts ? values.projectForecasts.flat() : null;
        values.projectForecasts = newForecastsValues;
        dataProvider
            .create(resource, {
                data: {
                    ...values,
                    projectPhases: phases,
                },
            })
            .then((response) => {
                form.restart();
                onClose(false);
                notify("Project added successfully","success");
                refresh();
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                notify(error_messages, "error");
            });
    };

    const WizardFromError = ({ name }) => (
        <Field
            name={name}
            subscription={{ touched: true, error: true }}
            render={({ meta: { touched, error } }) =>
                touched && error ? (
                    <span style={{ color: "#d32f2f" }}>{error}</span>
                ) : null
            }
        />
    );

    React.useEffect(() => {
        setProjectPhasesLoading(true);
        dataProvider
            .getOne("MasterData", { id: PROJECT_PHASE_MASTER_DATA_ID })
            .then((response) => {
                setProjectPhases(response.data.masterDataValues);
                setProjectPhasesLoading(false);
            })
            .catch((error) => {
                notify(`Project phases are unavailable: ${error.message}`, "warning");
                setProjectPhasesError(error.message);
                setProjectPhasesLoading(false);
            });
    }, []);

    return (
        <ProjectCustomDrawer
            drawerAnchor="right"
            title="Create Project"
            projectInfo={projectInfo}
            drawerOpen={drawerOpen}
            onClose={onClose}
        >
            <Wizard
                initialValues={{
                    ...initialValues,
                    projectPhases,
                }}
                onSubmit={onSubmit}
                onClose={onClose}
                setProjectInfo={setProjectInfo}
            >
                <Wizard.Page>
                    <ProjectCreateInputs />
                </Wizard.Page>
                <Wizard.Page>
                    <Typography className={classes.subTitle}>Project Phases</Typography>
                    <ProjectPhasesInputs
                        loading={projectPhasesLoading}
                        error={projectPhasesError}
                        data={projectPhases}
                    />
                </Wizard.Page>
                <Wizard.Page
                    validate={(values) => {
                        const errors = {};
                        // const weeksCount = getWeeksCount(moment(values.startDate), moment(values.endDate));
                        const weeksCount = moment(values.endDate).diff(moment(values.startDate), 'week');
                        const impactedBuCount = values.impactedBusinessUnits ? values.impactedBusinessUnits.length : 0;
                        const forecastsValues = values && values.projectForecasts ? values.projectForecasts.flat() : null;
                        if (forecastsValues) {
                            if (forecastsValues.length < (weeksCount * (impactedBuCount + 1))) {
                                errors.projectForecasts = "Please fill all the project forecast details";
                            } else {
                                for (let i = 0; i < forecastsValues.length; i++) {
                                    if (
                                        forecastsValues[i] &&
                                        !forecastsValues[i].risk
                                    ) {
                                        errors.projectForecasts =
                                            "Please fill all the project forecast details";
                                        break;
                                    }
                                }
                            }
                        } else {
                            errors.projectForecasts =
                                "Please fill all the project forecast details";
                        }
                        return errors;
                    }}
                >
                    <div className={classes.forecastTopDiv}>
                        <div>
                            <Typography>Change Impact Forecast</Typography>
                            <ForecastToolbar />
                            {/* <h6 className={classes.projectNoteText}>
                Note : Click / tap on the color brick to select the impact. You
                can change the severity of any week by selecting color brick of
                that week. Please note that the followed bricks will change to
                same impact which is selected
              </h6>
              <ProjectForecastsInputs /> */}
                        </div>
                    </div>
                    <div style={{ marginBottom: 16 }}>
                        <WizardFromError name="projectForecasts" />
                    </div>
                </Wizard.Page>
            </Wizard>
        </ProjectCustomDrawer>
    );
};

const ProjectCreateInputs = () => {
    const classes = useStyles();
    const [projectDriverChoices, setProjectDriverChoices] = React.useState();
    const [projectDriversLoading, setProjectDriversLoading] = React.useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [showImpBus, setShowImpBus] = React.useState(false);
    const validateStartDate = (startDate, values) => {
        const endDate = values.endDate;
        if (startDate && endDate && startDate >= endDate) {
            return "start date can not be greater than end date";
        }
    };

    const validateEndDate = (endDate, values) => {
        const startDate = values.startDate;
        if (endDate && startDate && endDate <= startDate) {
            return "end date can not be lesser than start date";
        }
    };

    const validateGoLiveDate = (goLiveDate, values) => {
        const startDate = values.startDate;
        const endDate = values.endDate;
        if (goLiveDate < startDate || goLiveDate > endDate) {
            return "Go live Date should be in between start date and end date. ";
        }
    };

    const handleBuChange = () => {
        console.log(showImpBus);
        setShowImpBus(true);
        console.log(showImpBus);
    }
    console.log(showImpBus);

    React.useEffect(() => {
        dataProvider
            .getOne("ProjecDriverMasterData", { id: PROJECT_DRIVERS_MASTER_DATA_ID })
            .then(({ data }) => {
                setProjectDriverChoices(data.masterDataValues)
                setProjectDriversLoading(false)
            })
            .catch(error => {
                console.log(error);
                setProjectDriversLoading(false)
                setProjectDriverChoices([])
                notify("could not able to fetch project driver list","warning")
            })
    }, [])

    return (
        // <Grid container className={classes.gridContainer}>
        <Grid container spacing={2}>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <TextInput
                    variant="outlined"
                    label="Project Name"
                    source="name"
                    validate={[required(), maxLength(255)]}
                    className={classes.inputWidthMargin}
                    InputProps={{
                        classes: {
                            input: classes.inputWidthChange,
                            notchedOutline: classes.inputBorder,
                        },
                    }}
                    onChange={(e) => { console.log(e.target.value) }}
                />
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                {/* <div onClick={console.log("hello bussss")}> */}
                <ReferenceInput
                    onChange={() => handleBuChange()}
                    variant="outlined"
                    label="Business Unit"
                    source="businessUnitId"
                    reference="BusinessUnit"
                    validate={[required()]}
                    className={`${classes.referenceInputWidth} ${classes.inputWidthMargin}`}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                {/* </div> */}
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                {
                    showImpBus ? <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <ReferenceArrayInput
                                fullWidth
                                variant="outlined"
                                label="Impacted Business Unit"
                                source="impactedBusinessUnits"
                                reference="BusinessUnit"
                                className={`${classes.referenceInputWidth} ${classes.inputWidthMargin}`}
                                filter={{
                                    excludeIds:
                                        formData && formData.businessUnitId
                                            ? [formData.businessUnitId]
                                            : null,
                                }}
                            >
                                <AutocompleteArrayInput optionText="name" />
                            </ReferenceArrayInput>
                        )}
                    </FormDataConsumer> : <TextInput
                        disabled
                        source="body"
                        variant="outlined"
                        label="Impacted Business Unit"
                        className={`${classes.referenceInputWidth} ${classes.inputWidthMargin}`}
                    />
                }
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <ReferenceInput
                    variant="outlined"
                    label="Project Sponsor"
                    source="projectSponsorId"
                    reference="User"
                    validate={[required()]}
                    className={`${classes.referenceInputWidth} ${classes.inputWidthMargin}`}
                >
                    <AutocompleteInput optionText="fullName" />
                </ReferenceInput>
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <ReferenceInput
                    variant="outlined"
                    label="Business Owner"
                    source="businessOwnerId"
                    reference="User"
                    validate={[required()]}
                    className={`${classes.referenceInputWidth} ${classes.inputWidthMargin}`}
                >
                    <AutocompleteInput optionText="fullName" />
                </ReferenceInput>
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <AutocompleteInput
                    variant="outlined"
                    label="Strategic Driver"
                    source="projectDriver"
                    optionValue="name"
                    optionText="name"
                    choices={projectDriverChoices}
                    validate={[required()]}
                    fullWidth
                />
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <DateInput
                    variant="outlined"
                    label="Start Date"
                    source="startDate"
                    validate={[required(), validateStartDate]}
                    InputProps={{
                        classes: {
                            input: classes.inputWidthChange,
                            notchedOutline: classes.inputBorder,
                        },
                    }}
                />
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <DateInput
                    variant="outlined"
                    label="End Date"
                    source="endDate"
                    style={{ marginRight: 100 }}
                    validate={[required(), validateEndDate]}
                    InputProps={{
                        classes: {
                            input: classes.inputWidthChange,
                            notchedOutline: classes.inputBorder,
                        },
                    }}
                />
            </Grid>
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <ReferenceInput
                    variant="outlined"
                    label="Change Lead"
                    source="changeLeaderId"
                    reference="User"
                    validate={[required()]}
                    fullWidth
                >
                    <AutocompleteInput optionText="fullName" />
                </ReferenceInput>
            </Grid>
            {/* ----------------GO live date section start------------ */}
            {/*  <Grid className={classes.gridBoxMargin}> */}
            <Grid item xs={12} md={6} lg={4}>
                <DateInput
                    variant="outlined"
                    label="Go Live Date"
                    source="goLiveDate"
                    style={{ marginRight: 100 }}
                    validate={[required(), validateGoLiveDate]}
                    InputProps={{
                        classes: {
                            input: classes.inputWidthChange,
                            notchedOutline: classes.inputBorder,
                        },
                    }}
                />
            </Grid>
            {/* ------- Go live date sction end------------- */}
        </Grid>
    );
};

const ProjectPhasesInputs = ({ loading, error, data }) => {
    const classes = useStyles();
    console.log(data)
    const validateStartDate = (value, values, index) => {
        try {
            const startDate = value ? new Date(value) : null;
            const projectStartDate = values.startDate
                ? new Date(values.startDate)
                : null;
            const endDate = values.projectPhases[index].endDate
                ? new Date(values.projectPhases[index].endDate)
                : null;
            const prePhaseIndex = Math.max(index - 1, 0);
            const prePhaseEndDate = values.projectPhases[prePhaseIndex].endDate
                ? new Date(values.projectPhases[prePhaseIndex].endDate)
                : null;
            if (startDate && endDate && startDate >= endDate) {
                return "should be lesser than end date";
            }
            if (startDate && startDate < projectStartDate) {
                return `should be greater than "${projectStartDate.toLocaleDateString('en-GB')}"`;
            }
            if (
                index > 0 &&
                startDate &&
                prePhaseEndDate &&
                startDate <= prePhaseEndDate
            ) {
                return `should be greater than "${prePhaseEndDate.toLocaleDateString('en-GB')}"`;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const validateEndDate = (value, values, index) => {
        try {
            const endDate = value ? new Date(value) : null;
            const projectEndDate = values.endDate ? new Date(values.endDate) : null;
            const startDate = values.projectPhases[index].startDate
                ? new Date(values.projectPhases[index].startDate)
                : null;
            if (endDate && startDate && endDate <= startDate) {
                return "should be greater than start date";
            }
            if (endDate && endDate > projectEndDate) {
                return `should be lesser than "${projectEndDate.toLocaleDateString('en-GB')}"`;
            }
            if (startDate && !endDate) {
                return "end date is required";
            }
        } catch (e) {
            console.log(e);
        }
    };
    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <Error title={error} />;
    }
    if (!data) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    Error in getting project phases
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container className={classes.gridContainer} spacing={2}>
            {data.map((projectPhase, index) => (
                <div key={projectPhase.id}>
                    {/*  <Grid className={classes.gridBoxMargin}> */}
                    <Grid >
                        <TextInput
                            variant="outlined"
                            label={`Phase ${index + 1}`}
                            source={`projectPhases[${index}].name`}
                            initialValue={projectPhase.name}
                            value={projectPhase.name}
                            disabled={true}
                            validate={[required(), maxLength(255)]}
                            InputProps={{
                                classes: {
                                    input: classes.inputWidthChange,
                                    notchedOutline: classes.inputBorder,
                                },
                            }}
                        />
                    </Grid>
                    {/*  <Grid className={classes.gridBoxMargin}> */}
                    <Grid >
                        <Box style={{ display: "flex" }}>
                            <DateInput
                                variant="outlined"
                                label="Start Date"
                                source={`projectPhases[${index}].startDate`}
                                style={{ marginRight: 15 }}
                                validate={[
                                    required(),
                                    (value, values) => validateStartDate(value, values, index),
                                ]}
                                InputProps={{
                                    classes: {
                                        input: classes.phaseDateWidth,
                                        notchedOutline: classes.inputBorder,
                                    },
                                }}
                            />
                            <DateInput
                                variant="outlined"
                                label="End Date"
                                source={`projectPhases[${index}].endDate`}
                                style={{ marginLeft: 15 }}
                                validate={[
                                    required(),
                                    (value, values) => validateEndDate(value, values, index),
                                ]}
                                InputProps={{
                                    classes: {
                                        input: classes.phaseDateWidth,
                                        notchedOutline: classes.inputBorder,
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </div>
            ))}
        </Grid>
    );
};

const ProjectForecastsInputs = ({ businessUnitId }) => {
    const form = useForm();
    const formValues = form.getState().values;
    const savedForecasts = formValues && formValues.projectForecasts ? formValues.projectForecasts[businessUnitId] : null;
    const [projectForecasts, setProjectForecasts] = React.useState(savedForecasts);
    const startDate = formValues.startDate;
    const endDate = formValues.endDate;

    React.useEffect(() => {
        // console.log(formValues?.projectForecasts.flat())
        if (startDate && endDate && (!savedForecasts || (savedForecasts && savedForecasts.length === 0))) {
            let weeks = startEndDatesOfWeeks(moment(startDate), moment(endDate));
            weeks = weeks.map((week) => ({
                ...week,
                businessUnitId: businessUnitId,
            }));
            setProjectForecasts(weeks);
        }
    }, []);

    if (projectForecasts) return <ProjectForecastsInputsContainer
        isCreate={true}
        projectForecasts={projectForecasts}
        setProjectForecasts={setProjectForecasts}
        businessUnitId={businessUnitId}
    />;

    return null;
};

const ForecastToolbar = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const form = useForm();
    const formValues = form.getState().values;
    const impactedUnits = formValues.impactedBusinessUnits;
    const projectTitle = formValues.name;
    const [valuedUnits, setvaluedUnits] = React.useState([]);
    const businessUnitId = formValues.businessUnitId;
    const dataProvider = useDataProvider();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        const variableInputs = async () => {
            const variableName = await dataProvider.getMany("BusinessUnit", {
                ids: impactedUnits,
            });
            setvaluedUnits(variableName.data);
        };
        if (impactedUnits) variableInputs();
    }, []);
    const ForecastPanel = (props) => {
        const { children, value, index } = props;
        return <React.Fragment>{value === index && <React.Fragment>{children}</React.Fragment>}</React.Fragment>;
    };

    return (
        <div className={classes.tabsComponentDiv} >
            <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
                variant="scrollable"
                scrollButtons="auto"
            >
                <StyledTab label={`${projectTitle}`} />
                {valuedUnits.length > 0
                    ? valuedUnits.map((unit) => (
                        <StyledTab key={unit.id} label={`${unit.name}`}></StyledTab>
                    ))
                    : null}
            </StyledTabs>
            <Typography className={classes.padding} />
            <ForecastPanel value={value} index={0}>
                <h6 className={classes.projectNoteText}>
                    Note : Click / tap on the color brick to select the impact. You can
                    change the severity of any week by selecting color brick of that week.
                    Please note that the followed bricks will change to same impact which
                    is selected
                </h6>
                <ProjectForecastsInputs businessUnitId={businessUnitId} />
            </ForecastPanel>
            {valuedUnits.length > 0
                ? valuedUnits.map((unit, index) => (
                    <ForecastPanel key={unit.id} value={value} index={index + 1}>
                        <h6 className={classes.projectNoteText}>
                            Note : Click / tap on the color brick to select the impact. You
                            can change the severity of any week by selecting color brick of
                            that week. Please note that the followed bricks will change to
                            same impact which is selected
                        </h6>
                        <ProjectForecastsInputs businessUnitId={unit.id} />
                    </ForecastPanel>
                ))
                : null}
        </div>
    );
};
export default ProjectCreateDrawer;
