import { Box, Button, Card, Divider, Grid, IconButton, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import arrayMutators from "final-form-arrays";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import {
    AutocompleteInput,
    Loading,
    NumberInput, ReferenceInput,
    SelectInput, TextInput,
    Title,
    maxLength,
    required,
    useDataProvider,
    useNotify, usePermissions
} from "react-admin";
import { Field, Form, useForm } from "react-final-form";
import palette from "../../framework/theme/palette";
import { impersonatedURL, onFailureMessage } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import { Alert, Snackbar, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    referenceMargin: {
        margin: 0,
    },
    actionToolbar: {
        display: 'flex',
        marginTop: 40,
        justifyContent: 'flex-end'
    },
    gridsmeCard: {
        paddingLeft: 40,
        paddingBottom: 40
    },
    unitTextHeading: {
        fontStyle: "normal",
        color: "#333333",
        fontSize: 24,
        margin: 0,
        marginTop: 31,
        marginLeft: 40,
        marginBottom: 35,
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: "#BDBDBD",
    },
    gridItemPadding: {
        padding: "0px !important",
        marginRight: 30,
        marginBottom: 25,
    },
    smeButton: {
        padding: 0,
        marginLeft: "-10px",
    },
    saveButton: {
        width: 155,
        height: 48,
        backgroundColor: "#ACACAC",
        borderRadius: 8,
        fontSize: 16,
        fontWeight: 500,
        fontStyle: "normal",
        textTransform: "capitalize",
    },
    responsiveTitle: {
        [theme.breakpoints.down('md')]: {
            whiteSpace: "break-spaces",
            fontSize: 19
            // textAlign: 'center !important',
        },
    },
    deleteIcon: {
        color: palette.customsColor.amber,
        '&:hover': {
            color: '#f06548cc'
        }
    },
    title: {
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    }
}));

const SmeRequirementInputs = (props) => {
    const [loading, setLoading] = React.useState();
    const [updateSme, setUpdateSme] = React.useState();
    const [initialValues, setInitialValues] = React.useState();
    const [businessUnits, setBusinessUnits] = React.useState();
    const [smeRequirementsData, setSmeRequirementsData] = React.useState();
    const dataProvider = useDataProvider();

    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const isXS = useMediaQuery((theme) => theme.breakpoints.down("xs"));
    const user = TokenStorage.getUserDetails();
    const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};
    const { loading: loadingPermissions, permissions } = usePermissions();
    // impersonatedURL(isImpersonating, '#/sme_requirements?impersonated');
    impersonatedURL(isImpersonating, '#/sme_requirements?impersonated');

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({ message: name, type: type })
        setSnackbarOpen(true);
    };

    const hanndleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false);
    };
    const [anchorOrigin, setAnchorOrigin] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = React.useState({
        message: "",
        type: ""
    });

    const { vertical, horizontal } = anchorOrigin;
    const getProject = async (id) => {
        return dataProvider
            .getOne("Project", { id })
            .then((response) => response.data);
    };

    const getSmeRequirements = async (projectId, businessUnitId) => {
        setLoading(true);
        return dataProvider
            .getList("SmeRequirement", {
                filter: { projectId, businessUnitId },
                pagination: { page: 1, perPage: 50 },
                sort: { field: "id", order: "ASC" },
            })
            .then((response) => {
                return response.data
            });
    };

    const transformData = (values) => {

        const transformedData = {
            projectId: values.projectId,
        };

        const smeRequirements = [];

        values.smeRequirements.map((groupedSmeRequirements, index) => {
            groupedSmeRequirements.forEach(req => {
                if (req)
                    smeRequirements.push({
                        id: req.id,
                        month: req.month,
                        forecastEmployees: req.forecastEmployees,
                        employees: req.employees,
                        businessUnitId: req.businessUnitId,
                        commentary: req.commentary,
                    })
            })
        })

        transformedData.smeRequirements = smeRequirements;

        return transformedData;
    };
    const transformDataToFormValues = (values) => {
        const transformedData = {
            projectId: values.projectId,
        };

        const smeRequirementsForForm = [];
        const groupedBu = _.groupBy(values.smeRequirements, "businessUnitId")
        _.forOwn(groupedBu, (smeRequirements, businessUnitId) => {
            const modifiedSmes = smeRequirements.map(smeRequirement => ({
                id: smeRequirement.id,
                month: smeRequirement.month,
                forecastEmployees: smeRequirement.forecastEmployees,
                employees: smeRequirement.employees,
                commentary: smeRequirement.commentary,
                businessUnitId: smeRequirement.businessUnitId,
                businessUnit: smeRequirement.businessUnit
            }))

            smeRequirementsForForm[businessUnitId] = modifiedSmes;
        });

        transformedData.smeRequirements = smeRequirementsForForm;

        return transformedData;
    };

    const onSubmit = (values, form) => {
        dataProvider
            .postRequest("SmeRequirement", {
                queryType: updateSme ? "update" : "create",
                data: transformData(values),
            })
            .then((response) => {
                updateSme
                    ? handleSnackbarOpen("SME Requirements updated successfully", "success")
                    : handleSnackbarOpen("SME Requirements added successfully", "success")
                form.initialize(values);
                form.restart();
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error")
            });
    };

    const validate = (values) => {
        // console.log(values)
        const errors = {};
        if (
            values &&
            values.smeRequirements &&
            values.smeRequirements.length === 0
        ) {
            errors.smeRequirements_errors = "please add at least one requirement";
        }
        return errors;
    };

    // React.useEffect(() => {
    const handleProjectChange = (projectId) => {
        getProject(projectId)
            .then((data) => {
                const businessUnits = [];
                businessUnits.push(data.businessUnit)
                if (data.impactedBusinessUnits && data.impactedBusinessUnits.length) {
                    data.impactedBusinessUnits.forEach((bu) => {
                        businessUnits.push(bu)
                    })
                }
                const uniqueBusinessUnits = businessUnits.filter((item, index, self) =>
                    index === self.findIndex((t) => t.id === item.id && t.name === item.name)
                )
                setInitialValues((prev) => {
                    return {
                        ...prev,
                        projectId: data.id,
                        startDate: data.startDate,
                        endDate: data.endDate
                    }
                })
                setBusinessUnits(uniqueBusinessUnits)
                return data;
            });

        getSmeRequirements(projectId)
            .then((smeRequirements) => {
                console.log(smeRequirements);
                const isSmeReqAvailable = smeRequirements && smeRequirements.length > 0 ? true : false;
                const transformedData = isSmeReqAvailable ? transformDataToFormValues({ projectId, smeRequirements }) : [{ startDate: null }];
                setSmeRequirementsData(transformedData)
                setUpdateSme(isSmeReqAvailable);

                setInitialValues((prev) => {
                    return {
                        ...prev,
                        smeRequirements: transformedData.smeRequirements ? transformedData.smeRequirements : [],
                        updatedAt: smeRequirements && smeRequirements.length > 0 && smeRequirements[0]?.updatedAt
                    }
                })

                setLoading(false);

            }).catch(error => {
                console.log(error);
                handleSnackbarOpen("error in getting SME requirements", "error")
            })
    }

    // }, [])

    return (
        <>
            <WithPermission resource="Change Assessment" permissions={permissions} bypassUser={isCustomerAdmin} action={["view", "edit"]} {...props} >
                <Title title="Sme Requirements" className={classes.title} />
                <Form
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators,
                    }}
                    subscription={{ values: true }}
                    validate={validate}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values,
                        form: {
                            mutators: { push, pop },
                        },
                    }) => (
                        <form>
                            <Box display="flex" sx={{ alignItems: isSmall ? "flex-start" : "center", justifyContent: "space-between", flexDirection: isSmall ? "column" : "row" }}>
                                <Box style={{ width: isXS ? "100%" : 483 }}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={8} xs={12}>
                                            <ReferenceInput
                                                fullWidth
                                                variant="outlined"
                                                label="Project"
                                                source="projectId"
                                                reference="Project"
                                                sort={{ field: 'name', order: 'ASC' }}
                                                onChange={(value) => {
                                                    handleProjectChange(value, form);
                                                }}
                                                filter={{ isDraft: false, status: true }}
                                                className={classes.referenceMargin}
                                            >
                                                <AutocompleteInput optionText="name" />
                                            </ReferenceInput>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Typography sx={{ color: "gray", fontSize: "14px" }}>
                                        {initialValues && initialValues.updatedAt && `Updated at ${moment(initialValues.updatedAt).format("hh:mm:ss a, MM/DD/YYYY")} (UTC)`}
                                    </Typography>
                                </Box>
                            </Box>
                            {Boolean(values.projectId && businessUnits && smeRequirementsData) &&
                                (loading ? (
                                    <Loading />
                                ) : (
                                    <Box mt={5}>
                                        {businessUnits.map((businessUnit, index) => (
                                            <Box mb={2}>
                                                <Card>
                                                    <Box p={2}>
                                                        <Box mb={2}>{businessUnit.name}</Box>
                                                        <BusinessUnitRequirements
                                                            index={index}
                                                            push={push}
                                                            businessUnit={businessUnit}
                                                            requirementValues={
                                                                smeRequirementsData.smeRequirements
                                                                    ? smeRequirementsData.smeRequirements[businessUnit.id]
                                                                    : null}
                                                            projectStartDate={values.startDate}
                                                            projectEndDate={values.endDate}
                                                        />
                                                    </Box>
                                                </Card>
                                            </Box>
                                        ))}
                                        <Field name="smeRequirements_errors">
                                            {({ input, meta }) => (
                                                <Box color="red" mb={2}>
                                                    {meta.error && <span>{meta.error}</span>}
                                                </Box>
                                            )}
                                        </Field>
                                        <div className={classes.actionToolbar}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                onClick={handleSubmit}
                                                disabled={submitting || pristine}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Box>
                                ))}
                        </form>
                    )}
                />
                <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                    <Alert

                        onClose={hanndleSnackbar}
                        severity={popupMessage.type}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {popupMessage.message}
                    </Alert>
                </Snackbar>
            </WithPermission>
        </>
    );
};

function getMonthsBetweenDates(startDate, endDate) {
    let months = new Set();

    // Ensure the start date is before the end date
    if (startDate > endDate) {
        return Array.from(months);
    }

    // Initialize the current date to the start date
    let currentDate = new Date(startDate);

    // Loop until the current date is before or equal to the end date
    while (currentDate <= endDate) {
        // Get the current month and year
        let currentMonth = currentDate.getMonth() + 1; // Months are zero-based
        let currentYear = currentDate.getFullYear();

        // Add the current month and year to the Set
        let currentMonthYear = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;
        const dateObj = moment(currentMonthYear + '-01');
        const monthYearString = dateObj.format('MMMM YYYY')
        months.add({ id: currentMonthYear, name: monthYearString });

        // Move to the next month
        currentDate.setMonth(currentDate.getMonth() + 1);
    }
    // console.log(Array.from(months))
    return Array.from(months);
}


function updateMonthsBasedOnRequirements(months, requirements) {
    if (requirements && (requirements.length > 0)) {

        for (const month of months) {
            // Check if there is a requirement for the current month
            const matchingRequirement = requirements.find(req => req && req.month === month.id);

            // Update the disabled property based on the existence of a matching requirement
            month.disabled = matchingRequirement;
        }
    }

    return months;
}

const BusinessUnitRequirements = ({ index, push, businessUnit, requirementValues, projectStartDate, projectEndDate }) => {
    const [requirements, setRequirements] = React.useState();
    const form = useForm();
    const months = getMonthsBetweenDates(new Date(projectStartDate), new Date(projectEndDate));
    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [selectedMonths, setSelectedMonths] = React.useState(updateMonthsBasedOnRequirements(months, requirementValues));

    React.useEffect(() => {
        setRequirements(requirementValues)
    }, [requirementValues])

    const handleMonthChange = () => {
        const changedRequirement = form.getState().values.smeRequirements[businessUnit.id];
        const updatedMonths = updateMonthsBasedOnRequirements(months, changedRequirement)
        setSelectedMonths(updatedMonths)
    }
    const maxDigits = value => {
        // console.log(value)

        return value && value.toString().length > 3
            ? 'Must be 3 digits or less'
            : null;
    };
    return (
        <div>
            {Boolean(requirements && requirements.length) && requirements.map((requirement, i) => (
                Boolean(requirement) && <>
                    <Grid
                        container
                        alignItems="center"
                        spacing={isSmall ? 0 : 2}
                    >
                        <Grid item md={3} style={{ width: "100%" }}>
                            <SelectInput
                                variant="outlined"
                                source={`smeRequirements[${businessUnit.id}][${i}].month`}
                                label="Month"
                                choices={selectedMonths}
                                className={classes.inputMargin}
                                fullWidth
                                validate={[required(), maxLength(255)]}
                                onChange={handleMonthChange}
                            />

                        </Grid>
                        <Grid item md={2} style={{ width: isSmall ? "100%" : "80%" }} >
                            <NumberInput
                                fullWidth
                                variant="outlined"
                                label="Forecast (SME)"
                                source={`smeRequirements[${businessUnit.id}][${i}].forecastEmployees`}
                                validate={[required(), maxDigits]}
                            />
                        </Grid>
                        <Grid item md={2} style={{ width: isSmall ? "100%" : "80%" }} >
                            <NumberInput
                                fullWidth
                                variant="outlined"
                                label="Actual (SME)"
                                source={`smeRequirements[${businessUnit.id}][${i}].employees`}
                                validate={[required(), maxDigits]}
                            />
                        </Grid>
                        <Grid item md={4} style={{ width: isSmall ? "100%" : "80%" }} >
                            <TextInput
                                fullWidth
                                variant="outlined"
                                label="Commentary (max 255 characters)"
                                multiline
                                // resettable
                                source={`smeRequirements[${businessUnit.id}][${i}].commentary`}
                                validate={[maxLength(255)]}
                            />
                        </Grid>
                        <Grid item md={1} xs={12} style={{ width: isSmall ? "100%" : "20%", marginTop: '-18px' }}>
                            {
                                isSmall ? <Box sx={{ justifyContent: "center", display: "flex" }}>
                                    <Button variant="contained"
                                        style={{ backgroundColor: "#f06548cc", color: "#fff", }}
                                        onClick={() => {
                                            const proceed = window.confirm('You are deleting the entry. Are you sure?');
                                            if (proceed) {
                                                requirements[i] = undefined
                                                form.change(`smeRequirements[${requirement.businessUnitId}][${i}]`, undefined)
                                                setRequirements([...requirements])
                                                handleMonthChange()
                                            };
                                        }}
                                    >
                                        Delete SME
                                    </Button>
                                </Box> : <IconButton
                                    color="inherit"
                                    aria-label="remove value"
                                    size="small"
                                    onClick={() => {
                                        const proceed = window.confirm('You are deleting the entry. Are you sure?');
                                        if (proceed) {
                                            requirements[i] = undefined
                                            form.change(`smeRequirements[${requirement.businessUnitId}][${i}]`, undefined)
                                            setRequirements([...requirements])
                                            handleMonthChange()
                                        };
                                    }}
                                >
                                    <DeleteIcon className={classes.deleteIcon} />
                                </IconButton>
                            }

                        </Grid>

                    </Grid>
                    {
                        isSmall && <Box sx={{ marginY: "12px" }} >
                            <Divider />
                        </Box>
                    }
                </>

            ))}
            <Grid item xs={12} >
                <Box alignItems="center" justifyContent="center" display="flex">
                    <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => {
                            push(`smeRequirements[${businessUnit.id}]`, { businessUnit, businessUnitId: businessUnit.id });
                            if (requirements) {
                                requirements.push({ businessUnit, businessUnitId: businessUnit.id });
                                setRequirements([...requirements])
                            }

                            else
                                setRequirements([{ businessUnit, businessUnitId: businessUnit.id }])
                        }}
                        color="primary"
                        variant="text"
                    >
                        Add SME Requirement
                    </Button>
                </Box>

            </Grid>
        </div>
    )

}

export default SmeRequirementInputs;
