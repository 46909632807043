import {
  ASSESSMENT_SECTION_CREATE_MUTATION, ASSESSMENT_SECTION_DELETE_MUTATION, ASSESSMENT_SECTION_LIST_QUERY,
  ASSESSMENT_SECTION_RETRIEVE_QUERY,
  ASSESSMENT_SECTION_UPDATE_MUTATION
} from "./assessmentSection";
import {
  ASSESSMENT_SUB_TYPE_LIST_QUERY,
  ASSESSMENT_SUB_TYPE_RETRIEVE_QUERY
} from "./assessmentSubType";
import {
  ASSESSMENT_TYPE_RETRIEVE_QUERY, ASSESSMENT_TYPE_UPDATE_MUTATION
} from "./assessmentType";
import {
  ASSESSMENT_VALUE_CREATE_MUTATION, ASSESSMENT_VALUE_DELETE_MUTATION, ASSESSMENT_VALUE_LIST_QUERY,
  ASSESSMENT_VALUE_RETRIEVE_QUERY,
  ASSESSMENT_VALUE_UPDATE_MUTATION
} from "./assessmentValue";
import { BUSINESS_READLINESS_CREATE_MUTATION, BUSINESS_READLINESS_MASTER_DATA_QUERY_LIST, BUSINESS_READLINESS_QUERY, BUSINESS_READLINESS_UPDATE_MUTATION } from "./businessReadliness";
import {
  ALL_BUSINESS_UNITS_TREE_RETRIEVE_QUERY,
  ALL_BUSINESS_UNITS_WITH_LEVEL_RETRIEVE_QUERY, BUSINESS_UNIT_CREATE_MUTATION, BUSINESS_UNIT_DELETE_MUTATION, BUSINESS_UNIT_LIST_QUERY,
  BUSINESS_UNIT_RETRIEVE_QUERY,
  BUSINESS_UNIT_UPDATE_MUTATION, EXPORT_BU_QUERY
} from "./businessUnit";
import {
  ASSESSMENT_OVERVIEW_RETRIEVE_QUERY, CHANGE_ASSESSMENT_CREATE_MUTATION, CHANGE_ASSESSMENT_DELETE_MUTATION, CHANGE_ASSESSMENT_LIST_QUERY,
  CHANGE_ASSESSMENT_RETRIEVE_QUERY,
  CHANGE_ASSESSMENT_UPDATE_MUTATION, EXPORT_ALL_PROJECT_CHANGE_STATUS,
  EXPORT_CHANGE_ASSESSMENT_DATA_FOR_PDF, EXPORT_PROJECT_CHANGE_IMPACT_ASSESMENT_REPORT, OVERALL_ASSESSMENT_QUERY
} from "./changeAssessment";
import { CHANGE_ASSESSMENT_MASTERS_BY_PROJECT_TYPE_QUERY, CHANGE_ASSESSMENT_MASTERS_BY_TYPE_UPDATE_MUTATION } from './changeAssessmentDetails';
import { CHANGE_ASSESSMENT_MASTERS_BY_TYPE_LIST_QUERY, CHANGE_ASSESSMENT_MASTER_UPDATE_MUTATION } from "./changeAssessmentMaster";
import { EXPORT_REPORT_PDF } from "./exportPdf";
import {
  FAQ_CREATE_MUTATION, FAQ_DELETE_MUTATION, FAQ_LIST_QUERY, FAQ_UPDATE_BULK_MUTATION
} from "./faq";
import { KPI_CRITERIA_CREATE_MUTATION, KPI_CRITERIA_LIST_QUERY } from "./kpiCriteria";
import { EXPORT_LEARNING_CENTRE_QUERY } from "./learningCentre";
import {
  DRIVER_MASTER_DATA_RETRIEVE_QUERY,
  MASTER_DATA_CREATE_MUTATION, MASTER_DATA_DELETE_MUTATION, MASTER_DATA_DRIVERS_UPDATE_MUTATION, MASTER_DATA_DRIVER_QUERY, MASTER_DATA_LIST_QUERY,
  MASTER_DATA_RETRIEVE_QUERY,
  MASTER_DATA_UPDATE_MUTATION
} from "./masterData";
import {
  MASTER_DATA_VALUES_LIST_QUERY,
  MASTER_DATA_VALUE_RETRIEVE_QUERY
} from "./masterDataValue";
import {
  MILESTONE_CREATE_MUTATION, MILESTONE_DELETE_MUTATION, MILESTONE_LIST_QUERY,
  MILESTONE_UPDATE_MUTATION
} from "./mileStone";
import {
  PERMISSION_CREATE_MUTATION, PERMISSION_DELETE_MUTATION, PERMISSION_LIST_QUERY,
  PERMISSION_RETRIEVE_QUERY,
  PERMISSION_UPDATE_MUTATION
} from "./permission";
import {
  ALL_BUS_HEAT_MAP_QUERY, ALL_PROJECTS_HEAT_MAP_QUERY, EXPORT_CHANGE_IMPACT_QUERY,
  EXPORT_NUM_OF_PROJECTS_QUERY, EXPORT_PROJECT_MILESTONE, EXPORT_PROJECT_QUERY,
  MONTHLY_BU_HEAT_MAP_QUERY,
  POJECT_STEP_COUNTER_MUTATION,
  PROJECT_CREATE_MUTATION, PROJECT_DELETE_MUTATION, PROJECT_FOR_CHANGE_ASSESSMENTS_LIST_QUERY,
  PROJECT_FOR_CHANGE_ASSESSMENTS_RETRIEVE_QUERY, PROJECT_HEAT_MAP_QUERY, PROJECT_LIST_QUERY,
  PROJECT_RETRIEVE_QUERY,
  PROJECT_UPDATE_MUTATION
} from "./project";
import { PROJECT_FORECAST_CREATE_MUTATION, PROJECT_FORECAST_LIST_QUERY } from "./projectForecast";
import {
  BU_WISE_PROJECT_FORECASTS_RETRIEVE_QUERY, PROJECT_COUNT_BY_DRIVER_RETRIEVE_QUERY, PROJECT_DRIVER_WISE_PROJECT_FORECASTS_RETRIEVE_QUERY
} from "./reports";
import { RISK_ASSESSMENT_CREATE_MUTATION, RISK_ASSESSMENT_QUERY } from "./riskAssesment";
import { RISK_ASSESSMENT_MASTERS_BY_TYPE_LIST_QUERY, RISK_ASSESSMENT_MASTER_UPDATE_MUTATION } from "./riskAssessmentMaster";
import { RISK_MATRIX_MASTERS_BY_TYPE_LIST_QUERY, RISK_MATRIX_MASTER_UPDATE_MUTATION } from "./riskMatrixMaster";
import {
  ROLE_CREATE_MUTATION, ROLE_DELETE_MUTATION, ROLE_LIST_QUERY,
  ROLE_RETRIEVE_QUERY,
  ROLE_UPDATE_MUTATION
} from "./role";
import {
  ALL_BU_SME_REQ_RETRIEVE_QUERY, ALL_PROJECT_SME_REQ_RETRIEVE_QUERY, EXPORT_All_BUS_SME_REQ,
  EXPORT_All_PROJECT_SME_REQ, EXPORT_SME_REQUIRED, EXPORT_SME_REQUIRED_REPORT, MONTHLY_SME_REQUIREMENTS_RETRIEVE_QUERY, SME_REQUIREMENT_CREATE_MUTATION, SME_REQUIREMENT_DELETE_MUTATION, SME_REQUIREMENT_LIST_QUERY,
  SME_REQUIREMENT_RETRIEVE_QUERY,
  SME_REQUIREMENT_UPDATE_MUTATION
} from "./smeRequirement";
import { THEME_CUSTOMIZATION_CREATE_MUTATION, THEME_CUSTOMIZATION_DELETE_MUTATION, THEME_CUSTOMIZATION_UPDATE_MUTATION, THEME_CUSTOMIZATION_VALUE_RETRIEVE_QUERY } from "./themeCustomization";
import { EXPORT_TOOLS_AND_TEMPLATE_QUERY } from "./toolsAndTemplate";
import {
  CHANGE_PASSWORD_MUTATION, EXPORT_CLIENT_QUERY, EXPORT_USER_QUERY, LOGIN_MUTATION, SET_NEW_PASSWORD_MUTATION, USER_CREATE_MUTATION, USER_DELETE_MUTATION, USER_LIST_QUERY,
  USER_RETRIEVE_QUERY,
  USER_UPDATE_MUTATION
} from "./user";

export const Query = {
  User: {
    create: {
      query_name: "createUser",
      query: USER_CREATE_MUTATION,
    },
    list: {
      query_name: "allUsers",
      query: USER_LIST_QUERY,
    },
    retrieve: {
      query_name: "user",
      query: USER_RETRIEVE_QUERY,
    },
    update: {
      query_name: "updateUser",
      query: USER_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deleteUser",
      query: USER_DELETE_MUTATION,
    },
  },
  Role: {
    create: {
      query_name: "createRole",
      query: ROLE_CREATE_MUTATION,
    },
    list: {
      query_name: "allRoles",
      query: ROLE_LIST_QUERY,
    },
    retrieve: {
      query_name: "role",
      query: ROLE_RETRIEVE_QUERY,
    },
    update: {
      query_name: "updateRole",
      query: ROLE_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deleteRole",
      query: ROLE_DELETE_MUTATION,
    },
  },
  Permission: {
    create: {
      query_name: "createPermission",
      query: PERMISSION_CREATE_MUTATION,
    },
    list: {
      query_name: "allPermissions",
      query: PERMISSION_LIST_QUERY,
    },
    retrieve: {
      query_name: "permission",
      query: PERMISSION_RETRIEVE_QUERY,
    },
    update: {
      query_name: "updatePermission",
      query: PERMISSION_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deletePermission",
      query: PERMISSION_DELETE_MUTATION,
    },
  },
  BusinessUnit: {
    create: {
      query_name: "createBusinessUnit",
      query: BUSINESS_UNIT_CREATE_MUTATION,
    },
    list: {
      query_name: "allBusinessUnits",
      query: BUSINESS_UNIT_LIST_QUERY,
    },
    retrieve: {
      query_name: "businessUnit",
      query: BUSINESS_UNIT_RETRIEVE_QUERY,
    },
    update: {
      query_name: "updateBusinessUnit",
      query: BUSINESS_UNIT_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deleteBusinessUnit",
      query: BUSINESS_UNIT_DELETE_MUTATION,
    },
  },
  ChangeAssessmentMaster: {
    retrieve: {
      query_name: "getAllChangeAssessmentMastersByType",
      query: CHANGE_ASSESSMENT_MASTERS_BY_TYPE_LIST_QUERY,
    },
    update: {
      query_name: "updateChangesAssessmentMaster",
      query: CHANGE_ASSESSMENT_MASTER_UPDATE_MUTATION,
    }

  },
  ChangeAssessmentDetails: {
    retrieve: {
      query_name: 'getChangeAssessmentDetails',
      query: CHANGE_ASSESSMENT_MASTERS_BY_PROJECT_TYPE_QUERY
    },
    update: {
      query_name: "updateChangeAssessmentDetails",
      query: CHANGE_ASSESSMENT_MASTERS_BY_TYPE_UPDATE_MUTATION
    }
  },
  MasterData: {
    create: {
      query_name: "createMasterData",
      query: MASTER_DATA_CREATE_MUTATION,
    },
    list: {
      query_name: "allMasterData",
      query: MASTER_DATA_LIST_QUERY,
    },
    retrieve: {
      query_name: "masterData",
      query: MASTER_DATA_RETRIEVE_QUERY,
    },
    update: {
      query_name: "updateMasterData",
      query: MASTER_DATA_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deleteMasterData",
      query: MASTER_DATA_DELETE_MUTATION,
    }
  },
  MasterDataDriver: {
    retrieve: {
      query_name: 'masterDataForDriver',
      query: MASTER_DATA_DRIVER_QUERY
    },
    update: {
      query_name: "updateMasterDataForDriver",
      query: MASTER_DATA_DRIVERS_UPDATE_MUTATION,
    },
  },
  ProjecDriverMasterData: {
    retrieve: {
      query_name: 'driverMasterData',
      query: DRIVER_MASTER_DATA_RETRIEVE_QUERY
    },
  },
  MasterDataValue: {
    list: {
      query_name: "allMasterDataValues",
      query: MASTER_DATA_VALUES_LIST_QUERY,
    },
    retrieve: {
      query_name: "masterDataValue",
      query: MASTER_DATA_VALUE_RETRIEVE_QUERY,
    },
  },
  Project: {
    create: {
      query_name: "createProject",
      query: PROJECT_CREATE_MUTATION,
    },
    list: {
      query_name: "allProjects",
      query: PROJECT_LIST_QUERY,
    },
    retrieve: {
      query_name: "project",
      query: PROJECT_RETRIEVE_QUERY,
    },
    update: {
      query_name: "updateProject",
      query: PROJECT_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deleteProject",
      query: PROJECT_DELETE_MUTATION,
    },
    updateStep: {
      query_name: "updateProjectStatusAndStep",
      query: POJECT_STEP_COUNTER_MUTATION
    }
  },
  ProjectForCA: {
    list: {
      query_name: "allProjectsForChangeAssessments",
      query: PROJECT_FOR_CHANGE_ASSESSMENTS_LIST_QUERY,
    },

    retrieve: {
      query_name: "projectChangeAssessment",
      query: PROJECT_FOR_CHANGE_ASSESSMENTS_RETRIEVE_QUERY,
    },
  },
  ProjectHeatMap: {
    retrieve: {
      query_name: "projectHeatMap",
      query: PROJECT_HEAT_MAP_QUERY,
    },
  },
  RiskAssessmentMaster: {
    update: {
      query_name: "updateRiskAssessmentMaster",
      query: RISK_ASSESSMENT_MASTER_UPDATE_MUTATION
    },
    list: {
      query_name: "getRiskAssessmentMasters",
      query: RISK_ASSESSMENT_MASTERS_BY_TYPE_LIST_QUERY
    },
  },
  RiskMatrixMaster: {
    list: {
      query_name: "getRiskMatrixMasters",
      query: RISK_MATRIX_MASTERS_BY_TYPE_LIST_QUERY
    },
    update: {
      query_name: "updateRiskMatrixMaster",
      query: RISK_MATRIX_MASTER_UPDATE_MUTATION
    }
  },
  RiskAssessment: {
    list: {
      query_name: "getRiskAssessmentMasters",
      query: RISK_ASSESSMENT_MASTERS_BY_TYPE_LIST_QUERY
    },
    create: {
      query_name: "createRiskAssessment",
      query: RISK_ASSESSMENT_CREATE_MUTATION
    },
    retrieve: {
      query_name: "getRiskAssessments",
      query: RISK_ASSESSMENT_QUERY
    }
  },
  BusinessReadlinessMaster: {
    list: {
      query_name: "getReadinessGovernanceMasters",
      query: BUSINESS_READLINESS_MASTER_DATA_QUERY_LIST
    },
    update: {
      query_name: "updateReadinessGovernanceMaster",
      query: BUSINESS_READLINESS_UPDATE_MUTATION
    }
  },
  BusinessReadliness: {
    list: {
      query_name: "getReadinessGovernanceMasters",
      query: BUSINESS_READLINESS_MASTER_DATA_QUERY_LIST
    },
    create: {
      query_name: "createReadinessGovernanceAssessments",
      query: BUSINESS_READLINESS_CREATE_MUTATION
    },
    retrieve: {
      query_name: "getReadinessGovernanceAssessments",
      query: BUSINESS_READLINESS_QUERY
    }
  },
  AllProjectsHeatMap: {
    retrieve: {
      query_name: "allProjectsHeatMap",
      query: ALL_PROJECTS_HEAT_MAP_QUERY,
    },
  },
  AllBusHeatMap: {
    retrieve: {
      query_name: "allBuHeatMap",
      query: ALL_BUS_HEAT_MAP_QUERY,
    },
  },
  ChangeAssessment: {
    create: {
      query_name: "createChangeAssessment",
      query: CHANGE_ASSESSMENT_CREATE_MUTATION,
    },
    list: {
      query_name: "allChangeAssessments",
      query: CHANGE_ASSESSMENT_LIST_QUERY,
    },
    retrieve: {
      query_name: "changeAssessment",
      query: CHANGE_ASSESSMENT_RETRIEVE_QUERY,
    },
    update: {
      query_name: "updateChangeAssessment",
      query: CHANGE_ASSESSMENT_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deleteChangeAssessment",
      query: CHANGE_ASSESSMENT_DELETE_MUTATION,
    },
  },
  AssessmentOverview: {
    retrieve: {
      query_name: "assessmentOverview",
      query: ASSESSMENT_OVERVIEW_RETRIEVE_QUERY,
    },
  },
  OverallAssessment: {
    retrieve: {
      query_name: "overallAssessment",
      query: OVERALL_ASSESSMENT_QUERY,
    },
  },
  AssessmentValue: {
    create: {
      query_name: "createAssessmentValue",
      query: ASSESSMENT_VALUE_CREATE_MUTATION,
    },
    list: {
      query_name: "allAssessmentValues",
      query: ASSESSMENT_VALUE_LIST_QUERY,
    },
    retrieve: {
      query_name: "assessmentValue",
      query: ASSESSMENT_VALUE_RETRIEVE_QUERY,
    },
    update: {
      query_name: "updateAssessmentValue",
      query: ASSESSMENT_VALUE_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deleteAssessmentValue",
      query: ASSESSMENT_VALUE_DELETE_MUTATION,
    },
  },
  AssessmentSection: {
    create: {
      query_name: "createAssessmentSection",
      query: ASSESSMENT_SECTION_CREATE_MUTATION,
    },
    list: {
      query_name: "allAssessmentSections",
      query: ASSESSMENT_SECTION_LIST_QUERY,
    },
    retrieve: {
      query_name: "assessmentSection",
      query: ASSESSMENT_SECTION_RETRIEVE_QUERY,
    },
    update: {
      query_name: "updateAssessmentSection",
      query: ASSESSMENT_SECTION_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deleteAssessmentSection",
      query: ASSESSMENT_SECTION_DELETE_MUTATION,
    },
  },
  AssessmentSubType: {
    list: {
      query_name: "allAssessmentSubTypes",
      query: ASSESSMENT_SUB_TYPE_LIST_QUERY,
    },
    retrieve: {
      query_name: "assessmentSubType",
      query: ASSESSMENT_SUB_TYPE_RETRIEVE_QUERY,
    },
  },
  AssessmentType: {
    update: {
      query_name: "updateAssessmentType",
      query: ASSESSMENT_TYPE_UPDATE_MUTATION,
    },
    retrieve: {
      query_name: "assessmentType",
      query: ASSESSMENT_TYPE_RETRIEVE_QUERY,
    },
  },
  KpiCriteria: {
    create: {
      query_name: "createKpiCriterias",
      query: KPI_CRITERIA_CREATE_MUTATION,
    },
    list: {
      query_name: "getKpiCriterias",
      query: KPI_CRITERIA_LIST_QUERY,
    },
  },
  SmeRequirement: {
    create: {
      query_name: "createSmeRequirements",
      query: SME_REQUIREMENT_CREATE_MUTATION,
    },
    list: {
      query_name: "allSmeRequirements",
      query: SME_REQUIREMENT_LIST_QUERY,
    },
    retrieve: {
      query_name: "assessmentSmeRequirement",
      query: SME_REQUIREMENT_RETRIEVE_QUERY,
    },
    update: {
      query_name: "updateSmeRequirements",
      query: SME_REQUIREMENT_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deleteSmeRequirements",
      query: SME_REQUIREMENT_DELETE_MUTATION,
    },
  },
  MonthlySmeRequirements: {
    retrieve: {
      query_name: "monthlySmeRequirements",
      query: MONTHLY_SME_REQUIREMENTS_RETRIEVE_QUERY,
    },
  },
  AllProjectsSmeReq: {
    retrieve: {
      query_name: "allProjectsSmeReq",
      query: ALL_PROJECT_SME_REQ_RETRIEVE_QUERY,
    },
  },
  AllBusSmeReq: {
    retrieve: {
      query_name: "allBusinessUnitsSmeReq",
      query: ALL_BU_SME_REQ_RETRIEVE_QUERY,
    },
  },
  AllBusinessUnitsTree: {
    retrieve: {
      query_name: "allBusinessUnitChildren",
      query: ALL_BUSINESS_UNITS_TREE_RETRIEVE_QUERY,
    },
  },
  ExportAllBusSmeReq: {
    retrieve: {
      query_name: "exportAllBusSmeReq",
      query: EXPORT_All_BUS_SME_REQ,
    },
  },
  ExportAllProjectSmeReq: {
    retrieve: {
      query_name: "exportAllProjectSmeReq",
      query: EXPORT_All_PROJECT_SME_REQ,
    },
  },
  ExportMileStone: {
    retrieve: {
      query_name: "exportMileStone",
      query: EXPORT_PROJECT_MILESTONE,
    },
  },
  ExportChangeImpactAssesmentReport: {
    retrieve: {
      query_name: "exportchangeImpactAssesmentReport",
      query: EXPORT_PROJECT_CHANGE_IMPACT_ASSESMENT_REPORT,
    },
  },
  ExportUsers: {
    retrieve: {
      query_name: "exportUsers",
      query: EXPORT_USER_QUERY,
    },
  },
  ExportClients: {
    retrieve: {
      query_name: "exportClients",
      query: EXPORT_CLIENT_QUERY,
    },
  },
  ExportBUs: {
    retrieve: {
      query_name: "exportBUs",
      query: EXPORT_BU_QUERY,
    },
  },
  ExportProjects: {
    retrieve: {
      query_name: "exportProjects",
      query: EXPORT_PROJECT_QUERY,
    },
  },
  ExportChangeImpactOverview: {
    retrieve: {
      query_name: "exportChangeImpactOverview",
      query: EXPORT_CHANGE_IMPACT_QUERY,
    },
  },
  ExportNumOfProjects: {
    retrieve: {
      query_name: "exportNumOfProjects",
      query: EXPORT_NUM_OF_PROJECTS_QUERY,
    },
  },
  ExportSmeRequired: {
    retrieve: {
      query_name: "exportSmeRequired",
      query: EXPORT_SME_REQUIRED,
    },
  },
  ExportSmeRequiredReport: {
    retrieve: {
      query_name: "exportSmeRequiredReport",
      query: EXPORT_SME_REQUIRED_REPORT,
    },
  },
  BuWiseProjectForecasts: {
    retrieve: {
      query_name: "projectForecastsByBu",
      query: BU_WISE_PROJECT_FORECASTS_RETRIEVE_QUERY,
    },
  },
  AllMonthlyBuHeatMap: {
    retrieve: {
      query_name: "allMonthlyBuHeatMap",
      query: MONTHLY_BU_HEAT_MAP_QUERY,
    },
  },
  ProjectDriverWiseProjectForecasts: {
    retrieve: {
      query_name: "projectForecastsByProjectDriver",
      query: PROJECT_DRIVER_WISE_PROJECT_FORECASTS_RETRIEVE_QUERY,
    },
  },
  ProjectCountByDriver: {
    retrieve: {
      query_name: "projectCountByDriver",
      query: PROJECT_COUNT_BY_DRIVER_RETRIEVE_QUERY,
    },
  },
  login: {
    login: {
      query_name: "login",
      query: LOGIN_MUTATION,
    },
  },
  ChangePassword: {
    changePassword: {
      query_name: "changePassword",
      query: CHANGE_PASSWORD_MUTATION,
    },
  },
  SetNewPassword: {
    setNewPassword: {
      query_name: "setNewPassword",
      query: SET_NEW_PASSWORD_MUTATION,
    },
  },
  Milestone: {
    create: {
      query_name: "createMilestones",
      query: MILESTONE_CREATE_MUTATION,
    },
    list: {
      query_name: "allMilestones",
      query: MILESTONE_LIST_QUERY,
    },
    update: {
      query_name: "updateMilestones",
      query: MILESTONE_UPDATE_MUTATION,
    },
    delete: {
      query_name: "deleteMilestone",
      query: MILESTONE_DELETE_MUTATION,
    },
  },
  Faq: {
    create: {
      query_name: "createFaq",
      query: FAQ_CREATE_MUTATION,
    },
    list: {
      query_name: "allFaqs",
      query: FAQ_LIST_QUERY,
    },
    update: {
      query_name: "createOrUpdateFaqs",
      query: FAQ_UPDATE_BULK_MUTATION,
    },
    delete: {
      query_name: "deleteFaq",
      query: FAQ_DELETE_MUTATION,
    },
  },
  ProjectForecast: {
    list: {
      query_name: "allProjectForecasts",
      query: PROJECT_FORECAST_LIST_QUERY,
    },
    create: {
      query_name: "createProjectForecasts",
      query: PROJECT_FORECAST_CREATE_MUTATION
    }
  },
  ExportAllProjectChangeStatus: {
    retrieve: {
      query_name: "exportAllProjectChangeStatus",
      query: EXPORT_ALL_PROJECT_CHANGE_STATUS,
    },
  },
  ExportChangeAssessmentDataForPDF: {
    retrieve: {
      query_name: "exportChangeAssessmentDataForPDF",
      query: EXPORT_CHANGE_ASSESSMENT_DATA_FOR_PDF,
    },
  },
  AllBusinessUnitsWithLevel: {
    retrieve: {
      query_name: "allBusinessUnitsWithLevel",
      query: ALL_BUSINESS_UNITS_WITH_LEVEL_RETRIEVE_QUERY,
    },
  },
  ExportToolsAndTemplates: {
    retrieve: {
      query_name: "exportToolsAndTemplate",
      query: EXPORT_TOOLS_AND_TEMPLATE_QUERY
    }
  },
  ExportLearningCentre: {
    retrieve: {
      query_name: "exportTrainingCentreGuide",
      query: EXPORT_LEARNING_CENTRE_QUERY
    }
  },
  ThemeCustomize: {
    create: {
      query_name: "createThemeCustomization",
      query: THEME_CUSTOMIZATION_CREATE_MUTATION
    },
    update: {
      query_name: "updateThemeCustomization",
      query: THEME_CUSTOMIZATION_UPDATE_MUTATION
    },
    retrieve: {
      query_name: "getTheme",
      query: THEME_CUSTOMIZATION_VALUE_RETRIEVE_QUERY,
    },
    delete: {
      query_name: "delteThemeCustomization",
      query: THEME_CUSTOMIZATION_DELETE_MUTATION
    }
  },
  ExportReportPdf: {
    retrieve: {
      query_name: "exportPDF",
      query: EXPORT_REPORT_PDF
    }
  }
};
