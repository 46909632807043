import { Typography } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment";
import * as React from "react";
import { Loading, useDataProvider, useNotify, } from "react-admin";
import { Field, Form, useForm } from "react-final-form";
import dataProvider from "../../framework/dataProvider";
import { impersonatedURL, onFailureMessage, startEndDatesOfWeeks } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import { ProjectFormButton } from "./ProjectFormButton";
import ProjectForecastsInputsContainer from "./projectForecastsInputsContainer";
import { Alert, Snackbar } from "@mui/material";
const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    subTitle: {
        marginBottom: 16,
    },
    forecastTitle: {
        backgroundColor: "#F2F2F2",
        display: "flex",
    },
    forecastCard: {
        marginBottom: 30,
        margin: "0 auto",
        width: "90%",
        [theme.breakpoints.down("md")]: {
            width: "100%"
        }
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: "#BDBDBD",
    },
    inputWidthChange: {
        width: 322,
        [theme.breakpoints.down("xs")]: {
            width: '83vw',
        }
    },
    inputWidthMargin: {
        // marginRight: 100,
    },
    referenceInputWidth: {
        width: 350,
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        }
    },
    gridBoxMargin: {
        marginBottom: 12,
        width: 350,
    },
    phaseDateWidth: {
        width: 132,
        [theme.breakpoints.down("xs")]: {
            width: '35vw'
        },
    },
    gridContainer: {
        display: "grid",
        gridColumnGap: 100,
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
        },
        [theme.breakpoints.between("sm", "md")]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
        },
    },
    forecastTopDiv: {
        width: "95%",
    },
    projectNoteText: {
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 400,
        marginBottom: 30,
    },
    tabsComponentDiv: {
        marginTop: 20,
    },
    actionToolbar: {
        display: 'flex',
        marginTop: 40,
        justifyContent: 'flex-end'
    },
}));

const MyTabScrollButton = React.forwardRef((props, ref) => {
    const { direction, ...other } = props;

    return (
        <ButtonBase
            component="div"
            ref={ref}
            style={{
                opacity: other.disabled ? 0 : 1, "&:focus": {
                    backgroundColor: '#0b5bdb',
                },
            }}
            {...other}
        >
            {direction === "left" ? (
                <ArrowBackIosNewIcon sx={{
                    backgroundColor: '#00A0D9', textAlign: 'center', color: '#fff', marginRight: '10px', borderRadius: '50%', fontSize: '30px', padding: '5px', "&:focus": {
                        backgroundColor: '#0b5bdb',
                    },
                    "&:hover": {
                        backgroundColor: '#0486b5',
                    },
                }} />
            ) : (
                <ArrowForwardIosIcon sx={{
                    backgroundColor: '#00A0D9', textAlign: 'center', color: '#fff', marginLeft: '10px', borderRadius: '50%', fontSize: '30px', padding: '5px', "&:focus": {
                        backgroundColor: '#0b5bdb',
                    },
                    "&:hover": {
                        backgroundColor: '#0486b5',
                    },
                }} />
            )}
        </ButtonBase>
    );
});

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        height: 5,
        "& > span": {
            maxWidth: 80,
            width: "100%",
            backgroundColor: "#4F4F4F"
        },
    },
})((props) => <Tabs style={{ width: '85vw' }} {...props} ScrollButtonComponent={MyTabScrollButton} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        color: "#000",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: 60,
        "&:focus": {
            opacity: 1,
        },
        minWidth: 97,
        paddingLeft: 0,
        paddingRight: 0,
    },
}))((props) => <Tab disableRipple {...props} />);


const ForecastToolbar = ({ activeStep, handleBack, handleNext, steps }) => {
    const user = TokenStorage.getUserDetails();
    const { isImpersonating } = user ? user : {};
    const classes = useStyles();
    const [project, setProject] = React.useState();
    const localProjectDetails = TokenStorage.getProjectDetails()
    const [value, setValue] = React.useState(0);
    const [valuedUnits, setvaluedUnits] = React.useState([]);
    const dataProvider = useDataProvider();
    const [initialValues, setInitialValues] = React.useState();
  

    const [anchorOrigin, setAnchorOrigin] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const [popupMessage, setPopupMessage] = React.useState({
        name: "",
        type: ""
    })
    const {vertical, horizontal} = anchorOrigin;
    const getProject = (id) => {
        return dataProvider
            .getOne("Project", { id })
            .then((response) => response.data);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = (name, type) => {
        setPopupMessage({name,type})
        setSnackbarOpen(true);
    };
  
    const hanndleSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
  
      setSnackbarOpen(false);
    };
    
    React.useEffect(() => {
        getProject(localProjectDetails?.id)
            .then((data) => {
                setProject(data);
                setvaluedUnits(data.impactedBusinessUnits)
            })
        dataProvider
            .getList("ProjectForecast", {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: "endDate", order: "ASC" },
                filter: { projectId: localProjectDetails?.id },
            }).then(response => {

                const uniqueBusinessUnitIds = [...new Set(response.data.map(entry => entry.businessUnitId))];

                let forecasts = []

                uniqueBusinessUnitIds.map((id) => {
                    let buBaseForcasts = []
                    response.data.map((item) => {
                        if (id === item.businessUnitId) {
                            buBaseForcasts.push(item)
                        }
                    })
                    return forecasts[id] = buBaseForcasts
                })
                console.log("forecasts", forecasts);
                setInitialValues({ projectForecasts: forecasts, isCreate: !(forecasts.length > 0) })
            })
    }, []);

    const ForecastPanel = (props) => {
        const { children, value, index } = props;
        return <React.Fragment>{value === index && <React.Fragment>{children}</React.Fragment>}</React.Fragment>;
    };
    const handleSteper = async (values) => {
        const stepers = {
            id: localProjectDetails.id,
            isDraft: false,
            stepCount: 0
        }
        dataProvider
            .postRequest('Project', {
                queryType: "updateStep",
                data: stepers,
            })
            .then((response) => {
                // form.restart();
                console.log(response)
                // onClose(false, true);
                // handleNext(response.data)

                TokenStorage.clearProjectData()
                impersonatedURL(isImpersonating, '#/project?impersonated')

                // handleNext(response.data)
                // TokenStorage.clearProjectData()
                // impersonatedURL(isImpersonating, '#/project?impersonated')
                // refresh();
                // createMilestoneView()
            })
            .catch((error) => {
                console.log(error)
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error")
            });
    }
    const onSubmit = (values, form) => {

        const forecasts = values.projectForecasts.flat()
        const projectForecasts = forecasts.map(({ id, ...rest }) => ({ ...rest, clientId: user.id }))
        console.log(projectForecasts)

        dataProvider
            .postRequest("ProjectForecast", {
                queryType: "create",
                data: {
                    projectId: project.id,
                    clientId: user.id,
                    projectForecasts: projectForecasts
                }
            }).then(async (response) => {
                console.log(response)
                await handleSteper(values)
                handleSnackbarOpen("ProjectForecast updated successfully!", "success")
            })
            .catch(error => {
                handleSnackbarOpen(error.message, "warning")
            });
    }

    return (
        <>
            {
                project && <Form

                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validate={(values) => {
                        const errors = {};
                        // const weeksCount = getWeeksCount(moment(values.startDate), moment(values.endDate));
                        const weeksCount = moment(values.endDate).diff(moment(values.startDate), 'week');
                        const impactedBuCount = values.impactedBusinessUnits ? values.impactedBusinessUnits.length : 0;
                        const forecastsValues = values && values.projectForecasts ? values.projectForecasts.flat() : null;
                        if (forecastsValues) {
                            if (forecastsValues.length < (weeksCount * (impactedBuCount))) {
                                errors.projectForecasts = "Please fill all the project forecast details";
                                // console.log(errors)
                            } else {
                                for (let i = 0; i < forecastsValues.length; i++) {
                                    if (
                                        forecastsValues[i] &&
                                        !forecastsValues[i].risk
                                    ) {
                                        errors.projectForecasts =
                                            "Please fill all the project forecast details";
                                        // console.log(errors)
                                        break;
                                    }
                                }
                            }
                        } else {
                            errors.projectForecasts =
                                "Please fill all the project forecast details";
                            // console.log(errors)
                        }
                        // console.log(errors)
                        return errors;
                    }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={classes.tabsComponentDiv} >
                                <StyledTabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="styled tabs example"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    {/* <StyledTab label={`${project.businessUnit.name}`} /> */}
                                    {valuedUnits.length > 0
                                        ? valuedUnits.map((unit) => (
                                            <StyledTab key={unit.id} label={`${unit.name}`}></StyledTab>
                                        ))
                                        : null}
                                </StyledTabs>
                                <Typography className={classes.padding} />
                                {/* <ForecastPanel value={value} index={0}>
                                    <h6 className={classes.projectNoteText}>
                                        Note : Click / tap on the color brick to select the impact. You can
                                        change the severity of any week by selecting color brick of that week.
                                        {
                                            initialValues && initialValues.isCreate && "Please note that the followed bricks will change to same impact which is selected."
                                        }

                                    </h6>
                                    <ProjectForecastsInputs businessUnitId={project.businessUnitId} project={project} />
                                </ForecastPanel> */}
                                {valuedUnits.length > 0
                                    ? valuedUnits.map((unit, index) => (
                                        <ForecastPanel key={unit.id} value={value} index={index}>
                                            <h6 className={classes.projectNoteText}>
                                                Note : Click / tap on the color brick to select the impact. You
                                                can change the severity of any week by selecting color brick of
                                                that week.
                                                {
                                                    initialValues && initialValues.isCreate && "Please note that the followed bricks will change to same impact which is selected."
                                                }
                                            </h6>
                                            <ProjectForecastsInputs businessUnitId={unit.id} project={project} />
                                        </ForecastPanel>
                                    ))
                                    : null}

                            </div>
                            <Field
                                name="projectForecasts"
                                subscription={{ touched: true, error: true }}
                                render={({ meta: { touched, error } }) =>
                                    touched && error ? (
                                        <span style={{ color: "#d32f2f" }}>{error}</span>
                                    ) : null
                                }
                            />
                            <ProjectFormButton activeStep={activeStep} handleSubmit={handleSubmit} handleBack={handleBack} steps={steps} />
                        </form>
                    )}
                />
            }

            <Snackbar open={snackbarOpen} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
                <Alert

                    onClose={hanndleSnackbar}
                    severity={popupMessage.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {popupMessage.name}
                </Alert>
            </Snackbar>
        </>
    );
};

const ProjectForecastsInputs = ({ businessUnitId: bu, project }) => {
    const user = TokenStorage.getUserDetails();
    const form = useForm();
    const formValues = form.getState().values;
    const savedForecasts = formValues && formValues.projectForecasts ? formValues.projectForecasts[bu] : null;
    const [projectForecasts, setProjectForecasts] = React.useState(savedForecasts);
    const isCreate = formValues.isCreate
    const { startDate, endDate, businessUnitId, impactedBusinessUnits } = project
    form.change("startDate", startDate)
    form.change("endDate", endDate)
    form.change("businessUnitId", businessUnitId)
    form.change("impactedBusinessUnits", impactedBusinessUnits)
    const availableForecasts = formValues.projectForecasts ? formValues.projectForecasts[bu] : null;
    const [loading, setLoading] = React.useState();

    React.useEffect(() => {
        setLoading(true);
        if (!availableForecasts || (availableForecasts && availableForecasts.length === 0)) {
            dataProvider
                .getList("ProjectForecast", {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: "endDate", order: "ASC" },
                    filter: { projectId: project.id, businessUnitId: bu },
                })
                .then(({ data }) => {
                    let forecasts = data && data.length ? data : [];
                    let weeks = startEndDatesOfWeeks(moment(startDate), moment(endDate));
                    const forecastsCount = forecasts.length;
                    const weeksCount = weeks.length;
                    const updatedForecasts = forecasts.map(({ id, ...rest }) => ({ ...rest, clientId: user.id }))
                    if (forecastsCount > weeksCount) {
                        forecasts.splice(weeksCount, forecastsCount);
                    } else if (forecastsCount < weeksCount) {
                        for (let i = forecastsCount; i < weeksCount; i++) {
                            updatedForecasts[i] = {
                                ...weeks[i],
                                businessUnitId: bu,
                                projectId: project.id,
                                clientId: user.id
                            }
                        }
                    }
                    setProjectForecasts(updatedForecasts)
                    form.change(`projectForecasts[${bu}]`, updatedForecasts);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error)
                    setProjectForecasts(null)
                    setLoading(false);
                })
        } else {
            setLoading(false);
        }
    }, [bu, project]);

    if (loading) return <Loading />
    if (projectForecasts) return <ProjectForecastsInputsContainer
        isCreate={isCreate}
        projectForecasts={projectForecasts}
        setProjectForecasts={setProjectForecasts}
        businessUnitId={bu}
    />;

    return null;
};

export default ForecastToolbar;