import React from 'react'
import PowerBiReportEmbed from './powerBiReportEmbed'

function EnterpriseHealthSummary() {
    return (
        <PowerBiReportEmbed
            reportId='7bcdd8f0-b7c0-49af-9b4b-8b6c1168f36e'
            resource='Enterprise Report'
            url='enterprise_health_summary'
            title="Enterprise Portfolio Health"
        />
    )
}

export default EnterpriseHealthSummary