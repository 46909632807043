import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    TextInput,
    maxLength,
    required,
    useDataProvider,
    useRefresh,
} from "react-admin";
import { Form } from "react-final-form";
import { onFailureMessage } from "../../utils/AppUtils";
import CustomDrawer from "../customComponents/customDrawer";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        bottom: 0,
        marginBottom: 40,
        // width: 360,
        [theme.breakpoints.down('xs')]: {
            // justifyContent: 'flex-end',
        },
        // padding: 16
    },
    mobileBtn: {
        [theme.breakpoints.down('xs')]: {
            width: "40vw",
        },
    },
    inputMargin: {
        marginTop: 5,
    },
    gridItemPadding: {
        padding: "0 0 14px 8px !important",
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: "#BDBDBD",
    },
    formWidth: {
        maxWidth: 360,
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px',
        },
    }
}));

export const BusinessUnitEditDrawer = ({ record, drawerOpen, onClose,handleSnackbarOpen}) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const [initialValues, setInitialValues] = React.useState();
    const classes = useStyles();

    const resource = "BusinessUnit";
    // console.log("initialvalueget", initialValues);
    const onSubmit = (values, form) => {
        dataProvider
            .update(resource, {
                id: record.id,
                data: values,
            })
            .then((response) => {
                form.initialize({});
                form.restart();
                onClose(false);
                handleSnackbarOpen("Business Unit updated successfully", "success");
                refresh();
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error");
            });
    };

    const validate = () => { };

    React.useEffect(() => {
        if (record && record.id)
            dataProvider.getOne(resource, { id: record.id }).then((response) => {
                setInitialValues(response.data);
            });
    }, [record]);

    return (
        <CustomDrawer
            drawerAnchor="right"
            title="Edit Business Unit"
            drawerOpen={drawerOpen}
            onClose={onClose}
        >
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                    <form className={classes.formWidth} onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Business Unit Name"
                                    source="name"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Location"
                                    source="geography"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            {Boolean(initialValues && initialValues.parentBusinessUnitId) && <Grid item xs={12}>
                                <ReferenceInput
                                    fullWidth
                                    variant="outlined"
                                    label="Parent Business Unit"
                                    source="parentBusinessUnitId"
                                    reference="BusinessUnit"
                                    filter={{
                                        excludeIds: record && record.id ? [record.id] : null,
                                    }}
                                    validate={[required()]}
                                >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </Grid>}
                            <Grid item xs={12}>
                                <NumberInput
                                    fullWidth
                                    variant="outlined"
                                    label="Total FTE"
                                    source="fte"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.toolbar}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.mobileBtn}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        onClick={() => onClose(false)}
                                        className={classes.mobileBtn}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </CustomDrawer>
    );
};

export default BusinessUnitEditDrawer;
