import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";

const useStyles = makeStyles({
    actionToolbar: {
        display: 'flex',
        marginTop: 40,
        justifyContent: 'flex-end'
    },
});
export const ProjectFormButton = ({ activeStep, handleSubmit, handleBack, steps, isDraft }) => {
    const classes = useStyles();
    const form = useForm();
    return (
        <div className={classes.actionToolbar}>
            {activeStep > 0 && (
                <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{ marginRight: 25 }}
                    onClick={handleBack}
                >
                    Previous
                </Button>
            )}
            {!(activeStep === steps.length - 1) && (
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginRight: 25 }}
                    onClick={() => {
                        // setDraftStatus(prev => !prev)
                        form.change('isNext', true)
                        handleSubmit()
                    }}
                >
                    Next
                </Button>
            )}
            {!(activeStep === steps.length - 1) && (isDraft == true) && (
                <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    size="large"
                    style={{ marginRight: 25 }}
                    onClick={() => {
                        // setDraftStatus(prev => !prev)
                        form.change('isDraft', true)
                        handleSubmit()
                    }}
                >
                    Save To Draft
                </Button>
            )}
            {activeStep === steps.length - 1 && (
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                // disabled={submitting}
                >
                    Submit
                </Button>
            )}
        </div>
    )
}
