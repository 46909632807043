import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { handleProjectInfo } from "../../utils/AppUtils";

const styles = (theme) => ({
    buttonDiv: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 40,
    },
    nextButton: {
        marginRight: 42,
    },
    previousButton: {
        marginRight: 42,
    },
});

class Wizard extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };
    static Page = ({ children }) => children;

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues || {},
        };
    }
    next = (values) => {
        this.setState((state) => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values,
        }));
    };

    previous = () =>
        this.setState((state) => ({
            page: Math.max(state.page - 1, 0),
        }));

    toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        window.location.reload(false);
        this.props.onClose(open);
    };

    /**
     * NOTE: Both validate and handleSubmit switching are implemented
     * here because 🏁 Redux Final Form does not accept changes to those
     * functions once the form has been defined.
     */

    validate = (values) => {
        const activePage = React.Children.toArray(this.props.children)[
            this.state.page
        ];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = (values, form) => {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values, form);
        } else {
            this.next(values);
        }
    };

    render() {
        const { classes, setProjectInfo } = this.props;
        const { children, previousBtnEnabled = true } = this.props;
        const { page, values } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;

        handleProjectInfo(values, page, setProjectInfo);

        return (
            <Form
                initialValues={values}
                validate={this.validate}
                onSubmit={this.handleSubmit}
                mutators={this.props.mutators}
            >
                {({ handleSubmit, submitting, values }) => (
                    <form onSubmit={handleSubmit}>
                        {activePage}
                        <div className={classes.buttonDiv}>
                            {previousBtnEnabled && page > 0 && (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    onClick={this.previous}
                                    className={classes.previousButton}
                                >
                                    Previous
                                </Button>
                            )}
                            {!isLastPage && (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.nextButton}
                                >
                                    Next
                                </Button>
                            )}
                            {isLastPage && (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={submitting}
                                    className={classes.nextButton}
                                >
                                    Submit
                                </Button>
                            )}
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                className={classes.cancelButton}
                                onClick={this.toggleDrawer(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                        {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                    </form>
                )}
            </Form>
        );
    }
}

export default withStyles(styles)(Wizard);
