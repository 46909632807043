import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
    AutocompleteArrayInput,
    CheckboxGroupInput,
    ReferenceArrayInput,
    TextInput,
    maxLength,
    required,
    useDataProvider,
} from "react-admin";
import { Form } from "react-final-form";
import { groupPermissions, onFailureMessage } from "../../utils/AppUtils";
import CustomDrawer from "../customComponents/customDrawer";
import { Box, Checkbox, Divider, FormControlLabel } from "@material-ui/core";
import permission from "../permission";
const useStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        bottom: 0,
        marginBottom: 40,
        width: 360
        // padding: 16
    },
    textHeadings: {
        fontWeight: 400,
        fontSize: 16,
        fontStyle: "normal",
        margin: 0,
        color: "#B5B5B5",
    },
    inputMargin: {
        marginTop: 5,
    },
    gridItemPadding: {
        padding: "0 0 14px 8px !important",
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: "#BDBDBD",
    },
    formWidth: {
        maxWidth: 350

    }
});

export const RoleEditDrawer = ({ choicess, record, drawerOpen, onClose,handleSnackbarOpen }) => {
    const dataProvider = useDataProvider();
    const [initialValues, setInitialValues] = React.useState();
    const [permissionParentName, setPermissionParentName] = React.useState({ view: false, edit: false })
    const classes = useStyles();

    const resource = "Role";




    const handlePermissionView = (e) => {
       
        if (e.length !== 0) {
            const oldState = { ...permissionParentName }
            oldState['view'] = true

            setPermissionParentName(oldState)
            // setPermission(truez
        }
        else {
            const oldState = { ...permissionParentName }
            oldState['view'] = false

            setPermissionParentName(oldState)
            // setPermission(true)
        }
    };
    const handlePermissionEdit = (e) => {
      
        if (e.length !== 0) {
            const oldState = { ...permissionParentName }
            oldState['edit'] = true

            setPermissionParentName(oldState)
            // setPermission(false)
        }
        else {
            const oldState = { ...permissionParentName }
            oldState['edit'] = false

            setPermissionParentName(oldState)
            // setPermission(true)
        }
    };
    const handleChangeView = (e, form) => {
        const oldState = { ...permissionParentName }

        oldState['view'] = !oldState.view
        setPermissionParentName(oldState)
       
        const permissionState = Object.keys(form.getState().values && form.getState().values.viewPermissions).length > 0 ? form.getState().values.viewPermissions : [];
        let newPermission = []

        if (permissionState.length > 0) {
            newPermission = []
        }
        else {
            newPermission = choicess.ViewGroup.map(item => item.id)
        }

        form.change('viewPermissions', newPermission)

        
    }
    const handleChangeEdit = (e, form) => {
        const oldState = { ...permissionParentName }

        oldState['edit'] = !oldState.edit
        setPermissionParentName(oldState)
       
        const permissionState = Object.keys(form.getState().values && form.getState().values.editPermissions).length > 0 ? form.getState().values.editPermissions : [];
        let newPermission = []
        if (permissionState.length > 0) {
            newPermission = []
        }
        else {
            newPermission = choicess.EditGroup.map(item => item.id)
        }

        form.change('editPermissions', newPermission)

    }
    const onSubmit = (values, form) => {
        const newValue = [...values.viewPermissions, ...values.editPermissions]
        dataProvider
            .update(resource, {
                id: record.id,
                data: {
                    id: record.id,
                    name: values.name,
                    permissions: newValue
                },
            })
            .then((response) => {
                form.initialize({});
                form.restart();
                onClose(false);
                handleSnackbarOpen("Role updated successfully", "success");
                setPermissionParentName({ view: false, edit: false })
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error");
            });
    };

    const validate = () => { };

    React.useEffect(() => {
        if (record && record.id)
            dataProvider.getOne(resource, { id: record.id }).then((response) => {
               
                const viewsData = groupPermissions(choicess.ViewGroup, response.data.permissions) 
                const editData = groupPermissions(choicess.EditGroup, response.data.permissions) 
                setInitialValues({
                    ...response.data,
                    viewPermissions: response.data.permissions
                        ? viewsData
                        : null,
                    editPermissions: response.data.permissions
                        ? editData
                        : null,
                });
                setPermissionParentName({view: viewsData.length, edit: editData.length})
            });
    }, [record]);

    return (
        <CustomDrawer
            drawerAnchor="right"
            title="Edit Role"
            drawerOpen={drawerOpen}
            onClose={onClose}
        >
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit, form }) => (
                    <form className={classes.formWidth} onSubmit={handleSubmit}>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    label="Role name"
                                    source="name"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <FormControlLabel
                                    control={<Checkbox checked={permissionParentName.view} onChange={(e) => handleChangeView(e, form)} name="checkedA" />}
                                    label="View Projects/Reports"
                                />
                                <CheckboxGroupInput
                                    onChange={(e) => handlePermissionView(e)}
                                    fullWidth
                                    variant="outlined"
                                    label="Permissions"
                                    source="viewPermissions"
                                    // validate={[required()]}
                                    choices={choicess.ViewGroup}
                                    optionText="name"
                                    row={false}
                                    style={{ paddingLeft: '12px' }}
                                />
                                <Box sx={{ padding: '0 0 10px 0' }}>
                                    <Divider />
                                </Box>
                                <FormControlLabel
                                    control={<Checkbox checked={permissionParentName.edit} onChange={(e) => handleChangeEdit(e, form)} name="checkedA" />}
                                    label="Edit Projects/Reports"
                                />
                                <CheckboxGroupInput
                                    onChange={(e) => handlePermissionEdit(e)}
                                    fullWidth
                                    variant="outlined"
                                    label=""
                                    source="editPermissions"
                                    // validate={[required()]}
                                    choices={choicess.EditGroup}
                                    optionText="name"
                                    row={false}
                                    style={{ paddingLeft: '12px' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.toolbar}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        onClick={() => onClose(false)}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </CustomDrawer>
    );
};

export default RoleEditDrawer;
