import PowerBiReportEmbed from './powerBiReportEmbed'

function KpiGoNoGoReport() {
    return (
        <PowerBiReportEmbed
            reportId='527fb5a0-a0ea-40eb-8f6d-b01848f640a3'
            resource='Go/No-Go Criteria'
            url='kpi_go_no_go_report'
        />
    )
}

export default KpiGoNoGoReport