import PBIEmbed from "./pbiEmbed";

export const ChangeMaturityReport = (props) => {
    return (
        <PBIEmbed
            title='Change Maturity Report'
            reportId='4e534fc8-f1bb-4272-a41d-5473d28ea467'
            pageName="db8f03f08a030d350e22"
            resource='Report'
            url='change_maturity_project'
        />
    )
}

export default ChangeMaturityReport;