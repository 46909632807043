import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    TextInput,
    maxLength,
    required,
    useDataProvider,
    useNotify,
    useRefresh,
} from "react-admin";
import { Form, } from "react-final-form";
import { onFailureMessage } from "../../utils/AppUtils";
import CustomDrawer from "../customComponents/customDrawer";
import { Alert, Snackbar } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        bottom: 0,
        marginBottom: 40,
        // width: 360,
        [theme.breakpoints.down('xs')]: {
            // justifyContent: 'flex-end',
        },
        // padding: 16
    },
    inputMargin: {
        marginTop: 5,
    },
    gridItemPadding: {
        padding: "0 0 14px 8px !important",
    },
    inputBorder: {
        borderRadius: 8,
        borderColor: "#BDBDBD",
    },
    formWidth: {
        maxWidth: 360,
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px',
        },
    },
    mobileBtn: {
        [theme.breakpoints.down('xs')]: {
            width: "40vw",
        },
    },
}));

export const BusinessUnitCreateDrawer = ({
    initialValues,
    drawerOpen,
    onClose,
    handleSnackbarOpen
}) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const classes = useStyles();

    //start of enable save/submit button 
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [buNameChange, setBuNameChange] = useState(true);
    const [locationChange, setLocationChange] = useState(true);
    const [parentBu, setParentBu] = useState(true);
    const [totalFte, setTotalFte] = useState(true);

    
    const handleBUName = (e) => {
        const nameField = e.target.value;
        console.log(nameField, 'this is name')
        if (nameField !== '') {
            setBuNameChange(false)
        }
        else {
            setBuNameChange(true)
        }
    };
    const handleLocation = (e) => {
        const locationField = e?.target?.value;
        if (locationField !== '') {
            setLocationChange(false)
        } else {
            setLocationChange(true)
        }
    };
    const handleParentBu = (e) => {
        const parentBuField = e?.target?.value;
        console.log(parentBuField, "this is parent bu");
        if (parentBuField !== '') {
            setParentBu(false)
        } else {
            setParentBu(true)
        }
    };
    const handleTotalFte = (e) => {
        const totalFteField = e?.target?.value;
        if (totalFteField !== '') {
            setTotalFte(false)
        } else {
            setTotalFte(true)
        }
    };


    useEffect(() => {
        if (buNameChange === false && locationChange === false
            && parentBu === false && totalFte === false) {
            setShowSaveButton(false)
        }
        else {
            setShowSaveButton(true)
        }

    }, [buNameChange, locationChange, parentBu, totalFte])
    //end of enable save/submit button 

    const resource = "BusinessUnit";
    const onSubmit = (values, form) => {
        dataProvider
            .create(resource, {
                data: values,
            })
            .then((response) => {
                form.initialize({});
                form.restart();
                onClose(false);
                handleSnackbarOpen("Business Unit added successfully", "success");
                refresh();
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                handleSnackbarOpen(error_messages, "error");
            });
    };

    const validate = () => { };

    return (
        <CustomDrawer
            drawerAnchor="right"
            title="Create Business Unit"
            drawerOpen={drawerOpen}
            onClose={onClose}
        >
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                    <form className={classes.formWidth} onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    // onChange={handleChange("name")}
                                    onChange={(e) => handleBUName(e)}
                                    fullWidth
                                    variant="outlined"
                                    label="Business Unit Name"
                                    source="name"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    // onChange={handleChange("location")}
                                    onChange={(e) => handleLocation(e)}
                                    fullWidth
                                    variant="outlined"
                                    label="Location"
                                    source="geography"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ReferenceInput
                                    onChange={(e) => handleParentBu(e)}
                                    fullWidth
                                    variant="outlined"
                                    label="Parent Business Unit"
                                    source="parentBusinessUnitId"
                                    reference="BusinessUnit"
                                    validate={[required()]}
                                >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12}>
                                <NumberInput
                                    onChange={(e) => handleTotalFte(e)}
                                    fullWidth
                                    variant="outlined"
                                    label="Total FTE"
                                    source="fte"
                                    validate={[required(), maxLength(255)]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.toolbar}>
                                    {!showSaveButton ?
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.mobileBtn}
                                            disabled={false}
                                        >
                                            Save
                                        </Button> :
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.mobileBtn}
                                            disabled={true}
                                        >
                                            Save
                                        </Button>
                                    }


                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        onClick={() => onClose(false)}
                                        className={classes.mobileBtn}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </CustomDrawer>
    );
};

export default BusinessUnitCreateDrawer;
