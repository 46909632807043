import { Box, Button, Card, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { PasswordInput, Title, required, useDataProvider, useNotify, useRefresh } from "react-admin";
import { Form } from "react-final-form";
import { useHistory } from 'react-router-dom';
import { onFailureMessage } from "../../utils/AppUtils";


const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "center",
    },
    title: {
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    }
}));


export const ChangePassword = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const history = useHistory();

    const onSubmit = (values, form) => {
        dataProvider
            .postRequest("ChangePassword", {
                queryType: "changePassword",
                data: { ...values },
            }).then(({ data }) => {
                form.restart();
                notify("Password changed successfully","success");
                // Super admin should land to client list after sign in
                if (data.user.isSuperAdmin) {
                    history.push("/user");
                }
                // other user should land to dashboard after sign in
                else {
                    history.push("/");
                }
                refresh();
            })
            .catch(error => {
                const error_messages = onFailureMessage(error);
                notify(error_messages, "error");
            });
    }

    const validate_confirm_password = (confirmPassword, values) => {
        if (confirmPassword !== values.newPassword)
            return "confirm password is not matching";
    }

    const validate = (values) => {
        const errors = {}

        if (values && values.oldPassword === values.newPassword)
            errors.newPassword = "current and new password are same";

        return errors;
    }

    return (
        <div>
            <Title title="Change Password" className={classes.title}/>
            <Card>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    render={({ handleSubmit }) => {
                        return (
                            <Grid container spacing={2} className={classes.root}>
                                <Grid item md={4}>
                                    <Box display="flex" flexDirection="column" p={2}>
                                        <Box>
                                            <PasswordInput variant="outlined" label="Current Password" source="oldPassword" validate={[required()]} fullWidth />
                                        </Box>
                                        <Box>
                                            <PasswordInput variant="outlined" label="New Password" source="newPassword" validate={[required()]} fullWidth />
                                        </Box>
                                        <Box>
                                            <PasswordInput variant="outlined" label="Confirm Password" source="confirmPassword" validate={[validate_confirm_password, required()]} fullWidth />
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        )
                    }}
                />

            </Card>
        </div>
    )
}

export default ChangePassword;
