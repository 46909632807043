import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { createForm } from "final-form";
import _ from "lodash";
import moment from "moment";
import React from "react";
import {
    AutocompleteInput, Loading,
    ReferenceInput, required, Title, useDataProvider,
    useNotify, usePermissions, useRefresh
} from "react-admin";
import { Form } from "react-final-form";
import { impersonatedURL, onFailureMessage } from "../../utils/AppUtils";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import FormWizard from "../customComponents/formWizard";
import AccordionActuals from "./accordionActuals";
import ChangeManagementResourcing from "./changeManagementResourcing";
import NonAccordionActuals from "./nonAccordionActuals";
import useConstant from "./useConstant";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    mainCard: {
        padding: theme.spacing(2),
    },
    stepperSection: {
        marginTop: 60,
        marginBottom: '6em'
    },
    stepperHeader: {
        background: "none",
        padding: 0
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    inputWidthMargin: {
        marginRight: 40,
    },
    referenceInputWidth: {
        width: 350,
    },
    dateValueText: {
        fontSize: 16,
        fontWeight: 400,
        color: "#B5B5B5",
        margin: 0
    },
    title: {
        textTransform: "uppercase",
        fontSize: "20px" // Adjust the font size as needed
    }
}));

function getStepContent(sectionData, isUpdateAssessment) {
    switch (sectionData.name) {
        case "Overall Change Assessment":
            return (
                <AccordionActuals
                    isUpdateAssessment={isUpdateAssessment}
                    assessmentSectionId={sectionData.id}
                    assessmentTypes={sectionData.assessmentTypes}
                />
            );
        case "Change Environment":
            return (
                <NonAccordionActuals
                    assessmentSectionId={sectionData.id}
                    assessmentTypes={sectionData.assessmentTypes}
                />
            );
        case "Planned Implementation Approach":
            return (
                <NonAccordionActuals
                    assessmentSectionId={sectionData.id}
                    assessmentTypes={sectionData.assessmentTypes}
                />
            );
        case "Change Components":
            return (
                <AccordionActuals
                    isUpdateAssessment={isUpdateAssessment}
                    assessmentSectionId={sectionData.id}
                    assessmentTypes={sectionData.assessmentTypes}
                />
            );
        // case "Change Management Resourcing":
        //     return <ChangeManagementResourcing />;
        default:
            return "Unknown stepIndex";
    }
}

const currentDate = new Date();

export default function HorizontalLabelPositionBelowStepper(props) {
    // const projectId = parseInt(props.match.params.projectId);
    // console.log(projectId);
    const classes = useStyles();
    const [assessmentMasterData, setAssessmentMasterData] = React.useState();
    const [isUpdateAssessment, setIsUpdateAssessment] = React.useState(false);
    const [changeAssessmentData, setChangeAssessmentData] = React.useState();
    const [businessUnitId, setBusinessUnitId] = React.useState();
    const [projectId, setProjectId] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const resource = "ChangeAssessment";
    const { loading: loadingPermissions, permissions } = usePermissions();

    const user = TokenStorage.getUserDetails();
    const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};
    impersonatedURL(isImpersonating, '#/ChangeImpactAssessment?impersonated');

    const topMainForm = useConstant(() =>
        createForm({
            onSubmit: (values) => console.log(values),
            initialValues: {
                projectId,
                businessUnitId: changeAssessmentData
                    ? changeAssessmentData.businessUnitId
                    : businessUnitId,
            },
        })
    );

    const formValuesToData = (values) => {
        let assessmentValues = [];

        // convert flat array to tree structure
        for (let sectionId in values.changeAssessmentValues) {
            for (let typeId in values.changeAssessmentValues[sectionId]) {
                for (let subTypeId in values.changeAssessmentValues[sectionId][
                    typeId
                ]) {
                    const _sectionId = sectionId.replace(/["'_]/g, "");
                    const _typeId = typeId.replace(/["'_]/g, "");
                    const _subTypeId = subTypeId.replace(/["'_]/g, "");

                    assessmentValues.push({
                        assessmentSectionId: parseInt(_sectionId),
                        assessmentTypeId: parseInt(_typeId),
                        assessmentSubTypeId: parseInt(_subTypeId),
                        assessmentValueId: parseInt(
                            values.changeAssessmentValues[sectionId][typeId][subTypeId]
                                .assessmentValueId
                        ),
                    });
                }
            }
        }

        // transform the data according to the API document
        const transformData = {
            projectId: projectId,
            businessUnitId: businessUnitId,
            changeAssessmentValues: assessmentValues,
        };
        return transformData;
    };

    const createChangeAssessment = (formValues, form, onSuccess) => {
        dataProvider
            .create(resource, {
                data: formValues,
            })
            .then((response) => {
                topMainForm.restart();
                form.restart();
                setBusinessUnitId(null);
                onSuccess();
                notify("change assessment added successfully");
                refresh();
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                notify(error_messages, "error");
            });
    };

    const updateChangeAssessment = (formValues, form, onSuccess) => {
        dataProvider
            .update(resource, {
                id: changeAssessmentData.id,
                data: {
                    ...formValues,
                    id: changeAssessmentData.id,
                },
            })
            .then((response) => {
                topMainForm.restart();
                form.restart();
                setIsUpdateAssessment(false);
                setChangeAssessmentData(null);
                setBusinessUnitId(null);
                onSuccess();
                notify("change assessment updated successfully");
                refresh();
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                notify(error_messages, "error");
            });
    };

    const onSubmit = (values, form, onSuccess) => {
        const formValues = formValuesToData(values);
        // update the change assessment details if the details already exists else create it
        if (changeAssessmentData) {
            updateChangeAssessment(formValues, form, onSuccess);
        } else {
            createChangeAssessment(formValues, form, onSuccess);
        }
    };

    const getChangeAssessmentList = async (businessUnitId) => {
        return dataProvider
            .getList("ChangeAssessment", {
                pagination: { page: 1, perPage: 1 },
                sort: { field: "id", order: "DESC" },
                filter: { projectId, businessUnitId },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getChangeAssessment = async (changeAssessmentId) => {
        return dataProvider
            .getOne("ChangeAssessment", { id: changeAssessmentId })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                notify(
                    `Change Assessment details are unavailable: ${error.message}`,
                    "error"
                );
            });
    };
    const handleProjectIdChange = async (project_unit_id) => {
        // setLoading(true);
        setBusinessUnitId(null)
        if (project_unit_id) {
            setProjectId(project_unit_id)
        }
    }
    const handleBUChange = async (business_unit_id) => {
        setLoading(true);
        setBusinessUnitId(business_unit_id);
        const data = await getChangeAssessmentList(business_unit_id);
        if (data && data.length > 0) {
            const changeAssessmentId = data[0].id;
            const changeAssessmentDetails = await getChangeAssessment(
                changeAssessmentId
            );
            if (changeAssessmentDetails) {
                const transformedData = transformDataToFormValues(
                    changeAssessmentDetails
                );
                setIsUpdateAssessment(true);
                setChangeAssessmentData(transformedData);
                setLoading(false);
                return;
            }
        }
        setIsUpdateAssessment(false);
        setChangeAssessmentData(null);
        setLoading(false);
    };

    const transformDataToFormValues = (data) => {
        const transformedData = { ...data };
        const changeAssessmentValues = {};
        for (const changeAssessmentValue of data.changeAssessmentValues) {
            const valueId = changeAssessmentValue.assessmentValueId;
            const subTypeId = changeAssessmentValue.assessmentSubTypeId;
            const typeId = changeAssessmentValue.assessmentTypeId;
            const sectionId = changeAssessmentValue.assessmentSectionId;
            _.defaultsDeep(changeAssessmentValues, {
                [`_${sectionId}`]: {
                    [`_${typeId}`]: { [`_${subTypeId}`]: changeAssessmentValue },
                },
            });
        }

        transformedData.changeAssessmentValues = changeAssessmentValues;

        return transformedData;
    };

    React.useEffect(() => {
        dataProvider
            .getList("AssessmentSection", {
                filter: {},
                pagination: { page: 1, perPage: 10 },
                sort: { field: "id", order: "ASC" },
            })
            .then((response) => {
                setAssessmentMasterData(response.data);
            })
            .catch((error) => {
                const error_messages = onFailureMessage(error);
                notify(error_messages, "error");
            });
    }, []);

    if (!assessmentMasterData) return <Loading />;

    return (
        <WithPermission resource="ChangeImpactAssessment" permissions={permissions} bypassUser={isCustomerAdmin} action={["create", "edit"]} {...props} >
            <Title title="Change Impact Assessment" className={classes.title}/>
            <Form
                form={topMainForm}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Card className={classes.mainCard}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <ReferenceInput
                                        variant="outlined"
                                        label="Project"
                                        source="projectId"
                                        reference="ProjectForCA"
                                        initialValue={projectId}
                                        onChange={handleProjectIdChange}
                                        // disabled={true}
                                        fullWidth
                                    >
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <ReferenceInput
                                        variant="outlined"
                                        label="Business Unit"
                                        source="businessUnitId"
                                        reference="BusinessUnit"
                                        filter={{ projectId: projectId }}
                                        onChange={handleBUChange}
                                        disabled={!projectId}
                                        validate={[required()]}
                                        fullWidth
                                    >
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h6 className={classes.dateValueText}>Original Date</h6>
                                        <h6 className={classes.dateValueText} style={{ marginTop: 8 }}>
                                            {changeAssessmentData ?
                                                moment(changeAssessmentData.createdAt).format(
                                                    "YYYY-MM-DD") : ""}
                                        </h6>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h6 className={classes.dateValueText}>Updated Date</h6>
                                        <h6 className={classes.dateValueText} style={{ marginTop: 8 }}>
                                            {changeAssessmentData ?
                                                moment(changeAssessmentData.updatedAt).format(
                                                    "YYYY-MM-DD") : ""}
                                        </h6>
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </form>
                )}
            />
            {Boolean(businessUnitId) &&
                (loading ? (
                    <Loading />
                ) : (
                    <div className={classes.stepperSection}>
                        <FormWizard
                            onSubmit={onSubmit}
                            initialValues={changeAssessmentData ? changeAssessmentData : null}
                            previousBtnEnabled={true}
                            steps={assessmentMasterData.map((section) => section.name)}
                        >
                            {assessmentMasterData.map((section) => (
                                <div key={section.id}>
                                    <FormWizard.Step>
                                        {getStepContent(section, isUpdateAssessment)}
                                    </FormWizard.Step>
                                </div>
                            ))}
                        </FormWizard>
                    </div>
                ))}
        </WithPermission>
    );
}
