import PowerBiReportEmbed from './powerBiReportEmbed'

function KpiCloseOutReport() {
    return (
        <PowerBiReportEmbed
            reportId='66d6d305-70b0-4cc7-8c49-629fca03db48'
            resource='Close-Out Criteria'
            url='kpi_closeout_report'
        />
    )
}

export default KpiCloseOutReport