import { Box, Typography, useMediaQuery } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import makeStyles from "@material-ui/styles/makeStyles";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import moment from "moment";
import * as React from "react";
import { cloneElement } from "react";
import {
  Datagrid,
  Filter,
  FunctionField,
  List,
  Loading,
  SearchInput,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  useDataProvider,
  useListContext,
  usePermissions,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useHistory } from "react-router-dom";
import { INACTIVE_ACTIVE_CHOICES } from "../../framework/constants";
import {
  currentDate,
  impersonatedURL,
  onFailureMessage,
  saveFileFromBinary,
} from "../../utils/AppUtils";
import ButtonWithPermission from "../../utils/ButtonWithPermission";
import ExportButton from "../../utils/ExportButton";
import { ManageChip } from "../../utils/ManageChip";
import PostPagination from "../../utils/PostPagination";
import { TokenStorage } from "../../utils/TokenStorage";
import WithPermission from "../../utils/WithPermission";
import MilestoneCustomChart from "../customComponents/MilestoneCustomChart";
import MoreActions from "../customComponents/moreActions";
import ProjectCreateDrawer from "./projectCreateDrawer";
import MilestoneCreateDrawer from "./projectCreateMilestone_modified";
import ProjectEditDrawer from "./projectEditDrawer";
import MilestoneEditDrawer from "./projectEditMilestone_modified";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { ThemeContext } from "../../framework/theme/themeContext";
const phaseBgColors = [
  "#629DD1",
  "#297FD5",
  "#4A66AC",
  "#5AA2AE",
  "#7F8FA9",
  "#F15024",
  "#F2D03B",
  "#00B050​",
  "#FF3300​",
  "#FC8722",
  "#8C72AA",
  "#D5BFED",
  "#46D9BF​",
  " #00A0D9​",
  "#03508C​",
];

const useStyles = makeStyles((theme) => ({
  headerCell: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  rowCell: {
    [theme.breakpoints.down("xs")]: {
      borderBottom: "rgba(145, 158, 171, 0.24)",
      paddingLeft: "10px",
    },
  },
  expandIcon: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "15px",
    },
  },
  stylingTitle: {
    "& p": {
      color: theme.palette.grey[600],
    },
    "& div": {
      marginTop: 5,
    },
  },
  title: {
    textTransform: "uppercase",
    fontSize: "20px", // Adjust the font size as needed
  },
}));

const ListActions = (props) => {
  const {
    className,
    exporter,
    isCustomerAdmin,
    isSuperAdmin,
    filters,
    maxResults,
    handleCreateDrawerOpen,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  const dataProvider = useDataProvider();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({ message: name, type: type })
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  const [anchorOrigin, setAnchorOrigin] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = React.useState({
    message: "",
    type: ""
  });

  const { vertical, horizontal } = anchorOrigin;
  const [projects, setProjects] = React.useState();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const { themeObject, setTheme } = React.useContext(ThemeContext)
  React.useEffect(() => {
    dataProvider
      .getList("Project", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "id", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        if (data && data.length) setProjects(data);
      });
  }, [dataProvider]);

  const handleExportBtnClick = () => {
    const projectsData = JSON.stringify(projects);
    dataProvider
      .postRequest("ExportProjects", {
        queryType: "retrieve",
        data: { projectsData },
      })
      .then((response) => {
        saveFileFromBinary(response.data, "Projects" + currentDate);
      })
      .catch((error) => {
        handleSnackbarOpen("No data found to export", "warning")
      });
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}

      <Button
        variant="contained"
        onClick={handleExportBtnClick}
        sx={{
          width: isSmall ? "0px" : "150px",
          marginRight: "8px",
          backgroundColor: themeObject.palette.primary.main,
          boxShadow: "0",
          height: "41px",
          paddingX: "0",
          "&:hover": {
            backgroundColor: themeObject.palette.primary.buttonContrast,
            color: "white",
            cursor: "pointer",
            boxShadow: "0",
          },
        }}
      >
        {isSmall ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0",
              paddingLeft: "2px",
              borderBottomRightRadius: "5px",
              borderTopRightRadius: "5px",
              backgroundColor: "rgba(255,255,255,0.2)",
              fontSize: "16px",
              width: "100%",
              height: "41px",
            }}
          >
            <FileDownloadOutlinedIcon style={{ marginLeft: "-4px" }} />
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              {isSmall ? "" : "Export"}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                paddingLeft: "2px",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
                backgroundColor: "rgba(255,255,255,0.2)",
                fontSize: "16px",
                width: "41px",
                height: "41px",
              }}
            >
              <FileDownloadOutlinedIcon style={{ marginLeft: "-4px" }} />
            </Box>
          </Box>
        )}
      </Button>

      {/* <Button style={{ marginRight: 8 }} variant="contained" style={{backgroundColor: "#405189", height: '40px',padding: "0 16px",width: "176px", margin: '0 10px', color: "#fff"}} onClick={handleExportBtnClick} ><DownloadIcon fontSize='small' />{isSmall ? "" : "Export"}</Button> */}
      <CreateProjectBtn {...props} />
      <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
        <Alert

          onClose={hanndleSnackbar}
          severity={popupMessage.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {popupMessage.message}
        </Alert>
      </Snackbar>
    </TopToolbar>
  );
};

const ProjectFilter = (props) => (
  <Filter {...props}>
    <SearchInput variant="outlined" source="q" alwaysOn />
  </Filter>
);

const CreateProjectBtn = (props) => {
  const history = useHistory();
  const { isCustomerAdmin, permissions, isSuperAdmin, handleCreateDrawerOpen } =
    props;
  const user = TokenStorage.getUserDetails();
  // console.log(props)
  // console.log(user)
  const { isImpersonating } = user ? user : {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const handleClick = () => {
    console.log("console bosan tow");
    TokenStorage.clearProjectData();
    impersonatedURL(isImpersonating, "#/projectCreate?impersonated");
    // history.push('/projectCreate')
  };
  return (
    <ButtonWithPermission
      variant="contained"
      onClick={handleClick}
      bypassUser={isCustomerAdmin}
      action={["create"]}
      resource="Project"
      permissions={permissions}
      endIcon={<AddIcon style={{ marginLeft: "-4px" }} />}
    >
      {isSmall ? "" : "Add Project"}
    </ButtonWithPermission>
  );
};

const Empty = (props) => (
  <Box textAlign="center" m={1} p={1}>
    <Typography variant="h4" paragraph>
      No Projects yet.
    </Typography>
    <Typography variant="body1">Do you want to add one?</Typography>
    <Box mt={1}>
      <CreateProjectBtn {...props} />
    </Box>
  </Box>
);

const ProjectPanel = ({ id, record, resource, isCustomerAdmin, ...props }) => {
  const dataProvider = useDataProvider();
  const [chartData, setChartData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const ref = React.useRef();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [nominated, setNominated] = React.useState();
  const [mileStone, setMileStone] = React.useState();
  const [createMilestoneDrawerOpen, setCreateMilestoneDrawerOpen] =
    React.useState();
  const [editMilestoneDrawerOpen, setEditMilestoneDrawerOpen] =
    React.useState();
  const { permissions } = props;
  const [legends, setLegends] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({ message: name, type: type })
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  const [anchorOrigin, setAnchorOrigin] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = React.useState({
    message: "",
    type: ""
  });

  const { vertical, horizontal } = anchorOrigin;
  const getProjectData = React.useCallback(async () => {
    setLoading(true);

    const milestoneAndProject = await Promise.all([
      dataProvider.getList("Milestone", {
        pagination: { page: 1, perPage: 25 },
        sort: { field: "id", order: "ASC" },
        filter: { projectId: record.id },
      }),
      dataProvider.getOne("Project", { id: record.id }),
    ]);

    const milestones = milestoneAndProject[0].data;
    const project = milestoneAndProject[1].data;

    const milestoneObj = {};

    milestones.map((item, index) => {
      milestoneObj[`Milestone Name ${[index + 1]}`] = item.name;
      milestoneObj[`Milestone Phase ${[index + 1]}`] = item.projectPhase;
      milestoneObj[`Milestone Date ${[index + 1]}`] = new Date(
        item.milestoneDate
      ).toLocaleDateString("en-GB");
    });

    const startDate = new Date(project?.endDate);
    const endDate = new Date(project?.endDate);
    const goLiveDate = new Date(project?.endDate);
    if (project.status === true) {
      project.status = "active";
    } else {
      project.status = "inactive";
    }

    const excelData = {
      "Project Name": project?.name,
      "Business Unit": project?.businessUnit?.name,
      "Project Sponsor": project?.projectSponsor?.fullName,
      "Business Owner": project?.businessOwner?.fullName,
      "Change Lead": project?.changeLeader?.fullName,
      "Stretegic Driver": project?.projectDriver,
      "Start Date": startDate.toLocaleDateString("en-GB"),
      "End Date": endDate.toLocaleDateString("en-GB"),
      "Go Live Date": goLiveDate.toLocaleDateString("en-GB"),
      Status: project.status,
      ...milestoneObj,
    };

    setMileStone(excelData);

    const chart_data = {
      labels: ["milestones"],
      datasets: {},
      startDate: project.startDate,
      endDate: project.endDate,
    };

    const phasesData =
      project && project.projectPhases && project.projectPhases.length
        ? project.projectPhases.map((phase, index) => ({
          label: phase.name,
          data: [[phase.startDate, phase.endDate]],
          backgroundColor: phaseBgColors[index],
          name: phase.name,
          barThickness: 40,
          borderWidth: 2,
        }))
        : null;

    const mileStonePointBackgroundColor = [
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
    ];
    const mileStonePointBorderColor = [
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
    ];

    // -------------------------- Chart end
    const milestonesData =
      milestones && milestones.length
        ? milestones.map((milestone, index) => ({
          label: "",
          data: [milestone.milestoneDate],
          name: milestone.name,
          backgroundColor: "white",
          borderColor: "white",
          pointRadius: 5,
          pointStyle: "circle",
          pointHoverRadius: 10,
          barThickness: 30,
          pointBorderColor: mileStonePointBorderColor[index],
          pointBackgroundColor: mileStonePointBackgroundColor[index],
          legend: {
            display: false,
          },
          options: {},
          plugins: {
            // afterDraw: (chart) => {
            //     const ctx = chart.ctx;
            //     const xAxis = chart.scales.x;
            //     // Add custom labels for start and end dates
            //     const startDateLabel = moment(project.startDate).format('DD/MM/YYYY');
            //     const endDateLabel = moment(project.endDate).format('DD/MM/YYYY');
            //     const startX = xAxis.getPixelForValue(moment(project.startDate).valueOf());
            //     const endX = xAxis.getPixelForValue(moment(project.endDate).valueOf());
            //     // Draw custom labels
            //     ctx.textAlign = 'center';
            //     ctx.fillStyle = 'black';
            //     ctx.fillText(startDateLabel, startX, chart.height - 10);
            //     ctx.fillText(endDateLabel, endX, chart.height - 10);
            //     return ctx
            // }
          },
          type: "scatter",
        }))
        : null;

    chart_data.datasets = { phasesData, milestonesData };
    setChartData(chart_data);
    setLoading(false);
  }, [dataProvider, record.id]);

  const handleCreateMilestoneDrawerOpen = (open, isSubmitted = false) => {
    setCreateMilestoneDrawerOpen(open);
    if (isSubmitted) getProjectData();
  };

  const handleEditMilestoneDrawerOpen = (open, isSubmitted = false) => {
    setEditMilestoneDrawerOpen(open);
    if (isSubmitted) getProjectData();
  };

  React.useEffect(() => {
    if (permissions.permissions) {
      const viewPermission = permissions.permissions.find(
        (element) => element.id == 18 || element.id == 17
      );
      viewPermission && setNominated(true);
    }
  }, [permissions]);

  React.useEffect(() => {
    getProjectData();
  }, [getProjectData]);

  const handleExportMileStone = () => {
    const projectMileStoneData = JSON.stringify([mileStone]);
    dataProvider
      .postRequest("ExportMileStone", {
        queryType: "retrieve",
        data: { projectMileStoneData },
      })
      .then((response) => {
        saveFileFromBinary(response.data, "Milestone");
      })
      .catch((error) => {
        handleSnackbarOpen("No data found to export", "warning")
      });
  };

  if (loading) return <Loading />;

  if (!chartData || (chartData && !chartData.datasets.milestonesData)) {
    return (
      <>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box>
            <Button
              disabled={nominated}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => handleCreateMilestoneDrawerOpen(true)}
              color="primary"
              variant="text"
            >
              Add Milestone
            </Button>
          </Box>
        </Box>
        <MilestoneCreateDrawer
          record={record}
          drawerOpen={createMilestoneDrawerOpen}
          onClose={handleCreateMilestoneDrawerOpen}
        />
        <MilestoneEditDrawer
          record={record}
          drawerOpen={editMilestoneDrawerOpen}
          onClose={handleEditMilestoneDrawerOpen}
        />
        <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
          <Alert

            onClose={hanndleSnackbar}
            severity={popupMessage.type}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {popupMessage.message}
          </Alert>
        </Snackbar>
      </>
    );
  }

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Box
          pl={2}
          pr={isSmall ? "8px" : "30px"}
          height={250}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="flex-end">
            <ExportButton
              exportFile={handleExportMileStone}
              varient="contained"
              width={isSmall ? "50px" : ""}
              btnName={isSmall ? "" : "Export"}
            />
          </Box>
          <Box ref={ref}>
            <MilestoneCustomChart chartData={chartData} />
          </Box>
        </Box>
        <Box>
          {
            <Box
              sx={{
                overflow: "hidden",
                maxHeight: legends ? "500px" : "0",
                transition: legends ? "max-height 1.5s" : "max-height 0.3s",
              }}
            >
              {[...chartData.datasets.phasesData].map((item, index) => (
                // <Box sx={{display: 'flex',flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Box
                  sx={{
                    padding: "5px 15px",
                    margin: "2px 10px",
                    display: "inline-block",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      margin: "0 10px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          item.backgroundColor === "white"
                            ? "#000"
                            : item.backgroundColor,
                        padding: "6px 30px",
                      }}
                    ></Box>

                    <Typography
                      variant="subtitle1"
                      style={{ color: "#000", margin: "0 5px" }}
                    >
                      {item.name}
                    </Typography>
                    {item.type === undefined ? (
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#000" }}
                      >{`${moment(item.data[0][0]).format(
                        "DD-MM-YYYY"
                      )} - ${moment(item.data[0][1]).format(
                        "DD-MM-YYYY"
                      )}`}</Typography>
                    ) : (
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#000" }}
                      >{`${moment(item.data[0]).format(
                        "DD-MM-YYYY"
                      )}`}</Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          }
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Button
            disabled={nominated}
            startIcon={
              !legends ? (
                <AddCircleOutlineIcon />
              ) : (
                <RemoveCircleOutlineOutlinedIcon />
              )
            }
            onClick={() => setLegends(!legends)}
            color="primary"
            variant="text"
          >
            {!legends ? "Show Legends" : "Hide Legends"}
          </Button>

          {/* <Button
                    disabled={nominated}
                    startIcon={<ModeOutlinedIcon />}
                    onClick={() => handleEditMilestoneDrawerOpen(true)}
                    color="primary"
                    variant="text"
                >
                    Add/Edit Milestone
                </Button> */}
        </Box>
      </Box>
      <MilestoneCreateDrawer
        record={record}
        drawerOpen={createMilestoneDrawerOpen}
        onClose={handleCreateMilestoneDrawerOpen}
      />
      <MilestoneEditDrawer
        record={record}
        drawerOpen={editMilestoneDrawerOpen}
        onClose={handleEditMilestoneDrawerOpen}
      />
    </>
  );
};
const CustomStatusField = ({ record }) => (
  <span>
    {record.isDraft ? (
      <ManageChip inactiveChip={true} record={INACTIVE_ACTIVE_CHOICES[2]} />
    ) : record.status ? (
      <ManageChip inactiveChip={true} record={INACTIVE_ACTIVE_CHOICES[1]} />
    ) : (
      <ManageChip inactiveChip={true} record={INACTIVE_ACTIVE_CHOICES[0]} />
    )}
  </span>
  // <ManageChip inactiveChip={true} record={{ id: false, name: "Draft" }}/>
);
export const ProjectList = (props) => {
  const [createDrawerOpen, setCreateDrawerOpen] = React.useState();
  const [editDrawerOpen, setEditDrawerOpen] = React.useState();
  const [record, setRecord] = React.useState();
  const dataProvider = useDataProvider();

  const refresh = useRefresh();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { loading: loadingPermissions, permissions } = usePermissions();
  const user = TokenStorage.getUserDetails();
  const { isSuperAdmin, isCustomerAdmin, isImpersonating } = user ? user : {};

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarOpen = (name, type) => {
    setPopupMessage({ message: name, type: type })
    setSnackbarOpen(true);
  };

  const hanndleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false);
  };
  const [anchorOrigin, setAnchorOrigin] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const [popupMessage, setPopupMessage] = React.useState({
    message: "",
    type: ""
  });

  const { vertical, horizontal } = anchorOrigin;
  const handleCreateDrawerOpen = (open) => {
    setCreateDrawerOpen(open);
  };

  const handleCreateDrawerClose = () => {
    setCreateDrawerOpen(false);
  };

  const handleEditDrawerOpen = (open) => {
    setEditDrawerOpen(open);
  };
  const handleClick = (record) => {
    TokenStorage.storeProjectDetails(JSON.stringify(record));
    impersonatedURL(isImpersonating, "#/projectCreate?impersonated");
    // history.push('/projectCreate')
  };
  const handleEditDrawerClose = (open) => {
    setRecord(null);
    setEditDrawerOpen(open);
  };

  const rowActionsChoices = [
    { id: "edit", name: "Edit", resource: "Project", action: ["edit"] },
    { id: "delete", name: "Delete", resource: "Project", action: ["delete"] },
  ];
  const handleEditProject = (records) => {
    TokenStorage.storeProjectDetails(JSON.stringify(records));
    impersonatedURL(isImpersonating, "#/projectCreate?impersonated");
  };
  const handleDeleteProject = () => {
    const proceed = window.confirm("You are deleting the entry. Are you sure?");
    if (proceed) {
      dataProvider
        .delete("Project", { id: record.id })
        .then((response) => {
          handleSnackbarOpen("Project deleted successfully", "success");
          refresh();
        })
        .catch((error) => {
          const error_messages = onFailureMessage(error);
          handleSnackbarOpen(error_messages, "error")
        });
    }
  };
  const ActionButtonSmallScreen = () => {
    const records = useRecordContext();
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          aria-label="edit"
          className={classes.IconButtonOverride}
          size="small"
        >
          <EditIcon
            size="small"
            style={{
              color: "#00A0D9",
              cursor: "pointer",
              margin: "0 5px",
              fontSize: "20px",
            }}
            onClick={() => handleEditProject(records)}
          />
        </IconButton>
        <IconButton
          aria-label="delete"
          className={classes.IconButtonOverride}
          size="small"
        >
          <DeleteForeverIcon
            size="small"
            style={{
              color: "#de0937",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={handleDeleteProject}
          />
        </IconButton>
      </Box>
    );
  };


  const handleMoreActionsClick = ({ action, record }) => {
    switch (action) {
      case "edit":
        // setRecord(record);
        handleClick(record);
        // handleEditDrawerOpen(true);
        break;
      case "delete":
        const proceed = window.confirm(
          "You are deleting the entry. Are you sure?"
        );
        if (proceed) {
          dataProvider
            .delete("Project", { id: record.id })
            .then((response) => {
              handleSnackbarOpen("Project deleted successfully", "success");
              refresh();
            })
            .catch((error) => {
              const error_messages = onFailureMessage(error);
              handleSnackbarOpen(error_messages, "error")
            });
        }
        break;
    }
  };
  const classes = useStyles();

  impersonatedURL(isImpersonating, "#/project?impersonated");
  const postRowStyle = (record, index) => ({
    backgroundColor: index == 0 ? "#efe" : "#000",
  });
  return (
    <WithPermission
      resource="Project"
      bypassUser={isCustomerAdmin}
      permissions={permissions}
      action={["view"]}
      {...props}
    >
      <List
        title={<span style={{ textTransform: "uppercase" }}>Projects</span>}
        hasCreate={false}
        empty={
          <Empty
            isCustomerAdmin={isCustomerAdmin}
            isSuperAdmin={isSuperAdmin}
            permissions={props.permissions}
            handleCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
        actions={
          <ListActions
            isCustomerAdmin={isCustomerAdmin}
            isSuperAdmin={isSuperAdmin}
            permissions={props.permissions}
            handleCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
        filters={<ProjectFilter />}
        bulkActionButtons={false}
        pagination={<PostPagination />}
        {...props}
      >
        {isSmall ? (
          <Datagrid
            classes={{
              headerCell: classes.headerCell,
              rowCell: classes.rowCell,
              expandIcon: classes.expandIcon,
            }}
          >
            <Box
              mb={1}
              className={classes.stylingTitle}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box>

                <TextField label="Project Name" source="name" sortable={true} />
              </Box>

              <Box>

                <TextField
                  label="Strategic Driver"
                  source="projectDriver"
                  sortable={false}
                />
              </Box>
              <Box>

                <TextField
                  label="Business Owner"
                  source="businessOwner.fullName"
                  sortable={false}
                />
              </Box>
              <ActionButtonSmallScreen />
            </Box>

          </Datagrid>
        ) : (
          <Datagrid
            expand={
              <ProjectPanel
                isCustomerAdmin={isCustomerAdmin}
                permissions={props.permissions}
              />
            }
            hover={true}
            title="Expand Milestone"

            sx={{
              "& .RaDatagrid-expandedPanel": {
                backgroundColor: "#000",
              },
            }}
          >
            <TextField label="Project Name" source="name" sortable={true} />
            <TextField
              label="Project Sponsor"
              source="projectSponsor.fullName"
              sortable={false}
            />
            <TextField
              label="Project Driver"
              source="projectDriver"
              sortable={false}
            />
            <TextField
              label="Business Owner"
              source="businessOwner.fullName"
              sortable={false}
            />

            <FunctionField
              label="Status"
              render={(record) => <CustomStatusField record={record} />}
            />
            <MoreActions
              record={record}
              choices={rowActionsChoices}
              bypassUser={isCustomerAdmin}
              permissions={props.permissions}
              handleClose={() => console.log("closing")}
              handleClick={handleMoreActionsClick}
              sortable={false}
            />
          </Datagrid>
        )}
      </List>
      <ProjectCreateDrawer
        record={record}
        drawerOpen={createDrawerOpen}
        onClose={handleCreateDrawerClose}
      />
      <ProjectEditDrawer
        record={record}
        drawerOpen={editDrawerOpen}
        onClose={handleEditDrawerClose}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={1500} anchorOrigin={{ vertical, horizontal }} onClose={hanndleSnackbar}>
        <Alert

          onClose={hanndleSnackbar}
          severity={popupMessage.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {popupMessage.message}
        </Alert>
      </Snackbar>
    </WithPermission>
  );
};

export default ProjectList;
