/* eslint-disable import/no-anonymous-default-export */
import { RouteWithoutLayout } from 'react-admin';
import { Route } from 'react-router-dom';
import ChangeAssessment from "../views/changeAssessment";
import AnalysisOverview from "../views/changeAssessment/analysisOverview";
import ChangeHeatMapBuWise from "../views/changeHeatMap/allBusHeatMap";
import ChangeHeatMap from "../views/changeHeatMap/allProjectsHeatMap_modified";
import Faqs from "../views/faq";
import { GovernanceAssessments } from '../views/impactAssesment/GovernanceAssessments';
import { RiskManagement } from '../views/impactAssesment/RiskManagement';
import { BusinessReadliness } from '../views/impactAssesment/businessReadliness';
import ForgotPassword from "../views/login/forgotPassword";
import MasterData from '../views/masterData';
import ChangeImpactAssessmentReport from '../views/powerbiReports/changeImpactAssessmentReports';
import KpiCriteria from '../views/project/kpiCriteria';
import { ProjectCreate } from '../views/project/projectCreate';
import SmeRequirementInputs from '../views/project/smeRequirementInputs';
import ChangeStatusReport from "../views/reports/changeStatusReport";
// import SmeRequirementReport from "../views/smeRequirement/smeRequirementReport";
import ToolsAndTemplates from '../views/toolsAndTemplates';
import ChangePassword from "../views/user/changePassword";
import { KPI_CRITERIA_TYPE } from './constants';
import RiskAssessmentReport from '../views/powerbiReports/riskAssessmentReport';
import BusinessReadinessReport from '../views/powerbiReports/businessReadinessReport';
import ChangeGovernanceReport from '../views/powerbiReports/changeGovernanceReport';
import KpiCloseOutReport from '../views/powerbiReports/kpiCloseOutReport';
import KpiGoNoGoReport from '../views/powerbiReports/kpiGoNoGoReport';
import SmeRequirementReport from '../views/powerbiReports/smeRequirementReport';
import EnterpriseHealthSummary from '../views/powerbiReports/enterpriseHealthSummary';
import EnterpriseReport from '../views/powerbiReports/enterpriseReport';
import ThemeCustomization from '../views/themeCustomization';
import LearningCentre from '../views/learningCentre';
import CIAReport from '../views/projectReports/ciaReport';
import ChangeEnvironmentReport from '../views/projectReports/changeEnvironmentReport';
import ChangeSaturationReport from '../views/projectReports/changeSaturation';
import ChangeMaturityReport from '../views/projectReports/changeMaturity';
import ChangeHeatmapReport from '../views/projectReports/changeHeatmap';
import SMEReport from '../views/portfolioReport/smeReport';
import RiskManagementReport from '../views/portfolioReport/riskManagementReport';
import ChangeImpactReport from '../views/portfolioReport/changeImpactReport';
import ChangeEnvironmentPortfolioReport from '../views/portfolioReport/changeEnvironment';
import ChangeHeatmapPortfolioReport from '../views/portfolioReport/changeHeatmapReport';
import GoNoGoReport from '../views/projectReports/goNoGo';
import CRAProjectReport from '../views/projectReports/changeRiskAssessmentReport';
import BusinessReadinessProjectReport from '../views/projectReports/businessReadiness';
import ChangeGovernanceProjectReport from '../views/projectReports/changeGovernance';
import SMEReqProjectReport from '../views/projectReports/smeReqProject';
import CloseOutReport from '../views/projectReports/closeOut';
import ViewLearningContent from '../views/learningCentre/contentPage';
import Dashboard from './dashboard';
export default [
    <Route exact path="/MasterData" component={MasterData} />,
    // <Route exact path="/ChangeAssessment/:projectId" component={ChangeAssessment} />,
    <Route exact path="/ChangeImpactAssessment" component={ChangeAssessment} />,

    <Route exact path="/risk_assessment" component={RiskManagement} />,
    <Route exact path="/readiness" component={BusinessReadliness} />,
    <Route exact path="/governance" component={GovernanceAssessments} />,
    <Route exact path="/sme_requirements" component={SmeRequirementInputs} />,
    <Route exact path="/go_no_go_criteria">
        <KpiCriteria type={KPI_CRITERIA_TYPE.GONOGO} />
    </Route>,
    <Route exact path="/close_out_criteria">
        <KpiCriteria type={KPI_CRITERIA_TYPE.CLOSEOUT} />
    </Route>,
    <Route exact path="/analysis_overview" component={AnalysisOverview} />,
    <Route exact path="/dashboard" component={Dashboard} />,
    // <Route exact path="/sme_requirements" component={SmeRequirement} />,
    // <Route exact path="/sme_requirements_report" component={SmeRequirementReport} />,
    // <Route exact path="/change_heat_map" component={ChangeHeatMap} />,
    // <Route exact path="/change_heat_map_bu_wise" component={ChangeHeatMapBuWise} />,
    <Route exact path="/change_password" component={ChangePassword} />,
    <Route exact path="/faq" component={Faqs} />,
    <Route exact path="/change_status_report" component={ChangeStatusReport} />,
    <Route exact path="/cat_templates" component={ToolsAndTemplates} />,
    <Route exact path="/powerbireports" component={ChangeImpactAssessmentReport} />,
    <Route exact path="/projectCreate" component={ProjectCreate} />,
    <Route exact path="/change_risk_report" component={RiskAssessmentReport} />,
    <Route exact path="/change_business_readiness_report" component={BusinessReadinessReport} />,
    // <Route exact path="/change_governance_report" component={ChangeGovernanceReport} />,
    <Route exact path="/kpi_closeout_report" component={KpiCloseOutReport} />,
    <Route exact path="/kpi_go_no_go_report" component={KpiGoNoGoReport} />,
    <Route exact path="/enterprise_health_summary" component={EnterpriseHealthSummary} />,
    <Route exact path="/enterprise_report" component={EnterpriseReport} />,
    <Route exact path="/theme_customization" component={ThemeCustomization} />,
    <Route exact path="/learning_centre" component={LearningCentre} />,
    <Route exact path="/change_impact_project" component={CIAReport} />,
    <Route exact path="/change_environment_project" component={ChangeEnvironmentReport} />,
    <Route exact path="/change_saturation_project" component={ChangeSaturationReport} />,
    <Route exact path="/change_maturity_project" component={ChangeMaturityReport} />,
    <Route exact path="/change_heatmap_project" component={ChangeHeatmapReport} />,
    <Route exact path="/sme_requirement_portfolio" component={SMEReport} />,
    <Route exact path="/risk_management_portfolio" component={RiskManagementReport} />,
    <Route exact path="/change_impact_portfolio" component={ChangeImpactReport} />,
    <Route exact path="/change_environment_portfolio" component={ChangeEnvironmentPortfolioReport} />,
    <Route exact path="/change_heatmap_portfolio" component={ChangeHeatmapPortfolioReport} />,
    <Route exact path="/change_risk_assessment_report" component={CRAProjectReport} />,
    <Route exact path="/business_readiness_report" component={BusinessReadinessProjectReport} />,
    <Route exact path="/change_governance_report" component={ChangeGovernanceProjectReport} />,
    <Route exact path="/sme_requirement_project_report" component={SMEReqProjectReport} />,
    <Route exact path="/go_no_go_report" component={GoNoGoReport} />,
    <Route exact path="/close_out_report" component={CloseOutReport} />,
    <Route exact path="/learning_centre/:slug" component={ViewLearningContent} />,
    <RouteWithoutLayout exact path="/forgot_password" component={ForgotPassword} />,
];